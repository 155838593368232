


import Footer from "../../../components/ru/FooterRu";
import React, { useEffect, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { Link } from 'react-router-dom';
import lightGallery from 'lightgallery';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import lgFullscreen from 'lightgallery/plugins/fullscreen';
import lgVideo from 'lightgallery/plugins/video';
import lgAutoplay from 'lightgallery/plugins/autoplay';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/scss/lightgallery.scss';
import 'lightgallery/scss/lg-zoom.scss';
import WOW from 'wowjs';


function Video() {
    const [showPopup, setShowPopup] = useState(false);
    const [popupImage, setPopupImage] = useState('');
    const [popupLink, setPopupLink] = useState('');

    useEffect(() => {
        const wow = new WOW.WOW();
        wow.init();
    }, []);

    useEffect(() => {
        const lightGalleryInit = () => {
            const gallery = document.getElementById('video-gallery');

            if (gallery) {
                lightGallery(gallery, {
                    plugins: [lgVideo, lgThumbnail, lgFullscreen, lgAutoplay, lgZoom],
                    videojs: false,
                    autoplayFirstVideo: false,
                });
            }
        };

        lightGalleryInit();
    }, []);

    const handleVideoOpen = () => {
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        setPopupImage(isMobile ? '/assets/img/banner/sochi-latino.png' : '/assets/img/banner/sochi-latino.png');
        setPopupLink('/events-dance'); // Cambiar por el enlace deseado
        setShowPopup(true);
    };

    const closePopup = () => {
        setShowPopup(false);
    };

    const startRange = 5677;
    const endRange = 5762;

    const videos = [];

    for (let i = startRange; i <= endRange; i++) {
        const videoSrc = `/assets/video/open/gorky/23-07-24/VID_${i}.mp4`;
        const videoPoster = `/assets/video/open/gorky/23-07-24/covers/IMG_${i}.jpg`;
        const videoDescription = `VIDEO_${i} | Видео можно скачать бесплатно благодаря нашим партнерам и донорам. Если вы хотите оригинальный видеофайл, напишите нам в Телеграм`;

        videos.push({
            src: videoSrc,
            poster: videoPoster,
            description: videoDescription,
        });
    }

    const generateSubHtml = (video) => {
        const downloadSrc = video.src;
        const description = video.description;
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
        const downloadButton = isIOS ? (
            <a href={downloadSrc} target="_blank" className="download-btn">Скачать</a>
        ) : (
            <a href={downloadSrc} download className="download-btn">Скачать</a>
        );

        const additionalButton = (
            <a href="/support" className="donate-btn">ПОДДЕРЖАТЬ</a>
        );

        const html = (
            <div>
                <div style={{ justifyContent: 'space-between' }}>
                    {downloadButton}
                    {additionalButton}
                </div>
                <h4>{description}</h4>
            </div>
        );
        return renderToString(html);
    };

    return (
        <main>
            <section
                className="hero-wrap hero-wrap-2-fox"
                style={{ backgroundImage: 'url("/assets/video/open/gorky/23-07-24/covers/IMG_240723.JPG")' }}
            >
                <div className="overlay" />
                <div className="container">
                    <div className="row no-gutters slider-text align-items-center justify-content-center">
                        <div className="col-md-9 ftco-animate text-center fadeInUp ftco-animated">
                            <h1 className="mb-2 bread"> Open Air 23.07.24 </h1>
                            <p className="breadcrumbs">
                                <span className="mr-2">
                                    <Link to="/gallery">Галерея<i className="ion-ios-arrow-forward" /></Link>
                                </span>{" "}
                                <span>
                                    <Link to="/video-events">Все Видео<i className="ion-ios-arrow-forward" /></Link>
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            {showPopup && (
                <div className="popup-overlay" onClick={closePopup}>
                    <div className="popup-content">
                        <Link to={popupLink} target="_blank"> 
                            <img src={popupImage} alt="Popup" />
                        </Link>
                        <button onClick={closePopup}>Close</button>
                    </div>
                </div>
            )}

            <div className="site-wrap front-videos">
                <div className="container">
                    <div className="row" id="video-gallery">
                        {videos.map((video, index) => (
                            <div key={index} className="col-4 col-sm-3 col-md-3 col-lg-2 col-xl-2 item"
                                onClick={() => handleVideoOpen(video.src)}
                                data-video={JSON.stringify({ source: [{ src: video.src, type: "video/mp4" }], attributes: { preload: false, controls: true } })}
                                data-poster=""
                                data-sub-html={generateSubHtml(video)} >
                                <a>
                                    <img
                                        className="img-responsive"
                                        src={video.poster}
                                    />
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <Footer />
        </main>
    );
}

export default Video;








