


/* Elesrning*/

import Footer from "./FooterEs";
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import React, { useEffect, useState } from 'react';
import WOW from 'wowjs';


function Support() {

    useEffect(() => {
        const wow = new WOW.WOW();
        wow.init();
    }, []);

   


    return (

        <main>


            <section className="hero-section-o set-bg-o" style={{ backgroundImage: 'url("./assets/img/fondo/grey-blue-green-liquid.jpg")' }}>

                <div className="container">
                    <div className="hero-text text-white">
                        <h2>Apoya nuestro proyecto</h2>
                        <p>
                            <h3>¡Únete a nuestra misión de celebrar la vida y el baile! </h3>
                        </p>
                        <p>

                            En LATINOXCLUB, nos dedicamos a llevar alegría y diversión a través de la información, la promoción de eventos, la grabación de eventos latinos, la colaboración con diversas escuelas de baile, entre otras actividades.
                            <br /> Todo esto lo hacemos con un solo objetivo: crear momentos inolvidables y fortalecer la comunidad rusa y latina a través de la música y la cultura del baile.
                            <br />
                            Pero mantener esta pasión viva no es fácil, y es aquí donde necesitamos tu apoyo. Cada donación, nos ayuda a continuar brindando estos servicios de forma accesible, que tanto disfrutan nuestra comunidad.
                            <br />
                            <br />
                            <h3> Tu contribución nos permitirá: </h3> <br /><br />
                            <h3>
                                1. Construir una mejor plataforma para la comunidad:
                            </h3>
                            Con tu apoyo, podemos construir una gran plataforma - sitio web con diferentes servicios de libre acceso a nuestra comunidad.
                            <h3>2. Capturar momentos inolvidables: </h3> Tus donaciones nos ayudarán a mejorar nuestro equipo de grabación para capturar los mejores momentos en vídeo y compartirlos con todos.
                            <h3>3. Promocionar más eventos:  </h3> Con tu apoyo, podemos promocionar más fiestas y eventos, brindando a todos la oportunidad de disfrutar, aprender y socializar.
                            <h3>4. Fomentar la comunidad:  </h3> Cada evento y cada baile fortalecen los lazos de nuestra comunidad, creando un espacio donde todos pueden sentirse incluidos y bienvenidos.
                            <h3>5. Brindar beneficios especiales a nuestros donantes y miembros del club:  </h3> Nuestros miembros y socios tienen beneficios especiales en nuestra comunidad latina. <br /><br /><br />
                            </p>

                        <p>

                            Donar es fácil y cualquier monto es bienvenido. Con tu apoyo, podemos seguir llevando sonrisas, creando recuerdos y compartiendo la pasión por el baile con todos.
                            </p>
                        <p>

                            ¡Haz tu donación hoy y conviértete en parte de nuestra comunidad, únete a nuestro club, únete a LATINOXCLUB!
                            <br />
                            Juntos, haremos que cada paso de baile y cada fiesta sean experiencias inolvidables. ¡Gracias por tu generosidad y apoyo!
                            </p>
                            <p>
                            <br />
                            Puedes enviar tu donación a: 
                            </p>
                        <p>
                        <h3>Tinkoff: 2200700169893228 - Хулио И. П. </h3>
                        <h3>Sberbank: 2202205347747963 - Хулио И. П. </h3>
                        (En el comentario escriba el código/número de su vídeo para saber de quién es el aporte 🤗🙏💃🕺🏻)

                            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />.
                           


                        </p>
                    </div>
                    
                    
                </div>
            </section>
            {/* Hero section end */}



               


            


            <Footer />


        </main >



    );

}

export default Support;