

 
import Footer from "../../../components/ru/FooterRu";
import { Link } from "react-router-dom";


function Labachata() {


    return (

        <main >

            {/* <Menu /> */}

            <section
                className="hero-wrap hero-wrap-2"
                style={{ backgroundImage: 'url("/assets/img/gallery/fondo-musica.png")' }}
            >
                <div className="overlay" />
                <div className="container">
                    <div className="row no-gutters slider-text align-items-end justify-content-center">
                        <div className="col-md-9 ftco-animate pb-5 text-center">
                            <p className="breadcrumbs">
                                <span className="mr-2">
                                    <a href=" ">
                                    Главная <i className="fa fa-chevron-right" />
                                    </a>
                                </span>{" "}
                                <span>
                                    Больше музыки <i className="fa fa-chevron-right" />
                                </span>
                            </p>
                            <h1 className="mb-0 bread">Песни Текст</h1>
                        </div>
                    </div>
                </div>
            </section>


            <section className="video-lyrics">
                <iframe width="100%" height=" 100%" src="https://www.youtube.com/embed/TiM_TFpT_DE" title="La Bachata - MTZ Manuel Turizo | Video Oficial" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </section>


            <section className="ftco-section bg-light">
                <div className="container">
                    <div className="row d-flex">


                        <div className="col-6 ftco-animate">
                            <div className="blog-entry">
                               {/*  <a
                                    href=" "
                                    className="block-20"
                                    style={{ backgroundImage: 'url("images/image_1.jpg")' }}
                                ></a> */}

                                <div className="text d-block">
                                  
                                    <h3 className="heading">
                                        <a href="#">La Bachata - Manuel Turizo</a>
                                    </h3>
                                    <p>
                                        Te bloqueé de Insta <br />
                                        Pero, por otra cuenta, veo tus historias<br />
                                        Tu número lo borré <br />
                                        No sé pa' qué, si me lo sé de memoria. <br />
                                    </p>
                                    <p>
                                        Me hiciste daño y así te extraño <br />
                                        Y aunque sé que un día te voy a olvidar <br />
                                        Aún no lo hago, es complicado <br />
                                        To' lo que hicimo', me gusta recordar.<br />
                                    </p>
                                    <p>
                                        Ando manejando por las calles que me besaste <br />
                                        Oyendo las canciones que un día me dedicaste <br />
                                        Te diría que volvieras, pero eso no se pide <br />
                                        Mejor le pido a Dios que me cuide. <br />
                                    </p>
                                    <p>
                                        Porque ando manejando por las calles que me besaste <br />
                                        Oyendo las canciones que un día me dedicaste <br />
                                        Te diría que volvieras, pero eso no se pide <br />
                                        Mejor le pido a Dios que me cuide, eh. <br />
                                    </p>
                                    <p>
                                        Que me cuide de otra que se parezca a ti <br />
                                        No quiero caer como hice por ti <br />
                                        Ojalá te enamore', te hagan lo mismo que me hiciste a mí <br />
                                        Tú me enseñaste a no amar a cualquiera <br />
                                        Y también como no quiero que me quieran. <br />
                                    </p>
                                    <p>
                                        No-oh-oh, éramos tres en una relación de dos <br />
                                        No te perdono, pídele perdón a Dios <br />
                                        Dije que te olvidé y la verdad es que yo-oh-oh <br />
                                        Yo-oh-oh. <br />
                                    </p>
                                    <p>
                                        Ando manejando por las calles que me besaste <br />
                                        Oyendo las canciones que un día me dedicaste <br />
                                        Te diría que volvieras, pero eso no se pide <br />
                                        Mejor le pido a Dios que me cuide. <br />
                                    </p>
                                    <p>
                                        Porque ando manejando por las calles que me besaste <br />
                                        Oyendo las canciones que un día me dedicaste <br />
                                        Te diría que volvieras, pero eso no se pide <br />
                                        Mejor le pido a Dios que me cuide, eh.
                                    </p>


                                    <div className="meta">
                                        <p>
                                            <a href="#">
                                                <span className="fa fa-calendar mr-2" />
                                                Sept. 17, 2023
                                            </a>
                                            <a href="#">
                                                <span className="fa fa-user mr-2" />
                                                Admin
                                            </a>
                                            <a href="#" className="meta-chat">
                                                <span className="fa fa-comment mr-2" /> 3
                                            </a>
                                        </p>
                                    </div>



                                    <p>
                                        <a href=" " className="btn btn-secondary py-2 px-3">
                                            Read more
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>


                        <div className="col-6 ftco-animate">
                            <div className="blog-entry">
                               {/*  <a
                                    href=" "
                                    className="block-20"
                                    style={{ backgroundImage: 'url("images/image_2.jpg")' }}
                                ></a> */}
                                <div className="text d-block">
                                  
                                    <h3 className="heading">
                                        <a href="#">Бачата - Мануэль Туризо</a>
                                    </h3>
                                    <p>
                                        Блокировал тебя в Инсте,<br />
                                        Но через другой аккаунт я вижу твои истории.<br />
                                        Твой номер я удалил, <br />
                                        Не знаю зачем, ведь я его знаю наизусть. <br />
                                    </p>
                                    <p>
                                        Ты причинила мне боль, и вот я скучаю, <br />
                                        И хотя я знаю, что когда-то я тебя забуду, <br />
                                        Пока что я этого не сделал, это сложно, <br />
                                        Все, что мы делали, мне нравится вспоминать. <br />
                                    </p>
                                    <p>

                                        Я еду по улицам, где ты меня поцеловала, <br />
                                        Слушаю песни, которые ты мне посвятила. <br />
                                        Я бы сказал, чтобы ты вернулась, но так не просится, <br />
                                        Лучше я прошу у Бога, чтобы он меня охранял. <br />
                                    </p>
                                    <p>
                                        Потому что я еду по улицам, где ты меня поцеловала, <br />
                                        Слушаю песни, которые ты мне посвятила. <br />
                                        Я бы сказал, чтобы ты вернулась, но так не просится, <br />
                                        Лучше я прошу у Бога, чтобы он меня охранял, эй. <br />
                                    </p>
                                    <p>
                                        Пусть он охраняет меня от тех, кто похож на тебя, <br />
                                        Не хочу упасть так, как я упал из-за тебя. <br />
                                        Надеюсь, что ты влюбишься и тебе сделают то же, что ты сделала мне, <br />
                                        Ты научила меня не любить кого угодно <br />
                                        И также, как я не хочу, чтобы меня любили. <br />
                                    </p>
                                    <p>
                                        Нет-нет-нет, нас было трое в отношениях на двоих, <br />
                                        Я не прощаю тебе, проси прощения у Бога. <br />
                                        Сказал, что тебя забыл, и правда в том, что я-о-о, <br />
                                        Я-о-о. <br />
                                    </p>
                                    <p>
                                        Я еду по улицам, где ты меня поцеловала, <br />
                                        Слушаю песни, которые ты мне посвятила. <br />
                                        Я бы сказал, чтобы ты вернулась, но так не просится, <br />
                                        Лучше я прошу у Бога, чтобы он меня охранял. <br />
                                    </p>

                                    <div className="meta">
                                        <p>
                                            <a href="#">
                                                <span className="fa fa-calendar mr-2" />
                                                Sept. 17, 2023
                                            </a>
                                            <a href="#">
                                                <span className="fa fa-user mr-2" />
                                                Admin
                                            </a>
                                            <a href="#" className="meta-chat">
                                                <span className="fa fa-comment mr-2" /> 3
                                            </a>
                                        </p>
                                    </div>

                                    <p>
                                        <a href=" " className="btn btn-secondary py-2 px-3">
                                            Read more
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>

                       
                    </div>
                    <div className="row mt-5">
                        <div className="col text-center">
                            <div className="block-27">
                                <ul>
                                    <li>
                                        <a href="#">&lt;</a>
                                    </li>
                                    <li className="active">
                                        <span>1</span>
                                    </li>
                                    <li>
                                        <a href="#">2</a>
                                    </li>
                                    <li>
                                        <a href="#">3</a>
                                    </li>
                                    <li>
                                        <a href="#">4</a>
                                    </li>
                                    <li>
                                        <a href="#">5</a>
                                    </li>
                                    <li>
                                        <a href="#">&gt;</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>




            <Footer/>

        </main>



    );

}

export default Labachata;

