


import Footer from "./FooterRu";
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-autoplay.css';
import 'lightgallery/css/lg-video.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-fullscreen.css'; 
import 'lightgallery/css/lightgallery.css';
import lightGallery from 'lightgallery';  
import lgThumbnail from 'lightgallery/plugins/thumbnail'; 
import lgZoom from 'lightgallery/plugins/zoom'; 
import lgFullscreen from 'lightgallery/plugins/fullscreen'; 




function PhotosenEn() { 
    useEffect(() => { 
        const lightGalleryInit = () => { 
            const gallery = document.getElementById('lightgallery'); 
            if (gallery) { 
                lightGallery(gallery, { 
                    plugins: [lgThumbnail, lgZoom, lgFullscreen] 
                }); 
            } 
        }; 
        lightGalleryInit(); 
    }, []);

    const images = [
        {src: "/assets/img/party/vida-nocturna-gente-bailando-club.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/post-06.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/post-07.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/post-50.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/post-22.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/post-32.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/img-13223.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/img-13229.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/img-13193.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/entertaiment-01.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/entertaiment-12.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/fiesta3.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/girl-band-music.png", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/latino-fiesta-chicas2.png", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/mujer-rojo-fiesta.png", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
         
        {src: "/assets/img/party/fiesta-chicas.png", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/fiesta-chicas2.png", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/girfriends-fiesta.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/mujeres-sonrientes-fiesta.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/vida-nocturna.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/vida-nocturna-gente-bailando.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/vida-nocturna-gente-bailando-club.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/post-06.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/post-07.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/post-50.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/post-22.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/post-32.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/img-13223.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/img-13229.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/img-13193.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/entertaiment-01.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/entertaiment-12.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/fiesta3.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/girl-band-music.png", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/latino-fiesta-chicas2.png", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/mujer-rojo-fiesta.png", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        
        {src: "/assets/img/party/fiesta-chicas.png", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/fiesta-chicas2.png", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/girfriends-fiesta.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/mujeres-sonrientes-fiesta.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/vida-nocturna.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/vida-nocturna-gente-bailando.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/vida-nocturna-gente-bailando-club.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/post-06.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/post-07.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/post-50.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/post-22.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/post-32.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/img-13223.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/img-13229.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/img-13193.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/entertaiment-01.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/entertaiment-12.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/fiesta3.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/girl-band-music.png", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/latino-fiesta-chicas2.png", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/mujer-rojo-fiesta.png", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
                
        {src: "/assets/img/party/fiesta-chicas.png", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/fiesta-chicas2.png", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/girfriends-fiesta.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/mujeres-sonrientes-fiesta.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/vida-nocturna.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/vida-nocturna-gente-bailando.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        
        

    ];



    return ( 
        <main> 
            <div className="site-wrap front-videos"> 
                <div className="container"> 
                    <div className="row" id="lightgallery">
                        {images.map((image, index) => (
                            <div 
                                key={index}
                                className="col-4 col-sm-3 col-md-3 col-lg-2 col-xl-2 item" 
                                data-aos="fade" 
                                data-src={image.src}
                                data-sub-html={image.subHtml}
                            > 
                                <a href={image.src}> 
                                    <img 
                                        src={image.src}
                                        alt="IMage" 
                                        className="img-fluid" 
                                    /> 
                                </a> 
                            </div>
                        ))}
                    </div> 
                </div> 
            </div> 
            <Footer /> 
        </main>
    ); 
} 

export default PhotosenEn;
