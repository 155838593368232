
/* fox - blog */

import Footer from "./FooterRu";
import { Link } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import React, { useEffect, useState } from 'react';
import WOW from 'wowjs';

function EventsCulture() {

    useEffect(() => {
        const wow = new WOW.WOW();
        wow.init();
    }, []);

    
    const eventsData = [
        
        {day: "29 - 26", mos: "Jul - Aug", image: "/assets/img/event-isp/fondgorva-29-07.jpg", link: "https://gorchakovfund.ru/portal/news/view/otkryt_priem_zaiavok_na_stazhirovku_interussia_po_energetike_dlia_spetsialistov_iz_latinskoi_ameriki_64678", title: "Стажировка для специалистов из Латинской Америки",
        description: "Открыт прием заявок на стажировку InteRussia по энергетике для специалистов из Латинской Америки"
        },
        {day: "27-28", mos: "Jun", image: "/assets/img/event-isp/instilat-27-06.jpg", link: "https://ilaran.ru/events/#2077", title: " XIX Российско-испанский симпозиум",
        description: "Российско-испанский симпозиум «Растущая неопределенность мировой экономики и политики: вызовы для Испании и России»"
        },
        {day: "24", mos: "May", image: "/assets/img/event-isp/ibero-24-05.jpg", link: "https://iberoamerikanskiy-kulturn.timepad.ru/event/2877147/", title: " SEIS Y MEDIO: СПЕКТАКЛЬ НА ИСПАНСКОМ ЯЗЫКЕ ",
        description: "Спектакль и последующее обсуждение пройдут на испанском языке. Желательный уровень владения испанским А2+."
        },
        {day: "16", mos: "May", image: "/assets/img/event-isp/embper-240516.jpg", link: "https://posolstvo-peru.timepad.ru/event/2877567/", title: "Выставка традиционного перуанского текстильного искусства 👗",
        description: "Посольство Перу в России приглашает познакомиться ближе с традиционным текстильным искусством Перу, в частности в регионе Куско, и с перуанскими текстильными традициями."},
       
        {day: "14-15", mos: "May", image: "/assets/img/event-isp/instilat-14-05.jpg", link: "https://ilaran.ru/events/#1678", title: "Конференциия молодых ученых - Институт Латинской Америки",
        description: "Молодые исследователи в поисках нового взгляда на региональную проблематику» с целью повышения их квалификации"
        },
        {day: "12", mos: "May", image: "/assets/img/event-isp/rusonol-12-05.jpg", link: "https://libfl.ru/ru/event/rusonol-russko-ispanskiy-razgovornyy-klub-23", title: "Rusoñol: русско-испанский разговорный клуб 🎧",
        description: "Rusoñol» — это двуязычный разговорный клуб, который уже на протяжении многих лет объединяет людей, культуры, страны и континенты."
        },
        {day: "12", mos: "May", image: "/assets/img/event-isp/ibero-12-05.jpg", link: "https://widget.afisha.yandex.ru/w/events/326904?regionId=213&clientKey=b8930a8b-d2c2-46e4-8f15-5cc1fd5f4cb1", title: "МАСТЕР-КЛАСС ПО ЛАТИНОАМЕРИКАНСКИМ ТАНЦАМ 💫 ",
        description: "мастер-класс по сальсе, бачате, меренге и танго. Вместе мы научимся базовым и более сложным движениям, маленьким хитростям "
        },
        {day: "18", mos: "May", image: "/assets/img/events/corazon-18-05.jpg", link: "https://vk.com/corazon.bachata", title: "Corazon • Бачата-вечеринки 🔥",
            description: "🎼 Много бачаты, сальса и кизомба по запросу 🎧 DJ Don Corazon, DJ Daniela 👗 Дресс-код: прилично, удобно и красиво 🥤 Бар + кофейня, 2 кулера с водой 💧"
        },
        {day: "18", mos: "May", image: "/assets/img/events/viva-18-05.jpg", link: "https://vk.com/vivadancers", title: "ОТЧЕТНЫЙ КОНЦЕРТ И ВЕЧЕРИНКА VIVA DANCE🔥",
            description: "ЦЕНТР МОСКВЫ🔥 Здесь проводятся нереальные шоу и мощнейшие мероприятия различных форматов. В большом зрительном зале поместятся все желающие🔥",
        },
        {day: "9-12", mos: "May", image: "/assets/img/events/shine-09-05.jpg", link: "https://vk.com/shine_bachata", title: "Shine Bachata Festival 🏆🔥",
            description: "• Живая музыка бачаты на вечеринках • Мастер-классы 3 потоков: любители, профи, леди соло • VIP места и столы для отдыха, работает бар • Дресс-код на всех вечеринках Elegant",
        }
    ];

    return (

        <main>

            <section
                className="hero-wrap hero-wrap-2-fox"
                style={{ backgroundImage: 'url("/assets/img/gallery/dance-girl-man.png")' }}

            >
                <div className="overlay" />
                <div className="container">
                    <div className="row no-gutters slider-text align-items-center justify-content-center">
                        <div className="col-md-9 ftco-animate text-center fadeInUp ftco-animated">
                            <h1 className="mb-2 bread">Próximos Eventos</h1>
                            <p className="breadcrumbs">
                                <span className="mr-2">
                                    <a href=" "> Home <i className="ion-ios-arrow-forward" /></a>
                                </span>{" "}
                                <span> Eventos <i className="ion-ios-arrow-forward" /></span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="ftco-section-fox bg-light">
                <div className="container">
                    <div className="row">

                         
                        {eventsData.map((event, index) => (
                            <div key={index} className="col-md-6 col-lg-4 ftco-animate wow zoomIn" data-wow-delay={`${(index % 3) * 0.2 + 0.1}s`}>
                                <div className="blog-entry ">
                                    <div className="overflow-hidden">
                                        <a className="img-event block-20 d-flex align-items-end"
                                            style={{ backgroundImage: `url("${event.image}")` }}
                                        >
                                            <div className="meta-date text-center p-2">
                                                <span className="day">{event.day}</span>
                                                <span className="mos">{event.mos}</span>
                                                <span className="yr">2024</span>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="text bg-white p-4">
                                        <h3 className="heading">
                                            <Link to={event.link} target="_blank">{event.title}</Link>
                                        </h3>
                                        <p>{event.description}</p>
                                        <div className="d-flex align-items-center mt-4">
                                            <p className="mb-0">
                                                <Link to={event.link} target="_blank" className="btn btn-primary">
                                                    Leer Más <span className="ion-ios-arrow-round-forward" />
                                                </Link>
                                            </p>
                                            <p className="ml-auto mb-0">
                                                <a href="#" className="mr-2">
                                                    Admin
                                                </a>
                                                <a href="#" className="meta-chat">
                                                    <span className="icon-chat" /> 3
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </section>

            <Footer />

        </main >
    );
}

export default EventsCulture;

 