

import Footer from "./FooterEs";
import { Link } from "react-router-dom";


function Category() {



    return (


        <main >

            {/* <Menu /> */}


            <main>
                {/* About US Start */}
                <div className="about-area2 gray-bg pt-60 pb-60">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="whats-news-wrapper">
                                    {/* Heading & Nav Button */}
                                    <div className="row justify-content-between align-items-end mb-15">
                                        <div className="col-xl-4">
                                            <div className="section-tittle mb-30">
                                                <h3>Whats New</h3>
                                            </div>
                                        </div>
                                        <div className="col-xl-8 col-md-9">
                                            <div className="properties__button">
                                                {/*Nav Button  */}
                                                <nav>
                                                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                                        <a
                                                            className="nav-item nav-link active"
                                                            id="nav-home-tab"
                                                            data-toggle="tab"
                                                            href="#nav-home"
                                                            role="tab"
                                                            aria-controls="nav-home"
                                                            aria-selected="true"
                                                        >
                                                            Lifestyle
                                                        </a>
                                                        <a
                                                            className="nav-item nav-link"
                                                            id="nav-profile-tab"
                                                            data-toggle="tab"
                                                            href="#nav-profile"
                                                            role="tab"
                                                            aria-controls="nav-profile"
                                                            aria-selected="false"
                                                        >
                                                            Travel
                                                        </a>
                                                        <a
                                                            className="nav-item nav-link"
                                                            id="nav-contact-tab"
                                                            data-toggle="tab"
                                                            href="#nav-contact"
                                                            role="tab"
                                                            aria-controls="nav-contact"
                                                            aria-selected="false"
                                                        >
                                                            Events
                                                        </a>
                                                        <a
                                                            className="nav-item nav-link"
                                                            id="nav-last-tab"
                                                            data-toggle="tab"
                                                            href="#nav-last"
                                                            role="tab"
                                                            aria-controls="nav-contact"
                                                            aria-selected="false"
                                                        >
                                                            Sports
                                                        </a>
                                                        <a
                                                            className="nav-item nav-link"
                                                            id="nav-Sports"
                                                            data-toggle="tab"
                                                            href="#nav-nav-Sport"
                                                            role="tab"
                                                            aria-controls="nav-contact"
                                                            aria-selected="false"
                                                        >
                                                            Technology
                                                        </a>
                                                    </div>
                                                </nav>
                                                {/*End Nav Button  */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* Tab content */}
                                    <div className="row">
                                        <div className="col-12">
                                            {/* Nav Card */}
                                            <div className="tab-content" id="nav-tabContent">
                                                {/* card one */}
                                                <div
                                                    className="tab-pane fade show active"
                                                    id="nav-home"
                                                    role="tabpanel"
                                                    aria-labelledby="nav-home-tab"
                                                >
                                                    <div className="row">
                                                        <div className="col-xl-6 col-lg-6 col-md-6">
                                                            <div className="whats-news-single mb-40 mb-40">
                                                                <div className="whates-img">
                                                                    <img
                                                                        src="/assets/img/gallery/whats_news_details1.png"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div className="whates-caption whates-caption2">
                                                                    <h4>
                                                                        <a href="#">
                                                                            Verus amor nullum novit habere modum.Omnia vincit Amor.
                                                                        </a>
                                                                    </h4>
                                                                    <span>by Alice cloe - Jun 19, 2020</span>
                                                                    <p>
                                                                        Struggling to sell one multi-million dollar home
                                                                        currently on the market won’t stop actress and
                                                                        singer Jennifer Lopez.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-6 col-lg-6 col-md-6">
                                                            <div className="whats-news-single mb-40 mb-40">
                                                                <div className="whates-img">
                                                                    <img
                                                                        src="/assets/img/gallery/whats_news_details2.png"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div className="whates-caption whates-caption2">
                                                                    <h4>
                                                                        <a href="#">
                                                                            Verus amor nullum novit habere modum. Omnia vincit Amor.
                                                                        </a>
                                                                    </h4>
                                                                    <span>by Alice cloe - Jun 19, 2020</span>
                                                                    <p>
                                                                        Struggling to sell one multi-million dollar home
                                                                        currently on the market won’t stop actress and
                                                                        singer Jennifer Lopez.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-6 col-lg-6 col-md-6">
                                                            <div className="whats-news-single mb-40 mb-40">
                                                                <div className="whates-img">
                                                                    <img
                                                                        src="/assets/img/gallery/whats_news_details3.png"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div className="whates-caption whates-caption2">
                                                                    <h4>
                                                                        <a href="#">
                                                                            Verus amor nullum novit habere modum.Omnia vincit Amor.
                                                                        </a>
                                                                    </h4>
                                                                    <span>by Alice cloe - Jun 19, 2020</span>
                                                                    <p>
                                                                        Struggling to sell one multi-million dollar home
                                                                        currently on the market won’t stop actress and
                                                                        singer Jennifer Lopez.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-6 col-lg-6 col-md-6">
                                                            <div className="whats-news-single mb-40 mb-40">
                                                                <div className="whates-img">
                                                                    <img
                                                                        src="/assets/img/gallery/whats_news_details4.png"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div className="whates-caption whates-caption2">
                                                                    <h4>
                                                                        <a href="#">
                                                                            Verus amor nullum novit habere modum.Omnia vincit Amor.
                                                                        </a>
                                                                    </h4>
                                                                    <span>by Alice cloe - Jun 19, 2020</span>
                                                                    <p>
                                                                        Struggling to sell one multi-million dollar home
                                                                        currently on the market won’t stop actress and
                                                                        singer Jennifer Lopez.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-6 col-lg-6 col-md-6">
                                                            <div className="whats-news-single mb-40 mb-40">
                                                                <div className="whates-img">
                                                                    <img
                                                                        src="/assets/img/gallery/whats_news_details5.png"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div className="whates-caption whates-caption2">
                                                                    <h4>
                                                                        <a href="#">
                                                                            Verus amor nullum novit habere modum.Omnia vincit Amor.
                                                                        </a>
                                                                    </h4>
                                                                    <span>by Alice cloe - Jun 19, 2020</span>
                                                                    <p>
                                                                        Struggling to sell one multi-million dollar home
                                                                        currently on the market won’t stop actress and
                                                                        singer Jennifer Lopez.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-6 col-lg-6 col-md-6">
                                                            <div className="whats-news-single mb-40 mb-40">
                                                                <div className="whates-img">
                                                                    <img
                                                                        src="/assets/img/gallery/whats_news_details6.png"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div className="whates-caption whates-caption2">
                                                                    <h4>
                                                                        <a href="#">
                                                                            Verus amor nullum novit habere modum.Omnia vincit Amor.
                                                                        </a>
                                                                    </h4>
                                                                    <span>by Alice cloe - Jun 19, 2020</span>
                                                                    <p>
                                                                        Struggling to sell one multi-million dollar home
                                                                        currently on the market won’t stop actress and
                                                                        singer Jennifer Lopez.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Card two */}
                                                <div
                                                    className="tab-pane fade"
                                                    id="nav-profile"
                                                    role="tabpanel"
                                                    aria-labelledby="nav-profile-tab"
                                                >
                                                    <div className="row">
                                                        <div className="col-xl-6 col-lg-6 col-md-6">
                                                            <div className="whats-news-single mb-40 mb-40">
                                                                <div className="whates-img">
                                                                    <img
                                                                        src="/assets/img/gallery/whats_news_details4.png"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div className="whates-caption whates-caption2">
                                                                    <h4>
                                                                        <a href="#">
                                                                            Verus amor nullum novit habere modum.Omnia vincit Amor.
                                                                        </a>
                                                                    </h4>
                                                                    <span>by Alice cloe - Jun 19, 2020</span>
                                                                    <p>
                                                                        Struggling to sell one multi-million dollar home
                                                                        currently on the market won’t stop actress and
                                                                        singer Jennifer Lopez.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-6 col-lg-6 col-md-6">
                                                            <div className="whats-news-single mb-40 mb-40">
                                                                <div className="whates-img">
                                                                    <img
                                                                        src="/assets/img/gallery/whats_news_details6.png"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div className="whates-caption whates-caption2">
                                                                    <h4>
                                                                        <a href="#">
                                                                            Verus amor nullum novit habere modum.Omnia vincit Amor.
                                                                        </a>
                                                                    </h4>
                                                                    <span>by Alice cloe - Jun 19, 2020</span>
                                                                    <p>
                                                                        Struggling to sell one multi-million dollar home
                                                                        currently on the market won’t stop actress and
                                                                        singer Jennifer Lopez.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-6 col-lg-6 col-md-6">
                                                            <div className="whats-news-single mb-40 mb-40">
                                                                <div className="whates-img">
                                                                    <img
                                                                        src="/assets/img/gallery/whats_news_details5.png"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div className="whates-caption whates-caption2">
                                                                    <h4>
                                                                        <a href="#">
                                                                            Verus amor nullum novit habere modum.Omnia vincit Amor.
                                                                        </a>
                                                                    </h4>
                                                                    <span>by Alice cloe - Jun 19, 2020</span>
                                                                    <p>
                                                                        Struggling to sell one multi-million dollar home
                                                                        currently on the market won’t stop actress and
                                                                        singer Jennifer Lopez.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-6 col-lg-6 col-md-6">
                                                            <div className="whats-news-single mb-40 mb-40">
                                                                <div className="whates-img">
                                                                    <img
                                                                        src="/assets/img/gallery/whats_news_details4.png"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div className="whates-caption whates-caption2">
                                                                    <h4>
                                                                        <a href="#">
                                                                            Verus amor nullum novit habere modum.Omnia vincit Amor.
                                                                        </a>
                                                                    </h4>
                                                                    <span>by Alice cloe - Jun 19, 2020</span>
                                                                    <p>
                                                                        Struggling to sell one multi-million dollar home
                                                                        currently on the market won’t stop actress and
                                                                        singer Jennifer Lopez.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-6 col-lg-6 col-md-6">
                                                            <div className="whats-news-single mb-40 mb-40">
                                                                <div className="whates-img">
                                                                    <img
                                                                        src="/assets/img/gallery/whats_news_details5.png"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div className="whates-caption whates-caption2">
                                                                    <h4>
                                                                        <a href="#">
                                                                            Verus amor nullum novit habere modum.Omnia vincit Amor.
                                                                        </a>
                                                                    </h4>
                                                                    <span>by Alice cloe - Jun 19, 2020</span>
                                                                    <p>
                                                                        Struggling to sell one multi-million dollar home
                                                                        currently on the market won’t stop actress and
                                                                        singer Jennifer Lopez.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-6 col-lg-6 col-md-6">
                                                            <div className="whats-news-single mb-40 mb-40">
                                                                <div className="whates-img">
                                                                    <img
                                                                        src="/assets/img/gallery/whats_news_details1.png"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div className="whates-caption whates-caption2">
                                                                    <h4>
                                                                        <a href="#">
                                                                            Verus amor nullum novit habere modum.Omnia vincit Amor.
                                                                        </a>
                                                                    </h4>
                                                                    <span>by Alice cloe - Jun 19, 2020</span>
                                                                    <p>
                                                                        Struggling to sell one multi-million dollar home
                                                                        currently on the market won’t stop actress and
                                                                        singer Jennifer Lopez.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Card three */}
                                                <div
                                                    className="tab-pane fade"
                                                    id="nav-contact"
                                                    role="tabpanel"
                                                    aria-labelledby="nav-contact-tab"
                                                >
                                                    <div className="row">
                                                        <div className="col-xl-6 col-lg-6 col-md-6">
                                                            <div className="whats-news-single mb-40 mb-40">
                                                                <div className="whates-img">
                                                                    <img
                                                                        src="/assets/img/gallery/whats_news_details3.png"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div className="whates-caption whates-caption2">
                                                                    <h4>
                                                                        <a href="#">
                                                                            Verus amor nullum novit habere modum.Omnia vincit Amor.
                                                                        </a>
                                                                    </h4>
                                                                    <span>by Alice cloe - Jun 19, 2020</span>
                                                                    <p>
                                                                        Struggling to sell one multi-million dollar home
                                                                        currently on the market won’t stop actress and
                                                                        singer Jennifer Lopez.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-6 col-lg-6 col-md-6">
                                                            <div className="whats-news-single mb-40 mb-40">
                                                                <div className="whates-img">
                                                                    <img
                                                                        src="/assets/img/gallery/whats_news_details5.png"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div className="whates-caption whates-caption2">
                                                                    <h4>
                                                                        <a href="#">
                                                                            Verus amor nullum novit habere modum.Omnia vincit Amor.
                                                                        </a>
                                                                    </h4>
                                                                    <span>by Alice cloe - Jun 19, 2020</span>
                                                                    <p>
                                                                        Struggling to sell one multi-million dollar home
                                                                        currently on the market won’t stop actress and
                                                                        singer Jennifer Lopez.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-6 col-lg-6 col-md-6">
                                                            <div className="whats-news-single mb-40 mb-40">
                                                                <div className="whates-img">
                                                                    <img
                                                                        src="/assets/img/gallery/whats_news_details1.png"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div className="whates-caption whates-caption2">
                                                                    <h4>
                                                                        <a href="#">
                                                                            Verus amor nullum novit habere modum.Omnia vincit Amor.
                                                                        </a>
                                                                    </h4>
                                                                    <span>by Alice cloe - Jun 19, 2020</span>
                                                                    <p>
                                                                        Struggling to sell one multi-million dollar home
                                                                        currently on the market won’t stop actress and
                                                                        singer Jennifer Lopez.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-6 col-lg-6 col-md-6">
                                                            <div className="whats-news-single mb-40 mb-40">
                                                                <div className="whates-img">
                                                                    <img
                                                                        src="/assets/img/gallery/whats_news_details4.png"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div className="whates-caption whates-caption2">
                                                                    <h4>
                                                                        <a href="#">
                                                                            Verus amor nullum novit habere modum.Omnia vincit Amor.
                                                                        </a>
                                                                    </h4>
                                                                    <span>by Alice cloe - Jun 19, 2020</span>
                                                                    <p>
                                                                        Struggling to sell one multi-million dollar home
                                                                        currently on the market won’t stop actress and
                                                                        singer Jennifer Lopez.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-6 col-lg-6 col-md-6">
                                                            <div className="whats-news-single mb-40 mb-40">
                                                                <div className="whates-img">
                                                                    <img
                                                                        src="/assets/img/gallery/whats_news_details3.png"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div className="whates-caption whates-caption2">
                                                                    <h4>
                                                                        <a href="#">
                                                                            Verus amor nullum novit habere modum.Omnia vincit Amor.
                                                                        </a>
                                                                    </h4>
                                                                    <span>by Alice cloe - Jun 19, 2020</span>
                                                                    <p>
                                                                        Struggling to sell one multi-million dollar home
                                                                        currently on the market won’t stop actress and
                                                                        singer Jennifer Lopez.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-6 col-lg-6 col-md-6">
                                                            <div className="whats-news-single mb-40 mb-40">
                                                                <div className="whates-img">
                                                                    <img
                                                                        src="/assets/img/gallery/whats_news_details6.png"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div className="whates-caption whates-caption2">
                                                                    <h4>
                                                                        <a href="#">
                                                                            Verus amor nullum novit habere modum.Omnia vincit Amor.
                                                                        </a>
                                                                    </h4>
                                                                    <span>by Alice cloe - Jun 19, 2020</span>
                                                                    <p>
                                                                        Struggling to sell one multi-million dollar home
                                                                        currently on the market won’t stop actress and
                                                                        singer Jennifer Lopez.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* card fure */}
                                                <div
                                                    className="tab-pane fade"
                                                    id="nav-last"
                                                    role="tabpanel"
                                                    aria-labelledby="nav-last-tab"
                                                >
                                                    <div className="row">
                                                        <div className="col-xl-6 col-lg-6 col-md-6">
                                                            <div className="whats-news-single mb-40 mb-40">
                                                                <div className="whates-img">
                                                                    <img
                                                                        src="/assets/img/gallery/whats_news_details6.png"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div className="whates-caption whates-caption2">
                                                                    <h4>
                                                                        <a href="#">
                                                                            Verus amor nullum novit habere modum.Omnia vincit Amor.
                                                                        </a>
                                                                    </h4>
                                                                    <span>by Alice cloe - Jun 19, 2020</span>
                                                                    <p>
                                                                        Struggling to sell one multi-million dollar home
                                                                        currently on the market won’t stop actress and
                                                                        singer Jennifer Lopez.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-6 col-lg-6 col-md-6">
                                                            <div className="whats-news-single mb-40 mb-40">
                                                                <div className="whates-img">
                                                                    <img
                                                                        src="/assets/img/gallery/whats_news_details2.png"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div className="whates-caption whates-caption2">
                                                                    <h4>
                                                                        <a href="#">
                                                                            Verus amor nullum novit habere modum.Omnia vincit Amor.
                                                                        </a>
                                                                    </h4>
                                                                    <span>by Alice cloe - Jun 19, 2020</span>
                                                                    <p>
                                                                        Struggling to sell one multi-million dollar home
                                                                        currently on the market won’t stop actress and
                                                                        singer Jennifer Lopez.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-6 col-lg-6 col-md-6">
                                                            <div className="whats-news-single mb-40 mb-40">
                                                                <div className="whates-img">
                                                                    <img
                                                                        src="/assets/img/gallery/whats_news_details4.png"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div className="whates-caption whates-caption2">
                                                                    <h4>
                                                                        <a href="#">
                                                                            Verus amor nullum novit habere modum.Omnia vincit Amor.
                                                                        </a>
                                                                    </h4>
                                                                    <span>by Alice cloe - Jun 19, 2020</span>
                                                                    <p>
                                                                        Struggling to sell one multi-million dollar home
                                                                        currently on the market won’t stop actress and
                                                                        singer Jennifer Lopez.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-6 col-lg-6 col-md-6">
                                                            <div className="whats-news-single mb-40 mb-40">
                                                                <div className="whates-img">
                                                                    <img
                                                                        src="/assets/img/gallery/whats_news_details2.png"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div className="whates-caption whates-caption2">
                                                                    <h4>
                                                                        <a href="#">
                                                                            Verus amor nullum novit habere modum.Omnia vincit Amor.
                                                                        </a>
                                                                    </h4>
                                                                    <span>by Alice cloe - Jun 19, 2020</span>
                                                                    <p>
                                                                        Struggling to sell one multi-million dollar home
                                                                        currently on the market won’t stop actress and
                                                                        singer Jennifer Lopez.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-6 col-lg-6 col-md-6">
                                                            <div className="whats-news-single mb-40 mb-40">
                                                                <div className="whates-img">
                                                                    <img
                                                                        src="/assets/img/gallery/whats_news_details5.png"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div className="whates-caption whates-caption2">
                                                                    <h4>
                                                                        <a href="#">
                                                                            Verus amor nullum novit habere modum.Omnia vincit Amor.
                                                                        </a>
                                                                    </h4>
                                                                    <span>by Alice cloe - Jun 19, 2020</span>
                                                                    <p>
                                                                        Struggling to sell one multi-million dollar home
                                                                        currently on the market won’t stop actress and
                                                                        singer Jennifer Lopez.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-6 col-lg-6 col-md-6">
                                                            <div className="whats-news-single mb-40 mb-40">
                                                                <div className="whates-img">
                                                                    <img
                                                                        src="/assets/img/gallery/whats_news_details1.png"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div className="whates-caption whates-caption2">
                                                                    <h4>
                                                                        <a href="#">
                                                                            Verus amor nullum novit habere modum.Omnia vincit Amor.
                                                                        </a>
                                                                    </h4>
                                                                    <span>by Alice cloe - Jun 19, 2020</span>
                                                                    <p>
                                                                        Struggling to sell one multi-million dollar home
                                                                        currently on the market won’t stop actress and
                                                                        singer Jennifer Lopez.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* card Five */}
                                                <div
                                                    className="tab-pane fade"
                                                    id="nav-nav-Sport"
                                                    role="tabpanel"
                                                    aria-labelledby="nav-Sports"
                                                >
                                                    <div className="row">
                                                        <div className="col-xl-6 col-lg-6 col-md-6">
                                                            <div className="whats-news-single mb-40 mb-40">
                                                                <div className="whates-img">
                                                                    <img
                                                                        src="/assets/img/gallery/whats_news_details1.png"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div className="whates-caption whates-caption2">
                                                                    <h4>
                                                                        <a href="#">
                                                                            Verus amor nullum novit habere modum.Omnia vincit Amor.
                                                                        </a>
                                                                    </h4>
                                                                    <span>by Alice cloe - Jun 19, 2020</span>
                                                                    <p>
                                                                        Struggling to sell one multi-million dollar home
                                                                        currently on the market won’t stop actress and
                                                                        singer Jennifer Lopez.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-6 col-lg-6 col-md-6">
                                                            <div className="whats-news-single mb-40 mb-40">
                                                                <div className="whates-img">
                                                                    <img
                                                                        src="/assets/img/gallery/whats_news_details2.png"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div className="whates-caption whates-caption2">
                                                                    <h4>
                                                                        <a href="#">
                                                                            Verus amor nullum novit habere modum.Omnia vincit Amor.
                                                                        </a>
                                                                    </h4>
                                                                    <span>by Alice cloe - Jun 19, 2020</span>
                                                                    <p>
                                                                        Struggling to sell one multi-million dollar home
                                                                        currently on the market won’t stop actress and
                                                                        singer Jennifer Lopez.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-6 col-lg-6 col-md-6">
                                                            <div className="whats-news-single mb-40 mb-40">
                                                                <div className="whates-img">
                                                                    <img
                                                                        src="/assets/img/gallery/whats_news_details3.png"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div className="whates-caption whates-caption2">
                                                                    <h4>
                                                                        <a href="#">
                                                                            Verus amor nullum novit habere modum.Omnia vincit Amor.
                                                                        </a>
                                                                    </h4>
                                                                    <span>by Alice cloe - Jun 19, 2020</span>
                                                                    <p>
                                                                        Struggling to sell one multi-million dollar home
                                                                        currently on the market won’t stop actress and
                                                                        singer Jennifer Lopez.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-6 col-lg-6 col-md-6">
                                                            <div className="whats-news-single mb-40 mb-40">
                                                                <div className="whates-img">
                                                                    <img
                                                                        src="/assets/img/gallery/whats_news_details4.png"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div className="whates-caption whates-caption2">
                                                                    <h4>
                                                                        <a href="#">
                                                                            Verus amor nullum novit habere modum.Omnia vincit Amor.
                                                                        </a>
                                                                    </h4>
                                                                    <span>by Alice cloe - Jun 19, 2020</span>
                                                                    <p>
                                                                        Struggling to sell one multi-million dollar home
                                                                        currently on the market won’t stop actress and
                                                                        singer Jennifer Lopez.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-6 col-lg-6 col-md-6">
                                                            <div className="whats-news-single mb-40 mb-40">
                                                                <div className="whates-img">
                                                                    <img
                                                                        src="/assets/img/gallery/whats_news_details5.png"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div className="whates-caption whates-caption2">
                                                                    <h4>
                                                                        <a href="#">
                                                                            Verus amor nullum novit habere modum.Omnia vincit Amor.
                                                                        </a>
                                                                    </h4>
                                                                    <span>by Alice cloe - Jun 19, 2020</span>
                                                                    <p>
                                                                        Struggling to sell one multi-million dollar home
                                                                        currently on the market won’t stop actress and
                                                                        singer Jennifer Lopez.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-6 col-lg-6 col-md-6">
                                                            <div className="whats-news-single mb-40 mb-40">
                                                                <div className="whates-img">
                                                                    <img
                                                                        src="/assets/img/gallery/whats_news_details6.png"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div className="whates-caption whates-caption2">
                                                                    <h4>
                                                                        <a href="#">
                                                                            Verus amor nullum novit habere modum.Omnia vincit Amor.
                                                                        </a>
                                                                    </h4>
                                                                    <span>by Alice cloe - Jun 19, 2020</span>
                                                                    <p>
                                                                        Struggling to sell one multi-million dollar home
                                                                        currently on the market won’t stop actress and
                                                                        singer Jennifer Lopez.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* End Nav Card */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                {/* Flow Socail */}
                                <div className="single-follow mb-45">
                                    <div className="single-box">
                                        <div className="follow-us d-flex align-items-center">
                                            <div className="follow-social">
                                                <a href="#">
                                                    <img src="/assets/img/news/icon-fb.png" alt="" />
                                                </a>
                                            </div>
                                            <div className="follow-count">
                                                <span>8,045</span>
                                                <p>Fans</p>
                                            </div>
                                        </div>
                                        <div className="follow-us d-flex align-items-center">
                                            <div className="follow-social">
                                                <a href="#">
                                                    <img src="/assets/img/news/icon-tw.png" alt="" />
                                                </a>
                                            </div>
                                            <div className="follow-count">
                                                <span>8,045</span>
                                                <p>Fans</p>
                                            </div>
                                        </div>
                                        <div className="follow-us d-flex align-items-center">
                                            <div className="follow-social">
                                                <a href="#">
                                                    <img src="/assets/img/news/icon-ins.png" alt="" />
                                                </a>
                                            </div>
                                            <div className="follow-count">
                                                <span>8,045</span>
                                                <p>Fans</p>
                                            </div>
                                        </div>
                                        <div className="follow-us d-flex align-items-center">
                                            <div className="follow-social">
                                                <a href="#">
                                                    <img src="/assets/img/news/icon-yo.png" alt="" />
                                                </a>
                                            </div>
                                            <div className="follow-count">
                                                <span>8,045</span>
                                                <p>Fans</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* New Poster */}
                                <div className="news-poster d-none d-lg-block">
                                    <img src="/assets/img/news/news_card.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* About US End */}
                {/*Start pagination */}
                <div className="pagination-area  gray-bg pb-45">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="single-wrap">
                                    <nav aria-label="Page navigation example">
                                        <ul className="pagination justify-content-start">
                                            <li className="page-item">
                                                <a className="page-link" href="#">
                                                    {/* SVG icon */}
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                                        width="24px"
                                                        height="15px"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            fill="rgb(221, 221, 221)"
                                                            d="M8.142,13.118 L6.973,14.135 L0.127,7.646 L0.127,6.623 L6.973,0.132 L8.087,1.153 L2.683,6.413 L23.309,6.413 L23.309,7.856 L2.683,7.856 L8.142,13.118 Z"
                                                        />
                                                    </svg>
                                                </a>
                                            </li>
                                            <li className="page-item active">
                                                <a className="page-link" href="#">
                                                    01
                                                </a>
                                            </li>
                                            <li className="page-item">
                                                <a className="page-link" href="#">
                                                    02
                                                </a>
                                            </li>
                                            <li className="page-item">
                                                <a className="page-link" href="#">
                                                    03
                                                </a>
                                            </li>
                                            <li className="page-item">
                                                <a className="page-link" href="#">
                                                    {/* SVG iocn */}
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                                        width="40px"
                                                        height="15px"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            fill="rgb(255, 11, 11)"
                                                            d="M31.112,13.118 L32.281,14.136 L39.127,7.646 L39.127,6.624 L32.281,0.132 L31.167,1.154 L36.571,6.413 L0.491,6.413 L0.491,7.857 L36.571,7.857 L31.112,13.118 Z"
                                                        />
                                                    </svg>
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End pagination  */}
            </main>





            <Footer/>

        </main>



    );

}

export default Category;

