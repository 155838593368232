

import React from 'react';
 

function Projects( ) {
  // Contenido específico de Home en inglés
  return (
    <div>
      {/* <Menu onLanguageChange={onLanguageChange} /> */}


      <h2> Proyectos ESPAÑOL </h2>

      Proyectos ESPAÑOL
      {/* Resto del contenido de Home en inglés */}
    </div>
  );
}

export default Projects;