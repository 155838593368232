

import Menu from "./MenuRu";
import Footer from "./FooterRu";
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import React, { useEffect, useState } from 'react';


function FoxEvents() {

    return (

        <main>

            {/* <Menu /> */}

            <section
                className="hero-wrap hero-wrap-2"
                style={{ backgroundImage: 'url("/assets/img/foxmaster/bg_1.jpg")' }}
               
            >
                <div className="overlay" />
                <div className="container">
                    <div className="row no-gutters slider-text align-items-center justify-content-center">
                        <div className="col-md-9 ftco-animate text-center">
                            <h1 className="mb-2 bread">Próximos Eventos</h1>
                            <p className="breadcrumbs">
                                <span className="mr-2">
                                    <a href=" ">
                                        Home <i className="ion-ios-arrow-forward" />
                                    </a>
                                </span>{" "}
                                <span>
                                    Eventos <i className="ion-ios-arrow-forward" />
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>


            <section className="ftco-section bg-light">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-4 ftco-animate">
                            <div className="blog-entry">
                                <a
                                    href="blog-single.html"
                                    className="block-20 d-flex align-items-end"
                                     
                                    style={{ backgroundImage: 'url("/assets/img/foxmaster/image_1.jpg")' }}
                                >
                                    <div className="meta-date text-center p-2">
                                        <span className="day">02</span>
                                        <span className="mos">Jan</span>
                                        <span className="yr">2024</span>
                                    </div>
                                </a>
                                <div className="text bg-white p-4">
                                    <h3 className="heading">
                                        <a href="#">Skills To Develop Your Child Memory</a>
                                    </h3>
                                    <p>
                                        Far far away, behind the word mountains, far from the countries
                                        Vokalia and Consonantia, there live the blind texts.
                                    </p>
                                    <div className="d-flex align-items-center mt-4">
                                        <p className="mb-0">
                                            <a href="#" className="btn btn-primary">
                                                Read More <span className="ion-ios-arrow-round-forward" />
                                            </a>
                                        </p>
                                        <p className="ml-auto mb-0">
                                            <a href="#" className="mr-2">
                                                Admin
                                            </a>
                                            <a href="#" className="meta-chat">
                                                <span className="icon-chat" /> 3
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 ftco-animate">
                            <div className="blog-entry">
                                <a
                                    href="blog-single.html"
                                    className="block-20 d-flex align-items-end"
                                     
                                    style={{ backgroundImage: 'url("/assets/img/foxmaster/image_2.jpg")' }}
                                >
                                    <div className="meta-date text-center p-2">
                                        <span className="day">31</span>
                                        <span className="mos">Dec</span>
                                        <span className="yr">2023</span>
                                    </div>
                                </a>
                                <div className="text bg-white p-4">
                                    <h3 className="heading">
                                        <a href="#">Skills To Develop Your Child Memory</a>
                                    </h3>
                                    <p>
                                        Far far away, behind the word mountains, far from the countries
                                        Vokalia and Consonantia, there live the blind texts.
                                    </p>
                                    <div className="d-flex align-items-center mt-4">
                                        <p className="mb-0">
                                            <a href="#" className="btn btn-primary">
                                                Read More <span className="ion-ios-arrow-round-forward" />
                                            </a>
                                        </p>
                                        <p className="ml-auto mb-0">
                                            <a href="#" className="mr-2">
                                                Admin
                                            </a>
                                            <a href="#" className="meta-chat">
                                                <span className="icon-chat" /> 3
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 ftco-animate">
                            <div className="blog-entry">
                                <a
                                    href="blog-single.html"
                                    className="block-20 d-flex align-items-end"
                                    style={{ backgroundImage: 'url("/assets/img/foxmaster/image_3.jpg")' }}
                                >
                                    <div className="meta-date text-center p-2">
                                        <span className="day">31</span>
                                        <span className="mos">Dec</span>
                                        <span className="yr">2023</span>
                                    </div>
                                </a>
                                <div className="text bg-white p-4">
                                    <h3 className="heading">
                                        <a href="#">Skills To Develop Your Child Memory</a>
                                    </h3>
                                    <p>
                                        Far far away, behind the word mountains, far from the countries
                                        Vokalia and Consonantia, there live the blind texts.
                                    </p>
                                    <div className="d-flex align-items-center mt-4">
                                        <p className="mb-0">
                                            <a href="#" className="btn btn-primary">
                                                Read More <span className="ion-ios-arrow-round-forward" />
                                            </a>
                                        </p>
                                        <p className="ml-auto mb-0">
                                            <a href="#" className="mr-2">
                                                Admin
                                            </a>
                                            <a href="#" className="meta-chat">
                                                <span className="icon-chat" /> 3
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 ftco-animate">
                            <div className="blog-entry">
                                <a
                                    href="blog-single.html"
                                    className="block-20 d-flex align-items-end"
                                    style={{ backgroundImage: 'url("/assets/img/foxmaster/image_4.jpg")' }}                                  
                                >
                                    <div className="meta-date text-center p-2">
                                        <span className="day">31</span>
                                        <span className="mos">Dec</span>
                                        <span className="yr">2023</span>
                                    </div>
                                </a>
                                <div className="text bg-white p-4">
                                    <h3 className="heading">
                                        <a href="#">Skills To Develop Your Child Memory</a>
                                    </h3>
                                    <p>
                                        Far far away, behind the word mountains, far from the countries
                                        Vokalia and Consonantia, there live the blind texts.
                                    </p>
                                    <div className="d-flex align-items-center mt-4">
                                        <p className="mb-0">
                                            <a href="#" className="btn btn-primary">
                                                Read More <span className="ion-ios-arrow-round-forward" />
                                            </a>
                                        </p>
                                        <p className="ml-auto mb-0">
                                            <a href="#" className="mr-2">
                                                Admin
                                            </a>
                                            <a href="#" className="meta-chat">
                                                <span className="icon-chat" /> 3
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 ftco-animate">
                            <div className="blog-entry">
                                <a
                                    href="blog-single.html"
                                    className="block-20 d-flex align-items-end"
                                    style={{ backgroundImage: 'url("/assets/img/foxmaster/image_5.jpg")' }}
                                >
                                    <div className="meta-date text-center p-2">
                                        <span className="day">30</span>
                                        <span className="mos">Dec</span>
                                        <span className="yr">2023</span>
                                    </div>
                                </a>
                                <div className="text bg-white p-4">
                                    <h3 className="heading">
                                        <a href="#">Skills To Develop Your Child Memory</a>
                                    </h3>
                                    <p>
                                        Far far away, behind the word mountains, far from the countries
                                        Vokalia and Consonantia, there live the blind texts.
                                    </p>
                                    <div className="d-flex align-items-center mt-4">
                                        <p className="mb-0">
                                            <a href="#" className="btn btn-primary">
                                                Read More <span className="ion-ios-arrow-round-forward" />
                                            </a>
                                        </p>
                                        <p className="ml-auto mb-0">
                                            <a href="#" className="mr-2">
                                                Admin
                                            </a>
                                            <a href="#" className="meta-chat">
                                                <span className="icon-chat" /> 3
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 ftco-animate">
                            <div className="blog-entry">
                                <a
                                    href="blog-single.html"
                                    className="block-20 d-flex align-items-end"
                                    style={{ backgroundImage: 'url("/assets/img/foxmaster/image_6.jpg")' }}
                                >
                                    <div className="meta-date text-center p-2">
                                        <span className="day">29</span>
                                        <span className="mos">Dec</span>
                                        <span className="yr">2023</span>
                                    </div>
                                </a>
                                <div className="text bg-white p-4">
                                    <h3 className="heading">
                                        <a href="#">Skills To Develop Your Child Memory</a>
                                    </h3>
                                    <p>
                                        Far far away, behind the word mountains, far from the countries
                                        Vokalia and Consonantia, there live the blind texts.
                                    </p>
                                    <div className="d-flex align-items-center mt-4">
                                        <p className="mb-0">
                                            <a href="#" className="btn btn-primary">
                                                Read More <span className="ion-ios-arrow-round-forward" />
                                            </a>
                                        </p>
                                        <p className="ml-auto mb-0">
                                            <a href="#" className="mr-2">
                                                Admin
                                            </a>
                                            <a href="#" className="meta-chat">
                                                <span className="icon-chat" /> 3
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4 ftco-animate">
                            <div className="blog-entry">
                                <a
                                    href="blog-single.html"
                                    className="block-20 d-flex align-items-end"
                                     
                                    style={{ backgroundImage: 'url("/assets/img/foxmaster/image_1.jpg")' }}
                                >
                                    <div className="meta-date text-center p-2">
                                        <span className="day">28</span>
                                        <span className="mos">Dec</span>
                                        <span className="yr">2023</span>
                                    </div>
                                </a>
                                <div className="text bg-white p-4">
                                    <h3 className="heading">
                                        <a href="#">Skills To Develop Your Child Memory</a>
                                    </h3>
                                    <p>
                                        Far far away, behind the word mountains, far from the countries
                                        Vokalia and Consonantia, there live the blind texts.
                                    </p>
                                    <div className="d-flex align-items-center mt-4">
                                        <p className="mb-0">
                                            <a href="#" className="btn btn-primary">
                                                Read More <span className="ion-ios-arrow-round-forward" />
                                            </a>
                                        </p>
                                        <p className="ml-auto mb-0">
                                            <a href="#" className="mr-2">
                                                Admin
                                            </a>
                                            <a href="#" className="meta-chat">
                                                <span className="icon-chat" /> 3
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 ftco-animate">
                            <div className="blog-entry">
                                <a
                                    href="blog-single.html"
                                    className="block-20 d-flex align-items-end"
                                     
                                    style={{ backgroundImage: 'url("/assets/img/foxmaster/image_2.jpg")' }}
                                >
                                    <div className="meta-date text-center p-2">
                                        <span className="day">27</span>
                                        <span className="mos">Dec</span>
                                        <span className="yr">2023</span>
                                    </div>
                                </a>
                                <div className="text bg-white p-4">
                                    <h3 className="heading">
                                        <a href="#">Skills To Develop Your Child Memory</a>
                                    </h3>
                                    <p>
                                        Far far away, behind the word mountains, far from the countries
                                        Vokalia and Consonantia, there live the blind texts.
                                    </p>
                                    <div className="d-flex align-items-center mt-4">
                                        <p className="mb-0">
                                            <a href="#" className="btn btn-primary">
                                                Read More <span className="ion-ios-arrow-round-forward" />
                                            </a>
                                        </p>
                                        <p className="ml-auto mb-0">
                                            <a href="#" className="mr-2">
                                                Admin
                                            </a>
                                            <a href="#" className="meta-chat">
                                                <span className="icon-chat" /> 3
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 ftco-animate">
                            <div className="blog-entry">
                                <a
                                    href="blog-single.html"
                                    className="block-20 d-flex align-items-end"
                                    style={{ backgroundImage: 'url("/assets/img/foxmaster/image_3.jpg")' }}
                                >
                                    <div className="meta-date text-center p-2">
                                        <span className="day">26</span>
                                        <span className="mos">Dec</span>
                                        <span className="yr">2023</span>
                                    </div>
                                </a>
                                <div className="text bg-white p-4">
                                    <h3 className="heading">
                                        <a href="#">Skills To Develop Your Child Memory</a>
                                    </h3>
                                    <p>
                                        Far far away, behind the word mountains, far from the countries
                                        Vokalia and Consonantia, there live the blind texts.
                                    </p>
                                    <div className="d-flex align-items-center mt-4">
                                        <p className="mb-0">
                                            <a href="#" className="btn btn-primary">
                                                Read More <span className="ion-ios-arrow-round-forward" />
                                            </a>
                                        </p>
                                        <p className="ml-auto mb-0">
                                            <a href="#" className="mr-2">
                                                Admin
                                            </a>
                                            <a href="#" className="meta-chat">
                                                <span className="icon-chat" /> 3
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section>


            <Footer />


        </main >



    );

}

export default FoxEvents;
