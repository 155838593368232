

/* fox - blog */
 
import Footer from "./FooterEs";
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import WOW from 'wowjs';
import YandexAd from '../YandexAd';
import Adx1BannerVideo from '../Adx1BannerVideo'; 


function VideoDance() {

    useEffect(() => {
        const wow = new WOW.WOW();
        wow.init();
    }, []);

    const eventsData = [
        {day: "18", mos: "Sep", image: "/assets/video/open/gorky/18-09-24/covers/IMG_240918.jpg", link: "/video-open-18-09-24", title: "Open Air | Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. Импровизация пары, соло, Все танцуют!🔥"
            },
        {day: "17", mos: "Sep", image: "/assets/video/open/gorky/17-09-24/covers/IMG_240917.jpg", link: "/video-open-17-09-24", title: "Open Air | Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. Импровизация пары, соло, Все танцуют!🔥"
            },
        {day: "16", mos: "Sep", image: "/assets/video/open/gorky/16-09-24/covers/IMG_240916.jpg", link: "/video-open-16-09-24", title: "Open Air | Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. Импровизация пары, соло, Все танцуют!🔥"
            },
        {day: "15", mos: "Sep", image: "/assets/video/open/gorky/15-09-24/covers/IMG_240915.jpg", link: "/video-open-15-09-24", title: "Open Air | Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. Импровизация пары, соло, Все танцуют!🔥"
            },
        {day: "14", mos: "Sep", image: "/assets/video/open/gorky/14-09-24/covers/IMG_240914.jpg", link: "/video-open-14-09-24", title: "Open Air | Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. Импровизация пары, соло, Все танцуют!🔥"
            },
        {day: "13", mos: "Sep", image: "/assets/video/open/gorky/13-09-24/covers/IMG_240913.jpg", link: "/video-open-13-09-24", title: "Open Air | Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. Импровизация пары, соло, Все танцуют!🔥"
            },
        {day: "12", mos: "Sep", image: "/assets/video/open/gorky/12-09-24/covers/IMG_240912.jpg", link: "/video-open-12-09-24", title: "Open Air | Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. Импровизация пары, соло, Все танцуют!🔥"
            },
        {day: "11", mos: "Sep", image: "/assets/video/open/gorky/11-09-24/covers/IMG_240911.jpg", link: "/video-open-11-09-24", title: "Open Air | Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. Импровизация пары, соло, Все танцуют!🔥"
            },
        {day: "10", mos: "Sep", image: "/assets/video/open/gorky/10-09-24/covers/IMG_240910.jpg", link: "/video-open-10-09-24", title: "Open Air | Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. Импровизация пары, соло, Все танцуют!🔥"
            },
        {day: "08", mos: "Sep", image: "/assets/video/open/gorky/08-09-24/covers/IMG_240908.jpg", link: "/video-open-08-09-24", title: "Open Air | Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. Импровизация пары, соло, Все танцуют!🔥"
            },
        {day: "07", mos: "Sep", image: "/assets/video/open/gorky/07-09-24/covers/IMG_240907.jpg", link: "/video-open-07-09-24", title: "Open Air | Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. Импровизация пары, соло, Все танцуют!🔥"
            },
        {day: "06", mos: "Sep", image: "/assets/video/open/gorky/06-09-24/covers/IMG_240906.jpg", link: "/video-open-06-09-24", title: "Open Air | Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. Импровизация пары, соло, Все танцуют!🔥"
            },
        {day: "05", mos: "Sep", image: "/assets/video/open/gorky/05-09-24/covers/IMG_240905.jpg", link: "/video-open-05-09-24", title: "Open Air | Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. Импровизация пары, соло, Все танцуют!🔥"
            },
        {day: "04", mos: "Sep", image: "/assets/video/open/gorky/04-09-24/covers/IMG_240904.jpg", link: "/video-open-04-09-24", title: "Open Air | Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. Импровизация пары, соло, Все танцуют!🔥"
            },
        {day: "03", mos: "Sep", image: "/assets/video/open/gorky/03-09-24/covers/IMG_240903.jpg", link: "/video-open-03-09-24", title: "Open Air | Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. Импровизация пары, соло, Все танцуют!🔥"
            },
        {day: "01", mos: "Sep", image: "/assets/video/open/gorky/01-09-24/covers/IMG_240901.jpg", link: "/video-open-01-09-24", title: "Open Air | Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. Импровизация пары, соло, Все танцуют!🔥"
            },
        {day: " . ", mos: "Aug", image: "/assets/video/open/gorky/ship/covers/ship.jpg", link: "/video-open-ship", title: "Латина Open Air | на теплоходе",
            description: "Все танцуют на теплоходе!🔥"
            },
        {day: "31", mos: "Aug", image: "/assets/video/open/gorky/31-08-24/covers/IMG_240831.jpg", link: "/video-open-31-08-24", title: "Open Air | Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. Импровизация пары, соло, Все танцуют!🔥"
            },
        {day: "30", mos: "Aug", image: "/assets/video/open/gorky/30-08-24/covers/IMG_240830.jpg", link: "/video-open-30-08-24", title: "Open Air | Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. Импровизация пары, соло, Все танцуют!🔥"
            },
        {day: "28", mos: "Aug", image: "/assets/video/open/gorky/28-08-24/covers/IMG_240828.jpg", link: "/video-open-28-08-24", title: "Open Air | Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. Импровизация пары, соло, Все танцуют!🔥"
            },
        {day: "27", mos: "Aug", image: "/assets/video/open/gorky/27-08-24/covers/IMG_240827.jpg", link: "/video-open-27-08-24", title: "Open Air | Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. Импровизация пары, соло, Все танцуют!🔥"
            },
        {day: "25", mos: "Aug", image: "/assets/video/open/gorky/25-08-24/covers/IMG_240825.jpg", link: "/video-open-25-08-24", title: "Open Air | Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. Импровизация пары, соло, Все танцуют!🔥"
            },
        {day: "24", mos: "Aug", image: "/assets/video/open/gorky/24-08-24/covers/IMG_240824.jpg", link: "/video-open-24-08-24", title: "Open Air | Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. Импровизация пары, соло, Все танцуют!🔥"
            },
        {day: "23", mos: "Aug", image: "/assets/video/open/gorky/23-08-24/covers/IMG_240823.jpg", link: "/video-open-23-08-24", title: "Open Air | Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. Импровизация пары, соло, Все танцуют!🔥"
            },
        {day: "22", mos: "Aug", image: "/assets/video/open/gorky/22-08-24/covers/IMG_240822.jpg", link: "/video-open-22-08-24", title: "Open Air - Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. Импровизация пары, соло, Все танцуют!🔥"
            },
        {day: "21", mos: "Aug", image: "/assets/video/open/gorky/21-08-24/covers/IMG_240821.jpg", link: "/video-open-21-08-24", title: "Open Air - Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. Импровизация пары, соло, Все танцуют!🔥"
            },
        {day: "20", mos: "Aug", image: "/assets/video/open/gorky/20-08-24/covers/IMG_240820.jpg", link: "/video-open-20-08-24", title: "Open Air - Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. Импровизация пары, соло, Все танцуют!🔥"
            },
        {day: "16", mos: "Aug", image: "/assets/video/open/gorky/16-08-24/covers/IMG_240816.jpg", link: "/video-open-16-08-24", title: "Open Air - Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. Импровизация пары, соло, Все танцуют!🔥"
            },
        {day: "15", mos: "Aug", image: "/assets/video/open/gorky/15-08-24/covers/IMG_240815.jpg", link: "/video-open-15-08-24", title: "Open Air - Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. Импровизация пары, соло, Все танцуют!🔥"
            },
        {day: "14", mos: "Aug", image: "/assets/video/open/gorky/14-08-24/covers/IMG_240814.JPG", link: "/video-open-14-08-24", title: "Open Air - Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. Импровизация пары, соло, Все танцуют!🔥"
            },
        {day: "13", mos: "Aug", image: "/assets/video/open/gorky/13-08-24/covers/IMG_240813.JPG", link: "/video-open-13-08-24", title: "Open Air - Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. Импровизация пары, соло, Все танцуют!🔥"
            },
        {day: "10", mos: "Aug", image: "/assets/video/open/gorky/10-08-24/covers/IMG_240810.JPG", link: "/video-open-10-08-24", title: "Open Air - Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. Импровизация пары, соло, Все танцуют!🔥"
            },
        {day: "09", mos: "Aug", image: "/assets/video/open/gorky/09-08-24/covers/IMG_240809.jpg", link: "/video-open-09-08-24", title: "Open Air - Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. Импровизация пары, соло, Все танцуют!🔥"
            },
        {day: "08", mos: "Aug", image: "/assets/video/open/gorky/08-08-24/covers/IMG_240808.jpg", link: "/video-open-08-08-24", title: "Open Air - Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. ИМПРОВИЗАЦИЯ ПАРЫ, СОЛО, Все танцуют!🔥"
            },
        {day: "07", mos: "Aug", image: "/assets/video/open/gorky/07-08-24/covers/IMG_240807.jpg", link: "/video-open-07-08-24", title: "Open Air - Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. ИМПРОВИЗАЦИЯ ПАРЫ, СОЛО, Все танцуют!🔥"
            },
        {day: "06", mos: "Aug", image: "/assets/video/open/gorky/06-08-24/covers/IMG_240806.jpg", link: "/video-open-06-08-24", title: "Open Air - Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. ИМПРОВИЗАЦИЯ ПАРЫ, СОЛО, Все танцуют!🔥"
            },
        {day: "04", mos: "Aug", image: "/assets/video/open/gorky/04-08-24/covers/IMG_240804.JPG", link: "/video-open-04-08-24", title: "Open Air - Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. ИМПРОВИЗАЦИЯ ПАРЫ, СОЛО, Все танцуют!🔥"
            },
        {day: "03", mos: "Aug", image: "/assets/video/open/gorky/03-08-24/covers/IMG_240803.JPG", link: "/video-open-03-08-24", title: "Open Air - Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. ИМПРОВИЗАЦИЯ ПАРЫ, СОЛО, Все танцуют!🔥"
            },
        {day: "02", mos: "Aug", image: "/assets/video/open/gorky/02-08-24/covers/IMG_240802.JPG", link: "/video-open-02-08-24", title: "Open Air - Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. ИМПРОВИЗАЦИЯ ПАРЫ, СОЛО, Все танцуют!🔥"
            },
        {day: "01", mos: "Aug", image: "/assets/video/open/gorky/01-08-24/covers/IMG_240801.JPG", link: "/video-open-01-08-24", title: "Open Air - Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. ИМПРОВИЗАЦИЯ ПАРЫ, СОЛО, Все танцуют!🔥"
            },
        {day: "31", mos: "Jul", image: "/assets/video/open/gorky/31-07-24/covers/IMG_240731.JPG", link: "/video-open-31-07-24", title: "Open Air - Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. ИМПРОВИЗАЦИЯ ПАРЫ, СОЛО, Все танцуют!🔥"
            },
        {day: "25", mos: "Jul", image: "/assets/video/open/gorky/25-07-24/covers/IMG_240725.JPG", link: "/video-open-25-07-24", title: "Open Air - Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. ИМПРОВИЗАЦИЯ ПАРЫ, СОЛО, Все танцуют!🔥"
            },
        {day: "23", mos: "Jul", image: "/assets/video/open/gorky/23-07-24/covers/IMG_240723.JPG", link: "/video-open-23-07-24", title: "Open Air - Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. ИМПРОВИЗАЦИЯ ПАРЫ, СОЛО, Все танцуют!🔥"
            },
        {day: "20", mos: "Jul", image: "/assets/video/open/gorky/20-07-24/covers/IMG_240720.JPG", link: "/video-open-20-07-24", title: "Open Air - Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. ИМПРОВИЗАЦИЯ ПАРЫ, СОЛО, Все танцуют!🔥"
            },
        {day: "19", mos: "Jul", image: "/assets/video/open/gorky/19-07-24/covers/IMG_240719.JPG", link: "/video-open-19-07-24", title: "Open Air - Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. ИМПРОВИЗАЦИЯ ПАРЫ, СОЛО, Все танцуют!🔥"
            },
        {day: "18", mos: "Jul", image: "/assets/video/open/gorky/18-07-24/covers/IMG_240718.JPG", link: "/video-open-18-07-24", title: "Open Air - Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. ИМПРОВИЗАЦИЯ ПАРЫ, СОЛО, Все танцуют!🔥"
            },
        {day: "16", mos: "Jul", image: "/assets/video/open/gorky/16-07-24/covers/IMG_240716.JPG", link: "/video-open-16-07-24", title: "Open Air - Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. ИМПРОВИЗАЦИЯ ПАРЫ, СОЛО, Все танцуют!🔥"
            },
        {day: "14", mos: "Jul", image: "/assets/video/open/gorky/14-07-24/covers/IMG_240714.JPG", link: "/video-open-14-07-24", title: "Open Air - Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. ИМПРОВИЗАЦИЯ ПАРЫ, СОЛО, Все танцуют!🔥"
            },
        {day: "13", mos: "Jul", image: "/assets/video/open/gorky/13-07-24/covers/IMG_240713.JPG", link: "/video-open-13-07-24", title: "Open Air - Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. ИМПРОВИЗАЦИЯ ПАРЫ, СОЛО, Все танцуют!🔥"
            },
        {day: "12", mos: "Jul", image: "/assets/video/open/gorky/12-07-24/covers/IMG_240712.JPG", link: "/video-open-12-07-24", title: "Open Air - Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. ИМПРОВИЗАЦИЯ ПАРЫ, СОЛО, Все танцуют!🔥"
            },
        {day: "10", mos: "Jul", image: "/assets/video/open/gorky/10-07-24/covers/IMG_240710.JPG", link: "/video-open-10-07-24", title: "Open Air - Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. ИМПРОВИЗАЦИЯ ПАРЫ, СОЛО, Все танцуют!🔥"
            },
        {day: "09", mos: "Jul", image: "/assets/video/open/gorky/09-07-24/covers/IMG_240709.JPG", link: "/video-open-09-07-24", title: "Open Air - Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. ИМПРОВИЗАЦИЯ ПАРЫ, СОЛО, Все танцуют!🔥"
            },
        {day: "07", mos: "Jul", image: "/assets/video/open/gorky/07-07-24/covers/IMG_240707.JPG", link: "/video-open-07-07-24", title: "Open Air - Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. ИМПРОВИЗАЦИЯ ПАРЫ, СОЛО, Все танцуют!🔥"
            },
        {day: "06", mos: "Jul", image: "/assets/video/open/gorky/06-07-24/covers/IMG_240706.JPG", link: "/video-open-06-07-24", title: "Open Air - Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. ИМПРОВИЗАЦИЯ ПАРЫ, СОЛО, Все танцуют!🔥"
            },
        {day: "03", mos: "Jul", image: "/assets/video/open/gorky/03-07-24/covers/IMG_240703.jpg", link: "/video-open-03-07-24", title: "Open Air - Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. ИМПРОВИЗАЦИЯ ПАРЫ, СОЛО, Все танцуют!🔥"
            },
        {day: "30", mos: "Jun", image: "/assets/video/open/gorky/30-06-24/covers/IMG_240630.JPG", link: "/video-open-30-06-24", title: "Open Air - Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. ИМПРОВИЗАЦИЯ ПАРЫ, СОЛО, Все танцуют!🔥"
            },
        {day: "29", mos: "Jun", image: "/assets/video/open/gorky/29-06-24/covers/IMG_240629.JPG", link: "/video-open-29-06-24", title: "Open Air - Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. ИМПРОВИЗАЦИЯ ПАРЫ, СОЛО, Все танцуют!🔥"
            },
        {day: "28", mos: "Jun", image: "/assets/video/open/gorky/28-06-24/covers/IMG_240628.JPG", link: "/video-open-28-06-24", title: "Open Air - Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. ИМПРОВИЗАЦИЯ ПАРЫ, СОЛО, Все танцуют!🔥"
            },
        {day: "26", mos: "Jun", image: "/assets/video/open/gorky/26-06-24/covers/IMG_240626.jpg", link: "/video-open-26-06-24", title: "Open Air - Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. ИМПРОВИЗАЦИЯ ПАРЫ, СОЛО, Все танцуют!🔥"
            },
        {day: "25", mos: "Jun", image: "/assets/video/open/gorky/25-06-24/covers/IMG_240625.JPG", link: "/video-open-25-06-24", title: "Open Air - Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. ИМПРОВИЗАЦИЯ ПАРЫ, СОЛО, Все танцуют!🔥"
            },
        {day: "23", mos: "Jun", image: "/assets/video/open/gorky/23-06-24/covers/IMG_240623.JPG", link: "/video-open-23-06-24", title: "Open Air - Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. ИМПРОВИЗАЦИЯ ПАРЫ, СОЛО, Все танцуют!🔥"
            },
        {day: "22", mos: "Jun", image: "/assets/video/open/gorky/22-06-24/covers/IMG_240622.JPG", link: "/video-open-22-06-24", title: "Open Air - Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. ИМПРОВИЗАЦИЯ ПАРЫ, СОЛО, Все танцуют!🔥"
            },
        {day: "21", mos: "Jun", image: "/assets/video/open/gorky/21-06-24/covers/IMG_240621.JPG", link: "/video-open-21-06-24", title: "Open Air - Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. ИМПРОВИЗАЦИЯ ПАРЫ, СОЛО, Все танцуют!🔥"
            },
        {day: "19", mos: "Jun", image: "/assets/video/open/gorky/19-06-24/covers/IMG_240619.JPG", link: "/video-open-19-06-24", title: "Open Air - Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. ИМПРОВИЗАЦИЯ ПАРЫ, СОЛО, Все танцуют!🔥"
            },
        {day: "16", mos: "Jun", image: "/assets/video/open/gorky/16-06-24/covers/IMG_240616.JPG", link: "/video-open-16-06-24", title: "Open Air - Парк Горкого",
        description: "Начинающие, Любители, Профессионалы. ИМПРОВИЗАЦИЯ ПАРЫ, СОЛО, Все танцуют!🔥"
                },
        {day: "15", mos: "Jun", image: "/assets/video/open/gorky/15-06-24/covers/IMG_240615.JPG", link: "/video-open-15-06-24", title: "Open Air - Парк Музеон",
        description: "Начинающие, Любители, Профессионалы. ИМПРОВИЗАЦИЯ ПАРЫ, СОЛО, Все танцуют!🔥"
            },
        {day: "14", mos: "Jun", image: "/assets/video/open/gorky/14-06-24/covers/IMG_240614.JPG", link: "/video-open-14-06-24", title: "Open Air - Парк Музеон",
            description: "Начинающие, Любители, Профессионалы. ИМПРОВИЗАЦИЯ ПАРЫ, СОЛО, Все танцуют!🔥"
            },
        {day: "09", mos: "Jun", image: "/assets/video/open/gorky/09-06-24/covers/IMG_240609.JPG", link: "/video-open-09-06-24", title: "Open Air - Парк Горкого",
            description: "Начинающие, Любители, Профессионалы. ИМПРОВИЗАЦИЯ ПАРЫ, СОЛО, Все танцуют!🔥"
            },
        {day: "08", mos: "Jun", image: "/assets/video/open/gorky/08-06-24/covers/IMG_240608.JPG", link: "/video-open-08-06-24", title: "Open Air - Парк Музеон",
        description: "Начинающие, Любители, Профессионалы. ИМПРОВИЗАЦИЯ ПАРЫ, СОЛО, Все танцуют!🔥"
        },
        {day: "07", mos: "Jun", image: "/assets/video/open/gorky/07-06-24/covers/IMG_E2616.JPG", link: "/video-open-07-06-24", title: "Open Air - Парк Музеон",
        description: "Начинающие, Любители, Профессионалы. ИМПРОВИЗАЦИЯ ПАРЫ, СОЛО, Все танцуют!🔥"
        },
        {day: "02", mos: "Jun", image: "/assets/video/open/gorky/02-06-24/covers/IMG_2428.JPG", link: "/video-open-02-06-24", title: "Open Air - Парк Горкого",
        description: "Начинающие, Любители, Профессионалы. ИМПРОВИЗАЦИЯ ПАРЫ, СОЛО, Все танцуют!🔥"
        },
        {day: "31", mos: "May", image: "/assets/video/open/gorky/31-05-24/covers/IMG_1849.jpg", link: "/video-open-31-05-24", title: "Open Air - Парк Горкого",
        description: "Начинающие, Любители, Профессионалы. ИМПРОВИЗАЦИЯ ПАРЫ, СОЛО, Все танцуют!🔥"
        },
        {day: "30", mos: "May", image: "/assets/video/open/gorky/30-05-24/covers/IMG_2019.JPG", link: "/video-open-30-05-24", title: "Open Air - Парк Горкого",
        description: "Начинающие, Любители, Профессионалы. ИМПРОВИЗАЦИЯ ПАРЫ, СОЛО, Все танцуют!🔥"
        },
        {day: "28", mos: "May", image: "/assets/video/open/gorky/28-05-24/covers/IMG_2093.JPG", link: "/video-open-28-05-24", title: "Open Air - Парк Горкого",
        description: "Начинающие, Любители, Профессионалы. ИМПРОВИЗАЦИЯ ПАРЫ, СОЛО, Все танцуют!🔥"
        },
        {day: "25", mos: "May", image: "/assets/video/open/gorky/25-05-24/covers/IMG_2031.JPG", link: "/video-open-25-05-24", title: "Open Air - Парк Горкого",
        description: "Начинающие, Любители, Профессионалы. ИМПРОВИЗАЦИЯ ПАРЫ, СОЛО, Все танцуют!🔥"
        },
        {day: "19", mos: "May", image: "/assets/video/open/gorky/19-05-24/covers/IMG_2740.jpg", link: "/video-open-19-05-24", title: "Open Air - Парк Горкого",
        description: "Начинающие, Любители, Профессионалы. ИМПРОВИЗАЦИЯ ПАРЫ, СОЛО, Все танцуют!🔥"
        },
        {day: "18", mos: "May", image: "/assets/video/open/gorky/18-05-24/covers/IMG_1848.jpg", link: "/video-open-18-05-24", title: "Open Air - Парк Горкого",
        description: "Начинающие, Любители, Профессионалы. ИМПРОВИЗАЦИЯ ПАРЫ, СОЛО, Все танцуют!🔥"
        },
        
    ];

    return (

        <main>
 
            <section
                className="hero-wrap hero-wrap-2-fox"
                style={{ backgroundImage: 'url("/assets/img/gif/IMG_2024.gif")' }}
            >
                <div className="overlay" />
                <div className="container">
                    <div className="row no-gutters slider-text align-items-center justify-content-center">
                        <div className="col-md-9 ftco-animate text-center fadeInUp ftco-animated">
                            <h1 className="mb-2 bread"> Vídeo - Eventos</h1>
                            <p className="breadcrumbs">
                                <span className="mr-2">
                                    <Link to="/">Inicio<i className="ion-ios-arrow-forward" /></Link>                                     
                                </span>{" "}
                                <span>
                                    <Link to="/gallery">Galería<i className="ion-ios-arrow-forward" /></Link>                                   
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            {/* -------------- Yandex Banner 03 -------------- */}
            <Adx1BannerVideo blockId="R-A-9762127-3" renderTo="yandex_rtb_R-A-9762127-3" />
            

            <section className="ftco-section-fox bg-light">
                <div className="container">
                    <div className="row">
                         
                        {eventsData.map((event, index) => (
                            <div key={index} className="col-md-6 col-lg-4 ftco-animate wow zoomIn" data-wow-delay={`${(index % 3) * 0.2 + 0.1}s`}>
                                <div className="blog-entry ">
                                    <div className="overflow-hidden">
                                        <a className="img-event block-20 d-flex align-items-end"
                                            style={{ backgroundImage: `url("${event.image}")` }}
                                        >
                                            <div className="meta-date text-center p-2">
                                                <span className="day">{event.day}</span>
                                                <span className="mos">{event.mos}</span>
                                                <span className="yr">2024</span>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="text bg-white p-4">
                                        <h3 className="heading">
                                            <Link to={event.link}>{event.title}</Link>
                                        </h3>
                                        <p>{event.description}</p>
                                        <div className="d-flex align-items-center mt-4">
                                            <p className="mb-0">
                                                <Link to={event.link} className="btn btn-primary">
                                                    VÍDEOS <span className="ion-ios-arrow-round-forward" />
                                                </Link>
                                            </p>
                                            <p className="ml-auto mb-0">
                                                <a href="#" className="mr-2">
                                                    Admin
                                                </a>
                                                <a href="#" className="meta-chat">
                                                    <span className="icon-chat" /> 3
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </section>

            <section className="containerFox banner-yandex">
                {/* -------------- Yandex Banner 01 -------------- */}
                <YandexAd blockId="R-A-9762127-1" renderTo="yandex_rtb_R-A-9762127-1" />
            </section>


            <Footer />


        </main >



    );

}

export default VideoDance;
