


import Footer from "../../../components/ru/FooterRu";
import { useEffect } from 'react';
import { renderToString } from 'react-dom/server';
import { Link, useNavigate } from 'react-router-dom';
import lightGallery from 'lightgallery';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import lgFullscreen from 'lightgallery/plugins/fullscreen';
import lgVideo from 'lightgallery/plugins/video';
import lgAutoplay from 'lightgallery/plugins/autoplay';

import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/scss/lightgallery.scss';
import 'lightgallery/scss/lg-zoom.scss';

import WOW from 'wowjs';

function VideoNama() {
    useEffect(() => {
        const wow = new WOW.WOW();
        wow.init();
    }, []);

    useEffect(() => {
        const lightGalleryInit = () => {
            const gallery = document.getElementById('video-gallery');

            if (gallery) {
                lightGallery(gallery, {
                    plugins: [lgVideo, lgThumbnail, lgFullscreen, lgAutoplay, lgZoom],
                });
            }
        };

        lightGalleryInit();
    }, []);

    const startRange = 1857;
    const endRange = 1909;

    const videos = [];

    for (let i = startRange; i <= endRange; i++) {
        const videoSrc = `/assets/video/open/gorky/18-05-24/VID_${i}.mp4`;
        const videoPoster = `/assets/video/open/gorky/18-05-24/covers/IMG_${i}.jpg`;
        const videoDescription = `VID_${i} | Bachata`;

        videos.push({
            src: videoSrc,
            poster: videoPoster,
            description: videoDescription,
        });
    }

    const generateSubHtml = (video) => {
        const downloadSrc = video.src;
        const description = video.description;
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
        const downloadButton = isIOS ? (
            <a href={downloadSrc} target="_blank" className="download-btn">Download</a>
        ) : (
            <a href={downloadSrc} download className="download-btn">Download</a>
        );

        const html = (
            <div>
                {downloadButton}
                <h4>{description}</h4>
            </div>
        );
        return renderToString(html);
    };

    return (
        <main>
            <section
                className="hero-wrap hero-wrap-2-fox"
                style={{ backgroundImage: 'url("/assets/video/open/gorky/18-05-24/covers/IMG_1848.jpg")' }}

            >
                <div className="overlay" />
                <div className="container">
                    <div className="row no-gutters slider-text align-items-center justify-content-center">
                        <div className="col-md-9 ftco-animate text-center fadeInUp ftco-animated">
                            <h1 className="mb-2 bread"> Open Air 18.05.24 </h1>
                            <p className="breadcrumbs">
                                <span className="mr-2">
                                    <Link to="/gallery">Galería<i className="ion-ios-arrow-forward" /></Link>
                                </span>{" "}
                                <span>
                                    <Link to="/video-events">Vídeos<i className="ion-ios-arrow-forward" /></Link>
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <div className="site-wrap front-videos">
                <div className="container">
                    <div className="row" id="video-gallery">
                        {videos.map((video, index) => (
                            <div key={index} className="col-4 col-sm-3 col-md-3 col-lg-2 col-xl-2 item"
                                data-video={JSON.stringify({ source: [{ src: video.src, type: "video/mp4" }], attributes: { preload: false, controls: true } })}
                                data-poster=""
                                data-sub-html={generateSubHtml(video)} >
                                <a>
                                    <img
                                        className="img-responsive"
                                        src={video.poster}
                                    />
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div >
            <Footer />
        </main>
    );
}

export default VideoNama;







/*
    const videos = [
        { src: "/assets/video/party/nama/03-05-24/VID_1687.mp4", poster: "/assets/video/party/nama/covers/IMG_1687.jpg", description: "VID_1687 | Bachata" },
        { src: "/assets/video/party/nama/03-05-24/VID_1689.mp4", poster: "/assets/video/party/nama/covers/IMG_1689.jpg", description: "VID_1689 | Bachata" },
        { src: "/assets/video/party/nama/03-05-24/VID_1691.mp4", poster: "/assets/video/party/nama/covers/IMG_1691.jpg", description: "VID_1691 | Bachata" },
        { src: "/assets/video/party/nama/03-05-24/VID_1692.mp4", poster: "/assets/video/party/nama/covers/IMG_1692.jpg", description: "VID_1692 | Bachata" },
        { src: "/assets/video/party/nama/03-05-24/VID_1693.mp4", poster: "/assets/video/party/nama/covers/IMG_1693.jpg", description: "VID_1693 | Bachata" },
        { src: "/assets/video/party/nama/03-05-24/VID_1694.mp4", poster: "/assets/video/party/nama/covers/IMG_1694.jpg", description: "VID_1694 | Bachata" },
        { src: "/assets/video/party/nama/03-05-24/VID_1695.mp4", poster: "/assets/video/party/nama/covers/IMG_1695.jpg", description: "VID_1695 | Bachata" },
        { src: "/assets/video/party/nama/03-05-24/VID_1696.mp4", poster: "/assets/video/party/nama/covers/IMG_1696.jpg", description: "VID_1696 | Bachata" },
        { src: "/assets/video/party/nama/03-05-24/VID_1697.mp4", poster: "/assets/video/party/nama/covers/IMG_1697.jpg", description: "VID_1697 | Bachata" },
       
    ];

     */