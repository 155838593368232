



import React, { useEffect, useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';



function MenuMobEn({ changeLanguage, navigateTo }) {

    const navigate = useNavigate();

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isMenuCollapsed, setMenuCollapsed] = useState(true);

    const handleToggleClick = () => {
        setMenuCollapsed(!isMenuCollapsed);

        const navbarCollapse = document.querySelector('.navbar-collapse');
        navbarCollapse.style.display = isMenuCollapsed ? 'block' : 'none';

        const subMenus = document.querySelectorAll('.sub-menuMob');
        subMenus.forEach((submenu) => {
            submenu.style.display = 'none';
        });
    };

    const handleSubMenuClick = (e) => {
        e.preventDefault();
        const submenu = e.target.nextElementSibling;

        const otherSubMenus = document.querySelectorAll('.sub-menuMob');
        otherSubMenus.forEach((otherMenu) => {
            if (otherMenu !== submenu) {
                otherMenu.style.display = 'none';
            }
        });

        if (!isMenuCollapsed && submenu) {
            submenu.style.display = submenu.style.display === 'block' ? 'none' : 'block';
        }
    };


    //Colapsar al hacer clic en enlaces
    const handleNavLinkClick = () => {
        setMenuCollapsed(true);

    };

    const handleResize = () => {
        setWindowWidth(window.innerWidth);

        if (window.innerWidth > 750) {
            setMenuCollapsed(true);
        }
    };

    useEffect(() => {
        const resizeHandler = () => {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(handleResize, 100);
        };

        let resizeTimer;

        window.addEventListener('resize', resizeHandler);

        return () => {
            window.removeEventListener('resize', resizeHandler);
        };
    }, []);

    useEffect(() => {
        handleResize();
    }, [windowWidth]);

    useEffect(() => {
        // Asegurar que el menú esté colapsado al renderizar
        const navbarCollapse = document.querySelector('.navbar-collapse');
        navbarCollapse.style.display = isMenuCollapsed ? 'none' : 'block';

        const subMenus = document.querySelectorAll('.sub-menuMob');
        subMenus.forEach((submenu) => {
            submenu.style.display = 'none';
        });
    }, [isMenuCollapsed]);

    /* 00000000000000 Move var 0000000000000000000  */

    const [startY, setStartY] = useState(0);
    const [visible, setVisible] = useState(true);

    const handleTouchStart = (e) => {
        setStartY(e.touches[0].clientY);
    };

    const handleTouchMove = (e) => {
        const currentY = e.touches[0].clientY;
        if (startY < currentY && !visible) {
            setVisible(true);
        } else if (startY > currentY && visible) {
            setVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener('touchstart', handleTouchStart);
        window.addEventListener('touchmove', handleTouchMove);

        return () => {
            window.removeEventListener('touchstart', handleTouchStart);
            window.removeEventListener('touchmove', handleTouchMove);
        };
    }, [startY, visible]);


    return (

        <main>

            {/*================= TOPBARMOB =================*/}

            {/*  <div className='topbarmob'> */}
            <div className={`topbarmob ${visible ? 'visible' : 'hidden'}`}>
                {/* <div style={{ display: 'flex', position: 'fixed', width: '100%' ,  justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#333', color: 'white', padding: '10px 20px 5px' }}> */}
                <Link to=""  > <span style={{ color: 'white', fontWeight: 'bold' }} > Moscow</span> </Link>
                {/*  <div style={{ fontSize: '18px' }}> Moscow </div> */}

                <div style={{ fontSize: '20px' }}>
                    <Link to="https://www.youtube.com/OrbitRussia" target="_blank"> <i className="fab fa-youtube" style={{ color: 'white', marginRight: '10px' }} ></i> </Link>
                    <Link to="https://instagram.com/latinox.club" target="_blank"> <i className="fab fa-instagram" style={{ color: 'white', marginRight: '10px' }} ></i> </Link>
                    <Link to="https://t.me/latinoxclub" target="_blank"> <i className="fab fa-telegram" style={{ color: 'white', marginRight: '10px' }} ></i> </Link>
                    <Link to="https://vk.com/latinox.club" target="_blank"> <i className="fab fa-vk" style={{ color: 'white' }} ></i> </Link>
                </div>

                <div style={{ fontSize: '16px' }}>

                    <span role="img" aria-label="language"></span>

                    <select onChange={(e) => changeLanguage(e.target.value)}>
                        <option value="en">En</option>
                        <option value="es">Es</option>
                        <option value="ru">Ру</option>
                    </select>

                </div>
            </div>

            {/*================= main bar =================*/}

            {/* <div className='main-wrapper' > */}
            <div className={`main-wrapper ${visible ? 'visible' : 'hidden'}`}>
                {/* <nav className={`navbarmob ${touched ? 'visible' : 'hidden'}`}>
 */}
                {/* <nav className="navbarmob"> */}
                <nav className={`navbarmob ${visible ? 'visible' : 'hidden'}`}>

                    <div className="brand-and-icon">
                        {/* <Link to="/" className="navbar-brand"> LATINO</Link> */}
                        < Link to="/" className="navbar-brand">
                            <span style={{ color: 'white' }}>LATINO</span>
                            <span style={{ color: 'red' }}>X</span>
                            <span style={{ color: '#0095d0' }}>CLUB</span>
                        </Link>

                        <button type="button" className="navbar-toggler" onClick={handleToggleClick}>
                            <i className="fas fa-bars" />
                        </button>

                    </div>

                    <div className="navbar-collapse">
                        <ul className="navbar-navmob">

                            <li>
                                <amob href="/" onClick={handleSubMenuClick}> Home </amob>
                            </li>


                            {/*================= 2 BAILES =================*/}
                            <li>
                                <amob href="#" className="menu-link" onClick={handleSubMenuClick}>
                                    Dance
                                    <span className="drop-icon">
                                        <i className="fas fa-chevron-down" />
                                    </span>
                                </amob>
                                <div className="sub-menuMob">
                                    {/* item */}
                                    <div className="sub-menuMob-item">
                                        {/* <h4>top categories</h4> */}
                                        <ul>
                                            <li><Link to="/events-dance" onClick={() => handleNavLinkClick()}> Latino Partie </Link></li>
                                            <li><Link to="/schools-dance" onClick={() => handleNavLinkClick()}> Dance Schools </Link></li>
                                            <li><Link to="/teachers-dance" onClick={() => handleNavLinkClick()}> Dance Teachers </Link></li>
                                            <li><Link to="/video-events" onClick={() => handleNavLinkClick()}>  Videos </Link></li>
                                            <li><Link to="/photos" onClick={() => handleNavLinkClick()}> Photos </Link></li>
                                        </ul>
                                    </div>
                                    {/* end of item */}


                                    {/* item */}
                                    <div className="sub-menuMob-item">
                                        <h2>Siente la pasión latina</h2>
                                        <div className="imagemob">
                                            <img
                                                src="/assets/img/gif/dance.gif"
                                                alt="Dance image"
                                            /> </div>

                                        <button type="button" className="btn">

                                            <Link to="/video-events" onClick={() => handleNavLinkClick()}> All Videos! </Link>
                                        </button>

                                    </div>
                                    {/* end of item */}


                                </div>
                            </li>


                            {/*=============== 3 Restaurantes =================*/}

                            <li>
                                <amob href="#" className="menu-link" onClick={handleSubMenuClick}>
                                    Restaurants
                                    <span className="drop-icon">
                                        <i className="fas fa-chevron-down" />
                                    </span>
                                </amob>
                                <div className="sub-menuMob">
                                    {/* item */}
                                    <div className="sub-menuMob-item">
                                        {/* <h4>top categories</h4> */}
                                        <ul>
                                            <li><Link to="/restaurants" onClick={() => handleNavLinkClick()}> Los mejores restaurantes Latinos </Link></li>
                                            <li>
                                                <amob href="#">Clubes y pubs</amob>
                                            </li>
                                            <li>
                                                <amob href="#">Descuentos y promociones</amob>
                                            </li>
                                            <li>
                                                <amob href="#">Recomendados</amob>
                                            </li>
                                        </ul>
                                    </div>
                                    {/* end of item */}

                                    {/* item */}
                                    <div className="sub-menuMob-item">
                                        <h2>El sabor que te encantará</h2>
                                        <div className="imagemob">
                                            <img
                                                src="/assets/img/restaurant/foodie-food.gif"
                                                alt="product image"
                                            /> </div>

                                        <button type="button" className="btn">
                                            <Link to="/restaurants" onClick={() => handleNavLinkClick()}> Delicioso! </Link>
                                        </button>
                                    </div>

                                </div>
                            </li>

                            {/*==============================================*/}
                            {/*=============== 4 IDIOMAS ====================*/}
                            {/*==============================================*/}
                            <li>
                                <amob href="#" className="menu-link" onClick={handleSubMenuClick}>
                                    Spanish Language
                                    <span className="drop-icon">
                                        <i className="fas fa-chevron-down" />
                                    </span>
                                </amob>
                                <div className="sub-menuMob">
                                    {/* item */}
                                    <div className="sub-menuMob-item">
                                        {/* <h4>Spanish Language Schools</h4> */}
                                        <ul>
                                            <li><Link to="/schools-spanish" onClick={() => handleNavLinkClick()}> Spanish Language Schools </Link></li>
                                            <li><Link to="/teachers-spanish" onClick={() => handleNavLinkClick()}> Spanish Teachers </Link></li>
                                            <li><Link to="/events-spanish" onClick={() => handleNavLinkClick()}> Cultural Events </Link></li>
                                            <li><Link to="/spanish-courses" onClick={() => handleNavLinkClick()}> Linguistic Immersion </Link></li>
                                            <li><amob href="#">Translations and documentation</amob></li>
                                        </ul>
                                    </div>
                                    {/* end of item */}

                                    {/* item */}
                                    <div className="sub-menuMob-item">
                                        <h2>The second most spoken language in the world</h2>
                                        <div className="imagemob">
                                            <img
                                                src="/assets/img/language/hispanidad.gif"
                                                alt="product image"
                                            /> </div>

                                        <button type="button" className="btn">
                                            <Link to="/teachers-spanish" onClick={() => handleNavLinkClick()}>Let's speak Spanish! </Link>
                                        </button>
                                    </div>


                                </div>
                            </li>
                            {/*==============================================*/}
                            {/*=============== 5 LOOK====================*/}
                            {/*==============================================*/}
                            <li>
                                <amob href="#" className="menu-link" onClick={handleSubMenuClick}>
                                    STORE
                                    <span className="drop-icon">
                                        <i className="fas fa-chevron-down" />
                                    </span>
                                </amob>
                                <div className="sub-menuMob">
                                    {/* item */}
                                    <div className="sub-menuMob-item">
                                        {/* <h4>top categories</h4> */}
                                        <ul>
                                            <li><Link to="/store" onClick={() => handleNavLinkClick()}> Moda y belleza </Link></li>
                                            <li><Link to="/store" onClick={() => handleNavLinkClick()}> Soy Latin❤️ </Link></li>
                                            <li><Link to="/store" onClick={() => handleNavLinkClick()}> Productos </Link></li>
                                            <li><Link to="/store" onClick={() => handleNavLinkClick()}> Ferias y Exposiciones</Link></li>
                                            <li><Link to="/store" onClick={() => handleNavLinkClick()}> Promociones para Miembros </Link></li>
                                        </ul>
                                    </div>
                                    {/* end of item */}


                                    {/* item */}
                                    <div className="sub-menuMob-item">
                                        <h2>You are the most beautiful in the world</h2>
                                        <div className="imagemob">
                                            <img
                                                src="/assets/img/gif/store-peru.gif"
                                                alt="product image"
                                            /> </div>

                                        <button type="button" className="btn">
                                            <Link to="/store" onClick={() => handleNavLinkClick()}>  Esto es para ti! </Link>
                                        </button>

                                    </div>


                                </div>
                            </li>
                            {/*==============================================*/}
                            {/*=============== 6 ACTUALIDAD ===================*/}
                            {/*==============================================*/}
                            <li>
                                <amob href="#" className="menu-link" onClick={handleSubMenuClick}>
                                    Society
                                    <span className="drop-icon">
                                        <i className="fas fa-chevron-down" />
                                    </span>
                                </amob>
                                <div className="sub-menuMob">
                                    {/* item */}
                                    <div className="sub-menuMob-item">
                                        {/* <h4>top categories</h4> */}
                                        <ul>
                                            <li>
                                                <amob href="#">Embajadas</amob>
                                            </li>
                                            <li>
                                                <amob href="#">Visas para España y Latinoamérica</amob>
                                            </li>
                                            <li>
                                                <amob href="#">Negocios</amob>
                                            </li>
                                            <li>
                                                <amob href="#">Envíos & Transacciones</amob>
                                            </li>

                                            <li>
                                                <amob href="#">Tengo Un Plan</amob>
                                            </li>

                                        </ul>
                                    </div>
                                    {/* end of item */}

                                    {/* item */}
                                    <div className="sub-menuMob-item">
                                        <h2>Personas como tú</h2>
                                        <div className="imagemob">
                                            <img
                                                src="/assets/img/gif/dominican-republic.gif"
                                                alt="product image"
                                            /> </div>

                                        <button type="button" className="btn">
                                            <Link to="/new-year-Latam" onClick={() => handleNavLinkClick()}>   Viajemos! </Link>
                                        </button>
                                    </div>


                                </div>
                            </li>
                            {/*==============================================*/}
                            {/*=============== 7 ABOUT ===================*/}
                            {/*==============================================*/}
                            <li>
                                <amob href="#" className="menu-link" onClick={handleSubMenuClick}>
                                    HELLO!
                                    <span className="drop-icon">
                                        <i className="fas fa-chevron-down" />
                                    </span>
                                </amob>
                                <div className="sub-menuMob">
                                    {/* item */}
                                    <div className="sub-menuMob-item">
                                        {/* <h4>top categories</h4> */}
                                        <ul>
                                            <li>
                                                <amob href="#">CLUB - MEMBERS</amob>
                                            </li>
                                            <li>
                                                <amob href="#">Support Us</amob>
                                            </li>
                                            <li>
                                                <amob href="#">Digital Solutions</amob>
                                            </li>
                                            <li>
                                                <amob href="#"> About </amob>
                                            </li>

                                        </ul>
                                    </div>
                                    {/* end of item */}

                                    {/* item */}
                                    <div className="sub-menuMob-item">
                                        <h2>Latino Community</h2>
                                        <div className="imagemob">
                                            <img
                                                src="/assets/img/gif/latino-flags.gif"
                                                alt="product image"
                                            /> </div>

                                        <button type="button" className="btn">
                                            <Link to="/events-dance" onClick={() => handleNavLinkClick()}>  Thank you! </Link>
                                        </button>
                                    </div>

                                </div>
                            </li>
                            {/*==============================================*/}
                            {/*=============== 6 OFERTAS ====================*/}
                            {/*==============================================*/}
                            <li>
                                <amob href="#" onClick={handleSubMenuClick} >Foro latino</amob>
                            </li>
                        </ul>
                    </div>

                </nav>
            </div>

            {/*  </div> */}

        </main>

    );
}

export default MenuMobEn;