

import Footer from "../../../components/ru/FooterRu";
import Sidebar from "../../../components/es/SidebarEs";
import { Link } from "react-router-dom";





function Schdance() {



    return (

        <main>


            {/* Breadcrumb */}
            <div className="container">
                <div className="headline bg0 flex-wr-sb-c p-rl-20 p-tb-8">
                    <div className="f2-s-1 p-r-30 m-tb-6">
                        <Link to="/" className="breadcrumb-item f1-s-3 cl9"> Home </Link>

                        <Link to="/events-dance" className="breadcrumb-item f1-s-3 cl9"> Events </Link>

                        <span className="breadcrumb-item f1-s-3 cl9">
                            Dance in Moscow
                        </span>
                    </div>
                    <div className="pos-relative size-a-2 bo-1-rad-22 of-hidden bocl11 m-tb-6">
                        <input
                            className="f1-s-1 cl6 plh9 s-full p-l-25 p-r-45"
                            type="text"
                            name="search"
                            placeholder="Buscar"
                        />
                        <button className="flex-c-c size-a-1 ab-t-r fs-20 cl2 hov-cl10 trans-03">
                            <i className="zmdi zmdi-search" />
                        </button>
                    </div>
                </div>
            </div>


            {/* Content */}
            <section className="bg0 p-b-140 p-t-10">
                <div className="container">

                    <div className="row justify-content-center">

                        <div className="col-md-10 col-lg-8 p-b-30">
                            <div className="p-r-10 p-r-0-sr991">
                                {/*===============================================================================================*/}
                                {/*================================ DETALLES DEL CONTENIDO  ======================================*/}
                                {/*===============================================================================================*/}
                                {/* Blog Detail */}
                                <div className="p-b-70">

                                    <Link to="/schools-dance" className="f1-s-10 cl2 hov-cl10 trans-03 text-uppercase"> Танцевальные школы </Link>


                                    <h3 className="f1-l-3 cl2 p-b-16 p-t-33 respon2">
                                        Сальсатека | Сальса, Бачата, Опены от Юрия Смирнова
                                    </h3>
                                    <div className="flex-wr-s-s p-b-40">
                                        <span className="f1-s-3 cl8 m-r-15">
                                            <a href="#" className="f1-s-4 cl8 hov-cl10 trans-03">
                                                by Luis P
                                            </a>
                                            <span className="m-rl-3">-</span>
                                            <span>May 11</span>
                                        </span>
                                        <span className="f1-s-3 cl8 m-r-15">15K Subs</span>
                                        <a href="#" className="f1-s-3 cl8 hov-cl10 trans-03 m-r-15">
                                            0 Comment
                                        </a>
                                    </div>
                                    <div className="wrap-pic-max-w p-b-30">
                                        <img src="/assets/img/schdance/salsateca-gorky.jpg" alt="IMG" />
                                    </div>
                                    <p className="f1-s-11 cl6 p-b-25">
                                        С мая по октябрь танцы в Парке Горького - Нескучный сад.
                                        И в парке искусств МУЗЕОН на площадке "НОВА".
                                        <br />
                                        Сальса - бачата опен-эйры по понедельникам, вторникам, пятницам субботам и воскресеньям.
                                        <br />
                                        на 🌫Пушкинской набережной,<br />
                                        пн., вт., пт., сб. - дальняя площадка,<br />
                                        вс. - ближняя площадка от Андреевского моста.<br />
                                    </p>
                                    <p className="f1-s-11 cl6 p-b-25">
                                        🚉( м "Шаболовская", м "Фрунзенская",м "Ленинский проспект", м "Парк культуры", "Октябрьская"). Точка на карте 55.723053, 37.591695
                                        <br />
                                        🧭Время занятий будни с 19-00 до 20-00. сб. и вс. с 18:00 до 21:00 (3 урока)<br />
                                        Опен-эйр с 20-00 до 23-00.<br />
                                        <br />
                                        Занятия по бачате, сальсе и кизомбе перед каждым опен-эйром, следите за объявлениями в группе!

                                    </p>
                                    <p className="f1-s-15 cl1 p-b-25">
                                        На набережной для Вас:<br />
                                        - танцпол: досчатая набережная Москвы-реки 500 кв.м.,<br />
                                        - 🎶музыка сальса, бачата от Юрия Смирнова,<br />
                                        - 🎥📷фото, видео
                                    </p>
                                    <p className="f1-s-11 cl6 p-b-25">
                                        Занятия так же проходят в парке искусств МУЗЕОН на площадке "НОВА".
                                        Вторник, пятница, суббота и воскресенье с 19:00. Следите расписанием в группе.
                                        <br /> <br />
                                        Мы являемся официальным общественным танцевальным кружком
                                        ГОСУДАРСТВЕННОГО АВТОНОМНОГО УЧРЕЖДЕНИЯ КУЛЬТУРЫ ГОРОДА МОСКВЫ "ЦЕНТРАЛЬНЫЙ ПАРК КУЛЬТУРЫ И ОТДЫХА ИМЕНИ М. ГОРЬКОГО".
                                        <br />
                                        Мы должны вести журналы посещаемости занятий для отчетности перед Департаментом Культуры г. Москвы, поэтому запись на занятия в Музеоне обязательна.
                                        <br /> <br />
                                        Записаться можно в личных сообщениях в ТГ или в форме записи.
                                        <br />
                                        Если по какой-то причине не успели записаться, это можно сделать на месте, подойдите к преподавателю!
                                        <br />
                                        <Link to="https://t.me/fursolga" target="_blank"> t.me/fursolga </Link>
                                    </p>
                                    <p className="f1-s-11 cl6 p-b-25">
                                        Опен-эйры и уроки в Парке Горького условно бесплатные, проект существует за счет энтузиастов и на пожертвования танцоров, Парк расходы не возмещает, поэтому рекомендуемая сумма 400 р. за каждый урок. Будем благодарны всем, кто поддерживает нас и делает вклад в развитие танцев! Перевести можно на сбер, привязан к номеру телефона 8-962-363-40-40 Смирнов Юрий Алексеевич или отдать на месте.
                                        <br />
                                        ____________________________________________
                                    </p>
                                    <p className="f1-s-11 cl6 p-b-25">
                                        📞Телефон для связи:<br />
                                        8-962-363-40-40 Юрий <br />
                                        8-926-175-12-82 Ольга <br />
                                        (привязаны с сберу)<br />

                                        💃 Индивидуальные занятия сальса, бачата от 2500 с человека и 3000 с пары.
                                        <br />
                                        ____________________________________________
                                    </p>
                                    <p className="f1-s-11 cl6 p-b-25">
                                        📍Занятия направлены на разучивание базовой техники танцевания. Мы учимся танцевать в парах и изучаем танцы: сальса и бачата. Группы общего уровня, подходят для начинающих!
                                        Можно присоединиться!<br />

                                        <Link to="https://vk.com/app5708398_-213967623" target="_blank"> Запись на занятия  </Link><br />
                                        <Link to="https://vk.com/salsateca_msk" target="_blank"> Группа занятий vk.com/salsateca_msk </Link><br />




                                    </p>
                                    <p className="f1-s-11 cl6 p-b-25">
                                        ____________________________________ <br />
                                        С октября по субботам уроки и вечеринка проходят в зале. <br />
                                        По пятницам и субботам, уроки и бачата вечеринки с небольшим количеством кизомбы и сальсы в уютном зале арт-пространства Art9.2. Art9.2 — это отдельно стоящий трехэтажный особняк в центре Москвы!
                                    </p>
                                    <p className="f1-s-11 cl6 p-b-25">
                                        Для вас новый качественный звук, танцпол, покрытый ламинатом, вода и горячий чай с лимоном, печеньки и цукаты. Если у вас своя диета, можно принести еду и напитки с собой!
                                    </p>
                                    <p className="f1-s-11 cl6 p-b-25">
                                        ПЯТНИЦА <br />
                                        19:00 урок: бачата с Габриэлем🔥⭐ <br />
                                        19:45 урок: бачата Sensual 🔥⭐ <br />
                                        🌟20:30 - 00:00 вечеринка: 2 бачаты, 1 кизомба, 2 бачаты, 1 сальса. <br />
                                    </p>
                                    <p className="f1-s-11 cl6 p-b-25">
                                        🚩Дополнительно по предварительной записи: <br />
                                        📍20:00 до 20:45 - Урок кизомбы с Габриэлем зал на 2 этаже! <br />
                                        📍20:45 - 21:30 урок сальсы с Ольгой Фурс зал на 2 этаже.🔥⭐
                                    </p>

                                    <p className="f1-s-11 cl6 p-b-25">
                                        СУББОТА<br />
                                        19:00 урок: бачата с Габриэлем🔥⭐️<br />
                                        19:45 урок: бачата сеншуал с Юлией Быковой🔥⭐️<br />
                                        🌟20:30 - 00:00 вечеринка: 3 бачаты, 1 сальса и 2 кизомбы в час.
                                    </p>

                                    <p className="f1-s-11 cl6 p-b-25">
                                        занятия направлены на разучивание базовой техники танцевания. Мы учимся танцевать в парах и изучаем танцы: сальса бачата с нуля! <br />
                                        Можно присоединиться!<br />
                                        Проект существует на пожертвования, благодарим за поддержку! Рекомендуемая сумма 300 р., можно отдать на месте или перевести на карту 4276 3800 6646 3055. <br />
                                        <Link to="https://vk.com/salsateca_msk" target="_blank"> Группа занятий vk.com/salsateca_msk  </Link><br />



                                    </p>


                                    {/* =========== FUENTE ============ */}
                                    <div className="flex-s-s p-t-12 p-b-15">
                                        <span className="f1-s-12 cl5 m-r-8">Links:</span>
                                        <div className="flex-wr-s-s size-w-0">
                                            <a
                                                href="https://vk.com/salsa_bachata_reggaeton"
                                                className="f1-s-12 cl8 hov-link1 m-r-15"
                                                target="_blank"
                                            >
                                                [ SALSATECA RU ]
                                            </a>
                                            <a
                                                href="https://vk.com/salsateca_msk"
                                                className="f1-s-12 cl8 hov-link1 m-r-15"
                                                target="_blank"
                                            >
                                                [Занятия бачата, сальса Salsateca.ru     ]
                                            </a>
                                        </div>
                                    </div>


                                    {/*===========================================================================================*/}
                                    {/*================================ FIN DEL CONTENIDO  ======================================*/}
                                    {/*===========================================================================================*/}



                                    {/* Share */}
                                    <div className="flex-s-s">
                                        <span className="f1-s-12 cl5 p-t-1 m-r-15">Contact:</span>
                                        <div className="flex-wr-s-s size-w-0">
                                            {/* <a href="#" class="dis-block f1-s-13 cl0 bg-google borad-3 p-tb-4 p-rl-18 hov-btn1 m-r-3 m-b-3 trans-03"> */}

                                            <Link to="https://vk.com/salsa_bachata_reggaeton"
                                                className="dis-block f1-s-13 cl0 bg-facebook borad-3 p-tb-4 p-rl-18 hov-btn1 m-r-3 m-b-3 trans-03">
                                                <i className="fab fa-vk m-r-7" /> VKontakte </Link>


                                            <Link to="https://t.me/salsateka"
                                                className="dis-block f1-s-13 cl0 bg-twitter borad-3 p-tb-4 p-rl-18 hov-btn1 m-r-3 m-b-3 trans-03" >

                                                <i className="fab fa-telegram m-r-7" /> Telegram  </Link>


                                            <Link to="https://t.me/salsatecalessons"
                                                className="dis-block f1-s-13 cl0 bg-facebook borad-3 p-tb-4 p-rl-18 hov-btn1 m-r-3 m-b-3 trans-03">

                                                <i className="fab fa-telegram m-r-7" /> Telegram Уроки </Link>


                                            <Link to="https://www.youtube.com/channel/UCUHu-3FESh2vaN2rgaKzibQ"
                                                className="dis-block f1-s-13 cl0 bg-google borad-3 p-tb-4 p-rl-18 hov-btn1 m-r-3 m-b-3 trans-03">

                                                <i className="fab fa-youtube m-r-7" /> Youtube </Link>



                                        </div>
                                    </div>




                                    {/* ====================== Tag ====================== */}
                                    <div className="flex-s-s p-t-12 p-b-15">
                                        <span className="f1-s-12 cl5 m-r-8">Tags:</span>
                                        <div className="flex-wr-s-s size-w-0">

                                            <Link to="/video-events" className="f1-s-12 cl8 hov-link1 m-r-15">
                                                Video</Link>

                                            <Link to="/events-dance" className="f1-s-12 cl8 hov-link1 m-r-15">
                                                Events </Link>

                                            <Link to="/schools-dance" className="f1-s-12 cl8 hov-link1 m-r-15">
                                                Schools Dance </Link>

                                            <Link to="/" className="f1-s-12 cl8 hov-link1 m-r-15">
                                                Latino </Link>


                                        </div>
                                    </div>



                                </div>



                                {/* Leave a comment */}
                                <div>
                                    <h4 className="f1-l-4 cl3 p-b-12">Deja un comentario</h4>
                                    <p className="f1-s-13 cl8 p-b-40">
                                        Su email no será publicado. Los campos obligatorios están marcados *

                                    </p>
                                    <form>
                                        <textarea
                                            className="bo-1-rad-3 bocl13 size-a-15 f1-s-13 cl5 plh6 p-rl-18 p-tb-14 m-b-20"
                                            name="msg"
                                            placeholder="Comentario..."
                                            defaultValue={""}
                                        />
                                        <input
                                            className="bo-1-rad-3 bocl13 size-a-16 f1-s-13 cl5 plh6 p-rl-18 m-b-20"
                                            type="text"
                                            name="name"
                                            placeholder="Nombre*"
                                        />
                                        <input
                                            className="bo-1-rad-3 bocl13 size-a-16 f1-s-13 cl5 plh6 p-rl-18 m-b-20"
                                            type="text"
                                            name="email"
                                            placeholder="Email*"
                                        />
                                        <input
                                            className="bo-1-rad-3 bocl13 size-a-16 f1-s-13 cl5 plh6 p-rl-18 m-b-20"
                                            type="text"
                                            name="website"
                                            placeholder="Sitio web"
                                        />
                                        <button className="size-a-17 bg2 borad-3 f1-s-12 cl0 hov-btn1 trans-03 p-rl-15 m-t-10">
                                            Publicar comentario
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>


                        {/*===============================================================================================*/}
                        {/*================================ BARRA LATERAL ======================================*/}
                        {/*===============================================================================================*/}
                        {/* Sidebar */}



                        <Sidebar />


                    </div>
                </div>
            </section>




            <Footer />


        </main>



    );

}

export default Schdance;
