

import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

 import Footer from './FooterEs';
 
import { Link } from 'react-router-dom';



function Webuni() {

    return (

        <main>
 
            <section > 

                {/* Hero section */}

                 <section className="hero-section set-bg" style={{ backgroundImage: 'url("./assets/img/webuni/bg.jpg")' }}>

                    <div className="container">
                        <div className="hero-text text-white">
                            <h2>Cursos de Idioma Español</h2>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                                malesuada lorem maximus mauris scelerisque, at rutrum nulla <br />{" "}
                                dictum. Ut ac ligula sapien. Suspendisse cursus faucibus finibus.
                            </p>
                        </div>
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1">
                                <form className="intro-newslatter">
                                    <input type="text" placeholder="Name" />
                                    <input type="text" className="last-s" placeholder="E-mail" />
                                    <button className="site-btn">Sign Up Now</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Hero section end */}


                {/* categories section */}
                <section className="categories-section spad">
                    <div className="container">
                        <div className="section-title-webuni"> 
                            <h2>Todas las categorías</h2>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                                malesuada lorem maximus mauris scelerisque, at rutrum nulla dictum. Ut
                                ac ligula sapien. Suspendisse cursus faucibus finibus.
                            </p>
                        </div>
                        <div className="row">
                            {/* categorie */}
                            <div className="col-lg-4 col-md-6">
                                <div className="categorie-item">
                                    <div className="ci-thumb set-bg">
                                        <img src="assets/img/webuni/categories/1.jpg" />
                                    </div>

                                    <div className="ci-text">
                                        <h5>Español para principiantes</h5>
                                        <p>Lorem ipsum dolor sit amet, consectetur</p>
                                        <span>120 Courses</span>
                                    </div>
                                </div>
                            </div>
                            {/* categorie */}
                            <div className="col-lg-4 col-md-6">
                                <div className="categorie-item">
                                    <div className="ci-thumb set-bg">
                                        <img src="assets/img/webuni/categories/2.jpg" />
                                    </div>
                                    <div className="ci-text">
                                        <h5>Español Conversacional</h5>
                                        <p>Lorem ipsum dolor sit amet, consectetur</p>
                                        <span>70 Courses</span>
                                    </div>
                                </div>
                            </div>
                            {/* categorie */}
                            <div className="col-lg-4 col-md-6">
                                <div className="categorie-item">

                                    <div className="ci-thumb set-bg">
                                        <img src="assets/img/webuni/categories/3.jpg" />
                                    </div>
                                    <div className="ci-text">
                                        <h5>Traducciones </h5>
                                        <p>Lorem ipsum dolor sit amet, consectetur</p>
                                        <span>55 Courses</span>
                                    </div>
                                </div>
                            </div>
                            {/* categorie */}
                            <div className="col-lg-4 col-md-6">
                                <div className="categorie-item">
                                    <div className="ci-thumb set-bg">
                                        <img src="assets/img/webuni/categories/4.jpg" />
                                    </div>
                                    <div className="ci-text">
                                        <h5>Preparación para exámenes</h5>
                                        <p>Lorem ipsum dolor sit amet, consectetur</p>
                                        <span>40 Courses</span>
                                    </div>
                                </div>
                            </div>
                            {/* categorie */}
                            <div className="col-lg-4 col-md-6">
                                <div className="categorie-item">
                                    <div className="ci-thumb set-bg">
                                        <img src="assets/img/webuni/categories/5.jpg" />
                                    </div>

                                    <div className="ci-text">
                                        <h5>DELE</h5>
                                        <p>Lorem ipsum dolor sit amet, consectetur</p>
                                        <span>220 Courses</span>
                                    </div>
                                </div>
                            </div>
                            {/* categorie */}
                            <div className="col-lg-4 col-md-6">
                                <div className="categorie-item">
                                    <div className="ci-thumb set-bg">
                                        <img src="assets/img/webuni/categories/6.jpg" />
                                    </div>

                                    <div className="ci-text">
                                        <h5>SIELE</h5>
                                        <p>Lorem ipsum dolor sit amet, consectetur</p>
                                        <span>25 Courses</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* categories section end */}
                {/* search section */}
                <section className="search-section">
                    <div className="container">
                        <div className="search-warp">
                            <div className="section-title-webuni text-white">
                                <h2>Busca tu curso</h2>
                            </div>
                            <div className="row">
                                <div className="col-md-10 offset-md-1">
                                    {/* search form */}
                                    <form className="course-search-form">
                                        <input type="text" placeholder="Course" />
                                        <input type="text" className="last-m" placeholder="Category" />
                                        <button className="site-btn">Search Couse</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* search section end */}
                {/* course section */}
                <section className="course-section spad">
                    <div className="container">
                        <div className="section-title-webuni mb-0">
                            <h2>Cursos Relacionados</h2>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                                malesuada lorem maximus mauris scelerisque, at rutrum nulla dictum. Ut
                                ac ligula sapien. Suspendisse cursus faucibus finibus.
                            </p>
                        </div>
                    </div>
                    <div className="course-warp">
                        <ul className="course-filter controls">
                            <li className="control active" data-filter="all">
                                Todos
                            </li>
                            <li className="control" data-filter=".finance">
                                Básico
                            </li>
                            <li className="control" data-filter=".design">
                                Intermedio
                            </li>
                            <li className="control" data-filter=".web">
                                Avanzado
                            </li>
                            <li className="control" data-filter=".photo">
                                DELE / SIELE
                            </li>
                        </ul>
                        <div className="row course-items-area">
                            {/* course */}
                            <div className="mix col-lg-3 col-md-4 col-sm-6 finance">
                                <div className="course-item">
                                 
                                 
                                    <div className="course-thumb set-bg" style={{ backgroundImage: 'url("./assets/img/webuni/courses/1.jpg")' }}>
                                        <div className="price">Precio: Desde ₽1000 </div>
                                    </div>

                                    <div className="course-info">
                                        <div className="course-text">
                                            <h5>Español para niños</h5>
                                            <p>Lorem ipsum dolor sit amet, consectetur</p>
                                            <div className="students">120 Students</div>
                                        </div>
                                        <div className="course-author">

                                            <div className="ca-pic set-bg" style={{ backgroundImage: 'url("./assets/img/webuni/authors/1.jpg")' }} />

                                            <p>
                                                Ana Lucia, <span> Profesores Nativos </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* course */}
                            <div className="mix col-lg-3 col-md-4 col-sm-6 design">
                                <div className="course-item">

                                    <div className="course-thumb set-bg" style={{ backgroundImage: 'url("./assets/img/webuni/courses/2.jpg")' }}>
                                        <div className="price">Precio: Desde ₽1000 </div>
                                    </div>
                                    <div className="course-info">
                                        <div className="course-text">
                                            <h5>Español para principiantes</h5>
                                            <p>Lorem ipsum dolor sit amet, consectetur</p>
                                            <div className="students">120 Students</div>
                                        </div>
                                        <div className="course-author">
                                            <div className="ca-pic set-bg" style={{ backgroundImage: 'url("./assets/img/webuni/authors/2.jpg")' }} />

                                            <p>
                                                William Parker, <span>Profesores Nativos </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* course */}
                            <div className="mix col-lg-3 col-md-4 col-sm-6 web">
                                <div className="course-item">

                                    <div className="course-thumb set-bg" style={{ backgroundImage: 'url("./assets/img/webuni/courses/3.jpg")' }}>
                                        <div className="price">Precio: Desde ₽1000 </div>
                                    </div>

                                    <div className="course-info">
                                        <div className="course-text">
                                            <h5>Nivel Básico</h5>
                                            <p>Lorem ipsum dolor sit amet, consectetur</p>
                                            <div className="students">120 Students</div>
                                        </div>
                                        <div className="course-author">

                                            <div className="ca-pic set-bg" style={{ backgroundImage: 'url("./assets/img/webuni/authors/3.jpg")' }} />

                                            <p>
                                                William Parker, <span>Profesores Nativos </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* course */}
                            <div className="mix col-lg-3 col-md-4 col-sm-6 photo">
                                <div className="course-item">

                                    <div className="course-thumb set-bg" style={{ backgroundImage: 'url("./assets/img/webuni/courses/5.jpg")' }}>
                                        <div className="price">Precio: Desde ₽1000 </div>
                                    </div>

                                    <div className="course-info">
                                        <div className="course-text">
                                            <h5>Exámenes internacionales</h5>
                                            <p>Lorem ipsum dolor sit amet, consectetur</p>
                                            <div className="students">120 Students</div>
                                        </div>
                                        <div className="course-author">
                                            <div className="ca-pic set-bg" style={{ backgroundImage: 'url("./assets/img/webuni/authors/5.jpg")' }} />

                                            <p>
                                                William Parker, <span>Profesores Nativos </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* course */}
                            <div className="mix col-lg-3 col-md-4 col-sm-6 finance">
                                <div className="course-item">

                                    <div className="course-thumb set-bg" style={{ backgroundImage: 'url("./assets/img/webuni/courses/4.jpg")' }}>
                                        <div className="price">Precio: Desde ₽1000 </div>
                                    </div>

                                    <div className="course-info">
                                        <div className="course-text">
                                            <h5>Dialecto Cubano</h5>
                                            <p>Lorem ipsum dolor sit amet, consectetur</p>
                                            <div className="students">120 Students</div>
                                        </div>
                                        <div className="course-author">
                                            <div className="ca-pic set-bg" style={{ backgroundImage: 'url("./assets/img/webuni/authors/4.jpg")' }} />

                                            <p>
                                                William Parker, <span>Profesores Nativos </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* course */}
                            <div className="mix col-lg-3 col-md-4 col-sm-6 design">
                                <div className="course-item">

                                    <div className="course-thumb set-bg" style={{ backgroundImage: 'url("./assets/img/webuni/courses/6.jpg")' }}>
                                        <div className="price">Precio: Desde ₽1000 </div>
                                    </div>

                                    <div className="course-info">
                                        <div className="course-text">
                                            <h5>Traducciones</h5>
                                            <p>Lorem ipsum dolor sit amet, consectetur</p>
                                            <div className="students">120 Students</div>
                                        </div>
                                        <div className="course-author">
                                            <div className="ca-pic set-bg" style={{ backgroundImage: 'url("./assets/img/webuni/authors/6.jpg")' }} />

                                            <p>
                                                William Parker, <span>Profesores Nativos </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* course */}
                            <div className="mix col-lg-3 col-md-4 col-sm-6 web">
                                <div className="course-item">

                                    <div className="course-thumb set-bg" style={{ backgroundImage: 'url("./assets/img/webuni/courses/7.jpg")' }}>
                                        <div className="price">Precio: Desde ₽1000 </div>
                                    </div>

                                    <div className="course-info">
                                        <div className="course-text">
                                            <h5>Español para Negocios</h5>
                                            <p>Lorem ipsum dolor sit amet, consectetur</p>
                                            <div className="students">120 Students</div>
                                        </div>
                                        <div className="course-author">
                                            <div className="ca-pic set-bg" style={{ backgroundImage: 'url("./assets/img/webuni/authors/7.jpg")' }} />

                                            <p>
                                                William Parker, <span>Profesores Nativos </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* course */}
                            <div className="mix col-lg-3 col-md-4 col-sm-6 photo">
                                <div className="course-item">

                                    <div className="course-thumb set-bg" style={{ backgroundImage: 'url("./assets/img/webuni/courses/8.jpg")' }}>
                                        <div className="price">Precio: Desde ₽1000 </div>
                                    </div>

                                    <div className="course-info">
                                        <div className="course-text">
                                            <h5>Español para viajes</h5>
                                            <p>Lorem ipsum dolor sit amet, consectetur</p>
                                            <div className="students">120 Students</div>
                                        </div>
                                        <div className="course-author">
                                            <div className="ca-pic set-bg" style={{ backgroundImage: 'url("./assets/img/webuni/authors/8.jpg")' }} />

                                            <p>
                                                William Parker, <span>Profesores Nativos </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* course section end */}
                {/* signup section */}
                <section className="signup-section spad">
                    <div className="signup-bg set-bg" style={{ backgroundImage: 'url("./assets/img/webuni/signup-bg.jpg")' }}>

                    </div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="signup-warp">
                                    <div className="section-title-webuni text-white text-left">
                                        <h2>Regístrate para convertirte en profesor</h2>
                                        <p>
                                            Puedes llenar tus datos en este formulario o escribirmos a contact@latinox.club.
                                        </p>
                                    </div>
                                    {/* signup form */}
                                    <form className="signup-form">
                                        <input type="text" placeholder="Your Name" />
                                        <input type="text" placeholder="Your E-mail" />
                                        <input type="text" placeholder="Your Phone" />
                                        <label htmlFor="v-upload" className="file-up-btn">
                                            Upload Course
                                        </label>
                                        <input type="file" id="v-upload" />
                                        <button className="site-btn">Search Couse</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* signup section end */}

                
                {/* banner section */}
                <section className="banner-section spad">
                    <div className="container">
                        <div className="section-title-webuni mb-0 pb-2">
                            <h2>Únete a nuestra comunidad ahora!</h2>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                                malesuada lorem maximus mauris scelerisque, at rutrum nulla dictum. Ut
                                ac ligula sapien. Suspendisse cursus faucibus finibus.
                            </p>
                        </div>
                        <div className="text-center pt-5">
                            <a href="#" className="site-btn">
                                Regístrate
                            </a>
                        </div>
                    </div>
                </section>               
               
            </section >


            <Footer />

        </main>

    );

}

export default Webuni;