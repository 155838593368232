

import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Footer from './FooterRu';

import { Link } from 'react-router-dom';



function Webuni() {

    return (

        <main>

            <section >

                {/* Hero section */}


                <section className="hero-section set-bg" style={{ backgroundImage: 'url("./assets/img/webuni/bg.jpg")' }}>

                    <div className="container">
                        <div className="hero-text text-white">
                            <h2>Курсы испанского языка</h2>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                                malesuada lorem maximus mauris scelerisque, at rutrum nulla <br />{" "}
                                dictum. Ut ac ligula sapien. Suspendisse cursus faucibus finibus.
                            </p>
                        </div>
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1">
                                <form className="intro-newslatter">
                                    <input type="text" placeholder="Имя" />
                                    <input type="text" className="last-s" placeholder="Эл. почта" />
                                    <button className="site-btn">Зарегистрироваться</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Hero section end */}

                {/* categories section */}
                <section className="categories-section spad">
                    <div className="container">
                        <div className="section-title-webuni">
                            <h2>Все категории</h2>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                                malesuada lorem maximus mauris scelerisque, at rutrum nulla dictum. Ut
                                ac ligula sapien. Suspendisse cursus faucibus finibus.
                            </p>
                        </div>
                        <div className="row">
                            {/* categorie */}
                            <div className="col-lg-4 col-md-6">
                                <div className="categorie-item">
                                    <div className="ci-thumb set-bg">
                                        <img src="assets/img/webuni/categories/1.jpg" />
                                    </div>

                                    <div className="ci-text">
                                        <h5>Испанский для начинающих</h5>
                                        <p>Lorem ipsum dolor sit amet, consectetur</p>
                                        <span>120 курсов</span>
                                    </div>
                                </div>
                            </div>
                            {/* categorie */}
                            <div className="col-lg-4 col-md-6">
                                <div className="categorie-item">
                                    <div className="ci-thumb set-bg">
                                        <img src="assets/img/webuni/categories/2.jpg" />
                                    </div>
                                    <div className="ci-text">
                                        <h5>Разговорный испанский</h5>
                                        <p>Lorem ipsum dolor sit amet, consectetur</p>
                                        <span>70 курсов</span>
                                    </div>
                                </div>
                            </div>
                            {/* categorie */}
                            <div className="col-lg-4 col-md-6">
                                <div className="categorie-item">

                                    <div className="ci-thumb set-bg">
                                        <img src="assets/img/webuni/categories/3.jpg" />
                                    </div>
                                    <div className="ci-text">
                                        <h5>Переводы</h5>
                                        <p>Lorem ipsum dolor sit amet, consectetur</p>
                                        <span>55 курсов</span>
                                    </div>
                                </div>
                            </div>
                            {/* categorie */}
                            <div className="col-lg-4 col-md-6">
                                <div className="categorie-item">
                                    <div className="ci-thumb set-bg">
                                        <img src="assets/img/webuni/categories/4.jpg" />
                                    </div>
                                    <div className="ci-text">
                                        <h5>Подготовка к экзаменам</h5>
                                        <p>Lorem ipsum dolor sit amet, consectetur</p>
                                        <span>40 курсов</span>
                                    </div>
                                </div>
                            </div>
                            {/* categorie */}
                            <div className="col-lg-4 col-md-6">
                                <div className="categorie-item">
                                    <div className="ci-thumb set-bg">
                                        <img src="assets/img/webuni/categories/5.jpg" />
                                    </div>

                                    <div className="ci-text">
                                        <h5>DELE</h5>
                                        <p>Lorem ipsum dolor sit amet, consectetur</p>
                                        <span>220 курсов</span>
                                    </div>
                                </div>
                            </div>
                            {/* categorie */}
                            <div className="col-lg-4 col-md-6">
                                <div className="categorie-item">
                                    <div className="ci-thumb set-bg">
                                        <img src="assets/img/webuni/categories/6.jpg" />
                                    </div>

                                    <div className="ci-text">
                                        <h5>SIELE</h5>
                                        <p>Lorem ipsum dolor sit amet, consectetur</p>
                                        <span>25 курсов</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* categories section end */}
                {/* search section */}
                <section className="search-section">
                    <div className="container">
                        <div className="search-warp">
                            <div className="section-title-webuni text-white">
                                <h2>Найди свой курс</h2>
                            </div>
                            <div className="row">
                                <div className="col-md-10 offset-md-1">
                                    {/* search form */}
                                    <form className="course-search-form">
                                        <input type="text" placeholder="Курс" />
                                        <input type="text" className="last-m" placeholder="Категория" />
                                        <button className="site-btn">Поиск курса</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* search section end */}
                {/* course section */}
                <section className="course-section spad">
                    <div className="container">
                        <div className="section-title-webuni mb-0">
                            <h2>Связанные курсы</h2>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                                malesuada lorem maximus mauris scelerisque, at rutrum nulla dictum. Ut
                                ac ligula sapien. Suspendisse cursus faucibus finibus.
                            </p>
                        </div>
                    </div>
                    <div className="course-warp">
                        <ul className="course-filter controls">
                            <li className="control active" data-filter="all">
                                Все
                            </li>
                            <li className="control" data-filter=".finance">
                                Основы
                            </li>
                            <li className="control" data-filter=".design">
                                Средний уровень
                            </li>
                            <li className="control" data-filter=".web">
                                Продвинутый
                            </li>
                            <li className="control" data-filter=".photo">
                                DELE / SIELE
                            </li>
                        </ul>
                        <div className="row course-items-area">
                            {/* course */}
                            <div className="mix col-lg-3 col-md-4 col-sm-6 finance">
                                <div className="course-item">


                                    <div className="course-thumb set-bg" style={{ backgroundImage: 'url("./assets/img/webuni/courses/1.jpg")' }}>
                                        <div className="price">Цена: От ₽1000 </div>
                                    </div>

                                    <div className="course-info">
                                        <div className="course-text">
                                            <h5>Испанский для детей</h5>
                                            <p>Lorem ipsum dolor sit amet, consectetur</p>
                                            <div className="students">120 студентов</div>
                                        </div>
                                        <div className="course-author">

                                            <div className="ca-pic set-bg" style={{ backgroundImage: 'url("./assets/img/webuni/authors/1.jpg")' }} />

                                            <p>
                                                Ана Люсия, <span> Носители языка </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* course */}
                            <div className="mix col-lg-3 col-md-4 col-sm-6 design">
                                <div className="course-item">

                                    <div className="course-thumb set-bg" style={{ backgroundImage: 'url("./assets/img/webuni/courses/2.jpg")' }}>
                                        <div className="price">Цена: От ₽1000 </div>
                                    </div>
                                    <div className="course-info">
                                        <div className="course-text">
                                            <h5>Испанский для начинающих</h5>
                                            <p>Lorem ipsum dolor sit amet, consectetur</p>
                                            <div className="students">120 студентов</div>
                                        </div>
                                        <div className="course-author">
                                            <div className="ca-pic set-bg" style={{ backgroundImage: 'url("./assets/img/webuni/authors/2.jpg")' }} />

                                            <p>
                                                Уильям Паркер, <span>Носители языка </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* course */}
                            <div className="mix col-lg-3 col-md-4 col-sm-6 web">
                                <div className="course-item">

                                    <div className="course-thumb set-bg" style={{ backgroundImage: 'url("./assets/img/webuni/courses/3.jpg")' }}>
                                        <div className="price">Цена: От ₽1000 </div>
                                    </div>

                                    <div className="course-info">
                                        <div className="course-text">
                                            <h5>Начальный уровень</h5>
                                            <p>Lorem ipsum dolor sit amet, consectetur</p>
                                            <div className="students">120 студентов</div>
                                        </div>
                                        <div className="course-author">

                                            <div className="ca-pic set-bg" style={{ backgroundImage: 'url("./assets/img/webuni/authors/3.jpg")' }} />

                                            <p>
                                                Уильям Паркер, <span>Носители языка </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* course */}
                            <div className="mix col-lg-3 col-md-4 col-sm-6 photo">
                                <div className="course-item">

                                    <div className="course-thumb set-bg" style={{ backgroundImage: 'url("./assets/img/webuni/courses/5.jpg")' }}>
                                        <div className="price">Цена: От ₽1000 </div>
                                    </div>

                                    <div className="course-info">
                                        <div className="course-text">
                                            <h5>Международные экзамены</h5>
                                            <p>Lorem ipsum dolor sit amet, consectetur</p>
                                            <div className="students">120 студентов</div>
                                        </div>
                                        <div className="course-author">
                                            <div className="ca-pic set-bg" style={{ backgroundImage: 'url("./assets/img/webuni/authors/5.jpg")' }} />

                                            <p>
                                                Уильям Паркер, <span>Носители языка </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* course */}
                            <div className="mix col-lg-3 col-md-4 col-sm-6 finance">
                                <div className="course-item">

                                    <div className="course-thumb set-bg" style={{ backgroundImage: 'url("./assets/img/webuni/courses/4.jpg")' }}>
                                        <div className="price">Цена: От ₽1000 </div>
                                    </div>

                                    <div className="course-info">
                                        <div className="course-text">
                                            <h5>Кубинский диалект</h5>
                                            <p>Lorem ipsum dolor sit amet, consectetur</p>
                                            <div className="students">120 студентов</div>
                                        </div>
                                        <div className="course-author">
                                            <div className="ca-pic set-bg" style={{ backgroundImage: 'url("./assets/img/webuni/authors/4.jpg")' }} />

                                            <p>
                                                Уильям Паркер, <span>Носители языка </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* course */}
                            <div className="mix col-lg-3 col-md-4 col-sm-6 design">
                                <div className="course-item">

                                    <div className="course-thumb set-bg" style={{ backgroundImage: 'url("./assets/img/webuni/courses/6.jpg")' }}>
                                        <div className="price">Цена: От ₽1000 </div>
                                    </div>

                                    <div className="course-info">
                                        <div className="course-text">
                                            <h5>Переводы</h5>
                                            <p>Lorem ipsum dolor sit amet, consectetur</p>
                                            <div className="students">120 студентов</div>
                                        </div>
                                        <div className="course-author">
                                            <div className="ca-pic set-bg" style={{ backgroundImage: 'url("./assets/img/webuni/authors/6.jpg")' }} />

                                            <p>
                                                Уильям Паркер, <span>Носители языка </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* course */}
                            <div className="mix col-lg-3 col-md-4 col-sm-6 web">
                                <div className="course-item">

                                    <div className="course-thumb set-bg" style={{ backgroundImage: 'url("./assets/img/webuni/courses/7.jpg")' }}>
                                        <div className="price">Цена: От ₽1000 </div>
                                    </div>

                                    <div className="course-info">
                                        <div className="course-text">
                                            <h5>Испанский для бизнеса</h5>
                                            <p>Lorem ipsum dolor sit amet, consectetur</p>
                                            <div className="students">120 студентов</div>
                                        </div>
                                        <div className="course-author">
                                            <div className="ca-pic set-bg" style={{ backgroundImage: 'url("./assets/img/webuni/authors/7.jpg")' }} />

                                            <p>
                                                Уильям Паркер, <span>Носители языка </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* course */}
                            <div className="mix col-lg-3 col-md-4 col-sm-6 photo">
                                <div className="course-item">

                                    <div className="course-thumb set-bg" style={{ backgroundImage: 'url("./assets/img/webuni/courses/8.jpg")' }}>
                                        <div className="price">Цена: От ₽1000 </div>
                                    </div>

                                    <div className="course-info">
                                        <div className="course-text">
                                            <h5>Испанский для путешествий</h5>
                                            <p>Lorem ipsum dolor sit amet, consectetur</p>
                                            <div className="students">120 студентов</div>
                                        </div>
                                        <div className="course-author">
                                            <div className="ca-pic set-bg" style={{ backgroundImage: 'url("./assets/img/webuni/authors/8.jpg")' }} />

                                            <p>
                                                Уильям Паркер, <span>Носители языка </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* course section end */}
                {/* signup section */}
                <section className="signup-section spad">
                    <div className="signup-bg set-bg" style={{ backgroundImage: 'url("./assets/img/webuni/signup-bg.jpg")' }}>

                    </div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="signup-warp">
                                    <div className="section-title-webuni text-white text-left">
                                        <h2>Зарегистрируйтесь, чтобы стать преподавателем</h2>
                                        <p>
                                            Вы можете заполнить свои данные в этой форме или написать нам по адресу contact@latinox.club.
                                        </p>
                                    </div>
                                    <form className="signup-form">
                                        <input type="text" placeholder="Ваше имя" />
                                        <input type="text" placeholder="Ваш адрес электронной почты" />
                                        <input type="text" placeholder="Ваш телефон" />
                                        <label htmlFor="v-upload" className="file-up-btn">
                                            Загрузить курс
                                        </label>
                                        <input type="file" id="v-upload" />
                                        <button className="site-btn">Найти курс</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* signup section end */}
                {/* banner section */}
                <section className="banner-section spad">
                    <div className="container">
                        <div className="section-title-webuni mb-0 pb-2">
                            <h2>Присоединяйтесь к нашему сообществу сейчас!</h2>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                                malesuada lorem maximus mauris scelerisque, at rutrum nulla dictum. Ut
                                ac ligula sapien. Suspendisse cursus faucibus finibus.
                            </p>
                        </div>
                        <div className="text-center pt-5">
                            <a href="#" className="site-btn">
                                Зарегистрироваться
                            </a>
                        </div>
                    </div>
                </section>


            </section >


            <Footer />

        </main>

    );

}

export default Webuni;