

import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Footer from './FooterRu';
import { Link } from 'react-router-dom';
import YandexAd from '../YandexAd';
import Adx1BannerVideo from '../Adx1BannerVideo';


function Home() {

    {/* =============== Primer slide principal -  Trending Area Start =============== */ }
    useEffect(() => {
        const slider = document.querySelector('.slider-active');
        const slideElements = slider.querySelectorAll('.single-slider');

        const doAnimations = (elements) => {
            elements.forEach((element) => {
                const animationDelay = element.getAttribute('data-delay');
                const animationClasses = element.getAttribute('data-animation').split(' ');

                animationClasses.forEach((cls) => {
                    element.classList.add(cls);
                });

                element.style.animationDelay = animationDelay;
                element.style.webkitAnimationDelay = animationDelay;
            });
        };

        doAnimations(slideElements[0].querySelectorAll('[data-animation]'));

        slider.addEventListener('beforeChange', (e, slick, currentSlide, nextSlide) => {
            doAnimations(slideElements[nextSlide].querySelectorAll('[data-animation]'));
        });
    }, []);

    const sliderSettings = {
        autoplay: true, autoplaySpeed: 3500, dots: false, fade: false, arrows: false,
        responsive: [
            {breakpoint: 1024, settings: {slidesToShow: 1, slidesToScroll: 1, infinite: true, },},
            {breakpoint: 991, settings: {slidesToShow: 1, slidesToScroll: 1, arrows: false, },},
            {breakpoint: 767, settings: {slidesToShow: 1, slidesToScroll: 1, arrows: false, },},
        ],
    };

    const sliderData = [
        {imgSrc: '/assets/img/trending/slide-latina.jpg', category: 'Lifestyle',
            title: 'В Москве пройдет фестиваль культуры стран Латинской Америки и Карибского бассейна',
            date: 'Jul 14, 2024', autor: 'Irina V.', route: '/latina-fest-Moscow',
        },
        {imgSrc: '/assets/img/trending/openair5.jpg', category: 'Lifestyle',
            title: 'Опен-эйр: Сальсы и Бачаты под открытым небом в Москве.',
            date: 'May 04, 2024', autor: 'Elena V.', route: '/open-air-in-Moscow',
        },
        {imgSrc: 'assets/img/trending/girls-party-lights2.png', category: 'Lifestyle',
            title: 'Латино ритм в Москве: наслаждай вечеринки, заставляющие танцевать Россию',
            date: 'May 08, 2024', autor: 'Julia R.', route: '/latino-dance-in-Moscow',
        },
        {imgSrc: 'assets/img/trending/girl-beacha.png', category: 'Путешествия',
            title: 'Испания: Незабываемое путешествие между историей, культурой и пейзажами',
            date: 'Jan 05, 2024', autor: 'Alice K.', route: '/travel-to-spain',
        },
    ];
    {/* =============== Primer slide principal -  Trending Area End =============== */ }

    {/* ====== Whats New Start - Eventos Generales - Aficha ====== */ }
    const tabEvents = {
        Вечеринки: {
            main: {
                imgSrc: '/assets/img/schdance/salsateca-gorky-a.jpg', date: 'May-Sep, 2024', author: 'Open Air', colorClass: 'colorb',
                title: 'Сальсатека, Сальса, Бачата, опены от Юрия Смирнова', link: '/salsateca',
                description: 'С мая по октябрь танцы в Парке Горького - Нескучный сад. И в парке искусств МУЗЕОН на площадке "НОВА". Сальса - бачата опен-эйры по понедельникам, вторникам, пятницам субботам и воскресеньям. на 🌫Пушкинской набережной, пн., вт., пт., сб. - дальняя площадка, вс. - ближняя площадка от Андреевского моста.',
            },
            right: [{
                imgSrc: '/assets/img/events/bachata-open.jpg', category: 'Eventos', colorClass: 'colorb',
                title: 'БАЧАТА-ОПЕНЫ В Парке Горького 🔥', date: 'May-Sep, 2024', link: 'https://vk.com/openairb',
            },{
                imgSrc: '/assets/img/events/lima-28-07a.jpg', category: 'Eventos', colorClass: 'colorg',
                title: ' Лима ресторан - Перу 🇵🇪! 🤩🥳', date: 'Jul 27-28, 2024', link: '/events-dance',
            },{
                imgSrc: '/assets/img/events/agave-28-07.jpg', category: 'Eventos', colorClass: 'colorr',
                title: 'Bachata Love в Casa Agave 🤩', date: 'Jul 24-28, 2024', link: '/events-dance',
            },{
                imgSrc: '/assets/img/events/fusion-27-07.jpg', category: 'Events', colorClass: 'colorg',
                title: 'LATIN FUSION Party 💃🏼💣', date: 'Jul 27, 2024', link: '/events-dance',
            },]
        },
        Культура: {
            main: {
                imgSrc: '/assets/img/event-isp/dom-latina.jpg', date: 'May 24, 2024', author: 'Главный корпус РУДН', colorClass: 'colorr',
                title: 'Лекция «Традиционный костюм народов стран Латинской Америки и России»', link: '/events-culture',
                description: 'Проект «Россия – Латинская Америка: дизайн сквозь континенты» направлен на поддержку российских и латиноамериканских дизайнеров, содействие организации международных коллабораций, развитие креативных индустрий и международного гуманитарного сотрудничества, культурный обмен',
            },
            right: [{
                imgSrc: '/assets/img/event-isp/ibero-dance.jpg', category: 'События', colorClass: 'colorg',
                title: 'МАСТЕР-КЛАСС ПО ЛАТИНОАМЕРИКАНСКИМ ТАНЦАМ 💫', date: 'May 26, 2024', link: '/events-culture',
            }, {
                imgSrc: '/assets/img/event-isp/ibero-24-05.jpg', category: 'События', colorClass: 'colorr',
                title: 'SEIS Y MEDIO: СПЕКТАКЛЬ НА ИСПАНСКОМ ЯЗЫКЕ', date: 'May 24, 2024', link: '/events-culture',
            }, {
                imgSrc: '/assets/img/event-isp/embper-240516.jpg', category: 'События', colorClass: 'colorb',
                title: 'TALLER DE TEJIDO TRADICIONAL CUSQUEÑO', date: 'May 16, 2024', link: '/events-culture',
            }, {
                imgSrc: '/assets/img/gallery/whats_right_img3.png', category: 'События', colorClass: 'colorr',
                title: '24 Aequam memento rebus in arduis servare mentem', date: 'Apr 19, 2024', link: '/',
            },]
        },
        Язык: {
            main: {
                imgSrc: '/assets/img/event-isp/rusonol-12-05.jpg', date: 'May 26, 2024', author: 'Ибероамериканский к. центр', colorClass: 'colorb',
                title: 'Rusoñol: русско-испанский разговорный клуб 🎧', link: '/latino-dance-in-Moscow',
                description: '«Rusoñol» — это двуязычный разговорный клуб, который уже на протяжении многих лет объединяет людей, культуры, страны и континенты. Ибероамериканский культурный центр почти с самого своего открытия стал основной площадкой проведения встреч, которые проходят на русском и испанском языках.',
            },
            right: [{
                imgSrc: '/assets/img/event-isp/ibero-03-06.jpg', category: 'События', colorClass: 'colorg',
                title: 'ЛЕКЦИЯ “РАБЫНЯ ИЗАУРА: АНАЛИЗ ПРОИЗВЕДЕНИЯ БЕРНАРДО ГИМАРАЙНША”', date: 'Jun 03, 2024', link: '/events-dance',
            }, {
                imgSrc: '/assets/img/event-isp/dom-latina.jpg', category: 'События', colorClass: 'colorr',
                title: 'Лекция «Традиционный костюм народов стран Латинской Америки и России»', date: 'May 24, 2024', link: '/events-dance',
            }, {
                imgSrc: '/assets/img/events/latin-fusion-12-05.jpg', category: 'События', colorClass: 'colorb',
                title: 'LATIN FUSION Party 💃🏼💣', date: 'May 12, 2024', link: '/events-dance',
            }, {
                imgSrc: '/assets/img/events/muza-12-05.jpg', category: 'События', colorClass: 'colorg',
                title: 'KIZOMBA COSMOS PARTY', date: 'May 12, 2023', link: '/events-dance',
            },]
        },
        Сообщество: {
            main: {
                imgSrc: '/assets/img/event-isp/instilat-14-05.jpg', date: 'May 14-15, 2024', author: 'Alice cloe', colorClass: 'colorr',
                title: 'Молодые исследователи в поисках нового взгляда на региональную проблематику', link: '/events-culture',
                description: '«Латинская Америка: молодые исследователи в поисках нового взгляда на региональную проблематику» с целью повышения их квалификации, расширения поля профессиональных контактов и формирования горизонтальных связей.',
            },
            right: [{
                imgSrc: '/assets/img/event-isp/ibero-24-05.jpg', category: 'События', colorClass: 'colorr',
                title: 'SEIS Y MEDIO: СПЕКТАКЛЬ НА ИСПАНСКОМ ЯЗЫКЕ', date: 'Mayo 24, 2024', link: '/events-culture',
            }, {
                imgSrc: '/assets/img/event-isp/embper-240516.jpg', category: 'События', colorClass: 'colorb',
                title: 'TALLER DE TEJIDO TRADICIONAL CUSQUEÑO', date: 'May 16, 2024', link: '/events-culture',
            }, {
                imgSrc: '/assets/img/event-isp/ibero-12-05.jpg', category: 'События', colorClass: 'colorg',
                title: 'МАСТЕР-КЛАСС ПО ЛАТИНОАМЕРИКАНСКИМ ТАНЦАМ 💫', date: 'May 12, 2024', link: '/events-culture',
            }, {
                imgSrc: '/assets/img/gallery/whats_right_img3.png', category: 'События', colorClass: 'colorr',
                title: '24 Aequam memento rebus in arduis servare mentem', date: 'Apr 19, 2024', link: '/',
            },]
        },
        Концерты: {
            main: {
                imgSrc: '/assets/img/gallery/girl-band-music.png', date: 'May 06, 2024', author: 'Alice C.', colorClass: 'colorb',
                title: 'Латино ритм в Москве: вечеринки заставляющие танцевать Россию', link: '/latino-dance-in-Moscow',
                description: 'Eventos especiales como la Noche Latina Internacional ofrecen una combinación única de géneros latinos, que atrae a una audiencia diversa que comparte la pasión por la música y el baile.',
            },
            right: [{
                imgSrc: '/assets/img/events/corazon-18-05.jpg', category: 'События', colorClass: 'colorb',
                title: 'Corazon • Бачата-вечеринки 🔥', date: 'May 18, 2024', link: '/events-dance',
            }, {
                imgSrc: '/assets/img/events/shine-09-05.jpg', category: 'События', colorClass: 'colorr',
                title: 'Shine Bachata Festival 🏆🔥', date: 'May 09-12, 2024', link: '/events-dance',
            }, {
                imgSrc: '/assets/img/events/latin-fusion-12-05.jpg', category: 'События', colorClass: 'colorg',
                title: 'LATIN FUSION Party 💃🏼💣', date: 'May 12, 2024', link: '/events-dance',
            }, {
                imgSrc: '/assets/img/events/muza-12-05.jpg', category: 'События', colorClass: 'colorb',
                title: 'KIZOMBA COSMOS PARTY', date: 'May 12, 2024', link: '/events-dance',
            },]
        },
    };
    {/* ====== Whats New End - Eventos Generales - Aficha ====== */ }


    {/* ===============  Weekly2-News start - Most Popular * =============== */ }
    const SliderSettings = {
        /* dots: false,
       // fade: false,             
        centerMode: false, */ // Para que el modo bucle funcione correctamente
        arrows: false,
        infinite: true, // Para habilitar el deslizamiento continuo
        speed: 500, // Velocidad de transición de las diapositivas en milisegundos
        slidesToShow: 3, // Número de diapositivas para mostrar a la vez
        slidesToScroll: 1, // Número de diapositivas para desplazar a la vez
        autoplay: true, // Habilitar la reproducción automática
        //autoplaySpeed: 2000, // Velocidad de reproducción automática en milisegundos
        prevArrow: <div className="slick-prev"><i className="ti-angle-left"></i></div>,
        nextArrow: <div className="slick-next"><i className="ti-angle-right"></i></div>,

        responsive: [
            {breakpoint: 1200, settings: {slidesToShow: 3, slidesToScroll: 1, }}, 
            {breakpoint: 992, settings: {slidesToShow: 3, slidesToScroll: 1 }},
            {breakpoint: 700, settings: {arrows: false, slidesToShow: 2, slidesToScroll: 1 }},
            {breakpoint: 480, settings: {arrows: false, slidesToShow: 1, slidesToScroll: 1 }}
        ]
    };
    {/* ===============  Weekly2-News End - Most Popular * =============== */ }


    {/* =============== Recent Articles Start -Trending News =============== */ }
    const [selectedVideo, setSelectedVideo] = useState(null);

    const openVideo = (videoUrl) => {
        setSelectedVideo(videoUrl);
    }

    const sliderSettingsRecent = {
        dots: true, infinite: true, speed: 600, arrows: false, slidesToShow: 3, slidesToScroll: 1,
        /*  prevArrow: <button type="button" className="slick-prev"> <span className="flaticon-arrow"></span></button>,
         nextArrow: <button type="button" className="slick-next"> <span className="flaticon-arrow"></span></button>, */
        initialSlide: 3, loop: true,
        responsive: [
            {breakpoint: 1024, settings: {slidesToShow: 3, slidesToScroll: 3, infinite: true, dots: false, } },
            {breakpoint: 992, settings: {slidesToShow: 2, slidesToScroll: 1 } },
            {breakpoint: 768, settings: {slidesToShow: 1, slidesToScroll: 1 } }
        ]
    };
    {/* =============== Recent Articles End -Trending News =============== */ }


    {/* =============== Start Video Area =============== */ }
    const videos = [
        { url: 'assets/video/reel/VID_2042.mp4', title: 'Video', description: 'Latino Party' },
        { url: 'assets/video/reel/VID_240815.MOV', title: '15.08.24', description: 'Open Air - ПГ' },
        { url: 'assets/video/reel/VID_240725.MOV', title: '25.07.24', description: 'Open Air - ПГ' },
        { url: 'assets/video/reel/VID_240720.MOV', title: '20.07.24', description: 'Open Air - ПГ' },
        { url: 'assets/video/reel/VID_240719.MOV', title: '19.07.24', description: 'Open Air - ПГ' },
        { url: 'assets/video/reel/VID_240718.MOV', title: '18.07.24', description: 'Open Air - ПГ' },
        { url: 'assets/video/reel/VID_240716.MOV', title: '16.07.24', description: 'Open Air - ПГ' },
        { url: 'assets/video/reel/VID_240714.MOV', title: '14.07.24', description: 'Open Air - ПГ' },
        { url: 'assets/video/reel/VID_240712.MOV', title: '12.07.24', description: 'Open Air - ПГ' },
        { url: 'assets/video/reel/VID_240710.MOV', title: '10.07.24', description: 'Open Air - ПГ' },
        { url: 'assets/video/reel/VID_240707.MOV', title: '07.07.24', description: 'Open Air - ПГ' },
        { url: 'assets/video/reel/VID_240703.MOV', title: '03.07.24', description: 'Open Air - ПГ' },
        { url: 'assets/video/reel/VID_240622.MOV', title: '22.06.24', description: 'Open Air - ПГ' },
    ];

    const [currentVideo, setCurrentVideo] = useState(videos[0]);

    const sliderSettingsVideo = {
        infinite: true, slidesToShow: 5, slidesToScroll: 1,
        responsive: [
            { breakpoint: 1024, settings: { slidesToShow: 4, infinite: true, dots: true, }, },
            { breakpoint: 700, settings: { infinite: true, slidesToShow: 3, slidesToScroll: 1, dots: true, }, },
            { breakpoint: 480, settings: { infinite: true, slidesToShow: 3, slidesToScroll: 1, dots: true, }, },
        ],
    };
    {/* ===============  End Video area =============== */ }


    {/* ===============  Weekly3-News Start - Школы испанского языка -  Tira Media =============== */ }
    const sliderSettingsTira = {
        dots: true, infinite: true, speed: 500, arrows: false, autoplay: true, infinite: true, slidesToShow: 4, slidesToScroll: 1,
        responsive: [
            {breakpoint: 1200, settings: {slidesToShow: 4, slidesToScroll: 1, infinite: true, dots: true, },},
            {breakpoint: 992, settings: {slidesToShow: 3, slidesToScroll: 1, },},
            {breakpoint: 700, settings: {arrows: false, slidesToShow: 2, slidesToScroll: 1, }, },
            {breakpoint: 480, settings: {arrows: false, slidesToShow: 1, slidesToScroll: 1, }, },
        ],
    };
    {/* ===============  Weekly3-News End - Tira Media =============== */ }


    return (

        <main >

            {/* <Menu /> */}

            <section>

                <div className="front-page1">

                    {/* =============== Trending Area Start ===============  */}
                    <div className="trending-area fix pt-25 gray-bg">
                        <div className="container">
                            <div className="trending-main">
                                <div className="row">
                                    <div className="col-lg-8">

                                        <Slider className="slider-active" {...sliderSettings}>

                                            {sliderData.map((slide, index) => (


                                                <div className="single-slider" key={index}>
                                                    <div className="trending-top mb-30">
                                                        <div className="trend-top-img">


                                                            <img src={slide.imgSrc} alt="" />


                                                            <div className="trend-top-cap">
                                                                <span className="bgr" data-animation="fadeInUp" data-delay=".2s" data-duration="1000ms">
                                                                    {slide.category}
                                                                </span>

                                                                <h2>
                                                                    <li>
                                                                        <Link to={slide.route} data-animation="fadeInUp" data-delay=".4s" data-duration="1000ms">
                                                                            {slide.title}
                                                                        </Link>
                                                                    </li>
                                                                </h2>


                                                                <p data-animation="fadeInUp" data-delay=".6s" data-duration="1000ms">
                                                                    {`Автор ${slide.autor} - ${slide.date}`}
                                                                </p>

                                                                {/*    {console.log('slide.route:', slide.route)} */}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </Slider>


                                    </div>



                                    {/* ======================= Right content ======================= */}

                                    <div className="col-lg-4">
                                        {/* Trending Top */}
                                        <div className="row">
                                            <div className="col-lg-12 col-md-6 col-sm-6">
                                                <div className="trending-top mb-30">
                                                    <div className="trend-top-img">
                                                        <img src="/assets/img/events/poster-latina-fest.jpg" alt="" />
                                                        <div className="trend-top-cap trend-top-cap2">
                                                            <span className="bgb">События</span>
                                                            <h2>
                                                                <li> <Link to="/latina-fest-Moscow"> Фестиваль культуры стран Латинской Америки и Карибского </Link></li>

                                                            </h2>
                                                            <p>Moscow  - Jul 20 - 21, 2024</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-12 col-md-6 col-sm-6">
                                                <div className="trending-top mb-30">
                                                    <div className="trend-top-img">
                                                        <img src="/assets/img/blog/openair1.webp" alt="" />
                                                        <div className="trend-top-cap trend-top-cap2">
                                                            <span className="bgg"> Москва </span>
                                                            <h2>
                                                                <Link to="/open-air-in-Moscow"> Опен-эйр в Москве с латиноамериканском ритмом </Link>
                                                            </h2>
                                                            <p>Автор Alice cloe - Apr 19, 2024</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                    {/* =============== Trending Area End ===============  */}

                    {/* -------------- Yandex Banner 01 -------------- */}
                    <YandexAd blockId="R-A-9762127-1" renderTo="yandex_rtb_R-A-9762127-1" />


                    {/* ====== Whats New Start - Eventos Generales - Aficha ====== */}
                    <section className="whats-news-area pt-50 pb-20 gray-bg">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="whats-news-wrapper">

                                        {/* Heading & Nav Button */}
                                        <div className="row justify-content-between align-items-end mb-15">
                                            <div className="col-xl-4">
                                                <div className="section-tittle mb-30">
                                                    <h3>События</h3>
                                                </div>
                                            </div>
                                            <div className="col-xl-8 col-md-9">
                                                <div className="properties__button">
                                                    <nav>
                                                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                                            {Object.keys(tabEvents).map((tab, index) => (
                                                                <a
                                                                    key={index}
                                                                    className={`nav-item nav-link ${index === 0 ? 'active' : ''}`}
                                                                    id={`nav-${tab}-tab`}
                                                                    data-toggle="tab"
                                                                    href={`#nav-${tab}`}
                                                                    role="tab"
                                                                    aria-controls={`nav-${tab}`}
                                                                    aria-selected={index === 0 ? 'true' : 'false'}
                                                                >
                                                                    {tab}
                                                                </a>
                                                            ))}
                                                        </div>
                                                    </nav>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Tab content */}
                                        <div className="row">
                                            <div className="col-12">
                                                {/* Nav Card */}
                                                <div className="tab-content" id="nav-tabContent">
                                                    {Object.keys(tabEvents).map((tab, index) => (
                                                        <div
                                                            key={index}
                                                            className={`tab-pane fade ${index === 0 ? 'show active' : ''}`}
                                                            id={`nav-${tab}`}
                                                            role="tabpanel"
                                                            aria-labelledby={`nav-${tab}-tab`}
                                                        >
                                                            {tabEvents[tab].main && (
                                                                <div className="row">
                                                                    <div className="col-xl-6 col-lg-12">
                                                                        <div className="whats-news-single mb-40 mb-40">
                                                                            <div className="whates-img">
                                                                                <img
                                                                                    src={tabEvents[tab].main.imgSrc}
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                            <div className="whates-caption">
                                                                                <h4>
                                                                                    <Link to={tabEvents[tab].main.link}>
                                                                                        {tabEvents[tab].main.title}
                                                                                    </Link>
                                                                                </h4>
                                                                                <span> в {tabEvents[tab].main.author} - {tabEvents[tab].main.date}</span>
                                                                                <p>{tabEvents[tab].main.description}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-xl-6 col-lg-12">
                                                                        <div className="row">
                                                                            {tabEvents[tab].right.map((event, eventIndex) => (
                                                                                <div key={eventIndex} className="col-6 col-xl-12 col-lg-6 col-md-6 col-sm-6">
                                                                                    <div className="whats-right-single mb-20">
                                                                                        <div className="whats-right-img">
                                                                                            <img src={event.imgSrc} alt="" />
                                                                                        </div>
                                                                                        <div className="whats-right-cap">
                                                                                            <span className={event.colorClass}>{event.category}</span>
                                                                                            <h4>
                                                                                                <Link to={event.link} target="_blank">{event.title}</Link>
                                                                                            </h4>
                                                                                            <p>{event.date}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {!tabEvents[tab].main && (
                                                                <div className="row">
                                                                    {tabEvents[tab].right.map((event, eventIndex) => (
                                                                        <div key={eventIndex} className="col-6 col-xl-12 col-lg-6 col-md-6 col-sm-6">
                                                                            <div className="whats-right-single mb-20">
                                                                                <div className="whats-right-img">
                                                                                    <img src={event.imgSrc} alt="" />
                                                                                </div>
                                                                                <div className="whats-right-cap">
                                                                                    <span className={event.colorClass}>{event.category}</span>
                                                                                    <h4>
                                                                                        <Link to={event.link}>{event.title}</Link>
                                                                                    </h4>
                                                                                    <p>{event.date}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Banner */}
                                    <div className="banner-one mt-20 mb-30">
                                        <img src="/assets/img/gallery/body_card1.png" alt="" />
                                    </div>

                                </div>

                                <div className="col-lg-4">
                                    {/* Flow Socail */}
                                    <div className="single-follow mb-45">
                                        <div className="single-box">

                                            <div className="follow-us d-flex align-items-center">
                                                <div className="follow-social">
                                                    <Link to="https://www.youtube.com/OrbitRussia" target="_blank"> <img src="/assets/img/news/icon-yo.png" alt="" /> </Link>
                                                </div>
                                                <div className="follow-count">
                                                    <span> 107,125 </span>
                                                    <p>Subs</p>
                                                </div>
                                            </div>

                                            <div className="follow-us d-flex align-items-center">
                                                <div className="follow-social">
                                                    <Link to="https://instagram.com/latinox.club" target="_blank"> <img src="/assets/img/news/icon-ins.png" alt="" /> </Link>
                                                </div>
                                                <div className="follow-count">
                                                    <span>2351</span>
                                                    <p>Follow</p>
                                                </div>
                                            </div>

                                            <div className="follow-us d-flex align-items-center">
                                                <div className="follow-social">
                                                    <Link to="https://t.me/latinoxclub" target="_blank"> <img src="/assets/img/news/icon-tel.png" alt="" /> </Link>
                                                </div>
                                                <div className="follow-count">
                                                    <span>1262</span>
                                                    <p>Subs</p>
                                                </div>
                                            </div>

                                            <div className="follow-us d-flex align-items-center">

                                                <div className="follow-social">
                                                    <Link to="https://vk.com/latinox.club" target="_blank"> <img src="/assets/img/news/icon-vk.png" alt="" /> </Link>
                                                </div>
                                                <div className="follow-count">
                                                    <span>3,045</span>
                                                    <p>Fans</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    {/* Most Recent Area */}
                                    <div className="most-recent-area">
                                        {/* Section Tittle */}
                                        <div className="small-tittle mb-20">
                                            <h4>Музыка - Песни Текст </h4>
                                        </div>
                                        {/* Details */}
                                        <div className="most-recent mb-40">
                                            <div className="most-recent-img">
                                                <img src="/assets/img/gallery/entertaiment-01.jpg" alt="" />
                                                <div className="most-recent-cap">
                                                    <span className="bgbeg">Музыка</span>
                                                    <h4>
                                                        <Link to="/la-bachata-manuel-turizo"> Твоя любимая музыка <br />
                                                            Переведенные тексты песен </Link>

                                                    </h4>
                                                    <p>Andres L. | 2 hours ago</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Single */}
                                        <div className="most-recent-single">
                                            <div className="most-recent-images">
                                                <img src="/assets/img/gallery/popular-post-04.jpg" alt="" />
                                            </div>
                                            <div className="most-recent-capt">
                                                <h4>

                                                    <Link to="/la-bachata-manuel-turizo "> Verus amor nullum novit habere modum. </Link>

                                                </h4>
                                                <p>Jhon | 2 hours ago</p>
                                            </div>
                                        </div>
                                        {/* Single */}
                                        <div className="most-recent-single">
                                            <div className="most-recent-images">
                                                <img src="/assets/img/gallery/popular-post-05.jpg" alt="" />
                                            </div>
                                            <div className="most-recent-capt">
                                                <h4>
                                                    <Link to="/la-bachata-manuel-turizo "> Verus amor nullum novit habere modum. </Link>
                                                </h4>
                                                <p>Ana K. | 3 hours ago</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </section>
                    {/* ====== Whats New End - Eventos Generales - Aficha ====== */}

                    {/* -------------- Yandex Banner 02 -------------- */}
                    <YandexAd blockId="R-A-9762127-1" renderTo="yandex_rtb_R-A-9762127-1" />


                    {/* ===============  Weekly2-News Start - Танцевальные Школы  - Most Popular * =============== */}
                    <div className="weekly2-news-area pt-50 pb-30 gray-bg">
                        <div className="container">
                            <div className="weekly2-wrapper">
                                <div className="row">


                                    {/* Banner */}
                                    <div className="col-lg-3">
                                        <div className="home-banner2 d-none d-lg-block">
                                            {/* -------------- Yandex Banner 08 -------------- */}
                                            <Adx1BannerVideo blockId="R-A-9762127-8" renderTo="yandex_rtb_R-A-9762127-8"/>                                        
                                        </div>
                                    </div>


                                    <div className="col-lg-9">
                                        <div className="slider-wrapperMain">

                                            {/* section Tittle */}
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="small-tittle mb-30">
                                                        <h4> Танцевальные Школы </h4>
                                                    </div>
                                                </div>
                                            </div>


                                            {/* Slider Most Popular */}
                                            <div className="row">

                                                <div className="col-lg-12">
                                                    {/* <div className="weekly2-news-active d-flex"> */}
                                                    <div className="weekly2-news-active">

                                                        {/* <Slider {...SliderSettings} className="weekly2-news-active"> */}

                                                        <Slider {...SliderSettings}>

                                                            {/* Single 1 */}
                                                            <div className="weekly2-single">
                                                                <div className="weekly2-img">
                                                                    <img
                                                                        src="/assets/img/banner/salsateca.jpg"
                                                                        alt=""

                                                                    />
                                                                </div>
                                                                <div className="weekly2-caption">
                                                                    <h4>
                                                                        <Link to="/salsateca">  "Salsateca" </Link>
                                                                    </h4>

                                                                    <p>Москва | М. Пролетарская</p>
                                                                </div>
                                                            </div>


                                                            {/* Single 2 */}
                                                            <div className="weekly2-single">
                                                                <div className="weekly2-img">
                                                                    <img
                                                                        src="/assets/img/banner/Bailemos-dance.jpg"
                                                                        alt=""
                                                                    // className='image-responsive'
                                                                    />
                                                                </div>
                                                                <div className="weekly2-caption">
                                                                    <h4>
                                                                        <Link to="/schools-dance"> "Bailemos Dance" </Link>
                                                                    </h4>

                                                                    <p>Москва | М. Пролетарская</p>
                                                                </div>
                                                            </div>


                                                            {/* Single 3 */}
                                                            <div className="weekly2-single">
                                                                <div className="weekly2-img">
                                                                    <img
                                                                        src="/assets/img/banner/Brillo-latino.jpg"
                                                                        alt=""

                                                                    />
                                                                </div>
                                                                <div className="weekly2-caption">
                                                                    <h4>
                                                                        <Link to="/schools-dance"> "Brillo Latino" </Link>
                                                                    </h4>

                                                                    <p>Москва | м. Китай-город </p>
                                                                </div>
                                                            </div>

                                                            {/* Single  4*/}
                                                            <div className="weekly2-single">
                                                                <div className="weekly2-img">
                                                                    <img
                                                                        src="/assets/img/banner/Wilkin-dance.jpg"
                                                                        alt=""

                                                                    />
                                                                </div>
                                                                <div className="weekly2-caption">
                                                                    <h4>
                                                                        <Link to="/schools-dance"> "Wilkin Dance" </Link>
                                                                    </h4>

                                                                    <p> Москва | м. Пролетарская</p>
                                                                </div>
                                                            </div>


                                                            {/* Single 5 */}
                                                            <div className="weekly2-single">
                                                                <div className="weekly2-img">
                                                                    <img
                                                                        src="/assets/img/banner/Loco-danza.jpg"
                                                                        alt=""

                                                                    />
                                                                </div>
                                                                <div className="weekly2-caption">
                                                                    <h4>
                                                                        <Link to="/schools-dance"> "LocoDanza" </Link>
                                                                    </h4>

                                                                    <p> Москва | м. Бауманская </p>
                                                                </div>
                                                            </div>

                                                            {/* Single 6 */}
                                                            <div className="weekly2-single">
                                                                <div className="weekly2-img">
                                                                    <img
                                                                        src="/assets/img/banner/Boris-Quintero.jpg"
                                                                        alt=""

                                                                    />
                                                                </div>
                                                                <div className="weekly2-caption">
                                                                    <h4>
                                                                        <Link to="/schools-dance"> "Boris Quintero" </Link>
                                                                    </h4>

                                                                    <p> Москва | м. Белорусская </p>
                                                                </div>
                                                            </div>

                                                            {/* Single 7 */}
                                                            <div className="weekly2-single">
                                                                <div className="weekly2-img">
                                                                    <img
                                                                        src="/assets/img/banner/Chino-baile.jpg"
                                                                        alt=""

                                                                    />
                                                                </div>
                                                                <div className="weekly2-caption">
                                                                    <h4>
                                                                        <Link to="/schools-dance"> "CHINO Dance" </Link>
                                                                    </h4>

                                                                    <p> Москва | м. Проспект Мира </p>
                                                                </div>
                                                            </div>


                                                        </Slider>

                                                    </div>



                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className=" weekly3-caption">
                                                            <div className=" bottom-0 ">
                                                                <Link to="/schools-dance" className=" btn btn-sm btn-primary px-3"
                                                                    style={{ borderRadius: "30px 30px 30px 30px" }}>
                                                                    Все школы
                                                                </Link>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                            </div>




                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* ===============  Weekly2-News End - Most Popular * =============== */}



                    {/* =============== Youtube - Recent Articles Start -Trending News =============== */}
                    <div className="recent-articles pt-80 pb-80">
                        <div className="container">
                            <div className="recent-wrapper">

                                {/* section Tittle */}
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-tittle mb-30">
                                            <h3>Латино Музыка</h3>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        {/* <div className="recent-active dot-style d-flex dot-style"> */}
                                        <div className="recent-active dot-style dot-style">

                                            <Slider {...sliderSettingsRecent}>

                                                {/* Single */}
                                                <div className="single-recent">
                                                    <div className="what-img">
                                                        <img src="/assets/img/gallery/la-bachata-turizo.jfif" alt="" />
                                                    </div>
                                                    <div className="what-cap">
                                                        <h4>
                                                            <a href="#"> </a>
                                                            <h4>
                                                                <a href="#" />
                                                                <Link to=""> La Bachata - Manuel Turizo MTZ </Link>
                                                            </h4>
                                                        </h4>
                                                        <p>Dec 15, 2024</p>
                                                        <a
                                                            className="popup-video btn-icon"
                                                            onClick={() => openVideo('https://www.youtube.com/watch?v=TiM_TFpT_DE')}
                                                        >
                                                            <span className="flaticon-play-button" />
                                                        </a>
                                                    </div>
                                                </div>

                                                {/* Single */}
                                                <div className="single-recent">
                                                    <div className="what-img">
                                                        <img src="/assets/img/gallery/Pedro-Capo-Calma.png" alt="" />
                                                    </div>
                                                    <div className="what-cap">
                                                        <h4>
                                                            <Link to=""> Calma - Pedro Capó  </Link>
                                                        </h4>
                                                        <p>Dec 16, 2024</p>
                                                        <a
                                                            className="popup-video btn-icon"
                                                            onClick={() => openVideo('https://www.youtube.com/watch?v=1_zgKRBrT0Y')}
                                                        >
                                                            <span className="flaticon-play-button" />
                                                        </a>
                                                    </div>
                                                </div>


                                                {/* Single */}
                                                <div className="single-recent">
                                                    <div className="what-img">
                                                        <img src="/assets/img/gallery/un-año2.jfif" alt="" />
                                                    </div>
                                                    <div className="what-cap">
                                                        <h4>
                                                            <Link to=" "> Un Año - Reik, Sebastián Yatra    </Link>
                                                        </h4>
                                                        <p>Dec 17, 2024</p>
                                                        <a
                                                            className="popup-video btn-icon"
                                                            onClick={() => openVideo('https://www.youtube.com/watch?v=ghAvJMxE1qo')}
                                                        >
                                                            <span className="flaticon-play-button" />
                                                        </a>
                                                    </div>
                                                </div>

                                                {/* Single */}
                                                <div className="single-recent">
                                                    <div className="what-img">
                                                        <img src="/assets/img/gallery/Micro-TDH.png" alt="" />
                                                    </div>
                                                    <div className="what-cap">
                                                        <h4>
                                                            <Link to=" "> NEGRO MATE - Micro TDH  </Link>
                                                        </h4>
                                                        <p>Apr 19, 2024</p>
                                                        <a
                                                            className="popup-video btn-icon"
                                                            onClick={() => openVideo('https://www.youtube.com/watch?v=DhhreLJvXLU')}
                                                        >
                                                            <span className="flaticon-play-button" />
                                                        </a>
                                                    </div>
                                                </div>


                                                {/* Single */}
                                                <div className="single-recent">
                                                    <div className="what-img">
                                                        <img src="/assets/img/gallery/Tacones-Rojos-Yatra.jfif" alt="" />
                                                    </div>
                                                    <div className="what-cap">
                                                        <h4>
                                                            <Link to=" "> Tacones Rojos - Sebastián Yatra  </Link>
                                                        </h4>

                                                        <p>Dec 15, 2024</p>
                                                        <a
                                                            className="popup-video btn-icon"
                                                            onClick={() => openVideo('https://www.youtube.com/watch?v=Qz9gmiLBVFA')}
                                                        >
                                                            <span className="flaticon-play-button" />
                                                        </a>
                                                    </div>
                                                </div>


                                                {/* Single */}
                                                <div className="single-recent">
                                                    <div className="what-img">
                                                        <img src="/assets/img/gallery/Rauw-Alejandro-Todo-de-Ti.jfif" alt="" />
                                                    </div>
                                                    <div className="what-cap">
                                                        <h4>
                                                            <Link to=" "> Todo de Ti - Rauw Alejandro   </Link>
                                                        </h4>

                                                        <p>Dec 16, 2024</p>
                                                        <a
                                                            className="popup-video btn-icon"
                                                            onClick={() => openVideo('https://www.youtube.com/watch?v=CFPLIaMpGrY')}
                                                        >
                                                            <span className="flaticon-play-button" />
                                                        </a>
                                                    </div>
                                                </div>


                                                {/* Single */}
                                                <div className="single-recent">
                                                    <div className="what-img">
                                                        <img src="/assets/img/gallery/Myke-Towers -Lala.jfif" alt="" />
                                                    </div>
                                                    <div className="what-cap">
                                                        <h4>
                                                            <Link to=" "> Lala - Myke Towers  </Link>
                                                        </h4>

                                                        <p>Dec 17, 2024</p>
                                                        <a
                                                            className="popup-video btn-icon"
                                                            onClick={() => openVideo('https://www.youtube.com/watch?v=BVdngsy95mY')}
                                                        >
                                                            <span className="flaticon-play-button" />
                                                        </a>
                                                    </div>
                                                </div>



                                                {/* Single */}
                                                <div className="single-recent">
                                                    <div className="what-img">
                                                        <img src="/assets/img/gallery/don-bypass.jpeg" alt="" />
                                                    </div>
                                                    <div className="what-cap">
                                                        <h4>

                                                            <Link to=" ">  Bypass - Mr. Don  </Link>

                                                        </h4>
                                                        <p>Jan 01, 2024</p>
                                                        <a
                                                            className="popup-video btn-icon"
                                                            onClick={() => openVideo('https://www.youtube.com/watch?v=vBVLHlhCB1E')}
                                                        >
                                                            <span className="flaticon-play-button" />
                                                        </a>
                                                    </div>
                                                </div>


                                                {/* Single */}
                                                <div className="single-recent">
                                                    <div className="what-img">
                                                        <img src="/assets/img/gallery/La-Curiosidad-Myke-Towers.jfif" alt="" />
                                                    </div>
                                                    <div className="what-cap">
                                                        <h4>
                                                            <Link to=" "> La Curiosidad - Myke Towers ft.  </Link>
                                                        </h4>

                                                        <p>May 08, 2024</p>
                                                        <a
                                                            className="popup-video btn-icon"
                                                            onClick={() => openVideo('https://www.youtube.com/watch?v=Q_gIYp078so')}
                                                        >
                                                            <span className="flaticon-play-button" />
                                                        </a>
                                                    </div>
                                                </div>


                                                {/* Single */}
                                                <div className="single-recent">
                                                    <div className="what-img">
                                                        <img src="/assets/img/gallery/ADMV-Maluma.jfif" alt="" />
                                                    </div>
                                                    <div className="what-cap">
                                                        <h4>
                                                            <Link to=" "> ADMV - Maluma  </Link>
                                                        </h4>

                                                        <p>Apr 19, 2024</p>
                                                        <a
                                                            className="popup-video btn-icon"
                                                            onClick={() => openVideo('https://www.youtube.com/watch?v=2t9cxeo3fAI')}
                                                        >
                                                            <span className="flaticon-play-button" />
                                                        </a>
                                                    </div>
                                                </div>


                                                {/* Single */}
                                                <div className="single-recent">
                                                    <div className="what-img">
                                                        <img src="/assets/img/gallery/Fonsi-Turizo-Vacaciones.jfif" alt="" />
                                                    </div>
                                                    <div className="what-cap">
                                                        <h4>
                                                            <Link to=" "> Vacaciones - Luis Fonsi, M. Turizo   </Link>
                                                        </h4>

                                                        <p>Jan 01, 2024</p>
                                                        <a
                                                            className="popup-video btn-icon"
                                                            onClick={() => openVideo('https://www.youtube.com/watch?v=wdHW_lPpZMA')}
                                                        >
                                                            <span className="flaticon-play-button" />
                                                        </a>
                                                    </div>
                                                </div>


                                                {/* Single */}
                                                <div className="single-recent">
                                                    <div className="what-img">
                                                        <img src="/assets/img/gallery/Felipe-Pelaez-Pensando-En-Ti.jfif" alt="" />
                                                    </div>
                                                    <div className="what-cap">
                                                        <h4>
                                                            <Link to=" "> Vivo Pensando En Ti - Maluma, Felipe Peláez   </Link>
                                                        </h4>

                                                        <p>Jan 01, 2024</p>
                                                        <a
                                                            className="popup-video btn-icon"
                                                            onClick={() => openVideo('https://www.youtube.com/watch?v=cPW9Y94BJI0')}
                                                        >
                                                            <span className="flaticon-play-button" />
                                                        </a>
                                                    </div>
                                                </div>


                                                {/* Single */}
                                                <div className="single-recent">
                                                    <div className="what-img">
                                                        <img src="/assets/img/gallery/JBalvin-Mi-gente.jfif" alt="" />
                                                    </div>
                                                    <div className="what-cap">
                                                        <h4>
                                                            <Link to=" "> Mi Gente - J. Balvin, W. William   </Link>
                                                        </h4>

                                                        <p>Dec 17, 2024</p>
                                                        <a
                                                            className="popup-video btn-icon"
                                                            onClick={() => openVideo('https://www.youtube.com/watch?v=wnJ6LuUFpMo')}
                                                        >
                                                            <span className="flaticon-play-button" />
                                                        </a>
                                                    </div>
                                                </div>


                                                {/* Single */}
                                                <div className="single-recent">
                                                    <div className="what-img">
                                                        <img src="/assets/img/gallery/Gente-Zona-Gozadera.jfif" alt="" />
                                                    </div>
                                                    <div className="what-cap">
                                                        <h4>
                                                            <Link to=" "> La Gozadera - Gente de Zona    </Link>
                                                        </h4>

                                                        <p>Dec 21, 2024</p>
                                                        <a
                                                            className="popup-video btn-icon"
                                                            onClick={() => openVideo('https://www.youtube.com/watch?v=VMp55KH_3wo')}
                                                        >
                                                            <span className="flaticon-play-button" />
                                                        </a>
                                                    </div>
                                                </div>


                                                {/* Single */}
                                                <div className="single-recent">
                                                    <div className="what-img">
                                                        <img src="/assets/img/gallery/Del-Mar-Ozuna.png" alt="" />
                                                    </div>
                                                    <div className="what-cap">
                                                        <h4>
                                                            <Link to=" "> Del Mar - Ozuna, Sia, DC   </Link>
                                                        </h4>

                                                        <p>Dec 17, 2024</p>
                                                        <a
                                                            className="popup-video btn-icon"
                                                            onClick={() => openVideo('https://www.youtube.com/watch?v=K2kUyHgadQo')}
                                                        >
                                                            <span className="flaticon-play-button" />
                                                        </a>
                                                    </div>
                                                </div>





                                            </Slider>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* =============== Youtube - Recent Articles End -Trending News =============== */}


                    {/* -------------- Yandex Banner 03 -------------- */}
                    <Adx1BannerVideo blockId="R-A-9762127-3" renderTo="yandex_rtb_R-A-9762127-3" />



                    {/* =============== Start Video Area =============== */}
                    {/* <div className="youtube-area video-padding d-none d-sm-block"> */}
                    <div className="youtube-area video-padding">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="video-items-active">

                                        <div className="video-items text-center d-block d-md-none ">

                                            <h2>ВИДЕО </h2>
                                            {/* <ReactPlayer url={currentVideo} controls width="98%" height="" width="640px" height="360px" /> */}
                                            <ReactPlayer url={currentVideo.url} controls width="98%" height="" />

                                        </div>


                                    </div>
                                </div>
                            </div>

                            <div className="video-info">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="testmonial-nav text-center">

                                            <h2>Последние Видео </h2>

                                            <div className="single-video">

                                                <Slider {...sliderSettingsVideo}>
                                                    {videos.map((video, index) => (
                                                        <div
                                                            key={index}
                                                            style={{ margin: '10px', cursor: 'pointer' }}
                                                            onClick={() => setCurrentVideo(video)}
                                                        >

                                                            <div className="videosingle text-center d-none d-md-block ">
                                                                {/* <ReactPlayer url={video} width="250px" height="140px" controls /> */}
                                                                {/* <ReactPlayer url={video} controls width="99%" height="" title={`Video ${index + 1} `} frameBorder="0" /> Video {index + 1} */}
                                                                <ReactPlayer url={video.url} controls width="99%" height="" />
                                                                {video.title}
                                                                <h5>{video.description}</h5>
                                                            </div>

                                                            <div className="videosingle text-center d-block d-md-none ">
                                                                <ReactPlayer url={video.url} width="99%" height="" />
                                                                {video.title}
                                                                <h5>{video.description}</h5>
                                                            </div>


                                                            {/* <div className="video-intro">
                                                                <h4>Latino Party - 2024 </h4>
                                                            </div> */}

                                                        </div>
                                                    ))}

                                                </Slider>

                                            </div>

                                            <div className="bottom-0 boton-video">
                                                <Link to="/video-events" className=" btn btn-sm btn-primary px-3"
                                                    style={{ borderRadius: "30px 30px 30px 30px" }}>
                                                    Все видео
                                                </Link>
                                            </div>
                                        </div>

                                        {/*  <div className="row">
                                            <div className="col-lg-12">
                                                <div className=" weekly3-caption">
                                                    <div className=" bottom-0 ">
                                                        <Link to="/video-events" className=" btn btn-sm btn-primary px-3"
                                                            style={{ borderRadius: "30px 30px 30px 30px" }}>
                                                            Все видео
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}

                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                    {/* ===============  End Video area =============== */}


                    {/* ===============  Banner-04 Start =============== */}
                    <div className="banner-area gray-bg pt-90 pb-90">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-10 col-md-10">
                                    <div className="banner-one">
                                        {/* -------------- Yandex Banner 09 -------------- */}
                                        <Adx1BannerVideo blockId="R-A-9762127-9" renderTo="yandex_rtb_R-A-9762127-9" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* ===============  Banner-04 End =============== */}



                    {/* ===============  Weekly3-News start - Школы испанского языка =============== */}
                    <div className="weekly3-news-area pt-80 pb-130">
                        <div className="container">
                            <div className="weekly3-wrapper">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="slider-wrapperMain">

                                            {/* section Tittle */}
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="small-tittle mb-30 ">
                                                        <h4>    Школы испанского языка </h4>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-12">

                                                    <Slider {...sliderSettingsTira}>
                                                        {/* Item */}
                                                        <div className="weekly3-single">
                                                            <div className="weekly3-img">
                                                                <img src="/assets/img/spanish/cervantes.png" alt="" />
                                                            </div>
                                                            <div className="weekly3-caption">
                                                                <h4>
                                                                    <Link to="/schools-spanish"> "Институт Сервантеса в Москве" </Link>
                                                                </h4>
                                                                <p>5 May 2024</p>
                                                            </div>
                                                        </div>

                                                        {/* Item */}
                                                        <div className="weekly3-single">
                                                            <div className="weekly3-img">
                                                                <img
                                                                    src="/assets/img/spanish/ESPclub.png"
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div className="weekly3-caption">
                                                                <h4>
                                                                    <Link to="/schools-spanish"> "ESP Club" </Link>
                                                                </h4>
                                                                <p>19 Apr 2024</p>
                                                            </div>
                                                        </div>

                                                        {/* Item */}
                                                        <div className="weekly3-single">
                                                            <div className="weekly3-img">
                                                                <img
                                                                    src="/assets/img/spanish/espalabra.png"
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div className="weekly3-caption">
                                                                <h4>
                                                                    <Link to="/schools-spanish"> "ESPALABRA" </Link>
                                                                </h4>
                                                                <p>19 Apr 2024</p>
                                                            </div>
                                                        </div>

                                                        {/* Item */}
                                                        <div className="weekly3-single">
                                                            <div className="weekly3-img">
                                                                <img
                                                                    src="/assets/img/spanish/laespanola.png"
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div className="weekly3-caption">
                                                                <h4>
                                                                    <Link to="/schools-spanish"> "LA ESPAÑOLA" </Link>
                                                                </h4>
                                                                <p>19 Apr 2024</p>
                                                            </div>
                                                        </div>

                                                        {/* Item */}
                                                        <div className="weekly3-single">
                                                            <div className="weekly3-img">
                                                                <img
                                                                    src="/assets/img/spanish/ispanika.webp"
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div className="weekly3-caption">
                                                                <h4>
                                                                    <Link to="/schools-spanish"> "ИСПАНИКА" </Link>
                                                                </h4>
                                                                <p>19 May 2024</p>
                                                            </div>
                                                        </div>

                                                        {/* Item */}
                                                        <div className="weekly3-single">
                                                            <div className="weekly3-img">
                                                                <img
                                                                    src="/assets/img/spanish/vamos.jpg"
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div className="weekly3-caption">
                                                                <h4>
                                                                    <Link to="/schools-spanish"> "VAMOS" </Link>
                                                                </h4>
                                                                <p>19 May 2024</p>
                                                            </div>
                                                        </div>

                                                        {/* Item */}
                                                        <div className="weekly3-single">
                                                            <div className="weekly3-img">
                                                                <img
                                                                    src="/assets/img/spanish/hispaclub.jpg"
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div className="weekly3-caption">
                                                                <h4>
                                                                    <Link to="/schools-spanish"> "HispaClub" </Link>
                                                                </h4>
                                                                <p>19 May 2024</p>
                                                            </div>
                                                        </div>

                                                        {/* Item */}
                                                        <div className="weekly3-single">
                                                            <div className="weekly3-img">
                                                                <img
                                                                    src="/assets/img/spanish/espahola.jpg"
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div className="weekly3-caption">
                                                                <h4>
                                                                    <Link to="/schools-spanish"> "ESPHOLA" </Link>
                                                                </h4>
                                                                <p>19 May 2024</p>
                                                            </div>
                                                        </div>

                                                        {/* Item */}
                                                        <div className="weekly3-single">
                                                            <div className="weekly3-img">
                                                                <img
                                                                    src="/assets/img/spanish/espacademia.jpg"
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div className="weekly3-caption">
                                                                <h4>
                                                                    <Link to="/schools-spanish"> "ESPacademia" </Link>
                                                                </h4>
                                                                <p>19 May 2024</p>
                                                            </div>
                                                        </div>



                                                    </Slider>
                                                </div>
                                            </div>





                                        </div>



                                    </div>



                                    {/* <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4"> */}


                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className=" weekly3-caption">
                                                <div className=" bottom-0 ">
                                                    <Link to="/schools-spanish" className=" btn btn-sm btn-primary px-3"
                                                        style={{ borderRadius: "30px 30px 30px 30px" }}>
                                                        Все школы
                                                    </Link>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="row">
                                        <div className="col-lg-12">
                                            <div className="small-tittle mb-30 weekly3-caption">
                                                <h4>  <Link to="/"> Все школы </Link></h4>
                                            </div>
                                        </div>
                                    </div> */}




                                </div>
                            </div>
                        </div>
                    </div>
                    {/* ===============  Weekly3-News End - Tira Media =============== */}


                    {/* ===============  Banner-05 Start =============== */}
                    {/* <div className="banner-area gray-bg pt-90 pb-90">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-10 col-md-10">
                                    <div className="banner-one">
                                        <img src="/assets/img/gallery/body_card3.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div className="banner-area gray-bg pt-90 pb-90">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-10 col-md-10">
                                    <div className="banner-one">
                                        {/* -------------- Yandex Banner 04 -------------- */}
                                        <Adx1BannerVideo blockId="R-A-9762127-4" renderTo="yandex_rtb_R-A-9762127-4" />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* ===============  Banner-05 End =============== */}

                </div>



            </section >


            <Footer />

            {/* =============== Recent Articles Start End -Trending News =============== */}
            {selectedVideo && (
                <div className="video-overplay">
                    <div className="video-popup" >
                        <ReactPlayer
                            url={selectedVideo}
                            controls width="100%" height="100%"
                            style={{ objectFit: 'contain' }} />
                        <button onClick={() => setSelectedVideo(null)}> ✖  </button>

                    </div>

                </div>

            )}
            {/* =============== Recent Articles End -Trending News =============== */}



        </main >



    );

}

export default Home;