


/* Elesrning*/

import Footer from "./FooterEs";
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import React, { useEffect, useState } from 'react';
import WOW from 'wowjs';


function SchoolsDance() {

    useEffect(() => {
        const wow = new WOW.WOW();
        wow.init();
    }, []);

    const storesData = [
        
        {name: "Instituto Cervantes de Moscú", image: "/assets/img/spanish/cervantes.png", link: "https://moscu.cervantes.es/",
                categories: ["Институт Сервантеса является крупнейшей организацией, занимающейся преподаванием испанского языка."],
                location: "Г. Москва", duration: "1.49 Hrs", students: 15621 
        },
        {name: "ESP Club Moscú - Центр испанского языка", image: "/assets/img/spanish/ESPclub.png", link: "https://espclubmoscu.com",
                categories: ["У нас вы можете выучить испанский язык в группе или индивидуально, очно в Москве или онлайн со всего мира."],
                location: "Г. Москва", duration: "1.49 Hrs", students: 11071 
        },
        {name: "Школа испанского языка ESPALABRA", image: "/assets/img/spanish/espalabra.png", link: "https://espalabra.ru/",
                categories: ["ESPALABRA - это чуть больше, чем языковые курсы, чуть дружнее, чем клуб единомышленников, и намного интереснее"],
                location: "Г. Москва", duration: "1.49 Hrs", students: 3685 
        },
        {name: "LA ESPAÑOLA - Центр испанского языка и культуры", image: "/assets/img/spanish/laespanola.jpg", link: "https://clasesdeespanol.ru/",
                categories: ["Мы единственная школа в Москве, работающая по авторскому материалу 🔥"],
                location: "м. КРАСНЫЕ ВОРОТА", duration: "1.49 Hrs", students: 4156 
        },
        {name: "ИСПАНИКА онлайн-школа испанского языка", image: "/assets/img/spanish/ispanika.webp", link: "https://espanika-online.ru/",
                categories: ["онлайн-школа с профессиональными преподавателями, системным авторским подходом 📚"],
                location: "Г. Москва", duration: "1.49 Hrs", students: 939 
        },
        {name: "Centro Español VAMOS", image: "/assets/img/spanish/vamos.jpg", link: "https://centroespanol.ru/",
                categories: ["Изучение испанского языка и погружение в его культуру становится легким и увлекательным вместе"],
                location: "Г. Москва", duration: "1.49 Hrs", students: 1156 
        },
        {name: "HispaClub - Онлайн-школа испанского языка", image: "/assets/img/spanish/hispaclub.jpg", link: "https://hispaclubmsk.ru/",
                categories: ["онлайн курсы испанского языка с профессиональными преподавателями из Испании и Латинский Америки"],
                location: "Г. Москва", duration: "1.49 Hrs", students: 301 
        },
        {name: "ESPHOLA - Онлайн школа испанского языка ", image: "/assets/img/spanish/espahola.jpg", link: "https://esphola.com/",
                categories: ["мы коммьюнити целеустремленных людей, в котором обучение становится не только интересным процессом, но и полезным"],
                location: "Г. Москва", duration: "1.49 Hrs", students: 1108 
        },
        {name: "ESPacademia - школа испанского языка ", image: "/assets/img/spanish/espacademia.jpg", link: "https://espacademia.com",
                categories: ["мы коммьюнити целеустремленных людей, в котором обучение становится не только интересным процессом, но и полезным"],
                location: "Г. Москва", duration: "1.49 Hrs", students: 1209 
        },
        {name: "EspaRusski - Твой испанский", image: "/assets/img/spanish/espa-russki.jpg", link: "https://esparusski.ru/",
                categories: ["Рассказываем про испанский язык простым языком и с любовью, присоединяйся к нашей семье!"],
                location: "Г. Москва", duration: "1.49 Hrs", students: 8481 
        },
        {name: "Испанский в кайф - школа испанского", image: "/assets/img/spanish/b-kaif.jpg", link: "https://ispansky-v-kaif.com/",
                categories: ["Онлайн школа испанского языка, ориентированная на говорение, приглашает Вас провести бесплатный пробный урок"],
                location: "Г. Москва", duration: "1.49 Hrs", students: 1610 
        },
        
        {name: "Español Online", image: "/assets/img/spanish/eo.jpg", link: "https://espanolonline.ru/",
                categories: ["🎓Онлайн-школа испанского языка Español Online 🏆Полезные словарные подборки ⭐️Самые интересные места 😎Сохраняй, учи, читай"],
                location: "Г. Москва", duration: "1.49 Hrs", students: 1967 
        },
        {name: "ИНТЕНСИВНЫЕ КУРСЫ ИСПАНСКОГО ОНЛАЙН!", image: "/assets/img/spanish/intenso.jpg", link: "https://intenso.spb.ru/",
                categories: ["РАЗГОВОРНАЯ РЕЧЬ С ПЕРВОГО ЗАНЯТИЯ -  БЕСПЛАТНЫЙ пробный урок. Мы приглашаем Вас на занятия с носителями языка в небольших группах."],
                location: "Г. Москва", duration: "1.49 Hrs", students: 1967 
        },
        {name: "Adelante - Центр испанского языка ", image: "/assets/img/spanish/adelante.jpg", link: "www.centroadelante.ru",
                categories: ["Изучай испанский язык в Центре Adelante очно или онлайн, в группах или индивидуально! Испанский для взрослых и школьников!"],
                location: "Г. СПБ", duration: "1.49 Hrs", students: 13290 
        },
        {name: "Tu Español - Курсы испанского языка онлайн", image: "/assets/img/spanish/tuespanol.jpg", link: "https://tuespanol.ru/",
                categories: ["Место, где учиться просто и результаты видны сразу"],
                location: "Г. Москва", duration: "1.49 Hrs", students: 2533 
        }

    ];


    return (

        <main>


            {/* Header Start */}
            {/* <div className="container-fluid bg-primary py-5 mb-5 page-header container" > */}
            <div className="container-fluid py-5 mb-5 page-header-esp " >

                <div className="container py-5">
                    <div className="row justify-content-center">
                        <div className="col-lg-10 text-center">
                            <h1 className="display-3 text-white animated slideInDown">Escuelas de Español</h1>
                            <nav aria-label="breadcrumb-elearn">
                                <ol className="breadcrumb-elearn justify-content-center">
                                    <li className="breadcrumb-item">
                                        <a className="text-white" href="#">
                                            Inicio
                                        </a>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <a className="text-white" href="#">
                                            Escuelas de Español
                                        </a>
                                    </li>
                                    <li
                                        className="breadcrumb-item text-white active"
                                        aria-current="page"
                                    >
                                        Profesores
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            {/* Header End */}


            {/* Escuelas Start */}
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                        <h6 className="section-title-elearn bg-white text-center text-primary px-3">Escuelas</h6>
                        <h1 className="mb-5">Escuelas Populares</h1>
                    </div>

                    <div className="row g-4 justify-content-center">
                        {/* Iteración sobre los datos de las tiendas */}
                        {storesData.map((store, index) => (

                            < div key={index} className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay={`${(index % 3) * 0.2 + Math.floor(index / 3) * 0.2 + 0.1}s`}>
                                <div className="course-item bg-light">
                                    <div className="position-relative overflow-hidden">
                                        <img className="img-fluid" src={store.image} alt="" />
                                        <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                                            <Link to={store.link} target="_blank" className="flex-shrink-0 btn btn-sm btn-primary px-3 border-end" style={{ borderRadius: "30px 0 0 30px" }}>
                                                Leer Más
                                            </Link>
                                            <Link to={store.link} target="_blank" className="flex-shrink-0 btn btn-sm btn-primary px-3" style={{ borderRadius: "0 30px 30px 0" }}>
                                                Únete
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="text-center p-4 pb-0">
                                        <h3 className="mb-0">{store.name}</h3>
                                        <div className="mb-3">
                                            {[...Array(5)].map((_, i) => (
                                                <small key={i} className="fa fa-star text-primary" />
                                            ))}
                                            <small>({store.students})</small>
                                        </div>
                                        <h5 className="mb-4">{store.categories.join(", ")}</h5>
                                    </div>
                                    <div className="d-flex border-top">
                                        <small className="flex-fill text-center border-end py-2">
                                            <i className="fa fa-map-marker-alt text-primary me-2" />
                                            {store.location}
                                        </small>
                                        <small className="flex-fill text-center border-end py-2">
                                            <i className="fa fa-clock text-primary me-2" />
                                            {store.duration}
                                        </small>
                                        <small className="flex-fill text-center py-2">
                                            <i className="fa fa-user text-primary me-2" />
                                            {store.students} Alumnos
                                        </small>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {/* Escuelas End */}
  

            <Footer />


        </main >



    );

}

export default SchoolsDance;