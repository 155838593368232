

 
import Footer from "./FooterEs"; 
import { Link } from 'react-router-dom';  
import React, { useEffect, useState } from 'react'; 
import LightGallery from 'lightgallery/react'; 
import 'lightgallery/css/lightgallery.css'; 
import 'lightgallery/css/lg-zoom.css'; 
import 'lightgallery/css/lg-thumbnail.css'; 
import 'lightgallery/scss/lightgallery.scss'; 
import 'lightgallery/scss/lg-zoom.scss'; 
import lgThumbnail from 'lightgallery/plugins/thumbnail'; 
import lgZoom from 'lightgallery/plugins/zoom'; 
import lgFullscreen from 'lightgallery/plugins/fullscreen'; 
import lightGallery from 'lightgallery'; 



function PhotosenEn() { 
    useEffect(() => { 
        const lightGalleryInit = () => { 
            const gallery = document.getElementById('lightgallery'); 
            if (gallery) { 
                lightGallery(gallery, { 
                    plugins: [lgThumbnail, lgZoom, lgFullscreen] 
                }); 
            } 
        }; 
        lightGalleryInit(); 
    }, []);

    const images = [
        {src: "/assets/img/party/fiesta-chicas.png", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/fiesta-chicas2.png", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/girfriends-fiesta.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/mujeres-sonrientes-fiesta.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/vida-nocturna.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/vida-nocturna-gente-bailando.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/vida-nocturna-gente-bailando-club.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/post-06.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/post-07.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/post-50.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/post-22.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/post-32.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/img-13223.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/img-13229.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/img-13193.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/entertaiment-01.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/entertaiment-12.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/fiesta3.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/girl-band-music.png", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/latino-fiesta-chicas2.png", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/mujer-rojo-fiesta.png", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
         
        {src: "/assets/img/party/fiesta-chicas.png", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/fiesta-chicas2.png", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/girfriends-fiesta.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/mujeres-sonrientes-fiesta.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/vida-nocturna.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/vida-nocturna-gente-bailando.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/vida-nocturna-gente-bailando-club.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/post-06.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/post-07.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/post-50.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/post-22.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/post-32.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/img-13223.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/img-13229.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/img-13193.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/entertaiment-01.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/entertaiment-12.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/fiesta3.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/girl-band-music.png", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/latino-fiesta-chicas2.png", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/mujer-rojo-fiesta.png", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        
        {src: "/assets/img/party/fiesta-chicas.png", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/fiesta-chicas2.png", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/girfriends-fiesta.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/mujeres-sonrientes-fiesta.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/vida-nocturna.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/vida-nocturna-gente-bailando.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/vida-nocturna-gente-bailando-club.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/post-06.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/post-07.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/post-50.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/post-22.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/post-32.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/img-13223.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/img-13229.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/img-13193.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/entertaiment-01.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/entertaiment-12.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/fiesta3.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/girl-band-music.png", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/latino-fiesta-chicas2.png", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
        {src: "/assets/img/party/mujer-rojo-fiesta.png", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet </p>"},
                
        
        

    ];



    return ( 
        <main> 
            <div className="site-wrap front-videos"> 
                <div className="container"> 
                    <div className="row" id="lightgallery">
                        {images.map((image, index) => (
                            <div 
                                key={index}
                                className="col-4 col-sm-3 col-md-3 col-lg-2 col-xl-2 item" 
                                data-aos="fade" 
                                data-src={image.src}
                                data-sub-html={image.subHtml}
                            > 
                                <a href={image.src}> 
                                    <img 
                                        src={image.src}
                                        alt="IMage" 
                                        className="img-fluid" 
                                    /> 
                                </a> 
                            </div>
                        ))}
                    </div> 
                </div> 
            </div> 
            <Footer /> 
        </main>
    ); 
} 

export default PhotosenEn;


/*  
import Menu from "./MenuEs";
import Footer from "./FooterEs";
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

import React, { useEffect, useState } from 'react';

import LightGallery from 'lightgallery/react';

 import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

 import 'lightgallery/scss/lightgallery.scss';
import 'lightgallery/scss/lg-zoom.scss';

 import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import lgFullscreen from 'lightgallery/plugins/fullscreen';

import lightGallery from 'lightgallery';



function PhotosenEn() {

    useEffect(() => {
        const lightGalleryInit = () => {
            const gallery = document.getElementById('lightgallery');

            if (gallery) {
                lightGallery(gallery, {
                    plugins: [lgThumbnail, lgZoom, lgFullscreen]
                });
            }
        };

        lightGalleryInit();

    }, []);



    return (

        <main>

            <div className="site-wrap front-videos">


                    <div className="container">

                        <div className="row" id="lightgallery">


                               

                              


                            <div
                                className="col-4 col-sm-3 col-md-3 col-lg-2 col-xl-2 item"
                                data-aos="fade"
                                data-src="/assets/img/photosen/big-images/nature_big_1.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolor doloremque hic excepturi fugit, sunt impedit fuga tempora, ad amet aliquid?</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/photosen/nature_small_1.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-3 col-md-3 col-lg-2 col-xl-2 item"
                                data-aos="fade"
                                data-src="/assets/img/photosen/big-images/nature_big_2.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laboriosam omnis quaerat molestiae, praesentium. Ipsam, reiciendis. Aut molestiae animi earum laudantium.</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/photosen/nature_small_2.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-3 col-md-3 col-lg-2 col-xl-2 item"
                                data-aos="fade"
                                data-src="/assets/img/photosen/big-images/nature_big_3.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quidem reiciendis, dolorum illo temporibus culpa eaque dolore rerum quod voluptate doloribus.</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/photosen/nature_small_3.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-3 col-md-3 col-lg-2 col-xl-2 item"
                                data-aos="fade"
                                data-src="/assets/img/photosen/big-images/nature_big_4.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Enim perferendis quae iusto omnis praesentium labore tempore eligendi quo corporis sapiente.</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/photosen/nature_small_4.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-3 col-md-3 col-lg-2 col-xl-2 item"
                                data-aos="fade"
                                data-src="/assets/img/photosen/big-images/nature_big_5.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Saepe, voluptatum voluptate tempore aliquam, dolorem distinctio. In quas maiores tenetur sequi.</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/photosen/nature_small_5.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-3 col-md-3 col-lg-2 col-xl-2 item"
                                data-aos="fade"
                                data-src="/assets/img/photosen/big-images/nature_big_6.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rerum cum culpa blanditiis illum, voluptatum iusto quisquam mollitia debitis quaerat maiores?</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/photosen/nature_small_6.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-3 col-md-3 col-lg-2 col-xl-2 item"
                                data-aos="fade"
                                data-src="/assets/img/photosen/big-images/nature_big_7.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores similique impedit possimus, laboriosam enim at placeat nihil voluptatibus voluptate hic!</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/photosen/nature_small_7.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-3 col-md-3 col-lg-2 col-xl-2 item"
                                data-aos="fade"
                                data-src="/assets/img/photosen/big-images/nature_big_8.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quam vitae sed cum mollitia itaque soluta laboriosam eaque sit ratione, aliquid.</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/photosen/nature_small_8.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-3 col-md-3 col-lg-2 col-xl-2 item"
                                data-aos="fade"
                                data-src="/assets/img/photosen/big-images/nature_big_9.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Itaque officiis magnam, facilis nam eos perspiciatis eligendi pariatur numquam debitis quos!</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/photosen/nature_small_9.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-3 col-md-3 col-lg-2 col-xl-2 item"
                                data-aos="fade"
                                data-src="/assets/img/photosen/big-images/nature_big_1.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolor doloremque hic excepturi fugit, sunt impedit fuga tempora, ad amet aliquid?</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/photosen/nature_small_1.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-3 col-md-3 col-lg-2 col-xl-2 item"
                                data-aos="fade"
                                data-src="/assets/img/photosen/big-images/nature_big_2.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laboriosam omnis quaerat molestiae, praesentium. Ipsam, reiciendis. Aut molestiae animi earum laudantium.</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/photosen/nature_small_2.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-3 col-md-3 col-lg-2 col-xl-2 item"
                                data-aos="fade"
                                data-src="/assets/img/photosen/big-images/nature_big_3.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quidem reiciendis, dolorum illo temporibus culpa eaque dolore rerum quod voluptate doloribus.</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/photosen/nature_small_3.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-3 col-md-3 col-lg-2 col-xl-2 item"
                                data-aos="fade"
                                data-src="/assets/img/photosen/big-images/nature_big_4.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Enim perferendis quae iusto omnis praesentium labore tempore eligendi quo corporis sapiente.</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/photosen/nature_small_4.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-3 col-md-3 col-lg-2 col-xl-2 item"
                                data-aos="fade"
                                data-src="/assets/img/photosen/big-images/nature_big_5.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Saepe, voluptatum voluptate tempore aliquam, dolorem distinctio. In quas maiores tenetur sequi.</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/photosen/nature_small_5.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-3 col-md-3 col-lg-2 col-xl-2 item"
                                data-aos="fade"
                                data-src="/assets/img/photosen/big-images/nature_big_6.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rerum cum culpa blanditiis illum, voluptatum iusto quisquam mollitia debitis quaerat maiores?</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/photosen/nature_small_6.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-3 col-md-3 col-lg-2 col-xl-2 item"
                                data-aos="fade"
                                data-src="/assets/img/photosen/big-images/nature_big_7.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores similique impedit possimus, laboriosam enim at placeat nihil voluptatibus voluptate hic!</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/photosen/nature_small_7.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-3 col-md-3 col-lg-2 col-xl-2 item"
                                data-aos="fade"
                                data-src="/assets/img/photosen/big-images/nature_big_8.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quam vitae sed cum mollitia itaque soluta laboriosam eaque sit ratione, aliquid.</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/photosen/nature_small_8.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-3 col-md-3 col-lg-2 col-xl-2 item"
                                data-aos="fade"
                                data-src="/assets/img/photosen/big-images/nature_big_9.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quidem reiciendis debitis beatae facilis quos, enim quis nobis magnam architecto earum!</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/photosen/nature_small_9.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>



                            <div
                                className="col-4 col-sm-3 col-md-3 col-lg-2 col-xl-2 item"
                                data-aos="fade"
                                data-src="/assets/img/photosen/big-images/nature_big_1.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolor doloremque hic excepturi fugit, sunt impedit fuga tempora, ad amet aliquid?</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/photosen/nature_small_1.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-3 col-md-3 col-lg-2 col-xl-2 item"
                                data-aos="fade"
                                data-src="/assets/img/photosen/big-images/nature_big_2.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laboriosam omnis quaerat molestiae, praesentium. Ipsam, reiciendis. Aut molestiae animi earum laudantium.</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/photosen/nature_small_2.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-3 col-md-3 col-lg-2 col-xl-2 item"
                                data-aos="fade"
                                data-src="/assets/img/photosen/big-images/nature_big_3.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quidem reiciendis, dolorum illo temporibus culpa eaque dolore rerum quod voluptate doloribus.</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/photosen/nature_small_3.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-3 col-md-3 col-lg-2 col-xl-2 item"
                                data-aos="fade"
                                data-src="/assets/img/photosen/big-images/nature_big_4.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Enim perferendis quae iusto omnis praesentium labore tempore eligendi quo corporis sapiente.</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/photosen/nature_small_4.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-3 col-md-3 col-lg-2 col-xl-2 item"
                                data-aos="fade"
                                data-src="/assets/img/photosen/big-images/nature_big_5.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Saepe, voluptatum voluptate tempore aliquam, dolorem distinctio. In quas maiores tenetur sequi.</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/photosen/nature_small_5.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-3 col-md-3 col-lg-2 col-xl-2 item"
                                data-aos="fade"
                                data-src="/assets/img/photosen/big-images/nature_big_6.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rerum cum culpa blanditiis illum, voluptatum iusto quisquam mollitia debitis quaerat maiores?</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/photosen/nature_small_6.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-3 col-md-3 col-lg-2 col-xl-2 item"
                                data-aos="fade"
                                data-src="/assets/img/photosen/big-images/nature_big_7.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores similique impedit possimus, laboriosam enim at placeat nihil voluptatibus voluptate hic!</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/photosen/nature_small_7.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>

                            <div
                                className="col-4 col-sm-3 col-md-3 col-lg-2 col-xl-2 item"
                                data-aos="fade"
                                data-src="/assets/img/photosen/big-images/nature_big_8.jpg"
                                data-sub-html="<h4>Fading Light</h4><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quam vitae sed cum mollitia itaque soluta laboriosam eaque sit ratione, aliquid.</p>"
                            >
                                <a href="#">
                                    <img
                                        src="/assets/img/photosen/nature_small_8.jpg"
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>



                        </div>
                    </div>
                

            </div>



            <Footer />


        </main >



    );

}

export default PhotosenEn;
 */