


import React, { useEffect } from 'react';

const YandexAd = ({ blockId, renderTo }) => {
  useEffect(() => {
    // Verifica si el objeto Ya está disponible antes de usarlo
    if (window.Ya) {
      window.Ya.Context.AdvManager.render({
        blockId: blockId,
        renderTo: renderTo,
        async: true,
      });
    } else {
      console.error('Yandex Ads script not loaded');
    }
  }, [blockId, renderTo]);

  return <div id={renderTo}></div>;
};

export default YandexAd;