

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Adx1BannerVideo from '../Adx1BannerVideo'; 


function FooterEn() {

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            const windowWidth = window.innerWidth;

            // Ajusta los valores según tus preferencias
            const threshold = windowWidth > 800 ? 400 : 600;

            setIsVisible(scrollTop > threshold);
        };

        // Agregamos un evento adicional al montar el componente
        handleScroll();

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };



    return (
        <main>

            <footer>
                {/* Footer Start*/}
                <div className="footer-main footer-bg">
                    <div className="footer-area footer-padding">
                        <div className="container">
                            <div className="row d-flex justify-content-between">
                                <div className="col-xl-3 col-lg-3 col-md-5 col-sm-8">
                                    <div className="single-footer-caption mb-50">
                                        <div className="single-footer-caption mb-30">
                                            {/* logo */}
                                            <div className="footer-logo">
                                                <Link to="/"> <img src="/assets/img/logo/logo-latinox.png" alt="" /> </Link>

                                            </div>
                                            <div className="footer-tittle">
                                                <div className="footer-pera">
                                                    <p className="info1">
                                                    Explore the vibrant cultural richness of Latin America. Immerse yourself in its colorful traditions, captivating dances, melodic language, and exquisite gastronomy. Discover the joy of Latin festivities as you embark on a cultural journey celebrating the unique diversity of the region. Join us to experience the passion and energy of Latin America!
                                                    </p>
                                                    <div className="right-topbar">
                                                        <Link to="https://www.youtube.com/OrbitRussia" target="_blank"> <span className="fab fa-youtube"/></Link>
                                                        <Link to="https://instagram.com/latinox.club" target="_blank"> <span className="fab fa-instagram"/></Link>
                                                        <Link to="https://t.me/latinoxclub" target="_blank"> <span className="fab fa-telegram"/></Link>
                                                        <Link to="https://vk.com/latinox.club" target="_blank"> <span className="fab fa-vk"/></Link>
                                                    </div>
                                                    <p className="info2">
                                                        Baumanskaya Street, Moscow. Russia
                                                    </p>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-4 col-lg-4 col-md-5 col-sm-7">
                                    <div className="single-footer-caption mb-50">
                                        <div className="footer-tittle">
                                            <h4>Popular post</h4>
                                        </div>
                                        {/* Popular post */}
                                        <div className="whats-right-single mb-20">
                                            <div className="whats-right-img">
                                                <img src="/assets/img/dance/BavR.gif" alt="" />
                                            </div>
                                            <div className="whats-right-cap">
                                                <h4>
                                                <Link to="/latino-dance-in-Moscow"> Ritmo Latino en Moscú: Las Fiestas que Hacen Bailar la Capital Rusa </Link>
                                                  {/*   <a href=" ">
                                                        Verus amor nullum novit habere modum.
                                                    </a> */}
                                                </h4>
                                                <p>Jhon | 6 hours ago</p>
                                            </div>
                                        </div>

                                         {/* Popular post */}
                                         <div className="whats-right-single mb-20">
                                            <div className="whats-right-img">
                                                <img src="/assets/img/blog/openair5.jpg" alt="" />
                                            </div>
                                            <div className="whats-right-cap">
                                                <h4>
                                                    <Link to="/open-air-in-Moscow">  Open Air Experience: Salsa and Bachata Nights outdoors in Moscow </Link>
                                                </h4>
                                                <p>Ana | 8 hours ago</p>
                                            </div>
                                        </div>

                                        {/* Popular post */}
                                        <div className="whats-right-single mb-20">
                                            <div className="whats-right-img">
                                                <img src="/assets/img/gallery/girfriends-fiesta.jpg" alt="" />
                                            </div>
                                            <div className="whats-right-cap">
                                                <h4>
                                                <Link to="/latino-dance-in-Moscow">  Verus amor nullum novit habere modum. </Link>
                                                
                                                     
                                                </h4>
                                                <p>Luis | 5 hours ago</p>
                                            </div>
                                        </div>
                                         
                                    </div>
                                </div>

                                <div className="col-xl-3 col-lg-3 col-md-5 col-sm-7">
                                    <div className="single-footer-caption mb-50">
                                        <div className="bannerfoot">
                                            {/* -------------- Yandex Banner 08 -------------- */}
                                            <Adx1BannerVideo blockId="R-A-9762127-8" renderTo="yandex_rtb_R-A-9762127-8" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* footer-bottom area */}
                    <div className="footer-bottom-area footer-bg">
                        <div className="container">
                            <div className="footer-border">
                                <div className="row d-flex align-items-center">
                                    <div className="col-xl-12 ">
                                        <div className="footer-copy-right text-center">
                                            <p>
                                                {/* ============ Orbit ============ */}
                                                Copyright © 2024 | Made with{" "}
                                                <i className="fa fa-heart" aria-hidden="true" /> by {" "}
                                                <a href="" target="_blank">
                                                    Orbit Lab
                                                </a>
                                                {/* ============ Orbit ============ */}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Footer End*/}
            </footer>


            {/* ============== Back to top ============== */}
            <div >

                <button className='scrollUp' onClick={scrollToTop} style={{ display: isVisible ? 'block' : 'none' }}>

                    <i className="ti-arrow-up"></i>
                </button>

            </div>


            {/* Search model Begin */}
            <div className="search-model-box">
                <div className="d-flex align-items-center h-100 justify-content-center">
                    <div className="search-close-btn">+</div>
                    <form className="search-model-form">
                        <input type="text" id="search-input" placeholder="Searching key....." />
                    </form>
                </div>
            </div>
            {/* Search model end */}

        </main>

    );

}

export default FooterEn;