


import React, { useEffect, useState } from 'react';
import Footer from '../../../components/ru/FooterRu';
import { Link, useNavigate } from 'react-router-dom';
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/scss/lightgallery.scss';
import 'lightgallery/scss/lg-zoom.scss';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import lgFullscreen from 'lightgallery/plugins/fullscreen';
import lightGallery from 'lightgallery';

function Photosen() {
    useEffect(() => {
        const lightGalleryInit = () => {
            const gallery = document.getElementById('lightgallery');
            if (gallery) {
                lightGallery(gallery, {
                    plugins: [lgThumbnail, lgZoom, lgFullscreen]
                });
            }
        };
        lightGalleryInit();
    }, []);

    const startRange = 1;
    const endRange = 100;
    const images = [];

    for (let i = startRange; i <= endRange; i++) {
        images.push({
            src: `/assets/img/alpaca/alpak${i}.jpg`,
            subHtml: `<h4>"Улыбка Альпаки" || одежда из Перу</h4><p> https://vk.com/alpacaru </p>`
            /* subHtml: `<h4>"Улыбка А и" || о Перу</h4><p> Descipción Img-${i}</p>` */
        });
    }

    return (
        <main>
             <section
                className="hero-wrap hero-wrap-2-fox"
                style={{ backgroundImage: 'url("/assets/img/store/stand.jpg")' }}

            >
                <div className="overlay" />
                <div className="container">
                    <div className="row no-gutters slider-text align-items-center justify-content-center">
                        <div className="col-md-9 ftco-animate text-center fadeInUp ftco-animated">
                            <h1 className="mb-2 bread"> "Улыбка Альпаки" </h1>
                            <p className="breadcrumbs">
                                <span className="mr-2">
                                    <Link to="/restaurants">Home<i className="ion-ios-arrow-forward" /></Link>
                                </span>{" "}
                                <span>
                                    <Link to="/store">Магазины <i className="ion-ios-arrow-forward" /></Link>
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>


            <div className="site-wrap front-videos">
                <div className="container">
                    <div className="row" id="lightgallery">
                        {images.map((image, index) => (
                            <div
                                key={index}
                                className="col-4 col-sm-3 col-md-3 col-lg-2 col-xl-2 item"
                                data-aos="fade"
                                data-src={image.src}
                                data-sub-html={image.subHtml}
                            >
                                <a href={image.src}>
                                    <img
                                        src={image.src}
                                        alt="IMage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <Footer />
        </main>
    );
}

export default Photosen;



 /*    const images = [
        {src: "/assets/img/alpaca/alpak23.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum img-23 </p>"},
        {src: "/assets/img/alpaca/alpak24.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum img-24 </p>"},
        {src: "/assets/img/alpaca/alpak25.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum img-25 </p>"},
        {src: "/assets/img/alpaca/alpak26.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum img-26 </p>"},
        {src: "/assets/img/alpaca/alpak27.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum img-27 </p>"},
        {src: "/assets/img/alpaca/alpak28.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum img-28 </p>"},
        {src: "/assets/img/alpaca/alpak29.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum img-29 </p>"},
        {src: "/assets/img/alpaca/alpak30.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum img-30 </p>"},
        {src: "/assets/img/alpaca/alpak31.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum img-31 </p>"},
        {src: "/assets/img/alpaca/alpak32.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum img-32 </p>"},
        {src: "/assets/img/alpaca/alpak33.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum img-33 </p>"},
        {src: "/assets/img/alpaca/alpak34.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum img-34 </p>"},
        {src: "/assets/img/alpaca/alpak35.jpg", subHtml:"<h4>Fading Light</h4><p>Lorem ipsum img-35 </p>"},
         
        

    ];

 */
 