

import Menu from "./MenuEs";
import Footer from "./FooterEs";
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import React, { useEffect, useState } from 'react';

/* import AOS from 'aos';
import 'aos/dist/aos.css';  
 */



function FoxTeacher() {

    /*    useEffect(() => {
           AOS.init({
             duration: 800,
             easing: 'slide'
           });
       
         
           return () => {
   
               AOS.refresh();
   
    
           };
         }, []);   */



    return (


        <main > 

            <section
                className="hero-wrap hero-wrap-2-fox"
                style={{ backgroundImage: 'url("/assets/img/foxmaster/bg_1.jpg")' }}

            >

                <div className="overlay" />
                <div className="containerFox">
                    <div className="row no-gutters slider-text align-items-center justify-content-center">
                        <div className="col-md-9 ftco-animate text-center fadeInUp ftco-animated">
                            <h1 className="mb-2 bread">Profesores Nativos y Certificados </h1>
                            <p className="breadcrumbs">
                                <span className="mr-2">

                                    <Link to="/"  > Inicio <i className="ion-ios-arrow-forward" /> </Link>
                                </span>{" "}
                                <span>
                                    Escuelas <i className="ion-ios-arrow-forward" />
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>



            <section className="ftco-section bg-light"  >
                <div className="containerFox-fluid px-4" >
                    <div className="row " >

                        {/* Item */}
                        <div className="col-md-6 col-lg-3 ftco-animate">
                            <div className="staff">
                                <div className="img-wrap d-flex align-items-stretch">
                                    <div
                                        className="img align-self-stretch"
                                        style={{ backgroundImage: "url(assets/img/teachersdance/daria-korovina.jpg)" }}
                                    />
                                </div>
                                <div className="text pt-3 text-center">
                                    <h3> Daria Venegas </h3>
                                    <span className="position mb-2"> Español, Italiano, Inglés, Ruso </span>
                                    <div className="faded">
                                        <p>
                                            Обучаю детей и их родителей. Со мной результат быстрее. Загружаю испанский прямо в мозг
                                        </p>
                                        <ul className="ftco-social text-center">
                                            <li class="ftco-animate"><Link to="https://instagram.com/la_rusa_del_ecuador" target="_blank"><span class="icon-instagram"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://vk.com/daria_korovina" target="_blank"><span class="icon-vk"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://vk.com/daria_korovina" target="_blank"><span class="icon-telegram"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://www.youtube.com/@LaRusaDelEcuador" target="_blank"><span class="icon-youtube"></span></Link></li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Item */}
                        <div className="col-md-6 col-lg-3 ftco-animate">
                            <div className="staff">
                                <div className="img-wrap d-flex align-items-stretch">
                                    <div
                                        className="img align-self-stretch"
                                        style={{ backgroundImage: "url(assets/img/teachersdance/jairo-machado.jpg)" }}
                                    />
                                </div>
                                <div className="text pt-3 text-center">
                                    <h3> Jairo Machado </h3>
                                    <span className="position mb-2"> Español </span>
                                    <div className="faded">
                                        <p>
                                            Носитель испанского языка. Со мной результат быстрее. Загружаю испанский прямо в мозг
                                        </p>
                                        <ul className="ftco-social text-center">
                                            <li class="ftco-animate"><Link to="https://instagram.com/espanol_con_jairo" target="_blank"><span class="icon-instagram"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://instagram.com/espanol_con_jairo" target="_blank"><span class="icon-vk"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://instagram.com/espanol_con_jairo" target="_blank"><span class="icon-telegram"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://instagram.com/espanol_con_jairo" target="_blank"><span class="icon-youtube"></span></Link></li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Item */}
                        <div className="col-md-6 col-lg-3 ftco-animate">
                            <div className="staff">
                                <div className="img-wrap d-flex align-items-stretch">
                                    <div
                                        className="img align-self-stretch"
                                        style={{ backgroundImage: "url(assets/img/teachersdance/fernanda-jimenez.jpg)" }}
                                    />
                                </div>
                                <div className="text pt-3 text-center">
                                    <h3> Fernanda Jimenez </h3>
                                    <span className="position mb-2"> Español </span>
                                    <div className="faded">
                                        <p>
                                            Обучаю детей и их родителей. Я квалифицированный преподаватель испанского языка
                                        </p>
                                        <ul className="ftco-social text-center">
                                            <li class="ftco-animate"><Link to="https://instagram.com/fertipi" target="_blank"><span class="icon-instagram"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://instagram.com/fertipi" target="_blank"><span class="icon-vk"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://instagram.com/fertipi" target="_blank"><span class="icon-telegram"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://instagram.com/fertipi" target="_blank"><span class="icon-youtube"></span></Link></li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Item */}
                        <div className="col-md-6 col-lg-3 ftco-animate">
                            <div className="staff">
                                <div className="img-wrap d-flex align-items-stretch">
                                    <div
                                        className="img align-self-stretch"
                                        style={{ backgroundImage: "url(assets/img/teachersdance/randy-caro.jpg)" }}
                                    />
                                </div>
                                <div className="text pt-3 text-center">
                                    <h3> Randy Caro </h3>
                                    <span className="position mb-2"> Español </span>
                                    <div className="faded">
                                        <p>
                                            Я из Перу. Носитель испанского языка. Прожил более 10 лет в Москве
                                        </p>
                                        <ul className="ftco-social text-center">
                                            <li class="ftco-animate"><Link to="https://instagram.com/randy.espanol" target="_blank"><span class="icon-instagram"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://instagram.com/randy.espanol" target="_blank"><span class="icon-vk"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://instagram.com/randy.espanol" target="_blank"><span class="icon-telegram"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://instagram.com/randy.espanol" target="_blank"><span class="icon-youtube"></span></Link></li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Item */}
                        <div className="col-md-6 col-lg-3 ftco-animate">
                            <div className="staff">
                                <div className="img-wrap d-flex align-items-stretch">
                                    <div
                                        className="img align-self-stretch"
                                        style={{ backgroundImage: "url(assets/img/teachersdance/darya-shapalov.jpg)" }}
                                    />
                                </div>
                                <div className="text pt-3 text-center">
                                    <h3> Daria Shapal </h3>
                                    <span className="position mb-2"> Español, Inglés, Ruso </span>
                                    <div className="faded">
                                        <p>
                                            Обучаю детей и их родителей. Со мной результат быстрее. Загружаю испанский прямо в мозг
                                        </p>
                                        <ul className="ftco-social text-center">
                                            <li class="ftco-animate"><Link to="https://instagram.com/espanolcondarya" target="_blank"><span class="icon-instagram"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://instagram.com/espanolcondarya" target="_blank"><span class="icon-vk"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://instagram.com/espanolcondarya" target="_blank"><span class="icon-telegram"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://instagram.com/espanolcondarya" target="_blank"><span class="icon-youtube"></span></Link></li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Item */}
                        <div className="col-md-6 col-lg-3 ftco-animate">
                            <div className="staff">
                                <div className="img-wrap d-flex align-items-stretch">
                                    <div
                                        className="img align-self-stretch"
                                        style={{ backgroundImage: "url(assets/img/teachersdance/reny-jose.jpg)" }}
                                    />
                                </div>
                                <div className="text pt-3 text-center">
                                    <h3> Reny José </h3>
                                    <span className="position mb-2"> Español, Inglés, Ruso </span>
                                    <div className="faded">
                                        <p>
                                            Я полиглот, со мной ты заговоришь на испанском с нуля до С2. Подготовка к DELE и SIELE
                                        </p>
                                        <ul className="ftco-social text-center">
                                            <li class="ftco-animate"><Link to="https://instagram.com/espanol_con_reny_jose" target="_blank"><span class="icon-instagram"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://instagram.com/espanol_con_reny_jose" target="_blank"><span class="icon-vk"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://t.me/espanol_con_reny_jose" target="_blank"><span class="icon-telegram"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://instagram.com/espanol_con_reny_jose" target="_blank"><span class="icon-youtube"></span></Link></li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Item */}
                        <div className="col-md-6 col-lg-3 ftco-animate">
                            <div className="staff">
                                <div className="img-wrap d-flex align-items-stretch">
                                    <div
                                        className="img align-self-stretch"
                                        style={{ backgroundImage: "url(assets/img/teachersdance/clarita-espanol.jpg)" }}
                                    />
                                </div>
                                <div className="text pt-3 text-center">
                                    <h3> Clarita </h3>
                                    <span className="position mb-2"> Español, Ruso </span>
                                    <div className="faded">
                                        <p>
                                            Именно благодаря испанской культуре и танцам, я связала свою жизнь с преподаванием этого языка, и вот уже 19 лет помогаю людям освоить Español!
                                        </p>
                                        <ul className="ftco-social text-center">
                                            <li class="ftco-animate"><Link to="https://vk.com/espanaclara" target="_blank"><span class="icon-instagram"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://vk.com/espanaclara" target="_blank"><span class="icon-vk"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://vk.com/espanaclara" target="_blank"><span class="icon-telegram"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://vk.com/espanaclara" target="_blank"><span class="icon-youtube"></span></Link></li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Item */}
                        <div className="col-md-6 col-lg-3 ftco-animate">
                            <div className="staff">
                                <div className="img-wrap d-flex align-items-stretch">
                                    <div
                                        className="img align-self-stretch"
                                        style={{ backgroundImage: "url(assets/img/teachersdance/josu-sanchez.jpg)" }}
                                    />
                                </div>
                                <div className="text pt-3 text-center">
                                    <h3> Josu Sánchez </h3>
                                    <span className="position mb-2"> Español </span>
                                    <div className="faded">
                                        <p>
                                            Я квалифицированный преподаватель испанского языка, аккредитованный Университетом Барселоны и Институтом Сервантеса в Москве.
                                        </p>
                                        <ul className="ftco-social text-center">
                                            <li class="ftco-animate"><Link to="https://www.instagram.com/tuespanol" target="_blank"><span class="icon-instagram"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://vk.com/tuespanol" target="_blank"><span class="icon-vk"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://vk.com/tuespanol" target="_blank"><span class="icon-telegram"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://www.youtube.com/@TuEspanol" target="_blank"><span class="icon-youtube"></span></Link></li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Item */}
                        <div className="col-md-6 col-lg-3 ftco-animate">
                            <div className="staff">
                                <div className="img-wrap d-flex align-items-stretch">
                                    <div
                                        className="img align-self-stretch"
                                        style={{ backgroundImage: "url(assets/img/teachersdance/elena-vasileva.jpg)" }}
                                    />
                                </div>
                                <div className="text pt-3 text-center">
                                    <h3> Елена Васильева </h3>
                                    <span className="position mb-2"> Español, Italiano, Inglés, Ruso </span>
                                    <div className="faded">
                                        <p>
                                            Я магистр филологии, переводчик и преподаватель испанского языка с 15-летним стажем.
                                        </p>
                                        <ul className="ftco-social text-center">
                                            <li class="ftco-animate"><Link to="https://vk.com/clubhispanoelena" target="_blank"><span class="icon-instagram"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://vk.com/clubhispanoelena" target="_blank"><span class="icon-vk"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://vk.com/clubhispanoelena" target="_blank"><span class="icon-telegram"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://vk.com/clubhispanoelena" target="_blank"><span class="icon-youtube"></span></Link></li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Item */}
                        <div className="col-md-6 col-lg-3 ftco-animate">
                            <div className="staff">
                                <div className="img-wrap d-flex align-items-stretch">
                                    <div
                                        className="img align-self-stretch"
                                        style={{ backgroundImage: "url(assets/img/teachersdance/maxim-osipov.jpg)" }}
                                    />
                                </div>
                                <div className="text pt-3 text-center">
                                    <h3> Максим Осипов </h3>
                                    <span className="position mb-2"> Español, Ruso </span>
                                    <div className="faded">
                                        <p>
                                            Я преподаватель, экзаменатор DELE. Две магистратуры в Гранаде и Севилья
                                        </p>
                                        <ul className="ftco-social text-center">
                                            <li class="ftco-animate"><Link to="https://instagram.com/espanol_maximo" target="_blank"><span class="icon-instagram"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://instagram.com/espanol_maximo" target="_blank"><span class="icon-vk"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://instagram.com/espanol_maximo" target="_blank"><span class="icon-telegram"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://instagram.com/espanol_maximo" target="_blank"><span class="icon-youtube"></span></Link></li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Item */}
                        <div className="col-md-6 col-lg-3 ftco-animate">
                            <div className="staff">
                                <div className="img-wrap d-flex align-items-stretch">
                                    <div
                                        className="img align-self-stretch"
                                        style={{ backgroundImage: "url(assets/img/teachersdance/guzel.jpg)" }}
                                    />
                                </div>
                                <div className="text pt-3 text-center">
                                    <h3> Гузель Трофимова </h3>
                                    <span className="position mb-2"> Español, Inglés, Ruso </span>
                                    <div className="faded">
                                        <p>
                                            Я преподаю более 10 лет. Я люблю обучать и делаю это душевно, а самое важное профессионально: готовлю к международным экзаменам в том числе
                                        </p>
                                        <ul className="ftco-social text-center">
                                            <li class="ftco-animate"><Link to="https://vk.com/spanish_guzel" target="_blank"><span class="icon-instagram"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://vk.com/spanish_guzel" target="_blank"><span class="icon-vk"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://vk.com/spanish_guzel" target="_blank"><span class="icon-telegram"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://vk.com/spanish_guzel" target="_blank"><span class="icon-youtube"></span></Link></li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Item */}
                        <div className="col-md-6 col-lg-3 ftco-animate">
                            <div className="staff">
                                <div className="img-wrap d-flex align-items-stretch">
                                    <div
                                        className="img align-self-stretch"
                                        style={{ backgroundImage: "url(assets/img/teachersdance/anya-hablamos-esp.jpg)" }}
                                    />
                                </div>
                                <div className="text pt-3 text-center">
                                    <h3> DARIA </h3>
                                    <span className="position mb-2"> Español, Ruso </span>
                                    <div className="faded">
                                        <p>
                                            Обучаю детей и их родителей. Со мной результат быстрее. Загружаю испанский прямо в мозг
                                        </p>
                                        <ul className="ftco-social text-center">
                                            <li class="ftco-animate"><Link to="https://instagram.com/hablamos_esp" target="_blank"><span class="icon-instagram"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://vk.com/annaespanna" target="_blank"><span class="icon-vk"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://t.me/hablamos_esp" target="_blank"><span class="icon-telegram"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://www.youtube.com/@AnnaEspAnna" target="_blank"><span class="icon-youtube"></span></Link></li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Item */}
                        <div className="col-md-6 col-lg-3 ftco-animate">
                            <div className="staff">
                                <div className="img-wrap d-flex align-items-stretch">
                                    <div
                                        className="img align-self-stretch"
                                        style={{ backgroundImage: "url(assets/img/teachersdance/liza-es.jpg)" }}
                                    />
                                </div>
                                <div className="text pt-3 text-center">
                                    <h3> IDIOMAS CON LIZA </h3>
                                    <span className="position mb-2"> Español, Portugués, Inglés </span>
                                    <div className="faded">
                                        <p>
                                            Políglota. Profesora certificada. +2K alumnos en el mundo. Master class gratis.
                                        </p>
                                        <ul className="ftco-social text-center">
                                            <li class="ftco-animate"><Link to="https://instagram.com/idiomasconliza" target="_blank"><span class="icon-instagram"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://instagram.com/idiomasconliza" target="_blank"><span class="icon-vk"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://instagram.com/idiomasconliza" target="_blank"><span class="icon-telegram"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://www.youtube.com/@inglesconliza" target="_blank"><span class="icon-youtube"></span></Link></li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-md-6 col-lg-3 ftco-animate" >
                            <div className="staff">
                                <div className="img-wrap d-flex align-items-stretch">
                                    <div
                                        className="img align-self-stretch"
                                        style={{ backgroundImage: 'url("/assets/img/foxmaster/teacher-1.jpg")' }}

                                    />


                                </div>
                                <div className="text pt-3 text-center">
                                    <h3>Bianca Wilson</h3>
                                    <span className="position mb-2"> Spanish Teacher</span>
                                    <div className="faded">
                                        <p>
                                            Hola 01 I am an ambitious workaholic, but apart from that, pretty
                                            simple person.
                                        </p>
                                        <ul className="ftco-social text-center">
                                            <li class="ftco-animate"><a href="#"><span class="icon-telegram"></span></a></li>
                                            <li class="ftco-animate"><a href="#"><span class="icon-instagram"></span></a></li>
                                            <li class="ftco-animate"><a href="#"><span class="icon-vk"></span></a></li>
                                            <li class="ftco-animate"><a href="#"><span class="icon-youtube"></span></a></li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className="col-md-6 col-lg-3 ftco-animate">
                            <div className="staff">
                                <div className="img-wrap d-flex align-items-stretch">

                                    <div className="img align-self-stretch"
                                        style={{ backgroundImage: "url(./assets/img/foxmaster/teacher-2.jpg)" }}
                                    />
                                </div>
                                <div className="text pt-3 text-center">
                                    <h3>Mitch Parker</h3>
                                    <span className="position mb-2">English Teacher</span>
                                    <div className="faded">
                                        <p>
                                            I am an ambitious workaholic, but apart from that, pretty
                                            simple person.
                                        </p>
                                        <ul className="ftco-social text-center">
                                            <li class="ftco-animate"><a href="#"><span class="icon-telegram"></span></a></li>
                                            <li class="ftco-animate"><a href="#"><span class="icon-instagram"></span></a></li>
                                            <li class="ftco-animate"><a href="#"><span class="icon-vk"></span></a></li>
                                            <li class="ftco-animate"><a href="#"><span class="icon-youtube"></span></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-md-6 col-lg-3 ftco-animate">
                            <div className="staff">
                                <div className="img-wrap d-flex align-items-stretch">

                                    <div className="img align-self-stretch"
                                        style={{ backgroundImage: "url(./assets/img/foxmaster/staff-1.jpg)" }}
                                    />
                                </div>
                                <div className="text pt-3 text-center">
                                    <h3>Jhon Levin</h3>
                                    <span className="position mb-2">Spanish Teacher</span>
                                    <div className="faded">
                                        <p>
                                            I am an ambitious workaholic, but apart from that, pretty
                                            simple person.
                                        </p>
                                        <ul className="ftco-social text-center">
                                            <li class="ftco-animate"><a href="#"><span class="icon-telegram"></span></a></li>
                                            <li class="ftco-animate"><a href="#"><span class="icon-instagram"></span></a></li>
                                            <li class="ftco-animate"><a href="#"><span class="icon-vk"></span></a></li>
                                            <li class="ftco-animate"><a href="#"><span class="icon-youtube"></span></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-md-6 col-lg-3 ftco-animate">
                            <div className="staff">
                                <div className="img-wrap d-flex align-items-stretch">
                                    <div
                                        className="img align-self-stretch"
                                        style={{ backgroundImage: "url(assets/img/foxmaster/teacher-8.jpg)" }}
                                    />
                                </div>
                                <div className="text pt-3 text-center">
                                    <h3>Monshe Henderson</h3>
                                    <span className="position mb-2">Science Teacher</span>
                                    <div className="faded">
                                        <p>
                                            I am an ambitious workaholic, but apart from that, pretty
                                            simple person.
                                        </p>
                                        <ul className="ftco-social text-center">
                                            <li class="ftco-animate"><a href="#"><span class="icon-telegram"></span></a></li>
                                            <li class="ftco-animate"><a href="#"><span class="icon-instagram"></span></a></li>
                                            <li class="ftco-animate"><a href="#"><span class="icon-vk"></span></a></li>
                                            <li class="ftco-animate"><a href="#"><span class="icon-youtube"></span></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>




                        <div className="col-md-6 col-lg-3 ftco-animate">
                            <div className="staff">
                                <div className="img-wrap d-flex align-items-stretch">
                                    <div
                                        className="img align-self-stretch"
                                        style={{ backgroundImage: "url(assets/img/foxmaster/teacher-3.jpg)" }}
                                    />
                                </div>
                                <div className="text pt-3 text-center">
                                    <h3>Stella Smith</h3>
                                    <span className="position mb-2">Art Teacher</span>
                                    <div className="faded">
                                        <p>
                                            I am an ambitious workaholic, but apart from that, pretty
                                            simple person.
                                        </p>
                                        <ul className="ftco-social text-center">
                                            <li class="ftco-animate"><a href="#"><span class="icon-telegram"></span></a></li>
                                            <li class="ftco-animate"><a href="#"><span class="icon-instagram"></span></a></li>
                                            <li class="ftco-animate"><a href="#"><span class="icon-vk"></span></a></li>
                                            <li class="ftco-animate"><a href="#"><span class="icon-youtube"></span></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-md-6 col-lg-3 ftco-animate">
                            <div className="staff">
                                <div className="img-wrap d-flex align-items-stretch">
                                    <div
                                        className="img align-self-stretch"
                                        style={{ backgroundImage: "url(assets/img/foxmaster/teacher-4.jpg)" }}
                                    />
                                </div>
                                <div className="text pt-3 text-center">
                                    <h3>Monshe Henderson</h3>
                                    <span className="position mb-2">Science Teacher</span>
                                    <div className="faded">
                                        <p>
                                            I am an ambitious workaholic, but apart from that, pretty
                                            simple person.
                                        </p>
                                        <ul className="ftco-social text-center">
                                            <li class="ftco-animate"><a href="#"><span class="icon-telegram"></span></a></li>
                                            <li class="ftco-animate"><a href="#"><span class="icon-instagram"></span></a></li>
                                            <li class="ftco-animate"><a href="#"><span class="icon-vk"></span></a></li>
                                            <li class="ftco-animate"><a href="#"><span class="icon-youtube"></span></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 ftco-animate">
                            <div className="staff">
                                <div className="img-wrap d-flex align-items-stretch">
                                    <div
                                        className="img align-self-stretch"
                                        style={{ backgroundImage: "url(assets/img/foxmaster/teacher-5.jpg)" }}
                                    />
                                </div>
                                <div className="text pt-3 text-center">
                                    <h3>Tom Fernandez</h3>
                                    <span className="position mb-2">Teacher</span>
                                    <div className="faded">
                                        <p>
                                            I am an ambitious workaholic, but apart from that, pretty
                                            simple person.
                                        </p>
                                        <ul className="ftco-social text-center">
                                            <li class="ftco-animate"><a href="#"><span class="icon-telegram"></span></a></li>
                                            <li class="ftco-animate"><a href="#"><span class="icon-instagram"></span></a></li>
                                            <li class="ftco-animate"><a href="#"><span class="icon-vk"></span></a></li>
                                            <li class="ftco-animate"><a href="#"><span class="icon-youtube"></span></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 ftco-animate">
                            <div className="staff">
                                <div className="img-wrap d-flex align-items-stretch">
                                    <div
                                        className="img align-self-stretch"
                                        style={{ backgroundImage: "url(assets/img/foxmaster/teacher-6.jpg)" }}
                                    />
                                </div>
                                <div className="text pt-3 text-center">
                                    <h3>Mitch Parker</h3>
                                    <span className="position mb-2">English Teacher</span>
                                    <div className="faded">
                                        <p>
                                            I am an ambitious workaholic, but apart from that, pretty
                                            simple person.
                                        </p>
                                        <ul className="ftco-social text-center">
                                            <li class="ftco-animate"><a href="#"><span class="icon-telegram"></span></a></li>
                                            <li class="ftco-animate"><a href="#"><span class="icon-instagram"></span></a></li>
                                            <li class="ftco-animate"><a href="#"><span class="icon-vk"></span></a></li>
                                            <li class="ftco-animate"><a href="#"><span class="icon-youtube"></span></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 ftco-animate">
                            <div className="staff">
                                <div className="img-wrap d-flex align-items-stretch">
                                    <div
                                        className="img align-self-stretch"
                                        style={{ backgroundImage: "url(assets/img/foxmaster/teacher-7.jpg)" }}
                                    />
                                </div>
                                <div className="text pt-3 text-center">
                                    <h3>Stella Smith</h3>
                                    <span className="position mb-2">Art Teacher</span>
                                    <div className="faded">
                                        <p>
                                            I am an ambitious workaholic, but apart from that, pretty
                                            simple person.
                                        </p>
                                        <ul className="ftco-social text-center">
                                            <li class="ftco-animate"><a href="#"><span class="icon-telegram"></span></a></li>
                                            <li class="ftco-animate"><a href="#"><span class="icon-instagram"></span></a></li>
                                            <li class="ftco-animate"><a href="#"><span class="icon-vk"></span></a></li>
                                            <li class="ftco-animate"><a href="#"><span class="icon-youtube"></span></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>






            </section>
            {/* </div> */}

            <Footer />


        </main>



    );

}

export default FoxTeacher;
