


/* Elearning*/

import Footer from "./FooterEs";
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import React, { useEffect, useState } from 'react';
import WOW from 'wowjs';


function SchoolsDance() {

    useEffect(() => {
        const wow = new WOW.WOW();
        wow.init();
    }, []);

    return (

        <main>


            {/* Header Start */}
            <div className="container-fluid py-5 mb-5 page-header" >


                <div className="container py-5">
                    <div className="row justify-content-center">
                        <div className="col-lg-10 text-center">
                            <h1 className="display-3 text-white animated slideInDown">Galería</h1>
                            <nav aria-label="breadcrumb-elearn">
                                <ol className="breadcrumb-elearn justify-content-center">
                                    <li className="breadcrumb-item">
                                        <a className="text-white" href="#">
                                            Главная
                                        </a>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <a className="text-white" href="#">
                                            Школы
                                        </a>
                                    </li>
                                    <li
                                        className="breadcrumb-item text-white active"
                                        aria-current="page"
                                    >
                                        Танцы
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            {/* Header End */}


            {/* Categories Start */}
            <div className="container-xxl py-5 category">
                <div className="container">
                    <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                        <h6 className="section-title-elearn bg-white text-center text-primary px-3">
                            Galería
                        </h6>
                        <h1 className="mb-5"> Categorías   </h1>
                    </div>


                    <div className="row g-3">
                        <div className="col-lg-7 col-md-6">
                            <div className="row g-3">
                                <div
                                    className="col-lg-12 col-md-12 wow zoomIn"
                                    data-wow-delay="0.1s"
                                >
                                    <Link to="/video-events" className="position-relative d-block overflow-hidden"  >
                                        <img className="img-fluid" src="/assets/img/gallery/bachata01a.png" alt="" />


                                        <div
                                            className="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3"
                                            style={{ margin: 1 }}
                                        >
                                            <h5 className="m-0">VIDEOS</h5>
                                            <small className="text-primary">19 Т. Школы </small>
                                        </div>
                                    </Link>

                                </div>

                               {/*  <div
                                    className="col-lg-6 col-md-12 wow zoomIn"
                                    data-wow-delay="0.3s"
                                >
                                    <a className="position-relative d-block overflow-hidden" href="">
                                        <img className="img-fluid" src="/assets/img/gallery/salsa01.png" alt="" />
                                        <div
                                            className="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3"
                                            style={{ margin: 1 }}
                                        >
                                            <h5 className="m-0">Сальса</h5>
                                            <small className="text-primary">17 Т. Школы </small>
                                        </div>
                                    </a>
                                </div>
                                <div
                                    className="col-lg-6 col-md-12 wow zoomIn"
                                    data-wow-delay="0.5s"
                                >
                                    <a className="position-relative d-block overflow-hidden" href="">
                                        <img className="img-fluid" src="/assets/img/gallery/kizomba01.png" alt="" />
                                        <div
                                            className="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3"
                                            style={{ margin: 1 }}
                                        >
                                            <h5 className="m-0">Кизомба</h5>
                                            <small className="text-primary">15 Т. Школы </small>
                                        </div>
                                    </a>
                                </div> */}
                            </div>
                        </div>




                        <div
                            className="col-lg-5 col-md-6 wow zoomIn"
                            data-wow-delay="0.7s"
                            style={{ minHeight: 350 }}
                        >
                            <Link to="/photos"
                                className="position-relative d-block h-100 overflow-hidden"

                            >
                                <img
                                    className="img-fluid position-absolute w-100 h-100"
                                    src="/assets/img/gallery/social-dance01a.png"
                                    alt=""
                                    style={{ objectFit: "cover" }}
                                />
                                <div
                                    className="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3"
                                    style={{ margin: 1 }}
                                >
                                    <h5 className="m-0">FOTOS</h5>
                                    <small className="text-primary">49 Т. Школы </small>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* Categories Start */}



            {/* Т. Школы  Start */}
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                        <h6 className="section-title-elearn bg-white text-center text-primary px-3">
                            Танцевальные Школы
                        </h6>
                        <h1 className="mb-5"> Популярные Танцевальные Школы </h1>
                    </div>


                </div>
            </div>
            {/* Т. Школы  End */}



            <Footer />


        </main >



    );

}

export default SchoolsDance;