


/* Elearning*/

import Footer from "./FooterEn";
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import React, { useEffect, useState } from 'react';
import WOW from 'wowjs';


function SchoolsDance() {

    useEffect(() => {
        const wow = new WOW.WOW();
        wow.init();
    }, []);

    return (

        <main>

            {/* Spinner Start */}
            {/*  <div
                id="spinner"
                className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
            >
                <div
                    className="spinner-border text-primary"
                    style={{ width: "3rem", height: "3rem" }}
                    role="status"
                >
                    <span className="sr-only">Loading...</span>
                </div>
            </div> */}
            {/* Spinner End */}


            {/* Header Start */}
            {/* <div className="container-fluid bg-primary py-5 mb-5 page-header" > */}
            <div className="container-fluid py-5 mb-5 page-header" >


                <div className="container py-5">
                    <div className="row justify-content-center">
                        <div className="col-lg-10 text-center">
                            <h1 className="display-3 text-white animated slideInDown">Dance Schools</h1>
                            <nav aria-label="breadcrumb-elearn">
                                <ol className="breadcrumb-elearn justify-content-center">
                                    <li className="breadcrumb-item">
                                        <a className="text-white" href="#">
                                            Inicio
                                        </a>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <a className="text-white" href="#">
                                        Dance Schools
                                        </a>
                                    </li>
                                    <li
                                        className="breadcrumb-item text-white active"
                                        aria-current="page"
                                    >
                                        Bailes
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            {/* Header End */}


            {/* Categories Start */}
            <div className="container-xxl py-5 category">
                <div className="container">
                    <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                        <h6 className="section-title-elearn bg-white text-center text-primary px-3">
                            Categorías
                        </h6>
                        <h1 className="mb-5"> Categorías de Escuelas</h1>
                    </div>


                    <div className="row g-3">
                        <div className="col-lg-7 col-md-6">
                            <div className="row g-3">
                                <div
                                    className="col-lg-12 col-md-12 wow zoomIn"
                                    data-wow-delay="0.1s"
                                >
                                    <a className="position-relative d-block overflow-hidden" href="">
                                        <img className="img-fluid" src="/assets/img/gallery/bachata01a.png" alt="" />


                                        <div
                                            className="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3"
                                            style={{ margin: 1 }}
                                        >
                                            <h5 className="m-0">Bachata</h5>
                                            <small className="text-primary">19 Escuelas</small>
                                        </div>
                                    </a>
                                </div>

                                <div
                                    className="col-lg-6 col-md-12 wow zoomIn"
                                    data-wow-delay="0.3s"
                                >
                                    <a className="position-relative d-block overflow-hidden" href="">
                                        <img className="img-fluid" src="/assets/img/gallery/salsa01.png" alt="" />
                                        <div
                                            className="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3"
                                            style={{ margin: 1 }}
                                        >
                                            <h5 className="m-0">Salsa</h5>
                                            <small className="text-primary">17 Escuelas</small>
                                        </div>
                                    </a>
                                </div>
                                <div
                                    className="col-lg-6 col-md-12 wow zoomIn"
                                    data-wow-delay="0.5s"
                                >
                                    <a className="position-relative d-block overflow-hidden" href="">
                                        <img className="img-fluid" src="/assets/img/gallery/kizomba01.png" alt="" />
                                        <div
                                            className="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3"
                                            style={{ margin: 1 }}
                                        >
                                            <h5 className="m-0">Kizomba</h5>
                                            <small className="text-primary">15 Escuelas</small>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-lg-5 col-md-6 wow zoomIn"
                            data-wow-delay="0.7s"
                            style={{ minHeight: 350 }}
                        >
                            <a
                                className="position-relative d-block h-100 overflow-hidden"
                                href=""
                            >
                                <img
                                    className="img-fluid position-absolute w-100 h-100"
                                    src="/assets/img/gallery/social-dance01a.png"
                                    alt=""
                                    style={{ objectFit: "cover" }}
                                />
                                <div
                                    className="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3"
                                    style={{ margin: 1 }}
                                >
                                    <h5 className="m-0">Bailes sociales</h5>
                                    <small className="text-primary">49 Escuelas</small>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* Categories Start */}

            {/* Escuelas Start */}
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                        <h6 className="section-title-elearn bg-white text-center text-primary px-3">
                        Dance Schools
                        </h6>
                        <h1 className="mb-5"> Popular Schools</h1>
                    </div>
                    <div className="row g-4 justify-content-center">

                        {/* Item */}
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="course-item bg-light">
                                <div className="position-relative overflow-hidden">
                                    <img className="img-fluid" src="/assets/img/banner/salsateca.jpg" alt="" />
                                    <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                                        <Link to="/salsateca" target="_blank" className="flex-shrink-0 btn btn-sm btn-primary px-3 border-end"
                                            style={{ borderRadius: "30px 0 0 30px" }}>
                                            Читать ещё
                                        </Link>

                                        <Link to="https://t.me/salsateca_ru" target="_blank" className="flex-shrink-0 btn btn-sm btn-primary px-3"
                                            style={{ borderRadius: "0 30px 30px 0" }}>
                                            Записаться
                                        </Link>

                                    </div>
                                </div>
                                <div className="text-center p-4 pb-0">
                                    <h3 className="mb-0">Salsateca.ru</h3>
                                    <div className="mb-3">
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small>(23)</small>
                                    </div>
                                    <h5 className="mb-4">
                                        Сальса, Бачата, Кизомба &amp; Lady Style
                                    </h5>
                                </div>
                                <div className="d-flex border-top">
                                    <small className="flex-fill text-center border-end py-2">
                                        <i className="fa fa-map-marker-alt text-primary me-2" />
                                        м. Пролетарская
                                    </small>
                                    <small className="flex-fill text-center border-end py-2">
                                        <i className="fa fa-clock text-primary me-2" />
                                        1.49 Hrs
                                    </small>
                                    <small className="flex-fill text-center py-2">
                                        <i className="fa fa-user text-primary me-2" />
                                        1462 Ученики
                                    </small>
                                </div>
                            </div>
                        </div>

                        {/* Item */}
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                            <div className="course-item bg-light">
                                <div className="position-relative overflow-hidden">
                                    <img className="img-fluid" src="/assets/img/banner/Bailemos-dance.jpg" alt="" />
                                    <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                                        <Link to="https://instagram.com/bailemos_dance" target="_blank" className="flex-shrink-0 btn btn-sm btn-primary px-3 border-end"
                                            style={{ borderRadius: "30px 0 0 30px" }}>
                                            Читать ещё
                                        </Link>

                                        <Link to="https://instagram.com/bailemos_dance" target="_blank" className="flex-shrink-0 btn btn-sm btn-primary px-3"
                                            style={{ borderRadius: "0 30px 30px 0" }}>
                                            Записаться
                                        </Link>

                                    </div>
                                </div>
                                <div className="text-center p-4 pb-0">
                                    <h3 className="mb-0">Bailemos Dance</h3>
                                    <div className="mb-3">
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small>(23)</small>
                                    </div>
                                    <h5 className="mb-4">
                                        Сальса, Бачата, Реггетон, Кизомба &amp; Lady Style
                                    </h5>
                                </div>
                                <div className="d-flex border-top">
                                    <small className="flex-fill text-center border-end py-2">
                                        <i className="fa fa-map-marker-alt text-primary me-2" />
                                        м. Пролетарская
                                    </small>
                                    <small className="flex-fill text-center border-end py-2">
                                        <i className="fa fa-clock text-primary me-2" />
                                        1.49 Hrs
                                    </small>
                                    <small className="flex-fill text-center py-2">
                                        <i className="fa fa-user text-primary me-2" />
                                        117 Ученики
                                    </small>
                                </div>
                            </div>
                        </div>

                        {/* Item */}
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="course-item bg-light">
                                <div className="position-relative overflow-hidden">
                                    <img className="img-fluid" src="/assets/img/banner/Brillo-latino.jpg" alt="" />
                                    <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                                        <Link to="https://vk.com/brillolatino" target="_blank" className="flex-shrink-0 btn btn-sm btn-primary px-3 border-end"
                                            style={{ borderRadius: "30px 0 0 30px" }}>
                                            Читать ещё
                                        </Link>
                                        <Link to="https://vk.com/brillolatino" target="_blank" className="flex-shrink-0 btn btn-sm btn-primary px-3"
                                            style={{ borderRadius: "0 30px 30px 0" }}>
                                            Записаться
                                        </Link>
                                    </div>
                                </div>

                                <div className="text-center p-4 pb-0">
                                    <h3 className="mb-0">Brillo Latino</h3>
                                    <div className="mb-3">
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small>(47)</small>
                                    </div>
                                    <h5 className="mb-4">
                                        Сальса, Бачата &amp; Кизомба
                                    </h5>
                                </div>

                                <div className="d-flex border-top">
                                    <small className="flex-fill text-center border-end py-2">
                                        <i className="fa fa-map-marker-alt text-primary me-2" />
                                        м. Китай-город 
                                    </small>
                                    <small className="flex-fill text-center border-end py-2">
                                        <i className="fa fa-clock text-primary me-2" />
                                        1.49 Hrs
                                    </small>
                                    <small className="flex-fill text-center py-2">
                                        <i className="fa fa-user text-primary me-2" />
                                        815 Ученики
                                    </small>
                                </div>
                            </div>
                        </div>

                        {/* Item */}
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="course-item bg-light">
                                <div className="position-relative overflow-hidden">
                                    <img className="img-fluid" src="/assets/img/banner/Wilkin-dance.jpg" alt="" />
                                    <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                                        <Link to="https://vk.com/wilkin_dance" target="_blank" className="flex-shrink-0 btn btn-sm btn-primary px-3 border-end"
                                            style={{ borderRadius: "30px 0 0 30px" }}>
                                            Read More
                                        </Link>
                                        <Link to="https://vk.com/wilkin_dance" target="_blank" className="flex-shrink-0 btn btn-sm btn-primary px-3"
                                            style={{ borderRadius: "0 30px 30px 0" }}>
                                            Join Now
                                        </Link>
                                    </div>
                                </div>

                                <div className="text-center p-4 pb-0">
                                    <h3 className="mb-0">Wilkin Dance</h3>
                                    <div className="mb-3">
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small>(72)</small>
                                    </div>
                                    <h5 className="mb-4">
                                        Salsa, Bachata, Reggaeton, Kizomba &amp; Lady Style
                                    </h5>
                                </div>
                                <div className="d-flex border-top">
                                    <small className="flex-fill text-center border-end py-2">
                                        <i className="fa fa-map-marker-alt text-primary me-2" />
                                        M. Proletarskaya
                                    </small>
                                    <small className="flex-fill text-center border-end py-2">
                                        <i className="fa fa-clock text-primary me-2" />
                                        1.49 Hrs
                                    </small>
                                    <small className="flex-fill text-center py-2">
                                        <i className="fa fa-user text-primary me-2" />
                                        1043 Alumnos
                                    </small>
                                </div>
                            </div>
                        </div>

                        {/* Item */}
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                            <div className="course-item bg-light">
                                <div className="position-relative overflow-hidden">
                                    <img className="img-fluid" src="/assets/img/banner/Loco-danza.jpg" alt="" />
                                    <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                                        <Link to="https://vk.com/locodanza" target="_blank" className="flex-shrink-0 btn btn-sm btn-primary px-3 border-end"
                                            style={{ borderRadius: "30px 0 0 30px" }}>
                                            Read More
                                        </Link>
                                        <Link to="https://vk.com/locodanza" target="_blank" className="flex-shrink-0 btn btn-sm btn-primary px-3"
                                            style={{ borderRadius: "0 30px 30px 0" }}>
                                            Join Now
                                        </Link>
                                    </div>
                                </div>

                                <div className="text-center p-4 pb-0">
                                    <h3 className="mb-0"> LocoDanza</h3>
                                    <div className="mb-3">
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small>(31)</small>
                                    </div>
                                    <h5 className="mb-4">
                                        Бачата, Кизомба &amp; Бачата Lady Style
                                    </h5>
                                </div>

                                <div className="d-flex border-top">
                                    <small className="flex-fill text-center border-end py-2">
                                        <i className="fa fa-map-marker-alt text-primary me-2" />
                                        M. Baumanskaya
                                    </small>
                                    <small className="flex-fill text-center border-end py-2">
                                        <i className="fa fa-clock text-primary me-2" />
                                        1.49 Hrs
                                    </small>
                                    <small className="flex-fill text-center py-2">
                                        <i className="fa fa-user text-primary me-2" />
                                        621 Alumnos
                                    </small>
                                </div>
                            </div>
                        </div>

                        {/* Item */}
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="course-item bg-light">
                                <div className="position-relative overflow-hidden">
                                    <img className="img-fluid" src="/assets/img/banner/Danceliker.jpg" alt="" />
                                    <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                                        <Link to="https://vk.com/danceliker" target="_blank" className="flex-shrink-0 btn btn-sm btn-primary px-3 border-end"
                                            style={{ borderRadius: "30px 0 0 30px" }}>
                                            Read More
                                        </Link>
                                        <Link to="https://vk.com/danceliker" target="_blank" className="flex-shrink-0 btn btn-sm btn-primary px-3"
                                            style={{ borderRadius: "0 30px 30px 0" }}>
                                            Join Now
                                        </Link>
                                    </div>
                                </div>

                                <div className="text-center p-4 pb-0">
                                    <h3 className="mb-0"> Danceliker</h3>
                                    <div className="mb-3">
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small>(31)</small>
                                    </div>
                                    <h5 className="mb-4">
                                        Salsa, Bachata &amp; Bachata Lady Style
                                    </h5>
                                </div>

                                <div className="d-flex border-top">
                                    <small className="flex-fill text-center border-end py-2">
                                        <i className="fa fa-map-marker-alt text-primary me-2" />
                                        M. Frunzenskaya
                                    </small>
                                    <small className="flex-fill text-center border-end py-2">
                                        <i className="fa fa-clock text-primary me-2" />
                                        1.49 Hrs
                                    </small>
                                    <small className="flex-fill text-center py-2">
                                        <i className="fa fa-user text-primary me-2" />
                                        1350 Alumnos
                                    </small>
                                </div>
                            </div>
                        </div>

                        {/* Item */}
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="course-item bg-light">
                                <div className="position-relative overflow-hidden">
                                    <img className="img-fluid" src="/assets/img/banner/Spicy-salsa.jpg" alt="" />
                                    <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                                        <Link to="https://vk.com/spicysalsa" target="_blank" className="flex-shrink-0 btn btn-sm btn-primary px-3 border-end"
                                            style={{ borderRadius: "30px 0 0 30px" }}>
                                            Read More
                                        </Link>
                                        <Link to="https://vk.com/spicysalsa" target="_blank" className="flex-shrink-0 btn btn-sm btn-primary px-3"
                                            style={{ borderRadius: "0 30px 30px 0" }}>
                                            Join Now
                                        </Link>
                                    </div>
                                </div>

                                <div className="text-center p-4 pb-0">
                                    <h3 className="mb-0"> Spicy Salsa </h3>
                                    <div className="mb-3">
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small>(232)</small>
                                    </div>
                                    <h5 className="mb-4">
                                        Salsa, Bachata, Reggaeton, Kizomba &amp; Lady Style
                                    </h5>
                                </div>

                                <div className="d-flex border-top">
                                    <small className="flex-fill text-center border-end py-2">
                                        <i className="fa fa-map-marker-alt text-primary me-2" />
                                        M. Novokuznetskaya
                                    </small>
                                    <small className="flex-fill text-center border-end py-2">
                                        <i className="fa fa-clock text-primary me-2" />
                                        1.49 Hrs
                                    </small>
                                    <small className="flex-fill text-center py-2">
                                        <i className="fa fa-user text-primary me-2" />
                                        6884 Alumnos
                                    </small>
                                </div>
                            </div>
                        </div>

                        {/* Item */}
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                            <div className="course-item bg-light">
                                <div className="position-relative overflow-hidden">
                                    <img className="img-fluid" src="/assets/img/banner/Crazy-salsa.jpg" alt="" />
                                    <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                                        <Link to="https://vk.com/crazy_salsa" target="_blank" className="flex-shrink-0 btn btn-sm btn-primary px-3 border-end"
                                            style={{ borderRadius: "30px 0 0 30px" }}>
                                            Read More
                                        </Link>
                                        <Link to="https://vk.com/crazy_salsa" target="_blank" className="flex-shrink-0 btn btn-sm btn-primary px-3"
                                            style={{ borderRadius: "0 30px 30px 0" }}>
                                            Join Now
                                        </Link>
                                    </div>
                                </div>

                                <div className="text-center p-4 pb-0">
                                    <h3 className="mb-0"> Crazy Salsa </h3>
                                    <div className="mb-3">
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small>(67)</small>
                                    </div>
                                    <h5 className="mb-4">
                                        Salsa, Bachata &amp; Lady Style
                                    </h5>
                                </div>

                                <div className="d-flex border-top">
                                    <small className="flex-fill text-center border-end py-2">
                                        <i className="fa fa-map-marker-alt text-primary me-2" />
                                        M. Tretyakovskaya
                                    </small>
                                    <small className="flex-fill text-center border-end py-2">
                                        <i className="fa fa-clock text-primary me-2" />
                                        1.49 Hrs
                                    </small>
                                    <small className="flex-fill text-center py-2">
                                        <i className="fa fa-user text-primary me-2" />
                                        6884 Alumnos
                                    </small>
                                </div>
                            </div>
                        </div>

                        {/* Item */}
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="course-item bg-light">
                                <div className="position-relative overflow-hidden">
                                    <img className="img-fluid" src="/assets/img/banner/Boris-Quintero.jpg" alt="" />
                                    <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                                        <Link to="https://vk.com/bailenconborisquintero" target="_blank" className="flex-shrink-0 btn btn-sm btn-primary px-3 border-end"
                                            style={{ borderRadius: "30px 0 0 30px" }}>
                                            Read More
                                        </Link>
                                        <Link to="https://vk.com/bailenconborisquintero" target="_blank" className="flex-shrink-0 btn btn-sm btn-primary px-3"
                                            style={{ borderRadius: "0 30px 30px 0" }}>
                                            Join Now
                                        </Link>
                                    </div>
                                </div>

                                <div className="text-center p-4 pb-0">
                                    <h3 className="mb-0"> Boris Quintero </h3>
                                    <div className="mb-3">
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small>(63)</small>
                                    </div>
                                    <h5 className="mb-4">
                                        Salsa, Bachata, Merengue, Rumba, Afro,  Son  &amp;  Cha-cha-cha
                                    </h5>
                                </div>

                                <div className="d-flex border-top">
                                    <small className="flex-fill text-center border-end py-2">
                                        <i className="fa fa-map-marker-alt text-primary me-2" />
                                        M. Belorusskaya
                                    </small>
                                    <small className="flex-fill text-center border-end py-2">
                                        <i className="fa fa-clock text-primary me-2" />
                                        1.49 Hrs
                                    </small>
                                    <small className="flex-fill text-center py-2">
                                        <i className="fa fa-user text-primary me-2" />
                                        2720 Alumnos
                                    </small>
                                </div>
                            </div>
                        </div>

                        {/* Item */}
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="course-item bg-light">
                                <div className="position-relative overflow-hidden">
                                    <img className="img-fluid" src="/assets/img/banner/Sierra-maestra.jpg" alt="" />
                                    <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                                        <Link to="https://vk.com/sierra_maestra_moscow" target="_blank" className="flex-shrink-0 btn btn-sm btn-primary px-3 border-end"
                                            style={{ borderRadius: "30px 0 0 30px" }}>
                                            Read More
                                        </Link>
                                        <Link to="https://vk.com/sierra_maestra_moscow" target="_blank" className="flex-shrink-0 btn btn-sm btn-primary px-3"
                                            style={{ borderRadius: "0 30px 30px 0" }}>
                                            Join Now
                                        </Link>
                                    </div>
                                </div>

                                <div className="text-center p-4 pb-0">
                                    <h3 className="mb-0"> Sierra Maestra </h3>
                                    <div className="mb-3">
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small>(45)</small>
                                    </div>
                                    <h5 className="mb-4">
                                        Salsa  &amp; Bachata
                                    </h5>
                                </div>

                                <div className="d-flex border-top">
                                    <small className="flex-fill text-center border-end py-2">
                                        <i className="fa fa-map-marker-alt text-primary me-2" />
                                        M. Chistye Prudy
                                    </small>
                                    <small className="flex-fill text-center border-end py-2">
                                        <i className="fa fa-clock text-primary me-2" />
                                        1.49 Hrs
                                    </small>
                                    <small className="flex-fill text-center py-2">
                                        <i className="fa fa-user text-primary me-2" />
                                        8020 Alumnos
                                    </small>
                                </div>
                            </div>
                        </div>

                        {/* Item */}
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                            <div className="course-item bg-light">
                                <div className="position-relative overflow-hidden">
                                    <img className="img-fluid" src="/assets/img/banner/Chino-baile.jpg" alt="" />
                                    <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                                        <Link to="https://vk.com/chino.bachata" target="_blank" className="flex-shrink-0 btn btn-sm btn-primary px-3 border-end"
                                            style={{ borderRadius: "30px 0 0 30px" }}>
                                            Read More
                                        </Link>
                                        <Link to="https://vk.com/chino.bachata" target="_blank" className="flex-shrink-0 btn btn-sm btn-primary px-3"
                                            style={{ borderRadius: "0 30px 30px 0" }}>
                                            Join Now
                                        </Link>
                                    </div>
                                </div>

                                <div className="text-center p-4 pb-0">
                                    <h3 className="mb-0"> CHINO Dance </h3>
                                    <div className="mb-3">
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small>(42)</small>
                                    </div>
                                    <h5 className="mb-4">
                                        Bachata, Salsa &amp; Merengue
                                    </h5>
                                </div>

                                <div className="d-flex border-top">
                                    <small className="flex-fill text-center border-end py-2">
                                        <i className="fa fa-map-marker-alt text-primary me-2" />
                                        M. Prospekt Mira
                                    </small>
                                    <small className="flex-fill text-center border-end py-2">
                                        <i className="fa fa-clock text-primary me-2" />
                                        1.49 Hrs
                                    </small>
                                    <small className="flex-fill text-center py-2">
                                        <i className="fa fa-user text-primary me-2" />
                                        1705 Alumnos
                                    </small>
                                </div>
                            </div>
                        </div>

                        {/* Item */}
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="course-item bg-light">
                                <div className="position-relative overflow-hidden">
                                    <img className="img-fluid" src="/assets/img/banner/DyE.jpg" alt="" />
                                    <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                                        <Link to="https://vk.com/casadebachata" target="_blank" className="flex-shrink-0 btn btn-sm btn-primary px-3 border-end"
                                            style={{ borderRadius: "30px 0 0 30px" }}>
                                            Read More
                                        </Link>
                                        <Link to="https://vk.com/casadebachata" target="_blank" className="flex-shrink-0 btn btn-sm btn-primary px-3"
                                            style={{ borderRadius: "0 30px 30px 0" }}>
                                            Join Now
                                        </Link>
                                    </div>
                                </div>

                                <div className="text-center p-4 pb-0">
                                    <h3 className="mb-0"> Casa D&E Bachata </h3>
                                    <div className="mb-3">
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small>(29)</small>
                                    </div>
                                    <h5 className="mb-4">
                                        Bachata,  Bachata Sensual  &amp; Lady Style
                                    </h5>
                                </div>

                                <div className="d-flex border-top">
                                    <small className="flex-fill text-center border-end py-2">
                                        <i className="fa fa-map-marker-alt text-primary me-2" />
                                        M. Paveletskaya
                                    </small>
                                    <small className="flex-fill text-center border-end py-2">
                                        <i className="fa fa-clock text-primary me-2" />
                                        1.49 Hrs
                                    </small>
                                    <small className="flex-fill text-center py-2">
                                        <i className="fa fa-user text-primary me-2" />
                                        536 Alumnos
                                    </small>
                                </div>
                            </div>
                        </div>

                        {/* Item */}
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="course-item bg-light">
                                <div className="position-relative overflow-hidden">
                                    <img className="img-fluid" src="/assets/img/banner/Sbaya-shkola.jpg" alt="" />
                                    <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                                        <Link to="https://vk.com/svoyashkola" target="_blank" className="flex-shrink-0 btn btn-sm btn-primary px-3 border-end"
                                            style={{ borderRadius: "30px 0 0 30px" }}>
                                            Read More
                                        </Link>
                                        <Link to="https://vk.com/svoyashkola" target="_blank" className="flex-shrink-0 btn btn-sm btn-primary px-3"
                                            style={{ borderRadius: "0 30px 30px 0" }}>
                                            Join Now
                                        </Link>
                                    </div>
                                </div>

                                <div className="text-center p-4 pb-0">
                                    <h3 className="mb-0"> Своя школа </h3>
                                    <div className="mb-3">
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small>(34)</small>
                                    </div>
                                    <h5 className="mb-4">
                                        Bachata, Salsa, Ruedo  &amp; Plástica
                                    </h5>
                                </div>

                                <div className="d-flex border-top">
                                    <small className="flex-fill text-center border-end py-2">
                                        <i className="fa fa-map-marker-alt text-primary me-2" />
                                        M. Paveletskaya
                                    </small>
                                    <small className="flex-fill text-center border-end py-2">
                                        <i className="fa fa-clock text-primary me-2" />
                                        1.49 Hrs
                                    </small>
                                    <small className="flex-fill text-center py-2">
                                        <i className="fa fa-user text-primary me-2" />
                                        4306 Alumnos
                                    </small>
                                </div>
                            </div>
                        </div>

                        {/* Item */}
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                            <div className="course-item bg-light">
                                <div className="position-relative overflow-hidden">
                                    <img className="img-fluid" src="/assets/img/banner/AyC.jpg" alt="" />
                                    <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                                        <Link to="https://vk.com/armenycasa" target="_blank" className="flex-shrink-0 btn btn-sm btn-primary px-3 border-end"
                                            style={{ borderRadius: "30px 0 0 30px" }}>
                                            Read More
                                        </Link>
                                        <Link to="https://vk.com/armenycasa" target="_blank" className="flex-shrink-0 btn btn-sm btn-primary px-3"
                                            style={{ borderRadius: "0 30px 30px 0" }}>
                                            Join Now
                                        </Link>
                                    </div>
                                </div>

                                <div className="text-center p-4 pb-0">
                                    <h3 className="mb-0"> Armenycasa </h3>
                                    <div className="mb-3">
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small>(19)</small>
                                    </div>
                                    <h5 className="mb-4">
                                        Bachata, Salsa, Son, Afro, Rumba  &amp; Pachanga
                                    </h5>
                                </div>

                                <div className="d-flex border-top">
                                    <small className="flex-fill text-center border-end py-2">
                                        <i className="fa fa-map-marker-alt text-primary me-2" />
                                        M. Bulevar Tsvetnoi
                                    </small>
                                    <small className="flex-fill text-center border-end py-2">
                                        <i className="fa fa-clock text-primary me-2" />
                                        1.49 Hrs
                                    </small>
                                    <small className="flex-fill text-center py-2">
                                        <i className="fa fa-user text-primary me-2" />
                                        6101 Alumnos
                                    </small>
                                </div>
                            </div>
                        </div>

                        {/* Item */}
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="course-item bg-light">
                                <div className="position-relative overflow-hidden">
                                    <img className="img-fluid" src="/assets/img/banner/Laplaya.jpg" alt="" />
                                    <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                                        <Link to="https://vk.com/salsabachatareggaetonkizomba" target="_blank" className="flex-shrink-0 btn btn-sm btn-primary px-3 border-end"
                                            style={{ borderRadius: "30px 0 0 30px" }}>
                                            Read More
                                        </Link>
                                        <Link to="https://vk.com/salsabachatareggaetonkizomba" target="_blank" className="flex-shrink-0 btn btn-sm btn-primary px-3"
                                            style={{ borderRadius: "0 30px 30px 0" }}>
                                            Join Now
                                        </Link>
                                    </div>
                                </div>

                                <div className="text-center p-4 pb-0">
                                    <h3 className="mb-0"> La Playa </h3>
                                    <div className="mb-3">
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small>(26)</small>
                                    </div>
                                    <h5 className="mb-4">
                                        Bachata, Salsa, Reggaeton &amp; Contemporánea
                                    </h5>
                                </div>

                                <div className="d-flex border-top">
                                    <small className="flex-fill text-center border-end py-2">
                                        <i className="fa fa-map-marker-alt text-primary me-2" />
                                        M. Novokuznetskaya
                                    </small>
                                    <small className="flex-fill text-center border-end py-2">
                                        <i className="fa fa-clock text-primary me-2" />
                                        1.49 Hrs
                                    </small>
                                    <small className="flex-fill text-center py-2">
                                        <i className="fa fa-user text-primary me-2" />
                                        3197 Alumnos
                                    </small>
                                </div>
                            </div>
                        </div>

                        {/* Item */}
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="course-item bg-light">
                                <div className="position-relative overflow-hidden">
                                    <img className="img-fluid" src="/assets/img/banner/Danza-libre.jpg" alt="" />
                                    <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                                        <Link to="https://vk.com/danza_libre" target="_blank" className="flex-shrink-0 btn btn-sm btn-primary px-3 border-end"
                                            style={{ borderRadius: "30px 0 0 30px" }}>
                                            Read More
                                        </Link>
                                        <Link to="https://vk.com/danza_libre" target="_blank" className="flex-shrink-0 btn btn-sm btn-primary px-3"
                                            style={{ borderRadius: "0 30px 30px 0" }}>
                                            Join Now
                                        </Link>
                                    </div>
                                </div>

                                <div className="text-center p-4 pb-0">
                                    <h3 className="mb-0"> Danza Libre</h3>
                                    <div className="mb-3">
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small>(46)</small>
                                    </div>
                                    <h5 className="mb-4">
                                        Salsa, Bachata &amp; Bachata Lady Style
                                    </h5>
                                </div>

                                <div className="d-flex border-top">
                                    <small className="flex-fill text-center border-end py-2">
                                        <i className="fa fa-map-marker-alt text-primary me-2" />
                                        M. Belorusskaya
                                    </small>
                                    <small className="flex-fill text-center border-end py-2">
                                        <i className="fa fa-clock text-primary me-2" />
                                        1.49 Hrs
                                    </small>
                                    <small className="flex-fill text-center py-2">
                                        <i className="fa fa-user text-primary me-2" />
                                        1063 Alumnos
                                    </small>
                                </div>
                            </div>
                        </div>

                        {/* Item */}
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                            <div className="course-item bg-light">
                                <div className="position-relative overflow-hidden">
                                    <img className="img-fluid" src="/assets/img/banner/Artway.jpg" alt="" />
                                    <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                                        <Link to="https://vk.com/artwaydance" target="_blank" className="flex-shrink-0 btn btn-sm btn-primary px-3 border-end"
                                            style={{ borderRadius: "30px 0 0 30px" }}>
                                            Read More
                                        </Link>
                                        <Link to="https://vk.com/artwaydance" target="_blank" className="flex-shrink-0 btn btn-sm btn-primary px-3"
                                            style={{ borderRadius: "0 30px 30px 0" }}>
                                            Join Now
                                        </Link>
                                    </div>
                                </div>

                                <div className="text-center p-4 pb-0">
                                    <h3 className="mb-0"> ArtWay </h3>
                                    <div className="mb-3">
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small>(18)</small>
                                    </div>
                                    <h5 className="mb-4">
                                        Salsa, Timba, Rumba  &amp;   Afro
                                    </h5>
                                </div>

                                <div className="d-flex border-top">
                                    <small className="flex-fill text-center border-end py-2">
                                        <i className="fa fa-map-marker-alt text-primary me-2" />
                                        M. Tverskaya
                                    </small>
                                    <small className="flex-fill text-center border-end py-2">
                                        <i className="fa fa-clock text-primary me-2" />
                                        1.49 Hrs
                                    </small>
                                    <small className="flex-fill text-center py-2">
                                        <i className="fa fa-user text-primary me-2" />
                                        3152 Alumnos
                                    </small>
                                </div>
                            </div>
                        </div>

                        {/* Item */}
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="course-item bg-light">
                                <div className="position-relative overflow-hidden">
                                    <img className="img-fluid" src="/assets/img/banner/Dvizhenie.jpg" alt="" />
                                    <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                                        <Link to="https://vk.com/dwizhenie" target="_blank" className="flex-shrink-0 btn btn-sm btn-primary px-3 border-end"
                                            style={{ borderRadius: "30px 0 0 30px" }}>
                                            Read More
                                        </Link>
                                        <Link to="https://vk.com/dwizhenie" target="_blank" className="flex-shrink-0 btn btn-sm btn-primary px-3"
                                            style={{ borderRadius: "0 30px 30px 0" }}>
                                            Join Now
                                        </Link>
                                    </div>
                                </div>

                                <div className="text-center p-4 pb-0">
                                    <h3 className="mb-0"> Dvizhenie</h3>
                                    <div className="mb-3">
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small>(232)</small>
                                    </div>
                                    <h5 className="mb-4">
                                        Hustle, Bachata &amp; Lady Style
                                    </h5>
                                </div>

                                <div className="d-flex border-top">
                                    <small className="flex-fill text-center border-end py-2">
                                        <i className="fa fa-map-marker-alt text-primary me-2" />
                                        M. Baumanskaya
                                    </small>
                                    <small className="flex-fill text-center border-end py-2">
                                        <i className="fa fa-clock text-primary me-2" />
                                        1.49 Hrs
                                    </small>
                                    <small className="flex-fill text-center py-2">
                                        <i className="fa fa-user text-primary me-2" />
                                        13260 Alumnos
                                    </small>
                                </div>
                            </div>
                        </div>

                        {/* Item */}
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="course-item bg-light">
                                <div className="position-relative overflow-hidden">
                                    <img className="img-fluid" src="/assets/img/banner/Ivara.jpg" alt="" />
                                    <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                                        <Link to="https://vk.com/ivara" target="_blank" className="flex-shrink-0 btn btn-sm btn-primary px-3 border-end"
                                            style={{ borderRadius: "30px 0 0 30px" }}>
                                            Read More
                                        </Link>
                                        <Link to="https://vk.com/ivara" target="_blank" className="flex-shrink-0 btn btn-sm btn-primary px-3"
                                            style={{ borderRadius: "0 30px 30px 0" }}>
                                            Join Now
                                        </Link>
                                    </div>
                                </div>

                                <div className="text-center p-4 pb-0">
                                    <h3 className="mb-0"> IVARA </h3>
                                    <div className="mb-3">
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small>(318)</small>
                                    </div>
                                    <h5 className="mb-4">
                                        Bachata, Salsa, Tango Argentino, Kizomba, Hustle  &amp;  Modern swing
                                    </h5>
                                </div>

                                <div className="d-flex border-top">
                                    <small className="flex-fill text-center border-end py-2">
                                        <i className="fa fa-map-marker-alt text-primary me-2" />
                                        M. Kurskaya.
                                    </small>
                                    <small className="flex-fill text-center border-end py-2">
                                        <i className="fa fa-clock text-primary me-2" />
                                        1.49 Hrs
                                    </small>
                                    <small className="flex-fill text-center py-2">
                                        <i className="fa fa-user text-primary me-2" />
                                        16000 Alumnos
                                    </small>
                                </div>
                            </div>
                        </div>

                        {/* Item */}
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                            <div className="course-item bg-light">
                                <div className="position-relative overflow-hidden">
                                    <img className="img-fluid" src="/assets/img/banner/Ritmo-dance.jpg" alt="" />
                                    <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                                        <Link to="https://vk.com/ritmodance" target="_blank" className="flex-shrink-0 btn btn-sm btn-primary px-3 border-end"
                                            style={{ borderRadius: "30px 0 0 30px" }}>
                                            Read More
                                        </Link>
                                        <Link to="https://vk.com/ritmodance" target="_blank" className="flex-shrink-0 btn btn-sm btn-primary px-3"
                                            style={{ borderRadius: "0 30px 30px 0" }}>
                                            Join Now
                                        </Link>
                                    </div>
                                </div>

                                <div className="text-center p-4 pb-0">
                                    <h3 className="mb-0"> Ritmo </h3>
                                    <div className="mb-3">
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small>(232)</small>
                                    </div>
                                    <h5 className="mb-4">
                                        Kizomba, Urban kiz, Semba, Tarraxina, Tarraxo &amp; Kompa
                                    </h5>
                                </div>

                                <div className="d-flex border-top">
                                    <small className="flex-fill text-center border-end py-2">
                                        <i className="fa fa-map-marker-alt text-primary me-2" />
                                        M. Mendeleevskaya
                                    </small>
                                    <small className="flex-fill text-center border-end py-2">
                                        <i className="fa fa-clock text-primary me-2" />
                                        1.49 Hrs
                                    </small>
                                    <small className="flex-fill text-center py-2">
                                        <i className="fa fa-user text-primary me-2" />
                                        2480 Alumnos
                                    </small>
                                </div>
                            </div>
                        </div>

                        {/* Item */}
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="course-item bg-light">
                                <div className="position-relative overflow-hidden">
                                    <img className="img-fluid" src="/assets/img/banner/Salsa-social-a.jpg" alt="" />
                                    <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                                        <Link to="https://vk.com/salsasocial" target="_blank" className="flex-shrink-0 btn btn-sm btn-primary px-3 border-end"
                                            style={{ borderRadius: "30px 0 0 30px" }}>
                                            Read More
                                        </Link>
                                        <Link to="https://vk.com/salsasocial" target="_blank" className="flex-shrink-0 btn btn-sm btn-primary px-3"
                                            style={{ borderRadius: "0 30px 30px 0" }}>
                                            Join Now
                                        </Link>
                                    </div>
                                </div>

                                <div className="text-center p-4 pb-0">
                                    <h3 className="mb-0"> Salsa Social </h3>
                                    <div className="mb-3">
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small>(36)</small>
                                    </div>
                                    <h5 className="mb-4">
                                        Salsa, Bachata, Kizomba  &amp; Tango
                                    </h5>
                                </div>

                                <div className="d-flex border-top">
                                    <small className="flex-fill text-center border-end py-2">
                                        <i className="fa fa-map-marker-alt text-primary me-2" />
                                        M. Щербаков пер.
                                    </small>
                                    <small className="flex-fill text-center border-end py-2">
                                        <i className="fa fa-clock text-primary me-2" />
                                        1.49 Hrs
                                    </small>
                                    <small className="flex-fill text-center py-2">
                                        <i className="fa fa-user text-primary me-2" />
                                        19860 Alumnos
                                    </small>
                                </div>
                            </div>
                        </div>

                        {/* Item */}
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="course-item bg-light">
                                <div className="position-relative overflow-hidden">
                                    <img className="img-fluid" src="/assets/img/banner/Mia-salsa.jpg" alt="" />
                                    <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                                        <Link to="https://vk.com/miasalsa" target="_blank" className="flex-shrink-0 btn btn-sm btn-primary px-3 border-end"
                                            style={{ borderRadius: "30px 0 0 30px" }}>
                                            Read More
                                        </Link>
                                        <Link to="https://vk.com/miasalsa" target="_blank" className="flex-shrink-0 btn btn-sm btn-primary px-3"
                                            style={{ borderRadius: "0 30px 30px 0" }}>
                                            Join Now
                                        </Link>
                                    </div>
                                </div>

                                <div className="text-center p-4 pb-0">
                                    <h3 className="mb-0"> MiaSalsa </h3>
                                    <div className="mb-3">
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small>(16)</small>
                                    </div>
                                    <h5 className="mb-4">
                                        Salsa, Bachata, Reggaeton, Kizomba &amp; Lady Style
                                    </h5>
                                </div>

                                <div className="d-flex border-top">
                                    <small className="flex-fill text-center border-end py-2">
                                        <i className="fa fa-map-marker-alt text-primary me-2" />
                                        M. Novokuznetskaya
                                    </small>
                                    <small className="flex-fill text-center border-end py-2">
                                        <i className="fa fa-clock text-primary me-2" />
                                        1.49 Hrs
                                    </small>
                                    <small className="flex-fill text-center py-2">
                                        <i className="fa fa-user text-primary me-2" />
                                        685 Alumnos
                                    </small>
                                </div>
                            </div>
                        </div>




                    </div>
                </div>
            </div>
            {/* Escuelas End */}



            <Footer />


        </main >



    );

}

export default SchoolsDance;