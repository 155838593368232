


/* Elesrning*/

import Footer from "./FooterRu";
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import React, { useEffect, useState } from 'react';
import WOW from 'wowjs';


function Support() {

    useEffect(() => {
        const wow = new WOW.WOW();
        wow.init();
    }, []);




    return (

        <main>


            <section className="hero-section-o set-bg-o" style={{ backgroundImage: 'url("./assets/img/fondo/grey-blue-green-liquid.jpg")' }}>

                <div className="container">
                    <div className="hero-text text-white">
                        <h2>Поддержите наш проект</h2>
                        <p>
                            <h3>Присоединяйтесь к нашей миссии празднования жизни и танца! </h3>
                        </p>
                        <p>

                            В LATINOXCLUB мы стремимся приносить радость и веселье через информацию, продвижение мероприятий, запись видео латиноамериканских событий, сотрудничество с различными танцевальными школами и другие мероприятия. Все это мы делаем с одной целью: создавать незабываемые моменты и укреплять русскую и латиноамериканскую общину через музыку и культуру танца.<br />
                            <br />
                            Но сохранить эту страсть в живых непросто, и здесь нам нужна ваша поддержка. Каждое пожертвование помогает нам продолжать предоставлять эти услуги в доступной форме, которые так нравятся нашей общине.  <br />
                            <br />
                            <h3> Ваш вклад позволит нам: </h3> <br /><br />
                            <h3>
                                1. Создать лучшую платформу для сообщества:
                            </h3>
                            С вашей поддержкой мы можем создать отличную платформу — веб-сайт с различными бесплатными услугами для нашего сообщества.
                            <h3>2. Запечатлеть незабываемые моменты:  </h3> Ваши пожертвования помогут нам улучшить наше оборудование для записи, чтобы запечатлеть лучшие моменты на видео и поделиться ими со всеми.
                            <h3>3. Продвигать больше мероприятий:   </h3> С вашей поддержкой мы можем продвигать больше вечеринок и мероприятий, давая всем возможность наслаждаться, учиться и общаться.
                            <h3>4. Укреплять сообщество:   </h3> Каждое мероприятие и каждый танец укрепляют связи в нашем сообществе, создавая пространство, где каждый может чувствовать себя включенным и желанным.
                            <h3>5. Предоставлять особые привилегии для наших доноров и партнеров:  </h3> Наши доноры и партнеры получают особые привилегии в нашей латиноамериканской общине. <br /><br /><br />
                        </p>

                        <p>

                            Пожертвовать легко, и любая сумма приветствуется. С вашей поддержкой мы можем продолжать дарить улыбки, создавать воспоминания и делиться страстью к танцу со всеми.</p>
                        <p>

                            Сделайте пожертвование сегодня и станьте частью нашего сообщества, присоединяйтесь к нашему клубу, присоединяйтесь к LATINOXCLUB!
                            <br />
                            Вместе мы сделаем каждый танцевальный шаг и каждую вечеринку незабываемыми впечатлениями. Спасибо за вашу щедрость и поддержку!
                        </p>
                        <p>
                            <br />
                            Вы можете отправить свое пожертвование-внести свой вклад в:
                        </p>
                        <p>
                            <h3>Тинькофф: 2200700169893228 - Хулио И. П. </h3>
                            <h3>СберБанк: 2202205347747963 - Хулио И. П. </h3>
                            (В комментарии напишите код/номер вашего видео, чтобы знать от кого 🤗🙏💃🕺🏻)

                            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />.



                        </p>
                    </div>


                </div>
            </section>
            {/* Hero section end */}









            <Footer />


        </main >



    );

}

export default Support;