


import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Footer from './FooterEs';
import { Link } from 'react-router-dom';



function Home() {

    const tabEvents = {
        Party: {
            main: {
                imgSrc: '/assets/img/events/latina-roof-25-05.jpg', date: 'May 25, 2024', author: 'Крытая крыша GIPSY', colorClass: 'colorb',
                title: 'Латина на крыше. Перезагрузка', link: '/latino-dance-in-Moscow',
                description: 'Держись крепче, Москва! Легендарная Латина-вечеринка на крыше возвращается с полной перезагрузкой! Готовы открыть лето 2024 так, чтобы танцпол дымился от движений? Такой мощной Латины вы еще не видели!',
            },
            right: [{
                imgSrc: '/assets/img/events/corazon-01-06.jpg', category: 'Eventos', colorClass: 'colorb',
                title: 'Corazon • Бачата-вечеринки 🔥', date: 'Jun 06, 2024', link: '/events-dance',
            }, {
                imgSrc: '/assets/img/events/agave-22-05.jpg', category: 'Eventos', colorClass: 'colorr',
                title: 'Bachata Love в Casa Agave 🤩', date: 'May 22-26, 2024', link: '/events-dance',
            }, {
                imgSrc: '/assets/img/events/latin-fusion-26-05.jpg', category: 'События', colorClass: 'colorg',
                title: 'LATIN FUSION Party 💃🏼💣', date: 'May 26, 2024', link: '/events-dance',
            }, {
                imgSrc: '/assets/img/events/muza-26-05.jpg', category: 'Eventos', colorClass: 'colorb',
                title: 'KIZOMBA COSMOS PARTY 🌹🎶', date: 'May 26, 2024', link: '/events-dance',
            },]
        },
        Culture: {
            main: {
                imgSrc: '/assets/img/event-isp/dom-latina.jpg', date: 'May 24, 2024', author: 'Главный корпус РУДН', colorClass: 'colorr',
                title: 'Лекция «Традиционный костюм народов стран Латинской Америки и России»', link: '/events-culture',
                description: 'Проект «Россия – Латинская Америка: дизайн сквозь континенты» направлен на поддержку российских и латиноамериканских дизайнеров, содействие организации международных коллабораций, развитие креативных индустрий и международного гуманитарного сотрудничества, культурный обмен',
            },
            right: [{
                imgSrc: '/assets/img/event-isp/ibero-dance.jpg', category: 'Eventos', colorClass: 'colorg',
                title: 'МАСТЕР-КЛАСС ПО ЛАТИНОАМЕРИКАНСКИМ ТАНЦАМ 💫', date: 'May 26, 2024', link: '/events-culture',
            }, {
                imgSrc: '/assets/img/event-isp/ibero-24-05.jpg', category: 'Eventos', colorClass: 'colorr',
                title: 'SEIS Y MEDIO: СПЕКТАКЛЬ НА ИСПАНСКОМ ЯЗЫКЕ', date: 'May 24, 2024', link: '/events-culture',
            }, {
                imgSrc: '/assets/img/event-isp/embper-240516.jpg', category: 'Eventos', colorClass: 'colorb',
                title: 'TALLER DE TEJIDO TRADICIONAL CUSQUEÑO', date: 'May 16, 2024', link: '/events-culture',
            }, {
                imgSrc: '/assets/img/gallery/whats_right_img3.png', category: 'Eventos', colorClass: 'colorr',
                title: '24 Aequam memento rebus in arduis servare mentem', date: 'Apr 19, 2024', link: '/',
            },]
        },
        Language: {
            main: {
                imgSrc: '/assets/img/event-isp/rusonol-12-05.jpg', date: 'May 26, 2024', author: 'Ибероамериканский к. центр', colorClass: 'colorb',
                title: 'Rusoñol: русско-испанский разговорный клуб 🎧', link: '/latino-dance-in-Moscow',
                description: '«Rusoñol» — это двуязычный разговорный клуб, который уже на протяжении многих лет объединяет людей, культуры, страны и континенты. Ибероамериканский культурный центр почти с самого своего открытия стал основной площадкой проведения встреч, которые проходят на русском и испанском языках.',
            },
            right: [{
                imgSrc: '/assets/img/event-isp/ibero-03-06.jpg', category: 'Eventos', colorClass: 'colorg',
                title: 'ЛЕКЦИЯ “РАБЫНЯ ИЗАУРА: АНАЛИЗ ПРОИЗВЕДЕНИЯ БЕРНАРДО ГИМАРАЙНША”', date: 'Jun 03, 2024', link: '/events-dance',
            }, {
                imgSrc: '/assets/img/event-isp/dom-latina.jpg', category: 'Eventos', colorClass: 'colorr',
                title: 'Лекция «Традиционный костюм народов стран Латинской Америки и России»', date: 'May 24, 2024', link: '/events-dance',
            }, {
                imgSrc: '/assets/img/events/latin-fusion-12-05.jpg', category: 'События', colorClass: 'colorb',
                title: 'LATIN FUSION Party 💃🏼💣', date: 'May 12, 2024', link: '/events-dance',
            }, {
                imgSrc: '/assets/img/events/muza-12-05.jpg', category: 'Eventos', colorClass: 'colorg',
                title: 'KIZOMBA COSMOS PARTY', date: 'May 12, 2023', link: '/events-dance',
            },]
        },
        Society: {
            main: {
                imgSrc: '/assets/img/event-isp/instilat-14-05.jpg', date: 'May 14-15, 2024', author: 'Alice cloe', colorClass: 'colorr',
                title: 'Молодые исследователи в поисках нового взгляда на региональную проблематику', link: '/events-culture',
                description: '«Латинская Америка: молодые исследователи в поисках нового взгляда на региональную проблематику» с целью повышения их квалификации, расширения поля профессиональных контактов и формирования горизонтальных связей.',
            },
            right: [{
                imgSrc: '/assets/img/event-isp/ibero-24-05.jpg', category: 'Eventos', colorClass: 'colorr',
                title: 'SEIS Y MEDIO: СПЕКТАКЛЬ НА ИСПАНСКОМ ЯЗЫКЕ', date: 'Mayo 24, 2024', link: '/events-culture',
            }, {
                imgSrc: '/assets/img/event-isp/embper-240516.jpg', category: 'Eventos', colorClass: 'colorb',
                title: 'TALLER DE TEJIDO TRADICIONAL CUSQUEÑO', date: 'May 16, 2024', link: '/events-culture',
            }, {
                imgSrc: '/assets/img/event-isp/ibero-12-05.jpg', category: 'Eventos', colorClass: 'colorg',
                title: 'МАСТЕР-КЛАСС ПО ЛАТИНОАМЕРИКАНСКИМ ТАНЦАМ 💫', date: 'May 12, 2024', link: '/events-culture',
            }, {
                imgSrc: '/assets/img/gallery/whats_right_img3.png', category: 'Eventos', colorClass: 'colorr',
                title: '24 Aequam memento rebus in arduis servare mentem', date: 'Apr 19, 2024', link: '/',
            },]
        },
        Concerts: {
            main: {
                imgSrc: '/assets/img/gallery/girl-band-music.png', date: 'May 06, 2024', author: 'Alice C.', colorClass: 'colorb',
                title: 'Латино ритм в Москве: вечеринки заставляющие танцевать Россию', link: '/latino-dance-in-Moscow',
                description: 'Eventos especiales como la Noche Latina Internacional ofrecen una combinación única de géneros latinos, que atrae a una audiencia diversa que comparte la pasión por la música y el baile.',
            },
            right: [{
                imgSrc: '/assets/img/events/corazon-18-05.jpg', category: 'Eventos', colorClass: 'colorb',
                title: 'Corazon • Бачата-вечеринки 🔥', date: 'May 18, 2024', link: '/events-dance',
            }, {
                imgSrc: '/assets/img/events/shine-09-05.jpg', category: 'Eventos', colorClass: 'colorr',
                title: 'Shine Bachata Festival 🏆🔥', date: 'May 09-12, 2024', link: '/events-dance',
            }, {
                imgSrc: '/assets/img/events/latin-fusion-12-05.jpg', category: 'События', colorClass: 'colorg',
                title: 'LATIN FUSION Party 💃🏼💣', date: 'May 12, 2024', link: '/events-dance',
            }, {
                imgSrc: '/assets/img/events/muza-12-05.jpg', category: 'Eventos', colorClass: 'colorb',
                title: 'KIZOMBA COSMOS PARTY', date: 'May 12, 2024', link: '/events-dance',
            },]
        },
    };



    return (
        <main>
            <section className="whats-news-area pt-50 pb-20 gray-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="whats-news-wrapper">
                                <div className="row justify-content-between align-items-end mb-15">
                                    <div className="col-xl-4">
                                        <div className="section-tittle mb-30">
                                            <h3>Eventos</h3>
                                        </div>
                                    </div>
                                    <div className="col-xl-8 col-md-9">
                                        <div className="properties__button">
                                            <nav>
                                                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                                    {Object.keys(tabEvents).map((tab, index) => (
                                                        <a
                                                            key={index}
                                                            className={`nav-item nav-link ${index === 0 ? 'active' : ''}`}
                                                            id={`nav-${tab}-tab`}
                                                            data-toggle="tab"
                                                            href={`#nav-${tab}`}
                                                            role="tab"
                                                            aria-controls={`nav-${tab}`}
                                                            aria-selected={index === 0 ? 'true' : 'false'}
                                                        >
                                                            {tab}
                                                        </a>
                                                    ))}
                                                </div>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="tab-content" id="nav-tabContent">
                                            {Object.keys(tabEvents).map((tab, index) => (
                                                <div
                                                    key={index}
                                                    className={`tab-pane fade ${index === 0 ? 'show active' : ''}`}
                                                    id={`nav-${tab}`}
                                                    role="tabpanel"
                                                    aria-labelledby={`nav-${tab}-tab`}
                                                >
                                                    {tabEvents[tab].main && (
                                                        <div className="row">
                                                            <div className="col-xl-6 col-lg-12">
                                                                <div className="whats-news-single mb-40 mb-40">
                                                                    <div className="whates-img">
                                                                        <img
                                                                            src={tabEvents[tab].main.imgSrc}
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    <div className="whates-caption">
                                                                        <h4>
                                                                            <Link to={tabEvents[tab].main.link}>
                                                                                {tabEvents[tab].main.title}
                                                                            </Link>
                                                                        </h4>
                                                                        <span> в {tabEvents[tab].main.author} - {tabEvents[tab].main.date}</span>
                                                                        <p>{tabEvents[tab].main.description}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6 col-lg-12">
                                                                <div className="row">
                                                                    {tabEvents[tab].right.map((event, eventIndex) => (
                                                                        <div key={eventIndex} className="col-6 col-xl-12 col-lg-6 col-md-6 col-sm-6">
                                                                            <div className="whats-right-single mb-20">
                                                                                <div className="whats-right-img">
                                                                                    <img src={event.imgSrc} alt="" />
                                                                                </div>
                                                                                <div className="whats-right-cap">
                                                                                    <span className={event.colorClass}>{event.category}</span>
                                                                                    <h4>
                                                                                        <Link to={event.link}>{event.title}</Link>
                                                                                    </h4>
                                                                                    <p>{event.date}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {!tabEvents[tab].main && (
                                                        <div className="row">
                                                            {tabEvents[tab].right.map((event, eventIndex) => (
                                                                <div key={eventIndex} className="col-6 col-xl-12 col-lg-6 col-md-6 col-sm-6">
                                                                    <div className="whats-right-single mb-20">
                                                                        <div className="whats-right-img">
                                                                            <img src={event.imgSrc} alt="" />
                                                                        </div>
                                                                        <div className="whats-right-cap">
                                                                            <span className={event.colorClass}>{event.category}</span>
                                                                            <h4>
                                                                                <Link to={event.link}>{event.title}</Link>
                                                                            </h4>
                                                                            <p>{event.date}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default Home;
