


/* Elesrning*/

import Footer from "./FooterRu";
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import React, { useEffect, useState } from 'react';
import WOW from 'wowjs';


function Store() {
    useEffect(() => {
        const wow = new WOW.WOW();
        wow.init();
    }, []);

    // Array de objetos que contiene los datos de las tiendas
    const storesData = [
        
    {name: "Магазин Перу Peru.ru", image: "/assets/img/store/peru-ru.jpg", link: "/store-peru",
            categories: ["Уникальная одеждя из шерсти ламы и альпаки, произведённую в Перу."],
            location: "M. Proletarskaya", duration: "1.49 Hrs", students: 117 
    },
    {name: "Улыбка Альпаки ", image: "/assets/img/alpaca/alpak3.jpg", link: "/store-alpaca",
            categories: ["УНИКАЛЬНЫЕ ВЕЩИ: свитера и кофты из шерсти альпаки, этнические сумки, кеды из Перу, этноукрашения"],
            location: "M. Proletarskaya", duration: "1.49 Hrs", students: 117 
    },
    {name: "Какао-бобы из Эквадора", image: "/assets/img/store/pon-cacao.jpg", link: "https://vk.com/poncacao",
            categories: ["Какао бобы оптом и в розницу. Прямые поставки из Эквадора. Вкуснейший ремесленный шоколад."],
            location: "M. Proletarskaya", duration: "1.49 Hrs", students: 117 
    },
        {
            name: "В Перу",
            image: "/assets/img/store/vperu-ru.jpg",
            link: "http://www.vperu.ru/",
            categories: [" Интернет-магазин игрушек из натурального меха Альпака, и других товаров ручной работы из Перу"],
            location: "M. Proletarskaya",
            duration: "1.49 Hrs",
            students: 117
        },
        {
            name: "Овца и Лама",
            image: "/assets/img/store/etnowool.jpg",
            link: "http://www.etnowool.com/",
            categories: ["Salsa", "Bachata", "Reggaeton", "Kizomba", "Lady Style"],
            location: "M. Proletarskaya",
            duration: "1.49 Hrs",
            students: 117
        },
        {
            name: "Instituto Cervantes de Moscú",
            image: "/assets/img/spanish/cervantes.png",
            link: "https://moscu.cervantes.es/",
            categories: ["Salsa", "Bachata", "Reggaeton", "Kizomba", "Lady Style"],
            location: "M. Proletarskaya",
            duration: "1.49 Hrs",
            students: 117
        },
        {
            name: "ESP Club Moscú - Центр испанского языка и культуры",
            image: "/assets/img/spanish/ESPclub.png",
            link: "https://espclubmoscu.com",
            categories: ["Salsa", "Bachata", "Kizomba"],
            location: "M. Kitay-Gorod",
            duration: "1.49 Hrs",
            students: 815
        },
        {
            name: "Школа испанского языка ESPALABRA",
            image: "/assets/img/spanish/espalabra.png",
            link: "https://espalabra.ru/",
            categories: ["Salsa", "Bachata", "Reggaeton", "Kizomba", "Lady Style"],
            location: "M. Proletarskaya",
            duration: "1.49 Hrs",
            students: 1043
        }
    ];

    return (
        <main>
            {/* Header */}
            <div className="container-fluid py-5 mb-5 page-header-store">
                <div className="container py-5">
                    <div className="row justify-content-center">
                        <div className="col-lg-10 text-center">
                            <h1 className="display-3 text-white animated slideInDown">Tiendas</h1>
                            <nav aria-label="breadcrumb-elearn">
                                <ol className="breadcrumb-elearn justify-content-center">
                                    <li className="breadcrumb-item">
                                        <a className="text-white" href="#">Inicio</a>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <a className="text-white" href="#">Tiendas</a>
                                    </li>
                                    <li className="breadcrumb-item text-white active" aria-current="page">Ropa</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            {/* Header End */}

            {/* Tiendas */}
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                        <h6 className="section-title-elearn bg-white text-center text-primary px-3">Tiendas</h6>
                        <h1 className="mb-5">Categorías de tiendas</h1>
                    </div>

                    <div className="row g-4 justify-content-center">
                        {/* Iteración sobre los datos de las tiendas */}
                        {storesData.map((store, index) => (

                            < div key={index} className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay={`${(index % 3) * 0.2 + Math.floor(index / 3) * 0.2 + 0.1}s`}>
                                <div className="course-item bg-light">
                                    <div className="position-relative overflow-hidden">
                                        <img className="img-fluid" src={store.image} alt="" />
                                        <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                                            <Link to={store.link} target="_blank" className="flex-shrink-0 btn btn-sm btn-primary px-3 border-end" style={{ borderRadius: "30px 0 0 30px" }}>
                                                Leer Más
                                            </Link>
                                            <Link to={store.link} target="_blank" className="flex-shrink-0 btn btn-sm btn-primary px-3" style={{ borderRadius: "0 30px 30px 0" }}>
                                                Únete
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="text-center p-4 pb-0">
                                        <h3 className="mb-0">{store.name}</h3>
                                        <div className="mb-3">
                                            {[...Array(5)].map((_, i) => (
                                                <small key={i} className="fa fa-star text-primary" />
                                            ))}
                                            <small>({store.students})</small>
                                        </div>
                                        <h5 className="mb-4">{store.categories.join(", ")}</h5>
                                    </div>
                                    <div className="d-flex border-top">
                                        <small className="flex-fill text-center border-end py-2">
                                            <i className="fa fa-map-marker-alt text-primary me-2" />
                                            {store.location}
                                        </small>
                                        <small className="flex-fill text-center border-end py-2">
                                            <i className="fa fa-clock text-primary me-2" />
                                            {store.duration}
                                        </small>
                                        <small className="flex-fill text-center py-2">
                                            <i className="fa fa-user text-primary me-2" />
                                            {store.students} Alumnos
                                        </small>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {/* Tiendas End */}

            <Footer />
        </main >
    );
}

export default Store;