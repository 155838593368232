


import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ScrollToTop from './ScrollToTop';
import Constants from './components/Constants';


function App() {

  /* const [language, setLanguage] = useState('en'); */
  /* const [currentPage, setCurrentPage] = useState('Home'); */
  const [setCurrentPage] = useState('Home');

  /* 000000 Idioma del navegador  */
  const browserLang = (window.navigator.language || 'ru').substr(0, 2);
  const [language, setLanguage] = useState(browserLang);
  console.log('language:', language);
  /* 000000 */

  const changeLanguage = (newLanguage) => {
    setLanguage(newLanguage);
  };

  const navigateTo = (page) => {
    setCurrentPage(page);
  };

  const CurrentLanguageComponents = Constants[language];


  return (

    <Router>

      <Helmet>
         {/* Google Analytics */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-M8J14HSL78"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-M8J14HSL78');
          `}
        </script>

         {/* Yandex.Metrica */}
         <script type="text/javascript">
          {`
            (function(m,e,t,r,i,k,a){
              m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
              m[i].l=1*new Date();
              for (var j = 0; j < document.scripts.length; j++) {
                if (document.scripts[j].src === r) { return; }
              }
              k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)
            })(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

            ym(97509138, "init", {
                clickmap:true,
                trackLinks:true,
                accurateTrackBounce:true,
                webvisor:true
            });
          `}
        </script>

        <noscript>
        {`<div><img src="https://mc.yandex.ru/watch/97509138" style="position:absolute; left:-9999px;" alt="" /></div>`}
        </noscript>
      </Helmet>


      <div>

        <CurrentLanguageComponents.Menu changeLanguage={changeLanguage} navigateTo={navigateTo} />
        <ScrollToTop />

        <Routes>

          <Route path="/" element={<CurrentLanguageComponents.Home />} />
          <Route path="/about" element={<CurrentLanguageComponents.Home />} />
          <Route path="/contact" element={<CurrentLanguageComponents.Contact />} />
          <Route path="/support" element={<CurrentLanguageComponents.Support />} />

          <Route path="/blog-details" element={<CurrentLanguageComponents.BlogDetails />} />
          <Route path="/category" element={<CurrentLanguageComponents.Category />} />

          <Route path="/events-culture" element={<CurrentLanguageComponents.EventsCulture />} />
          <Route path="/events-dance" element={<CurrentLanguageComponents.EventsDance />} />
          <Route path="/events-spanish" element={<CurrentLanguageComponents.EventSpanish />} />
          <Route path="/gallery" element={<CurrentLanguageComponents.Gallery />} />
          <Route path="/photos" element={<CurrentLanguageComponents.Photos />} />
          <Route path="/projects" element={<CurrentLanguageComponents.Projects />} />
          <Route path="/restaurants" element={<CurrentLanguageComponents.RestaHome />} />
          <Route path="/spanish-courses" element={<CurrentLanguageComponents.SpanishLanguage />} />
          <Route path="/schools-dance" element={<CurrentLanguageComponents.SchoolsDance />} />
          <Route path="/schools-spanish" element={<CurrentLanguageComponents.SchoolsSpanish />} />
          <Route path="/store" element={<CurrentLanguageComponents.Store />} />
          <Route path="/teachers-spanish" element={<CurrentLanguageComponents.TeacherSpanish />} />
          <Route path="/teachers-dance" element={<CurrentLanguageComponents.TeachersDance />} />
          <Route path="/video-events" element={<CurrentLanguageComponents.VideoEvents />} />


          <Route path="/la-bachata-manuel-turizo" element={<CurrentLanguageComponents.TurizoLaBachata />} />
          <Route path="/latino-dance-in-Moscow" element={<CurrentLanguageComponents.LatinoDanceMoscow />} />
          <Route path="/travel-to-spain" element={<CurrentLanguageComponents.TravelToSpain />} />
          <Route path="/new-year-Latam" element={<CurrentLanguageComponents.NewYearLatam />} />
          <Route path="/open-air-in-Moscow" element={<CurrentLanguageComponents.OpenAirMoscow />} />
          <Route path="/latina-fest-Moscow" element={<CurrentLanguageComponents.LatinaFestMoscow />} />

          <Route path="/salsateca" element={<CurrentLanguageComponents.Salsateca />} />

          <Route path="/photo-240509" element={<CurrentLanguageComponents.Photo240509 />} />
          <Route path="/store-alpaca" element={<CurrentLanguageComponents.StoreAlpaca />} />
          <Route path="/store-peru" element={<CurrentLanguageComponents.StorePeru />} />

          <Route path="/video-open-18-05-24" element={<CurrentLanguageComponents.VideoOpen240518 />} />
          <Route path="/video-open-19-05-24" element={<CurrentLanguageComponents.VideoOpen240519 />} />
          <Route path="/video-open-25-05-24" element={<CurrentLanguageComponents.VideoOpen240525 />} />
          <Route path="/video-open-28-05-24" element={<CurrentLanguageComponents.VideoOpen240528 />} />
          <Route path="/video-open-30-05-24" element={<CurrentLanguageComponents.VideoOpen240530 />} />
          <Route path="/video-open-31-05-24" element={<CurrentLanguageComponents.VideoOpen240531 />} />
          <Route path="/video-open-02-06-24" element={<CurrentLanguageComponents.VideoOpen240602 />} />
          <Route path="/video-open-07-06-24" element={<CurrentLanguageComponents.VideoOpen240607 />} />
          <Route path="/video-open-08-06-24" element={<CurrentLanguageComponents.VideoOpen240608 />} />
          <Route path="/video-open-09-06-24" element={<CurrentLanguageComponents.VideoOpen240609 />} />
          <Route path="/video-open-14-06-24" element={<CurrentLanguageComponents.VideoOpen240614 />} />
          <Route path="/video-open-15-06-24" element={<CurrentLanguageComponents.VideoOpen240615 />} />
          <Route path="/video-open-16-06-24" element={<CurrentLanguageComponents.VideoOpen240616 />} />
          <Route path="/video-open-19-06-24" element={<CurrentLanguageComponents.VideoOpen240619 />} />
          <Route path="/video-open-21-06-24" element={<CurrentLanguageComponents.VideoOpen240621 />} />
          <Route path="/video-open-22-06-24" element={<CurrentLanguageComponents.VideoOpen240622 />} />
          <Route path="/video-open-23-06-24" element={<CurrentLanguageComponents.VideoOpen240623 />} />
          <Route path="/video-open-25-06-24" element={<CurrentLanguageComponents.VideoOpen240625 />} />
          <Route path="/video-open-26-06-24" element={<CurrentLanguageComponents.VideoOpen240626 />} />
          <Route path="/video-open-28-06-24" element={<CurrentLanguageComponents.VideoOpen240628 />} />
          <Route path="/video-open-29-06-24" element={<CurrentLanguageComponents.VideoOpen240629 />} />
          <Route path="/video-open-30-06-24" element={<CurrentLanguageComponents.VideoOpen240630 />} />
          <Route path="/video-open-01-07-24" element={<CurrentLanguageComponents.VideoOpen240701 />} />
          <Route path="/video-open-02-07-24" element={<CurrentLanguageComponents.VideoOpen240702 />} />
          <Route path="/video-open-03-07-24" element={<CurrentLanguageComponents.VideoOpen240703 />} />
          <Route path="/video-open-06-07-24" element={<CurrentLanguageComponents.VideoOpen240706 />} />
          <Route path="/video-open-07-07-24" element={<CurrentLanguageComponents.VideoOpen240707 />} />
          <Route path="/video-open-09-07-24" element={<CurrentLanguageComponents.VideoOpen240709 />} />
          <Route path="/video-open-10-07-24" element={<CurrentLanguageComponents.VideoOpen240710 />} />
          <Route path="/video-open-12-07-24" element={<CurrentLanguageComponents.VideoOpen240712 />} />
          <Route path="/video-open-13-07-24" element={<CurrentLanguageComponents.VideoOpen240713 />} />
          <Route path="/video-open-14-07-24" element={<CurrentLanguageComponents.VideoOpen240714 />} /> 
          <Route path="/video-open-16-07-24" element={<CurrentLanguageComponents.VideoOpen240716 />} />
          <Route path="/video-open-17-07-24" element={<CurrentLanguageComponents.VideoOpen240717 />} /> 
          <Route path="/video-open-18-07-24" element={<CurrentLanguageComponents.VideoOpen240718 />} />
          <Route path="/video-open-19-07-24" element={<CurrentLanguageComponents.VideoOpen240719 />} />
          <Route path="/video-open-20-07-24" element={<CurrentLanguageComponents.VideoOpen240720 />} />
          <Route path="/video-open-23-07-24" element={<CurrentLanguageComponents.VideoOpen240723 />} />
          <Route path="/video-open-25-07-24" element={<CurrentLanguageComponents.VideoOpen240725 />} />
          <Route path="/video-open-31-07-24" element={<CurrentLanguageComponents.VideoOpen240731 />} />
          <Route path="/video-open-01-08-24" element={<CurrentLanguageComponents.VideoOpen240801 />} />
          <Route path="/video-open-02-08-24" element={<CurrentLanguageComponents.VideoOpen240802 />} />
          <Route path="/video-open-03-08-24" element={<CurrentLanguageComponents.VideoOpen240803 />} />
          <Route path="/video-open-04-08-24" element={<CurrentLanguageComponents.VideoOpen240804 />} />
          <Route path="/video-open-06-08-24" element={<CurrentLanguageComponents.VideoOpen240806 />} />
          <Route path="/video-open-07-08-24" element={<CurrentLanguageComponents.VideoOpen240807 />} />
          <Route path="/video-open-08-08-24" element={<CurrentLanguageComponents.VideoOpen240808 />} />
          <Route path="/video-open-09-08-24" element={<CurrentLanguageComponents.VideoOpen240809 />} />
          <Route path="/video-open-10-08-24" element={<CurrentLanguageComponents.VideoOpen240810 />} />
          <Route path="/video-open-13-08-24" element={<CurrentLanguageComponents.VideoOpen240813 />} />
          <Route path="/video-open-14-08-24" element={<CurrentLanguageComponents.VideoOpen240814 />} />
          <Route path="/video-open-15-08-24" element={<CurrentLanguageComponents.VideoOpen240815 />} />
          <Route path="/video-open-16-08-24" element={<CurrentLanguageComponents.VideoOpen240816 />} />
          <Route path="/video-open-20-08-24" element={<CurrentLanguageComponents.VideoOpen240820 />} />
          <Route path="/video-open-21-08-24" element={<CurrentLanguageComponents.VideoOpen240821 />} />
          <Route path="/video-open-22-08-24" element={<CurrentLanguageComponents.VideoOpen240822 />} />
          <Route path="/video-open-23-08-24" element={<CurrentLanguageComponents.VideoOpen240823 />} />
          <Route path="/video-open-24-08-24" element={<CurrentLanguageComponents.VideoOpen240824 />} />
          <Route path="/video-open-25-08-24" element={<CurrentLanguageComponents.VideoOpen240825 />} />
          <Route path="/video-open-ship" element={<CurrentLanguageComponents.VideoOpenShip />} />
          <Route path="/video-open-27-08-24" element={<CurrentLanguageComponents.VideoOpen240827 />} />
          <Route path="/video-open-28-08-24" element={<CurrentLanguageComponents.VideoOpen240828 />} />
          <Route path="/video-open-30-08-24" element={<CurrentLanguageComponents.VideoOpen240830 />} />
          <Route path="/video-open-31-08-24" element={<CurrentLanguageComponents.VideoOpen240831 />} />
          <Route path="/video-open-01-09-24" element={<CurrentLanguageComponents.VideoOpen240901 />} />
          <Route path="/video-open-03-09-24" element={<CurrentLanguageComponents.VideoOpen240903 />} />
          <Route path="/video-open-04-09-24" element={<CurrentLanguageComponents.VideoOpen240904 />} />
          <Route path="/video-open-05-09-24" element={<CurrentLanguageComponents.VideoOpen240905 />} />
          <Route path="/video-open-06-09-24" element={<CurrentLanguageComponents.VideoOpen240906 />} />
          <Route path="/video-open-07-09-24" element={<CurrentLanguageComponents.VideoOpen240907 />} />
          <Route path="/video-open-08-09-24" element={<CurrentLanguageComponents.VideoOpen240908 />} />
          <Route path="/video-open-10-09-24" element={<CurrentLanguageComponents.VideoOpen240910 />} />
          <Route path="/video-open-11-09-24" element={<CurrentLanguageComponents.VideoOpen240911 />} />
          <Route path="/video-open-12-09-24" element={<CurrentLanguageComponents.VideoOpen240912 />} />
          <Route path="/video-open-13-09-24" element={<CurrentLanguageComponents.VideoOpen240913 />} />
          <Route path="/video-open-14-09-24" element={<CurrentLanguageComponents.VideoOpen240914 />} />
          <Route path="/video-open-15-09-24" element={<CurrentLanguageComponents.VideoOpen240915 />} />
          <Route path="/video-open-16-09-24" element={<CurrentLanguageComponents.VideoOpen240916 />} />
          <Route path="/video-open-17-09-24" element={<CurrentLanguageComponents.VideoOpen240917 />} />
          <Route path="/video-open-18-09-24" element={<CurrentLanguageComponents.VideoOpen240918 />} />
          <Route path="/video-open-19-09-24" element={<CurrentLanguageComponents.VideoOpen240919 />} />
          <Route path="/video-open-20-09-24" element={<CurrentLanguageComponents.VideoOpen240920 />} />
          <Route path="/video-open-21-09-24" element={<CurrentLanguageComponents.VideoOpen240921 />} />
          <Route path="/video-open-22-09-24" element={<CurrentLanguageComponents.VideoOpen240922 />} />





        </Routes>
       
      </div>


    </Router>


  );
}

export default App;



