


import Footer from "../../../components/en/FooterEn";
import React, { useEffect } from 'react';
import { renderToString } from 'react-dom/server';
import { Link } from 'react-router-dom';
import lightGallery from 'lightgallery';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import lgFullscreen from 'lightgallery/plugins/fullscreen';
import lgVideo from 'lightgallery/plugins/video';
import lgAutoplay from 'lightgallery/plugins/autoplay';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/scss/lightgallery.scss';
import 'lightgallery/scss/lg-zoom.scss';
import WOW from 'wowjs';

import Adx1BannerVideo from '../../../components/Adx1BannerVideo';


function Video() {
   
    useEffect(() => {
        const wow = new WOW.WOW();
        wow.init();
    }, []);

    useEffect(() => {
        const lightGalleryInit = () => {
            const gallery = document.getElementById('video-gallery');

            if (gallery) {
                lightGallery(gallery, {
                    plugins: [lgVideo, lgThumbnail, lgFullscreen, lgAutoplay, lgZoom],
                    videojs: false, // Desactivar videojs para controlar la reproducción automática
                    autoplayFirstVideo: false, // Desactivar la reproducción automática del primer video
                });
            }
        };

        lightGalleryInit();
    }, []);

    // Función para manejar la apertura de videos
    const handleVideoOpen = (videoSrc) => {
        // Verificar la plataforma y cargar el anuncio adecuado
        const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
        const blockId = isTouchDevice ? "R-A-9762127-6" : "R-A-9762127-7";
        const platform = isTouchDevice ? "touch" : "desktop";

        window.yaContextCb.push(() => {
            // eslint-disable-next-line no-undef
            Ya.Context.AdvManager.render({
                blockId: blockId,
                type: "fullscreen",
                platform: platform,
                renderTo: 'Adx1FullScreen'
            });
        });

        // Aquí puedes implementar la lógica para abrir el video
        console.log(`Abriendo video: ${videoSrc}`);
        // No iniciar reproducción automática
        // lightGallery().openVideo(videoSrc);
    };

    const startRange = 1201;
    const endRange = 1264;

    const videos = [];

    for (let i = startRange; i <= endRange; i++) {
        const videoSrc = `/assets/video/open/gorky/16-09-24/VID_${i}.mp4`;
        const videoPoster = `/assets/video/open/gorky/16-09-24/covers/IMG_${i}.jpg`;
        const videoDescription = `VIDEO_${i} | You can get original video file with a donation to our project, contact us via Telegram`;

        videos.push({
            src: videoSrc,
            poster: videoPoster,
            description: videoDescription,
        });
    }

    const generateSubHtml = (video) => {
        const downloadSrc = video.src;
        const description = video.description;
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
        const downloadButton = isIOS ? (
            <a href={downloadSrc} target="_blank" className="download-btn" >Download</a>
        ) : (
            <a href={downloadSrc} download className="download-btn" >Download</a>
        );
            
        /* const additionalButton = (
            <Link to="/support" className="donate-btn">DONATE</Link>            
        ); */
        const additionalButton = (
            <a href="https://t.me/latinoxclub" className="donate-btn">CONTACT US</a>
        );

        const html = (
            <div>
                <div style={{ justifyContent: 'space-between' }}>
                    {downloadButton}
                    {additionalButton}
                </div>
                <h4>{description}</h4>
            </div>
        );
        return renderToString(html);
    };

    return (
        <main>
            <section
                className="hero-wrap hero-wrap-2-fox"
                style={{ backgroundImage: 'url("/assets/video/open/gorky/17-09-24/covers/IMG_240917.jpg")' }}
            >
                <div className="overlay" />
                <div className="container">
                    <div className="row no-gutters slider-text align-items-center justify-content-center">
                        <div className="col-md-9 ftco-animate text-center fadeInUp ftco-animated">
                            <h1 className="mb-2 bread"> Open Air 17.09.24 </h1>
                            <p className="breadcrumbs">
                                <span className="mr-2">
                                    <Link to="/gallery">Galeria<i className="ion-ios-arrow-forward" /></Link>
                                </span>{" "}
                                <span>
                                    <Link to="/video-events">All Videos<i className="ion-ios-arrow-forward" /></Link>
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Inserta el contenedor para el anuncio de Yandex */}
            <div id="Adx1FullScreen"></div>

            {/* -------------- Yandex Banner 03 -------------- */}
            <Adx1BannerVideo blockId="R-A-9762127-3" renderTo="yandex_rtb_R-A-9762127-3" />


            <div className="site-wrap front-videos">
                <div className="container">
                    <div className="row" id="video-gallery">
                        {videos.map((video, index) => (
                            <div key={index} className="col-4 col-sm-3 col-md-3 col-lg-2 col-xl-2 item"
                                onClick={() => handleVideoOpen(video.src)} // Manejar clic para abrir video
                                data-video={JSON.stringify({ source: [{ src: video.src, type: "video/mp4" }], attributes: { preload: false, controls: true } })}
                                data-poster=""
                                data-sub-html={generateSubHtml(video)} >
                                <a>
                                    <img
                                        className="img-responsive"
                                        src={video.poster}
                                    />
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* -------------- Yandex Banner 04 -------------- */}
            <Adx1BannerVideo blockId="R-A-9762127-4" renderTo="yandex_rtb_R-A-9762127-4" />

          
            <Footer />
        </main>
    );
}

export default Video;






 