


import React, { useEffect, useState } from 'react';
import Footer from '../../../components/en/FooterEn';
import { Link, useNavigate } from 'react-router-dom';
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/scss/lightgallery.scss';
import 'lightgallery/scss/lg-zoom.scss';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import lgFullscreen from 'lightgallery/plugins/fullscreen';
import lightGallery from 'lightgallery';


function Photosen() {
    const startRange = 1;
    const endRange = 100;

    const [images, setImages] = useState([]);

    useEffect(() => {
        const loadImage = async (url) => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = () => resolve(url);
                img.onerror = () => reject();
                img.src = url;
            });
        };

        const loadImages = async () => {
            const loadedImages = [];
            for (let i = startRange; i <= endRange; i++) {
                const imageUrl = `/assets/img/pe-ru/peru-ru${i}.jpg`;
                try {
                    await loadImage(imageUrl);
                    loadedImages.push({
                        src: imageUrl,
                        subHtml:`<h4>"Магазин Перу" || Пончо шляпы свитеры</h4><p> https://vk.com/peru.lamalux </p>`
                        /* subHtml:` <h4>Fading Light</h4><p>Lorem ipsum img-${i}</p>` */
                    });
                } catch (error) {
                    console.error(`Image not found: ${imageUrl}`);
                }
            }
            setImages(loadedImages);
        };

        loadImages();
    }, []);

    useEffect(() => {
        const lightGalleryInit = () => {
            const gallery = document.getElementById('lightgallery');
            if (gallery) {
                lightGallery(gallery, {
                    plugins: [lgThumbnail, lgZoom, lgFullscreen]
                });
            }
        };
        if (images.length > 0) {
            lightGalleryInit();
        }
    }, [images]);

    return (
        <main>
            <section
                className="hero-wrap hero-wrap-2-fox"
                style={{ backgroundImage: 'url("/assets/img/store/stand.jpg")' }}

            >
                <div className="overlay" />
                <div className="container">
                    <div className="row no-gutters slider-text align-items-center justify-content-center">
                        <div className="col-md-9 ftco-animate text-center fadeInUp ftco-animated">
                            <h1 className="mb-2 bread"> Магазин Перу - Peru.ru </h1>
                            <p className="breadcrumbs">
                                <span className="mr-2">
                                    <Link to="/restaurants">Home<i className="ion-ios-arrow-forward" /></Link>
                                </span>{" "}
                                <span>
                                    <Link to="/store">Магазины <i className="ion-ios-arrow-forward" /></Link>
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <div className="site-wrap front-videos">
                <div className="container">
                    <div className="row" id="lightgallery">
                        {images.map((image, index) => (
                            <div
                                key={index}
                                className="col-4 col-sm-3 col-md-3 col-lg-2 col-xl-2 item"
                                data-aos="fade"
                                data-src={image.src}
                                data-sub-html={image.subHtml}
                            >
                                <a href={image.src}>
                                    <img
                                        src={image.src}
                                        alt={`Image ${index}`}
                                        className="img-fluid"
                                    />
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Footer />
        </main>
    );
}

export default Photosen;