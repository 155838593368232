

import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Adx1BannerVideo from '../Adx1BannerVideo'; 

function MenuDesk({ changeLanguage, navigateTo }) {

    const [activeTab, setActiveTab] = useState("enter-1");

    const handleTabChange = (tabId) => {
        setActiveTab(tabId);
    };

    const handleMouseEnter = (tabId) => {
        if (activeTab !== tabId) {
            setActiveTab(tabId);
        }
    };

    const handleMouseLeave = () => {
        setActiveTab("enter-1");

    };




    /*   const [activeTab] = useState("enter-1"); */

    useEffect(() => {
        const handleMouseOver = (event) => {
            const menuItem = event.currentTarget;
            const subMenu = menuItem.querySelector('.sub-mega-menu');
            if (subMenu) {
                subMenu.classList.add('show-sub-menu');
            }
        };

        const handleMouseOut = (event) => {
            const menuItem = event.currentTarget;
            const subMenu = menuItem.querySelector('.sub-mega-menu');
            if (subMenu) {
                subMenu.classList.remove('show-sub-menu');
            }
        };

        // Obtener todos los elementos de menú principal
        const menuItems = document.querySelectorAll('.main-menu > li');

        // Agregar event listeners a cada elemento de menú principal
        menuItems.forEach(menuItem => {
            menuItem.addEventListener('mouseover', handleMouseOver);
            menuItem.addEventListener('mouseout', handleMouseOut);
        });

        // Limpiar los event listeners cuando el componente se desmonta
        return () => {
            menuItems.forEach(menuItem => {
                menuItem.removeEventListener('mouseover', handleMouseOver);
                menuItem.removeEventListener('mouseout', handleMouseOut);
            });
        };
    }, []); // Ejecutar este efecto solo una vez al montar el componente

    const handleMenuLinkClick = () => {
        const subMenus = document.querySelectorAll('.sub-mega-menu');
        subMenus.forEach(subMenu => {
            subMenu.classList.remove('show-sub-menu');
        });
    };



    /* =================================== */


    return (


        <main>

            <header>

                {/* Header desktop */}
                <div className="container-menu-desktop">
                    <div className="topbar">
                        <div className="content-topbar container h-100">
                            <div className="left-topbar">
                                <span className="left-topbar-item flex-wr-s-c">
                                    <span>MOSCÚ, MOS</span>
                                    <img
                                        className="m-b-1 m-rl-8"
                                        src="/assets/images/icons/icon-night.png"
                                        alt="IMG"
                                    />
                                    <span>HI 55° LO 37°</span>
                                </span>
                                <Link to="/about" className="left-topbar-item"> Acerca de </Link>

                                {/* <a href="#" className="left-topbar-item"> */}
                                <Link to="/contact" className="left-topbar-item"> Contacto </Link>

                                <a href="#" className="left-topbar-item">
                                    Sing up
                                </a>
                                <a href="#" className="left-topbar-item">
                                    Log in
                                </a>
                            </div>
                            <div className="right-topbar">

                                <Link to="https://instagram.com/latinox.club" target="_blank"> <span className="fab fa-instagram" />   </Link>
                                <Link to="https://t.me/latinoxclub" target="_blank"> <span className="fab fa-telegram" />   </Link>
                                <Link to="https://vk.com/latinox.club" target="_blank"> <span className="fab fa-vk" />   </Link>
                                <Link to="https://www.youtube.com/OrbitRussia" target="_blank"> <span className="fab fa-youtube" />   </Link>
                                <Link to="https://www.youtube.com/OrbitRussia" target="_blank"> <span className="fab fa-facebook-f" />   </Link>

                            </div>

                            <div style={{ fontSize: '16px' }}>

                                <span role="img" aria-label="language"></span>

                                <select onChange={(e) => changeLanguage(e.target.value)}>
                                    <option value="es">Español</option>
                                    <option value="en">English</option>
                                    <option value="ru">Русский</option>
                                </select>

                            </div>

                        </div>
                    </div>


                    {/* Header Mobile */}
                    {/* menu Mobile */}



                    {/*===============================================================================================*/}
                    {/*===============================================================================================*/}
                    {/* ESCRITORIO */}
                    {/*  */}

                    <div className="wrap-logo container">
                        {/* Logo desktop */}
                        <div className="logo">
                            <Link to="/">
                                <img src="/assets/img/logo/logo-latinox.png" alt="LOGO" />
                            </Link>
                        </div>
                        
                        {/* Banner */}
                        <div className="banner-header">
                            {/* -------------- Yandex Banner 09 -------------- */}
                            <Adx1BannerVideo blockId="R-A-9762127-9" renderTo="yandex_rtb_R-A-9762127-9"/>                                        
                        </div>
                    </div>



                    <div className="wrap-main-nav container">
                        <div className="main-nav">

                            {/* =================== menu ESCRITORIO =================== */}
                            {/* menu desktop */}
                            <nav className="menu-desktop">
                                <a className="logo-stick" href=" ">
                                    <img src="/assets/img/logo/logo-latinox.png" alt="LOGO" />
                                </a>
                                <ul className="main-menu">
                                    {/* <ul className={`main-menu ${showSubMenu ? 'show-submenu' : ''}`} onClick={handleClickOutsideSubMenu}> */}
                                    <li className="main-menu-active">
                                        <Link to="/"> Inicio </Link>
                                    </li>



                                    {/*===============================================================================================*/}


                                    {/* ======= BLOQUE BAILES (news) ======= */}
                                    <li className="mega-menu-item"   >
                                        <Link to=" "> Bailemos </Link>

                                        {/* <div className="sub-mega-menu" onMouseLeave={handleMouseLeave} onClick={handleLinkClick} > */}
                                        <div className="sub-mega-menu" onMouseLeave={handleMouseLeave}>
                                            {/* <div className={`sub-mega-menu ${isMenuOpen ? 'show' : ''}`} onMouseLeave={handleMouseLeave}> */}


                                            <div className="nav flex-column nav-pills" role="tablist">
                                                <a
                                                    className={`nav-link ${activeTab === "enter-1" ? "active" : ""}`}
                                                    onMouseEnter={() => handleMouseEnter("enter-1")}
                                                >
                                                    Todo
                                                </a>
                                                <a
                                                    className={`nav-link ${activeTab === "enter-2" ? "active" : ""}`}
                                                    onMouseEnter={() => handleMouseEnter("enter-2")}
                                                >
                                                    Escuelas de baile
                                                </a>
                                                <a
                                                    className={`nav-link ${activeTab === "enter-3" ? "active" : ""}`}
                                                    onMouseEnter={() => handleMouseEnter("enter-3")}
                                                >
                                                    Música
                                                </a>
                                                <a
                                                    className={`nav-link ${activeTab === "enter-4" ? "active" : ""}`}
                                                    onMouseEnter={() => handleMouseEnter("enter-4")}
                                                >
                                                    Fiesta Latina
                                                </a>

                                            </div>


                                            <div className="tab-content">
                                                <div
                                                    className={`tab-pane ${activeTab === "enter-1" ? "show active" : ""}`}
                                                    id="enter-1"
                                                    role="tabpanel"
                                                >
                                                    <div className="row">


                                                        {/* ======= ItemA Actualidad ======= */}
                                                        <div className="col-3">

                                                            {/* Item post */}
                                                            <div>
                                                                <Link to="/events-dance" className="wrap-pic-w hov1 trans-03"
                                                                    onClick={handleMenuLinkClick}
                                                                >
                                                                    <img
                                                                        src="/assets/img/gif/nama.gif"
                                                                        alt="IMG"
                                                                    />
                                                                </Link>

                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <Link to="/events-dance"
                                                                            className="ocultar-menu f1-s-5 cl0 hov-cl10 trans-03"
                                                                            onClick={handleMenuLinkClick}
                                                                        >
                                                                            Fiesta Latina
                                                                        </Link>
                                                                    </h5>
                                                                    <span className="cl8">


                                                                        <Link to="/events-dance" className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            onClick={handleMenuLinkClick}> Sociedad </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Oct 15</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>





                                                        {/* ======= ItemA Actualidad ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>

                                                                <Link to="/schools-dance" className="wrap-pic-w hov1 trans-03" onClick={handleMenuLinkClick}>
                                                                    <img
                                                                        src="/assets/img/dance/disco-dancing.gif"
                                                                        alt="IMG"
                                                                    /> </Link>



                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <Link to="/schools-dance"
                                                                            className="f1-s-5 cl0 hov-cl10 trans-03" onClick={handleMenuLinkClick}
                                                                        >
                                                                            Escuelas de baile </Link>
                                                                    </h5>
                                                                    <span className="cl8">


                                                                        <Link to="/events-dance" className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            onClick={handleMenuLinkClick}> Social </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Oct 12</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>






                                                        {/* ======= ItemA Actualidad ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>

                                                                <Link to="/teachers-dance" className="wrap-pic-w hov1 trans-03"
                                                                    onClick={handleMenuLinkClick}
                                                                >
                                                                    <img
                                                                        src="/assets/images/daniel-desiree.gif"
                                                                        alt="IMG"
                                                                    />
                                                                </Link>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <Link to="/schools-dance" className="f1-s-5 cl0 hov-cl10 trans-03"
                                                                            onClick={handleMenuLinkClick}>  Profesores de baile </Link>
                                                                    </h5>

                                                                    <span className="cl8">

                                                                        <Link to="/events-dance" className="f1-s-6 cl8 hov-cl10 trans-03" onClick={handleMenuLinkClick}> Social </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Dec 27</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>



                                                        {/* ======= ItemA1 Actualidad ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <Link to="/gallery" className="wrap-pic-w hov1 trans-03" onClick={handleMenuLinkClick}>
                                                                    <img
                                                                        src="/assets/img/gif/dance.gif"
                                                                        alt="IMG"
                                                                    />
                                                                </Link>

                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <Link to="/gallery" className="f1-s-5 cl0 hov-cl10 trans-03" onClick={handleMenuLinkClick}
                                                                        >
                                                                            Fotos & Vídeos
                                                                        </Link>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/events-dance" className="f1-s-6 cl8 hov-cl10 trans-03" onClick={handleMenuLinkClick}> Social </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Dec 26</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>



                                                    </div>
                                                </div>



                                                <div
                                                    className={`tab-pane ${activeTab === "enter-2" ? "show active" : ""}`}
                                                    id="enter-2"
                                                    role="tabpanel"
                                                >
                                                    <div className="row">


                                                        {/* ======= ItemA  Actualidad ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href=" "
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img src="/assets/img/gallery/miss-universe-russia.png" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href=" "
                                                                            className="f1-s-5 cl0 hov-cl10 trans-03"
                                                                        >
                                                                            Salsa & Bachata

                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <a
                                                                            href="#"
                                                                            className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                        >
                                                                            IA
                                                                        </a>
                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Dec 26</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>



                                                        {/* ======= ItemA  Actualidad ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href=" "
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img
                                                                        src="/assets/images/trigo-agro-exportacion-maquinaria.png"
                                                                        alt="IMG"
                                                                    />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href=" "
                                                                            className="f1-s-5 cl0 hov-cl10 trans-03"
                                                                        >
                                                                            Sl
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/events-dance" className="f1-s-6 cl8 hov-cl10 trans-03"> Social </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Dec 25</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>





                                                        {/* ======= ItemA2 Actualidad ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href=" "
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img
                                                                        src="/assets/images/kremlin-dia-puente-cuadro.png"
                                                                        alt="IMG"
                                                                    />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href=" "
                                                                            className="f1-s-5 cl0 hov-cl10 trans-03"
                                                                        >
                                                                            Tango
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">

                                                                        <Link to="/events-dance" className="f1-s-6 cl8 hov-cl10 trans-03"> Eventos </Link>


                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Dec 23</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* ======= ItemA3 Actualidad ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href=" "
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img src="/assets/images/tubos-de-gas.png" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href=" "
                                                                            className="f1-s-5 cl0 hov-cl10 trans-03"
                                                                        >
                                                                            Flamenco

                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">


                                                                        <Link to="/events-dance" className="f1-s-6 cl8 hov-cl10 trans-03"> Social </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Dec 24</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>



                                                <div
                                                    className={`tab-pane ${activeTab === "enter-3" ? "show active" : ""}`}
                                                    id="enter-3"
                                                    role="tabpanel"
                                                >
                                                    <div className="row">


                                                        {/* ======= ItemA4 Actualidad ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href=" "
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img src="/assets/images/kremlin.png" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href=" "
                                                                            className="f1-s-5 cl0 hov-cl10 trans-03"
                                                                        >
                                                                            Vídeos
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/gallery" className="f1-s-6 cl8 hov-cl10 trans-03"> Social </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Dec 20</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>



                                                        {/* ======= ItemB1 Actualidad ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>

                                                                <Link to="/la-bachata-manuel-turizo" className="wrap-pic-w hov1 trans-03">
                                                                    <img
                                                                        src="/assets/images/chicas-computadora-tecnologia.png"
                                                                        alt="IMG"
                                                                    />
                                                                </Link>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <Link to="/la-bachata-manuel-turizo" className="f1-s-5 cl0 hov-cl10 trans-03">
                                                                            Letras traducidas 🌟
                                                                        </Link>

                                                                    </h5>
                                                                    <span className="cl8">

                                                                        <Link to="/actual" className="f1-s-6 cl8 hov-cl10 trans-03"> Actualidad </Link>

                                                                        {/*   <a
                                                                            href="#"
                                                                            className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                        >
                                                                            Actualidad
                                                                        </a> */}
                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Dec 25</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>



                                                        {/* ======= ItemB2 Actualidad ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href=" "
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img src="/assets/images/youtube.png" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href=" "
                                                                            className="f1-s-5 cl0 hov-cl10 trans-03"
                                                                        >
                                                                            ¿Qué buscas?
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/technology" className="f1-s-6 cl8 hov-cl10 trans-03"> Tecnología </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Dec 20</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        {/* ======= ItemB3 Actualidad ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href=" "
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img src="/assets/images/dolar-roto.png" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href=" "
                                                                            className="f1-s-5 cl0 hov-cl10 trans-03"
                                                                        >
                                                                            Vota ❤
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/events-dance" className="f1-s-6 cl8 hov-cl10 trans-03"> Eventos </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Dec 24</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>


                                                <div
                                                    className={`tab-pane ${activeTab === "enter-4" ? "show active" : ""}`}
                                                    id="enter-4"
                                                    role="tabpanel"
                                                >
                                                    <div className="row">
                                                        {/* ======= ItemD1 Actualidad ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                    <img src="/assets/images/post-25.jpg" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="#"
                                                                            className="f1-s-5 cl0 hov-cl10 trans-03"
                                                                        >
                                                                            Próximos Eventos
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/trending" className="f1-s-6 cl8 hov-cl10 trans-03"> Viral </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Dec 18</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* ======= ItemD2 Actualidad ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                    <img src="/assets/images/post-27.jpg" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="#"
                                                                            className="f1-s-5 cl0 hov-cl10 trans-03"
                                                                        >
                                                                            Fotos y Vídeos
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/trending" className="f1-s-6 cl8 hov-cl10 trans-03"> Viral </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Dec 20</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* ======= ItemD3 Actualidad ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                    <img src="/assets/images/post-26.jpg" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="#"
                                                                            className="f1-s-5 cl0 hov-cl10 trans-03"
                                                                        >
                                                                            Tu pareja de baile
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/trending" className="f1-s-6 cl8 hov-cl10 trans-03"> Viral </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Dec 12</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* ======= ItemD4 Actualidad ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                    <img src="/assets/images/post-34.jpg" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="#"
                                                                            className="f1-s-5 cl0 hov-cl10 trans-03"
                                                                        >
                                                                            Descuentos y cupones

                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/trending" className="f1-s-6 cl8 hov-cl10 trans-03"> Viral </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Dec 15</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </li>


                                    {/* ======= BLOQUE Restaurantes (enter) ======= */}
                                    <li className="mega-menu-item">

                                        <Link to=" " > Restaurantes </Link>

                                        <div className="sub-mega-menu" onMouseLeave={handleMouseLeave}  >
                                            <div className="nav flex-column nav-pills" role="tablist">
                                                <a
                                                    className={`nav-link ${activeTab === "enter-1" ? "active" : ""}`}
                                                    onMouseEnter={() => handleTabChange("enter-1")}
                                                >
                                                    Todo
                                                </a>
                                                <a
                                                    className={`nav-link ${activeTab === "enter-2" ? "active" : ""}`}
                                                    onMouseEnter={() => handleTabChange("enter-2")}
                                                >
                                                    Restaurantes Latinos
                                                </a>
                                                <a
                                                    className={`nav-link ${activeTab === "enter-3" ? "active" : ""}`}
                                                    onMouseEnter={() => handleTabChange("enter-3")}
                                                >
                                                    Eventos
                                                </a>
                                            </div>




                                            <div className="tab-content">
                                                <div
                                                    className={`tab-pane ${activeTab === "enter-1" ? "show active" : ""}`}
                                                    id="enter-1"
                                                    role="tabpanel"
                                                >
                                                    <div className="row">

                                                        {/* ======= ItemA1 Eventos ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>

                                                                <Link to="/restaurants" className="wrap-pic-w hov1 trans-03" onClick={handleMenuLinkClick}>
                                                                    <img
                                                                        src="/assets/img/restaurant/food/mexicana-com-1.jpg"
                                                                        alt="IMG"
                                                                    /> </Link>

                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <Link to="/restaurants" className="f1-s-5 cl0 hov-cl10 trans-03"
                                                                            onClick={handleMenuLinkClick}> Los mejores restaurantes Latinos </Link>

                                                                    </h5>
                                                                    <span className="cl8">

                                                                        <Link to="/events-dance" className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            onClick={handleMenuLinkClick}> Eventos </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Feb 23</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* ======= ItemA2 - Eventos ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <Link to="/restaurants" className="wrap-pic-w hov1 trans-03" onClick={handleMenuLinkClick}>
                                                                    <img
                                                                        src="/assets/img/gallery/amigos-copas.png"
                                                                        alt="IMG"
                                                                    /> </Link>

                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <Link to="/restaurants" className="f1-s-5 cl0 hov-cl10 trans-03"
                                                                            onClick={handleMenuLinkClick}> Clubes y pubs </Link>

                                                                    </h5>
                                                                    <span className="cl8">

                                                                        <Link to="/events-dance" className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            onClick={handleMenuLinkClick}> Eventos </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Dec 24</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* ======= ItemA3 - Eventos ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <Link to="/restaurants" className="wrap-pic-w hov1 trans-03" onClick={handleMenuLinkClick}>
                                                                    <img
                                                                        src="/assets/img/restaurant/places/salud.jpg"
                                                                        alt="IMG"
                                                                    /> </Link>

                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <Link to="/restaurants" className="f1-s-5 cl0 hov-cl10 trans-03"
                                                                            onClick={handleMenuLinkClick}> Descuentos y promociones </Link>

                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/events-dance" className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            onClick={handleMenuLinkClick}> Eventos </Link>
                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Dec 21</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* ======= ItemA4 - Eventos ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href=" "
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img src="/assets/images/kremlin.png" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href=" "
                                                                            className="f1-s-5 cl0 hov-cl10 trans-03"
                                                                        >
                                                                            Conoce Nuestra Comida

                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/events-dance" className="f1-s-6 cl8 hov-cl10 trans-03"> Eventos </Link>
                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Dec 20</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div
                                                    className={`tab-pane ${activeTab === "enter-2" ? "show active" : ""}`}
                                                    id="enter-2"
                                                    role="tabpanel"
                                                >
                                                    <div className="row">
                                                        {/* ======= ItemB1 - Eventos ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href=" "
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img src="/assets/images/erevan.png" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href=" "
                                                                            className="f1-s-5 cl0 hov-cl10 trans-03"
                                                                        >
                                                                            Eventos corporativos
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/events-dance" className="f1-s-6 cl8 hov-cl10 trans-03"> Eventos </Link>
                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Dec 20</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* ======= ItemB2 - Eventos ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href=" "
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img
                                                                        src="/assets/img/gallery/mujeres-cumple.png"
                                                                        alt="IMG"
                                                                    />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href=" "
                                                                            className="f1-s-5 cl0 hov-cl10 trans-03"
                                                                        >
                                                                            Eventos de la semana.
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <a
                                                                            href="#"
                                                                            className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                        >
                                                                            Defensa
                                                                        </a>
                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Apr 19</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* ======= ItemB3 - Eventos ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                    <img src="/assets/images/post-37.jpg" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="#"
                                                                            className="f1-s-5 cl0 hov-cl10 trans-03"
                                                                        >
                                                                            115 Donec metus orci, malesuada et lectus vitae
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/trending" className="f1-s-6 cl8 hov-cl10 trans-03"> Viral </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Dec 13</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* ======= ItemB4 - Eventos ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                    <img src="/assets/images/post-38.jpg" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="#"
                                                                            className="f1-s-5 cl0 hov-cl10 trans-03"
                                                                        >
                                                                            116 Donec metus orci, malesuada et lectus vitae
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/trending" className="f1-s-6 cl8 hov-cl10 trans-03"> Viral </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Dec 15</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div
                                                    className={`tab-pane ${activeTab === "enter-3" ? "show active" : ""}`}
                                                    id="enter-3"
                                                    role="tabpanel"
                                                >
                                                    <div className="row">
                                                        {/* ======= ItemC1 Eventos ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                    <img src="/assets/images/post-39.jpg" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="#"
                                                                            className="f1-s-5 cl0 hov-cl10 trans-03"
                                                                        >
                                                                            Próximos eventos
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/trending" className="f1-s-6 cl8 hov-cl10 trans-03"> Viral </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Dec 12</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* ======= ItemC2 Eventos ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                    <img src="/assets/images/post-41.jpg" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="#"
                                                                            className="f1-s-5 cl0 hov-cl10 trans-03"
                                                                        >
                                                                            Clases de cocina
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/trending" className="f1-s-6 cl8 hov-cl10 trans-03"> Viral </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Dec 15</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* ======= ItemC3 Eventos ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                    <img src="/assets/images/post-42.jpg" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="#"
                                                                            className="f1-s-5 cl0 hov-cl10 trans-03"
                                                                        >
                                                                            Recomendados
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/trending" className="f1-s-6 cl8 hov-cl10 trans-03"> Viral </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Dec 15</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* ======= ItemC4 Eventos ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                    <img src="/assets/images/post-40.jpg" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="#"
                                                                            className="f1-s-5 cl0 hov-cl10 trans-03"
                                                                        >
                                                                            Stand Up
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/trending" className="f1-s-6 cl8 hov-cl10 trans-03"> Viral </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Dec 12</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>


                                    {/* ======= BLOQUE IDIOMA ESPAÑOL (business) ======= */}
                                    <li className="mega-menu-item">

                                        <Link to=" "> Idioma Español </Link>
                                        <div className="sub-mega-menu" onMouseLeave={handleMouseLeave}  >

                                            <div className="nav flex-column nav-pills" role="tablist">
                                                <a
                                                    className={`nav-link ${activeTab === "enter-1" ? "active" : ""}`}
                                                    onMouseEnter={() => handleTabChange("enter-1")}
                                                >
                                                    Todo
                                                </a>
                                                <a
                                                    className={`nav-link ${activeTab === "enter-2" ? "active" : ""}`}
                                                    onMouseEnter={() => handleTabChange("enter-2")}
                                                >
                                                    Educación
                                                </a>
                                                <a
                                                    className={`nav-link ${activeTab === "enter-3" ? "active" : ""}`}
                                                    onMouseEnter={() => handleTabChange("enter-3")}
                                                >
                                                    Eventos
                                                </a>
                                            </div>


                                            <div className="tab-content">
                                                <div
                                                    className={`tab-pane ${activeTab === "enter-1" ? "show active" : ""}`}
                                                    id="enter-1"
                                                    role="tabpanel"
                                                >
                                                    <div className="row">


                                                        {/* ======= ItemA Econ ======= */}

                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>

                                                                <Link to="/schools-spanish" className="wrap-pic-w hov1 trans-03"
                                                                    onClick={handleMenuLinkClick}>
                                                                    <img
                                                                        src="/assets/img/gallery/rusas-universidad-clases.png"
                                                                        alt="IMG"
                                                                    /> </Link>



                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">

                                                                        <Link to="/schools-spanish" className="f1-s-5 cl0 hov-cl10 trans-03"
                                                                            onClick={handleMenuLinkClick}> Escuelas de Español </Link>

                                                                    </h5>
                                                                    <span className="cl8">


                                                                        <Link to="/events-dance" className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            onClick={handleMenuLinkClick}> Social </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Oct 12</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>



                                                        {/* ======= Item Econ ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>

                                                                <Link to="/teachers-spanish" className="wrap-pic-w hov1 trans-03" onClick={handleMenuLinkClick}>
                                                                    <img
                                                                        src="/assets/img/foxmaster/course-1.jpg"
                                                                        alt="IMG"
                                                                    />
                                                                </Link>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">

                                                                        <Link to="/teachers-spanish" className="f1-s-5 cl0 hov-cl10 trans-03"
                                                                            onClick={handleMenuLinkClick}> Profesores de Español </Link>
                                                                    </h5>

                                                                    <span className="cl8">
                                                                        <Link to="/events-dance" className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            onClick={handleMenuLinkClick}> Social </Link>
                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Dec 27</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        {/* ======= ItemA1 Econ ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>

                                                                <Link to="/teachers-spanish" className="wrap-pic-w hov1 trans-03" onClick={handleMenuLinkClick}>
                                                                    <img
                                                                        src="/assets/img/gallery/weekly2News2.png"
                                                                        alt="IMG"
                                                                    />
                                                                </Link>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <Link to="/teachers-spanish" className="f1-s-5 cl0 hov-cl10 trans-03"
                                                                            onClick={handleMenuLinkClick}>  Clubes de Español   </Link>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/events-dance" className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                            onClick={handleMenuLinkClick}> Social </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Dec 26</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        {/* ======= ItemA1 Social ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href=" "
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img
                                                                        src="/assets/img/gallery/latinosunidos.jpg"
                                                                        alt="IMG"
                                                                    />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href=" "
                                                                            className="f1-s-5 cl0 hov-cl10 trans-03"
                                                                        >
                                                                            Blogs
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/events-dance" className="f1-s-6 cl8 hov-cl10 trans-03"> Social </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Dec 25</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>



                                                    </div>
                                                </div>


                                                <div
                                                    className={`tab-pane ${activeTab === "enter-2" ? "show active" : ""}`}
                                                    id="enter-2"
                                                    role="tabpanel"
                                                >
                                                    <div className="row">


                                                        {/* ======= ItemA2 Social ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href=" "
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img src="/assets/images/tubos-de-gas.png" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href=" "
                                                                            className="f1-s-5 cl0 hov-cl10 trans-03"
                                                                        >
                                                                            Cursos de Español
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/events-dance" className="f1-s-6 cl8 hov-cl10 trans-03"> Social </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Dec 24</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        {/* ======= ItemA3 Social ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href=" "
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img
                                                                        src="/assets/images/juego-economia-rusa.png"
                                                                        alt="IMG"
                                                                    />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href=" "
                                                                            className="f1-s-5 cl0 hov-cl10 trans-03"
                                                                        >
                                                                            Traducciones y documentación
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/events-dance" className="f1-s-6 cl8 hov-cl10 trans-03"> Social </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Dec 23</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        {/* ======= ItemA4 Social ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href=" "
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img src="/assets/images/barras-de-oro.png" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href=" "
                                                                            className="f1-s-5 cl0 hov-cl10 trans-03"
                                                                        >
                                                                            Inmersión Lingüística
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/events-dance" className="f1-s-6 cl8 hov-cl10 trans-03"> Social </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Dec 20</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        {/* ======= ItemB1 Social ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href=" "
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img src="/assets/images/gasolina.png" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href=" "
                                                                            className="f1-s-5 cl0 hov-cl10 trans-03"
                                                                        >
                                                                            Cine y Literatura
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/events-dance" className="f1-s-6 cl8 hov-cl10 trans-03"> Social </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Dec 20</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>



                                                    </div>
                                                </div>


                                                <div
                                                    className={`tab-pane ${activeTab === "enter-3" ? "show active" : ""}`}
                                                    id="enter-3"
                                                    role="tabpanel"
                                                >
                                                    <div className="row">


                                                        {/* ======= ItemA2 Social ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href="e "
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img src="/assets/images/tubos-de-gas.png" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href=" "
                                                                            className="f1-s-5 cl0 hov-cl10 trans-03"
                                                                        >
                                                                            Eventos Culturales
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/events-dance" className="f1-s-6 cl8 hov-cl10 trans-03"> Social </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Dec 24</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        {/* ======= ItemA3 Social ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href=" "
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img
                                                                        src="/assets/images/juego-economia-rusa.png"
                                                                        alt="IMG"
                                                                    />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href=" "
                                                                            className="f1-s-5 cl0 hov-cl10 trans-03"
                                                                        >
                                                                            Clubes de Español
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/events-dance" className="f1-s-6 cl8 hov-cl10 trans-03"> Social </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Dec 23</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        {/* ======= ItemA4 Social ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href=" "
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img src="/assets/images/barras-de-oro.png" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href=" "
                                                                            className="f1-s-5 cl0 hov-cl10 trans-03"
                                                                        >
                                                                            Universidades
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/events-dance" className="f1-s-6 cl8 hov-cl10 trans-03"> Social </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Dec 20</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        {/* ======= ItemB1 Social ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href=" "
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img src="/assets/images/gasolina.png" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href=" "
                                                                            className="f1-s-5 cl0 hov-cl10 trans-03"
                                                                        >
                                                                            Stand Up
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/events-dance" className="f1-s-6 cl8 hov-cl10 trans-03"> Social </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Dec 20</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>



                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </li>


                                    {/* ======= BLOQUE TIENDAS (travel) ======= */}
                                    <li className="mega-menu-item">

                                        <Link to=" "> Tiendas </Link>

                                        <div className="sub-mega-menu" onMouseLeave={handleMouseLeave}  >
                                            <div className="nav flex-column nav-pills" role="tablist">
                                                <a
                                                    className={`nav-link ${activeTab === "enter-1" ? "active" : ""}`}
                                                    onMouseEnter={() => handleMouseEnter("enter-1")}
                                                >
                                                    Todo
                                                </a>
                                                <a
                                                    className={`nav-link ${activeTab === "enter-2" ? "active" : ""}`}
                                                    onMouseEnter={() => handleMouseEnter("enter-2")}
                                                >
                                                    Eventos
                                                </a>
                                                <a
                                                    className={`nav-link ${activeTab === "enter-3" ? "active" : ""}`}
                                                    onMouseEnter={() => handleMouseEnter("enter-3")}
                                                >
                                                    Otros
                                                </a>
                                            </div>



                                            <div className="tab-content">
                                                <div
                                                    className={`tab-pane ${activeTab === "enter-1" ? "show active" : ""}`}
                                                    id="enter-1"
                                                    role="tabpanel"
                                                >
                                                    <div className="row">
                                                        {/* ======= ItemA  TECNO ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <Link to="/store" className="wrap-pic-w hov1 trans-03"
                                                                    onClick={handleMenuLinkClick}>
                                                                    <img
                                                                        src="/assets/img/gallery/miss-universe-russia.png"
                                                                        alt="IMG"
                                                                    /> </Link>

                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href=" "
                                                                            className="f1-s-5 cl0 hov-cl10 trans-03"
                                                                        >
                                                                            Moda y Belleza
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <a
                                                                            href="#"
                                                                            className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                        >
                                                                            IA
                                                                        </a>
                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Dec 26</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* ======= ItemA1 Tecnología ======= */}
                                                        <div className="col-3">
                                                            {/* Item 1 - Tecnología */}
                                                            <div>
                                                            <Link to="/store" className="wrap-pic-w hov1 trans-03"
                                                                    onClick={handleMenuLinkClick}>
                                                                    <img
                                                                        src="/assets/img/store/alpak7.jpg"
                                                                        alt="IMG"
                                                                    /> </Link>
                                                                
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href=" "
                                                                            className="f1-s-5 cl0 hov-cl10 trans-03"
                                                                        >
                                                                            Soy Latin❤️
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">

                                                                        <Link to="/technology" className="f1-s-6 cl8 hov-cl10 trans-03"> Tecnología </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Dec 20</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* ======= ItemA2 Tecnología ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                            <Link to="/store" className="wrap-pic-w hov1 trans-03"
                                                                    onClick={handleMenuLinkClick}>
                                                                    <img
                                                                        src="/assets/img/store/stand.jpg"
                                                                        alt="IMG"
                                                                    /> </Link>
                                                                 
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href=" "
                                                                            className="f1-s-5 cl0 hov-cl10 trans-03"
                                                                        >
                                                                            Lanzamientos
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <a
                                                                            href="#"
                                                                            className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                        >
                                                                            Ciencia
                                                                        </a>
                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Dec 20</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* ======= ItemA3 Tecnología ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href=" "
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img src="/assets/images/ia-mujer-pantalla.png" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href=" "
                                                                            className="f1-s-5 cl0 hov-cl10 trans-03"
                                                                        >
                                                                            Productos
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <a
                                                                            href="#"
                                                                            className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                        >
                                                                            IA
                                                                        </a>
                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Dec 18</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                {/* ======= Fila 2- Tecnología ======= */}
                                                <div
                                                    className={`tab-pane ${activeTab === "enter-2" ? "show active" : ""}`}
                                                    id="enter-2"
                                                    role="tabpanel"
                                                >
                                                    <div className="row">
                                                        {/* ======= ItemA4 Tecnología ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href=" "
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img
                                                                        src="/assets/images/rusia-identi-digital.jpg"
                                                                        alt="IMG"
                                                                    />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href=" "
                                                                            className="f1-s-5 cl0 hov-cl10 trans-03"
                                                                        >
                                                                            Ferias y Exposiciones
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <a
                                                                            href="#"
                                                                            className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                        >
                                                                            Digital
                                                                        </a>
                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Dec 18</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* ======= ItemB1 Tecnología ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href=" "
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img
                                                                        src="/assets/images/ciencia-laboratorio.png"
                                                                        alt="IMG"
                                                                    />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href=" "
                                                                            className="f1-s-5 cl0 hov-cl10 trans-03"
                                                                        >
                                                                            Descuentos para Miembros
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <a
                                                                            href="#"
                                                                            className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                        >
                                                                            Ciencia
                                                                        </a>
                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Dec 18</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* ======= ItemB2 Tecnología ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                    <img src="/assets/images/post-36.jpg" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="#"
                                                                            className="f1-s-5 cl0 hov-cl10 trans-03"
                                                                        >
                                                                            Esto es Para Ti
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <a
                                                                            href="#"
                                                                            className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                        >
                                                                            Eventos
                                                                        </a>
                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Dec 13</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* ======= ItemB3 Tecnología ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                    <img src="/assets/images/post-37.jpg" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="#"
                                                                            className="f1-s-5 cl0 hov-cl10 trans-03"
                                                                        >
                                                                            TENGO UN PLAN
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <a
                                                                            href="#"
                                                                            className="f1-s-6 cl8 hov-cl10 trans-03"
                                                                        >
                                                                            Eventos
                                                                        </a>
                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Dec 13</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>


                                    {/* ======= BLOQUE NEGOCIOS (life) ======= */}




                                    {/* ======= BLOQUE SOCIEDAD (video) ======= */}
                                    <li className="mega-menu-item">
                                        <Link to="/"> Sociedad </Link>

                                        <div className="sub-mega-menu" onMouseLeave={handleMouseLeave}  >
                                            <div className="nav flex-column nav-pills" role="tablist">
                                                <a
                                                    className={`nav-link ${activeTab === "enter-1" ? "active" : ""}`}
                                                    onMouseEnter={() => handleTabChange("enter-1")}
                                                >
                                                    Todo
                                                </a>
                                                <a
                                                    className={`nav-link ${activeTab === "enter-2" ? "active" : ""}`}
                                                    onMouseEnter={() => handleTabChange("enter-2")}
                                                >
                                                    Negocios
                                                </a>

                                                <a
                                                    className={`nav-link ${activeTab === "enter-3" ? "active" : ""}`}
                                                    onMouseEnter={() => handleTabChange("enter-3")}
                                                >
                                                    Eventos
                                                </a>
                                            </div>


                                            <div className="tab-content">
                                                <div
                                                    className={`tab-pane ${activeTab === "enter-1" ? "show active" : ""}`}
                                                    id="enter-1"
                                                    role="tabpanel"
                                                >

                                                    <div className="row">
                                                        {/* ======= ItemA1 Video ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href=" "
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img src="/assets/img/gallery/kremlin-rio.png" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href=" "
                                                                            className="f1-s-5 cl0 hov-cl10 trans-03"
                                                                        >
                                                                            Actualidad
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/events-dance" className="f1-s-6 cl8 hov-cl10 trans-03"> Sociedad </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Dec 29</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* ======= ItemA2 Video ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                    <img src="/assets/img/gallery/emb-espana.png" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="#"
                                                                            className="f1-s-5 cl0 hov-cl10 trans-03"
                                                                        >
                                                                            Embajadas
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">

                                                                        <Link to="/trending" className="f1-s-6 cl8 hov-cl10 trans-03"> Viral </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Dec 12</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* ======= ItemA3 Video ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                    <img src="/assets/img/gallery/chicas-rusas-patio.png" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="#"
                                                                            className="f1-s-5 cl0 hov-cl10 trans-03"
                                                                        >
                                                                            Educación
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/trending" className="f1-s-6 cl8 hov-cl10 trans-03"> Viral </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Dec 20</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* ======= ItemA4 Video ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                    <img src="/assets/images/post-06.jpg" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="#"
                                                                            className="f1-s-5 cl0 hov-cl10 trans-03"
                                                                        >
                                                                            Viajes
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/trending" className="f1-s-6 cl8 hov-cl10 trans-03"> Viral </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Dec 15</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>


                                                <div
                                                    className={`tab-pane ${activeTab === "enter-2" ? "show active" : ""}`}
                                                    id="enter-2"
                                                    role="tabpanel"
                                                >

                                                    <div className="row">
                                                        {/* ======= ItemA1 Video ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a
                                                                    href=" "
                                                                    className="wrap-pic-w hov1 trans-03"
                                                                >
                                                                    <img src="/assets/img/gallery/kremlin-rio.png" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href=" "
                                                                            className="f1-s-5 cl0 hov-cl10 trans-03"
                                                                        >
                                                                            Comercio Internacional
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/events-dance" className="f1-s-6 cl8 hov-cl10 trans-03"> Sociedad </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Dec 29</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* ======= ItemA2 Video ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                    <img src="/assets/img/gallery/dinero-rublos-rusos.png" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="#"
                                                                            className="f1-s-5 cl0 hov-cl10 trans-03"
                                                                        >
                                                                            Envíos & Transacciones
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">

                                                                        <Link to="/trending" className="f1-s-6 cl8 hov-cl10 trans-03"> Viral </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Dec 12</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* ======= ItemA3 Video ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                    <img src="/assets/img/gallery/passport-latam.jpg" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="#"
                                                                            className="f1-s-5 cl0 hov-cl10 trans-03"
                                                                        >
                                                                            Visas para España y Latinoamérica
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/trending" className="f1-s-6 cl8 hov-cl10 trans-03"> Viral </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Dec 20</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* ======= ItemA4 Video ======= */}
                                                        <div className="col-3">
                                                            {/* Item post */}
                                                            <div>
                                                                <a href="#" className="wrap-pic-w hov1 trans-03">
                                                                    <img src="/assets/images/post-06.jpg" alt="IMG" />
                                                                </a>
                                                                <div className="p-t-10">
                                                                    <h5 className="p-b-5">
                                                                        <a
                                                                            href="#"
                                                                            className="f1-s-5 cl0 hov-cl10 trans-03"
                                                                        >
                                                                            Profesionales
                                                                        </a>
                                                                    </h5>
                                                                    <span className="cl8">
                                                                        <Link to="/trending" className="f1-s-6 cl8 hov-cl10 trans-03"> Viral </Link>

                                                                        <span className="f1-s-3 m-rl-3">-</span>
                                                                        <span className="f1-s-3">Dec 15</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>


                                            </div>
                                        </div>
                                    </li>


                                    {/* ======= BLOQUE DESTACADO ======= */}
                                    <li>
                                        <a href="#">HOLA</a>
                                        <ul className="sub-menu">

                                            <li>
                                                <Link to="/about"> Acerca de </Link>
                                            </li>
                                            <li>
                                                <Link to="/contact"> Contáctanos </Link>
                                            </li>
                                            <li>
                                                <Link to="/about"> CLUB - Membresía </Link>
                                            </li>
                                            <li>
                                                <Link to="/about"> Support Us </Link>
                                            </li>
                                            <li>
                                                <Link to="/about"> Soluciones Digitales </Link>
                                            </li>
                                            <li>
                                                <Link to="/about"> Foro Latino </Link>
                                            </li>

                                        </ul>
                                    </li>

                                </ul>
                            </nav>
                        </div>
                    </div>




                </div>
            </header>


        </main>



    );

}

export default MenuDesk;