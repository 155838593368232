

import Footer from "./FooterEn";
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';




import React, { useEffect, useState } from 'react';



function FoxTeacherDance() {

    /* const imagePath = "./assets/img/foxmaster/"; */


    return (


        <main >

            {/* <Menu /> */}

            {/* console.log(styles);

            <div className={styles.FoxEstilo } >
 */}
            <section
                className="hero-wrap hero-wrap-2-fox"
                style={{ backgroundImage: 'url("/assets/img/gallery/dance-girl-man.png")' }}

            >

                <div className="overlay" />
                <div className="containerFox">
                    <div className="row no-gutters slider-text align-items-center justify-content-center">
                        <div className="col-md-9 ftco-animate text-center fadeInUp ftco-animated">
                            <h1 className="mb-2 bread"> Latino Dance </h1>
                            <p className="breadcrumbs">
                                <span className="mr-2">
                                    {/* <a href=" ">
                                        Inicio <i className="ion-ios-arrow-forward" />
                                    </a> */}
                                    <Link to="/"  > Home <i className="ion-ios-arrow-forward" /> </Link>
                                </span>{" "}
                                <span>
                                    Dance Schools <i className="ion-ios-arrow-forward" />
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>



            <section className="ftco-section bg-light">
                <div className="containerFox-fluid px-4">
                    <div className="row">

                           {/* Item */}
                           <div className="col-md-6 col-lg-3 ftco-animate">
                            <div className="staff">
                                <div className="img-wrap d-flex align-items-stretch">
                                    <div
                                        className="img align-self-stretch"
                                        style={{ backgroundImage: "url(assets/img/teachersdance/david-benitez.jpg)" }}
                                    />
                                </div>
                                <div className="text pt-3 text-center">
                                    <h3> David Benitez </h3>
                                    <span className="position mb-2">Salsa, Bachata</span>
                                    <div className="faded">
                                        <p>
                                            Я танцор из Кубы, живу в Москве и могу научить тебя танцевать бачату и сальсу. Преподаю индивидуальные и групповые занятия
                                        </p>
                                        <ul className="ftco-social text-center">
                                            <li class="ftco-animate"><Link to="https://instagram.com/david.david.baile" target="_blank"><span class="icon-instagram"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://vk.com/benitez.baile" target="_blank"><span class="icon-vk"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://vk.com/benitez.baile" target="_blank"><span class="icon-telegram"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://vk.com/benitez.baile" target="_blank"><span class="icon-youtube"></span></Link></li>
                                          
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Item */}
                        <div className="col-md-6 col-lg-3 ftco-animate">
                            <div className="staff">
                                <div className="img-wrap d-flex align-items-stretch">
                                    <div
                                        className="img align-self-stretch"
                                        style={{ backgroundImage: "url(assets/img/teachersdance/Elena-balasanova.jpg)" }}
                                    />
                                </div>
                                <div className="text pt-3 text-center">
                                    <h3>Elena Balasanova </h3>
                                    <span className="position mb-2">Bachata, Salsa</span>
                                    <div className="faded">
                                        <p>
                                        Я могу научить тебя танцевать бачату и сальсу. Преподаю индивидуальные и групповые занятия
                                        
                                        </p>
                                        <ul className="ftco-social text-center">
                                            <li class="ftco-animate"><Link to="https://instagram.com/elenabalasanovaofficial" target="_blank"><span class="icon-instagram"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://vk.com/elenabalasanova" target="_blank"><span class="icon-vk"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://vk.com/elenabalasanova" target="_blank"><span class="icon-telegram"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://www.youtube.com/@elenabalasanova" target="_blank"><span class="icon-youtube"></span></Link></li>
                                          
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Item */}
                        <div className="col-md-6 col-lg-3 ftco-animate">
                            <div className="staff">
                                <div className="img-wrap d-flex align-items-stretch">
                                    <div
                                        className="img align-self-stretch"
                                        style={{ backgroundImage: "url(assets/img/teachersdance/niko-ramirez.jpg)" }}
                                    />
                                </div>
                                <div className="text pt-3 text-center">
                                    <h3> Niko Ramirez </h3>
                                    <span className="position mb-2">Salsa, Bachata</span>
                                    <div className="faded">
                                        <p>
                                            Я танцор из Перу, живу в Москве и могу научить тебя танцевать бачату и сальсу. Преподаю индивидуальные и групповые занятия
                                        </p>
                                        <ul className="ftco-social text-center">
                                            <li class="ftco-animate"><Link to="https://instagram.com/niko_ramirez_avila" target="_blank"><span class="icon-instagram"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://vk.com/niko_ramirez" target="_blank"><span class="icon-vk"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://vk.com/niko_ramirez" target="_blank"><span class="icon-telegram"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://vk.com/niko_ramirez" target="_blank"><span class="icon-youtube"></span></Link></li>
                                          
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Item */}
                        <div className="col-md-6 col-lg-3 ftco-animate">
                            <div className="staff">
                                <div className="img-wrap d-flex align-items-stretch">
                                    <div
                                        className="img align-self-stretch"
                                        style={{ backgroundImage: "url(assets/img/teachersdance/katia-guz.jpg)" }}
                                    />
                                </div>
                                <div className="text pt-3 text-center">
                                    <h3> Katia Guz Rodriges </h3>
                                    <span className="position mb-2">Salsa</span>
                                    <div className="faded">
                                        <p>
                                            Я танцор из Мексики, живу в Москве и могу научить тебя танцевать сальсу. Преподаю индивидуальные и групповые занятия
                                        </p>
                                        <ul className="ftco-social text-center">
                                            <li class="ftco-animate"><Link to="https://instagram.com/katia_rodrigesalsa" target="_blank"><span class="icon-instagram"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://instagram.com/katia_rodrigesalsa" target="_blank"><span class="icon-vk"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://instagram.com/katia_rodrigesalsa" target="_blank"><span class="icon-telegram"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://instagram.com/katia_rodrigesalsa" target="_blank"><span class="icon-youtube"></span></Link></li>
                                          
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                         {/* Item */}
                         <div className="col-md-6 col-lg-3 ftco-animate">
                            <div className="staff">
                                <div className="img-wrap d-flex align-items-stretch">
                                    <div
                                        className="img align-self-stretch"
                                        style={{ backgroundImage: "url(assets/img/teachersdance/keneth-alvarez.jpg)" }}
                                    />
                                </div>
                                <div className="text pt-3 text-center">
                                    <h3> Keneth Alvarez </h3>
                                    <span className="position mb-2">Salsa</span>
                                    <div className="faded">
                                        <p>
                                            Я танцор из Кубы, живу в Москве и могу научить тебя танцевать сальсу. Преподаю индивидуальные и групповые занятия
                                        </p>
                                        <ul className="ftco-social text-center">
                                            <li class="ftco-animate"><Link to="https://instagram.com/akentalvarez_dancesoul" target="_blank"><span class="icon-instagram"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://instagram.com/akentalvarez_dancesoul" target="_blank"><span class="icon-vk"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://t.me/keneth88" target="_blank"><span class="icon-telegram"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://t.me/keneth88" target="_blank"><span class="icon-youtube"></span></Link></li>
                                          
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                         {/* Item */}
                         <div className="col-md-6 col-lg-3 ftco-animate">
                            <div className="staff">
                                <div className="img-wrap d-flex align-items-stretch">
                                    <div
                                        className="img align-self-stretch"
                                        style={{ backgroundImage: "url(assets/img/teachersdance/julia-volkova.jpg)" }}
                                    />
                                </div>
                                <div className="text pt-3 text-center">
                                    <h3> Julia Volkova </h3>
                                    <span className="position mb-2">Kizomba, Bachata, Urbankiz </span>
                                    <div className="faded">
                                        <p>
                                            Я могу научить тебя танцевать кизомбу, бачату. Преподаю индивидуальные и групповые занятия
                                        </p>
                                        <ul className="ftco-social text-center">
                                            <li class="ftco-animate"><Link to="https://instagram.com/yyyyyyvolk66" target="_blank"><span class="icon-instagram"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://vk.com/yyyyyyvolk66" target="_blank"><span class="icon-vk"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://vk.com/yyyyyyvolk66" target="_blank"><span class="icon-telegram"></span></Link></li>
                                            <li class="ftco-animate"><Link to="https://vk.com/yyyyyyvolk66" target="_blank"><span class="icon-youtube"></span></Link></li>
                                          
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-md-6 col-lg-3 ftco-animate">
                            <div className="staff">
                                <div className="img-wrap d-flex align-items-stretch">
                                    <div
                                        className="img align-self-stretch"
                                        style={{ backgroundImage: "url(assets/img/webuni/authors/1.jpg)" }}
                                    />
                                </div>
                                <div className="text pt-3 text-center">
                                    <h3>Hugo Fernandez</h3>
                                    <span className="position mb-2">Salsa Teacher</span>
                                    <div className="faded">
                                        <p>
                                            I am an ambitious workaholic, but apart from that, pretty
                                            simple person.
                                        </p>
                                        <ul className="ftco-social text-center">
                                            <li class="ftco-animate"><a href="#"><span class="icon-telegram"></span></a></li>
                                            <li class="ftco-animate"><a href="#"><span class="icon-instagram"></span></a></li>
                                            <li class="ftco-animate"><a href="#"><span class="icon-vk"></span></a></li>
                                            <li class="ftco-animate"><a href="#"><span class="icon-youtube"></span></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-md-6 col-lg-3 ftco-animate">
                            <div className="staff">
                                <div className="img-wrap d-flex align-items-stretch">
                                    <div
                                        className="img align-self-stretch"
                                        style={{ backgroundImage: 'url("/assets/img/gallery/chica-fiesta-azul.jpg")' }}
                                    />

                                </div>
                                <div className="text pt-3 text-center">
                                    <h3>Bianca Wilson</h3>
                                    <span className="position mb-2"> Bachata Lady Stile</span>
                                    <div className="faded">
                                        <p>
                                            Hola 01 I am an ambitious workaholic, but apart from that, pretty
                                            simple person.
                                        </p>
                                        <ul className="ftco-social text-center">
                                            <li class="ftco-animate"><a href="#"><span class="icon-telegram"></span></a></li>
                                            <li class="ftco-animate"><a href="#"><span class="icon-instagram"></span></a></li>
                                            <li class="ftco-animate"><a href="#"><span class="icon-vk"></span></a></li>
                                            <li class="ftco-animate"><a href="#"><span class="icon-youtube"></span></a></li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-md-6 col-lg-3 ftco-animate">
                            <div className="staff">
                                <div className="img-wrap d-flex align-items-stretch">

                                    <div className="img align-self-stretch"
                                        style={{ backgroundImage: "url(./assets/img/team/3.png)" }}
                                    />
                                </div>
                                <div className="text pt-3 text-center">
                                    <h3>Mitch Parker</h3>
                                    <span className="position mb-2">Salsa Teacher</span>
                                    <div className="faded">
                                        <p>
                                            I am an ambitious workaholic, but apart from that, pretty
                                            simple person.
                                        </p>
                                        <ul className="ftco-social text-center">
                                            <li class="ftco-animate"><a href="#"><span class="icon-telegram"></span></a></li>
                                            <li class="ftco-animate"><a href="#"><span class="icon-instagram"></span></a></li>
                                            <li class="ftco-animate"><a href="#"><span class="icon-vk"></span></a></li>
                                            <li class="ftco-animate"><a href="#"><span class="icon-youtube"></span></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-md-6 col-lg-3 ftco-animate">
                            <div className="staff">
                                <div className="img-wrap d-flex align-items-stretch">

                                    <div className="img align-self-stretch"
                                        style={{ backgroundImage: "url(./assets/img/trending/right1.jpg)" }}
                                    />
                                </div>
                                <div className="text pt-3 text-center">
                                    <h3>Jhon Levin</h3>
                                    <span className="position mb-2">Bachata Teacher</span>
                                    <div className="faded">
                                        <p>
                                            I am an ambitious workaholic, but apart from that, pretty
                                            simple person.
                                        </p>
                                        <ul className="ftco-social text-center">
                                            <li class="ftco-animate"><a href="#"><span class="icon-telegram"></span></a></li>
                                            <li class="ftco-animate"><a href="#"><span class="icon-instagram"></span></a></li>
                                            <li class="ftco-animate"><a href="#"><span class="icon-vk"></span></a></li>
                                            <li class="ftco-animate"><a href="#"><span class="icon-youtube"></span></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-md-6 col-lg-3 ftco-animate">
                            <div className="staff">
                                <div className="img-wrap d-flex align-items-stretch">
                                    <div
                                        className="img align-self-stretch"
                                        style={{ backgroundImage: "url(assets/img/trending/trending_bottom2.jpg)" }}
                                    />
                                </div>
                                <div className="text pt-3 text-center">
                                    <h3>Lucas Blad</h3>
                                    <span className="position mb-2">Bachata Teacher</span>
                                    <div className="faded">
                                        <p>
                                            I am an ambitious workaholic, but apart from that, pretty
                                            simple person.
                                        </p>
                                        <ul className="ftco-social text-center">
                                            <li class="ftco-animate"><a href="#"><span class="icon-telegram"></span></a></li>
                                            <li class="ftco-animate"><a href="#"><span class="icon-instagram"></span></a></li>
                                            <li class="ftco-animate"><a href="#"><span class="icon-vk"></span></a></li>
                                            <li class="ftco-animate"><a href="#"><span class="icon-youtube"></span></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-md-6 col-lg-3 ftco-animate">
                            <div className="staff">
                                <div className="img-wrap d-flex align-items-stretch">
                                    <div
                                        className="img align-self-stretch"
                                        style={{ backgroundImage: "url(assets/img/webuni/authors/4.jpg)" }}
                                    />
                                </div>
                                <div className="text pt-3 text-center">
                                    <h3> Luciana Caceres</h3>
                                    <span className="position mb-2">Salsa Teacher</span>
                                    <div className="faded">
                                        <p>
                                            I am an ambitious workaholic, but apart from that, pretty
                                            simple person.
                                        </p>
                                        <ul className="ftco-social text-center">
                                            <li class="ftco-animate"><a href="#"><span class="icon-telegram"></span></a></li>
                                            <li class="ftco-animate"><a href="#"><span class="icon-instagram"></span></a></li>
                                            <li class="ftco-animate"><a href="#"><span class="icon-vk"></span></a></li>
                                            <li class="ftco-animate"><a href="#"><span class="icon-youtube"></span></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-md-6 col-lg-3 ftco-animate">
                            <div className="staff">
                                <div className="img-wrap d-flex align-items-stretch">
                                    <div
                                        className="img align-self-stretch"
                                        style={{ backgroundImage: "url(assets/img/webuni/authors/6.jpg)" }}
                                    />
                                </div>
                                <div className="text pt-3 text-center">
                                    <h3>Tom Fernandez</h3>
                                    <span className="position mb-2">Tango</span>
                                    <div className="faded">
                                        <p>
                                            I am an ambitious workaholic, but apart from that, pretty
                                            simple person.
                                        </p>
                                        <ul className="ftco-social text-center">
                                            <li class="ftco-animate"><a href="#"><span class="icon-telegram"></span></a></li>
                                            <li class="ftco-animate"><a href="#"><span class="icon-instagram"></span></a></li>
                                            <li class="ftco-animate"><a href="#"><span class="icon-vk"></span></a></li>
                                            <li class="ftco-animate"><a href="#"><span class="icon-youtube"></span></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-md-6 col-lg-3 ftco-animate">
                            <div className="staff">
                                <div className="img-wrap d-flex align-items-stretch">
                                    <div
                                        className="img align-self-stretch"
                                        style={{ backgroundImage: "url(assets/img/foxmaster/teacher-6.jpg)" }}
                                    />
                                </div>
                                <div className="text pt-3 text-center">
                                    <h3>Mitch Parker</h3>
                                    <span className="position mb-2">Flamenco Teacher</span>
                                    <div className="faded">
                                        <p>
                                            I am an ambitious workaholic, but apart from that, pretty
                                            simple person.
                                        </p>
                                        <ul className="ftco-social text-center">
                                            <li class="ftco-animate"><a href="#"><span class="icon-telegram"></span></a></li>
                                            <li class="ftco-animate"><a href="#"><span class="icon-instagram"></span></a></li>
                                            <li class="ftco-animate"><a href="#"><span class="icon-vk"></span></a></li>
                                            <li class="ftco-animate"><a href="#"><span class="icon-youtube"></span></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 ftco-animate">
                            <div className="staff">
                                <div className="img-wrap d-flex align-items-stretch">
                                    <div
                                        className="img align-self-stretch"
                                        style={{ backgroundImage: "url(assets/img/webuni/authors/7.jpg)" }}
                                    />
                                </div>
                                <div className="text pt-3 text-center">
                                    <h3>Estela Martinez</h3>
                                    <span className="position mb-2">Kizomba Teacher</span>
                                    <div className="faded">
                                        <p>
                                            I am an ambitious workaholic, but apart from that, pretty
                                            simple person.
                                        </p>
                                        <ul className="ftco-social text-center">
                                            <li class="ftco-animate"><a href="#"><span class="icon-telegram"></span></a></li>
                                            <li class="ftco-animate"><a href="#"><span class="icon-instagram"></span></a></li>
                                            <li class="ftco-animate"><a href="#"><span class="icon-vk"></span></a></li>
                                            <li class="ftco-animate"><a href="#"><span class="icon-youtube"></span></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>






            </section>
            {/* </div> */}

            <Footer />


        </main>



    );

}

export default FoxTeacherDance;
