

import React, { useEffect, useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';


function MenuMobRu({ changeLanguage, navigateTo }) {
    const navigate = useNavigate();

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isMenuCollapsed, setMenuCollapsed] = useState(true);


    const handleToggleClick = () => {
        setMenuCollapsed(!isMenuCollapsed);

        const navbarCollapse = document.querySelector('.navbar-collapse');
        navbarCollapse.style.display = isMenuCollapsed ? 'block' : 'none';

        const subMenus = document.querySelectorAll('.sub-menuMob');
        subMenus.forEach((submenu) => {
            submenu.style.display = 'none';
        });
    };

    const handleSubMenuClick = (e) => {
        e.preventDefault();
        const submenu = e.target.nextElementSibling;

        const otherSubMenus = document.querySelectorAll('.sub-menuMob');
        otherSubMenus.forEach((otherMenu) => {
            if (otherMenu !== submenu) {
                otherMenu.style.display = 'none';
            }
        });

        if (!isMenuCollapsed && submenu) {
            submenu.style.display = submenu.style.display === 'block' ? 'none' : 'block';
        }
    };

    //Colapsar al hacer clic en enlaces
    const handleNavLinkClick = () => {
        setMenuCollapsed(true);

    };

    const handleResize = () => {
        setWindowWidth(window.innerWidth);

        if (window.innerWidth > 750) {
            setMenuCollapsed(true);
        }
    };

    useEffect(() => {
        const resizeHandler = () => {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(handleResize, 100);
        };

        let resizeTimer;

        window.addEventListener('resize', resizeHandler);

        return () => {
            window.removeEventListener('resize', resizeHandler);
        };
    }, []);

    useEffect(() => {
        handleResize();
    }, [windowWidth]);

    useEffect(() => {
        // Asegurar que el menú esté colapsado al renderizar
        const navbarCollapse = document.querySelector('.navbar-collapse');
        navbarCollapse.style.display = isMenuCollapsed ? 'none' : 'block';

        const subMenus = document.querySelectorAll('.sub-menuMob');
        subMenus.forEach((submenu) => {
            submenu.style.display = 'none';
        });
    }, [isMenuCollapsed]);

    /* 00000000000000 Move var 0000000000000000000  */

    const [startY, setStartY] = useState(0);
    const [visible, setVisible] = useState(true);

    const handleTouchStart = (e) => {
        setStartY(e.touches[0].clientY);
    };

    const handleTouchMove = (e) => {
        const currentY = e.touches[0].clientY;
        if (startY < currentY && !visible) {
            setVisible(true);
        } else if (startY > currentY && visible) {
            setVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener('touchstart', handleTouchStart);
        window.addEventListener('touchmove', handleTouchMove);

        return () => {
            window.removeEventListener('touchstart', handleTouchStart);
            window.removeEventListener('touchmove', handleTouchMove);
        };
    }, [startY, visible]);


    return (

        <main>

            {/*================= TOPBARMOB =================*/}

            {/*  <div className='topbarmob'> */}
            <div className={`topbarmob ${visible ? 'visible' : 'hidden'}`}>
                {/* <div style={{ display: 'flex', position: 'fixed', width: '100%' ,  justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#333', color: 'white', padding: '10px 20px 5px' }}> */}
                <Link to=""  > <span style={{ color: 'white', fontWeight: 'bold' }}> Москва </span> </Link>
                {/*  <div style={{ fontSize: '18px' }}> Moscow </div> */}

                <div style={{ fontSize: '20px' }}>
                    <Link to="https://www.youtube.com/OrbitRussia" target="_blank"> <i className="fab fa-youtube" style={{ color: 'white', marginRight: '10px' }} ></i> </Link>
                    <Link to="https://instagram.com/latinox.club" target="_blank"> <i className="fab fa-instagram" style={{ color: 'white', marginRight: '10px' }} ></i> </Link>
                    <Link to="https://t.me/latinoxclub" target="_blank"> <i className="fab fa-telegram" style={{ color: 'white', marginRight: '10px' }} ></i> </Link>
                    <Link to="https://vk.com/latinox.club" target="_blank"> <i className="fab fa-vk" style={{ color: 'white' }} ></i> </Link>
                </div>

                <div style={{ fontSize: '16px' }}>

                    <span role="img" aria-label="language"></span>
                    <select onChange={(e) => changeLanguage(e.target.value)}>
                        <option value="ru">Ру</option>
                        <option value="es">Es</option>
                        <option value="en">En</option>
                    </select>
                </div>
            </div>

            {/*================= main bar =================*/}

            {/* <div className='main-wrapper' > */}
            <div className={`main-wrapper ${visible ? 'visible' : 'hidden'}`}>
                {/* <nav className={`navbarmob ${touched ? 'visible' : 'hidden'}`}>
 */}
                {/* <nav className="navbarmob"> */}
                <nav className={`navbarmob ${visible ? 'visible' : 'hidden'}`}>

                    <div className="brand-and-icon">
                        {/* <Link to="/" className="navbar-brand"> LATINO</Link> */}
                        < Link to="/" className="navbar-brand">
                            <span style={{ color: 'white' }}>LATINO</span>
                            <span style={{ color: 'red' }}>X</span>
                            <span style={{ color: '#0095d0' }}>CLUB</span>
                        </Link>

                        <button type="button" className="navbar-toggler" onClick={handleToggleClick}>
                            <i className="fas fa-bars" />
                        </button>

                    </div>

                    <div className="navbar-collapse">
                        <ul className="navbar-navmob">

                            <li>  <amob href="/" onClick={handleSubMenuClick}> Главная </amob> </li>


                            {/*================= 2 BAILES =================*/}
                            <li>
                                <amob href="#" className="menu-link" onClick={handleSubMenuClick}>
                                    Танцы
                                    <span className="drop-icon">
                                        <i className="fas fa-chevron-down" />
                                    </span>
                                </amob>
                                <div className="sub-menuMob">
                                    <div className="sub-menuMob-item">
                                        {/* <h4>Топ Категории</h4> */}
                                        <ul>
                                            <li> <Link to="/events-dance" onClick={() => handleNavLinkClick()}> Латино Вечеринки </Link> </li>
                                            <li> <Link to="/schools-dance" onClick={() => handleNavLinkClick()}> Танцевальные Школы </Link> </li>
                                            <li> <Link to="/teachers-dance" onClick={() => handleNavLinkClick()}> Преподаватели Танцев </Link> </li>
                                            <li> <Link to="/video-events" onClick={() => handleNavLinkClick()}> Видео </Link> </li>
                                            <li> <Link to="/photos" onClick={() => handleNavLinkClick()}> Фото </Link> </li>
                                        </ul>
                                    </div>

                                    <div className="sub-menuMob-item">
                                        <h2>Почувствуй латинскую страсть</h2>
                                        <div className="imagemob">
                                            <img
                                                src="/assets/img/gif/dance.gif"
                                                alt="Dance image"
                                            /> </div>

                                        <button type="button" className="btn btn-sm btn-primary">
                                            <Link to="/video-events" onClick={() => handleNavLinkClick()}> Все Видео! </Link>
                                        </button>

                                    </div>

                                </div>
                            </li>


                            {/*=============== 3 Restaurantes =================*/}

                            <li>
                                <amob href="#" className="menu-link" onClick={handleSubMenuClick}>
                                    Рестораны
                                    <span className="drop-icon">
                                        <i className="fas fa-chevron-down" />
                                    </span>
                                </amob>
                                <div className="sub-menuMob">
                                    {/* item */}
                                    <div className="sub-menuMob-item">
                                        {/*  <h4>Топ Категории</h4> */}
                                        <ul>
                                            <li><Link to="/restaurants" onClick={() => handleNavLinkClick()}> Лучшие латиноамериканские рестораны </Link></li>
                                            <li> <amob href="#">Клубы и пабы</amob> </li>
                                            <li> <amob href="#">Скидки и Акции</amob> </li>
                                            <li> <amob href="#">Рекомендуемые</amob> </li>
                                        </ul>
                                    </div>

                                    <div className="sub-menuMob-item">
                                        <h2>Восхитительный Фьюжн Вкусов</h2>
                                        <div className="imagemob">
                                            <img
                                                src="/assets/img/restaurant/foodie-food.gif"
                                                alt="product image"
                                            /> </div>

                                        <button type="button" className="btn">
                                            <Link to="/restaurants" onClick={() => handleNavLinkClick()}> Попробуй! </Link>
                                        </button>
                                    </div>

                                </div>
                            </li>

                            {/*=============== 4 IDIOMAS ====================*/}
                            <li>
                                <amob href="#" className="menu-link" onClick={handleSubMenuClick}>
                                    Испанский язык
                                    <span className="drop-icon">
                                        <i className="fas fa-chevron-down" />
                                    </span>
                                </amob>
                                <div className="sub-menuMob">
                                    {/* item */}
                                    <div className="sub-menuMob-item">
                                        {/*  <h4>Топ Категории </h4> */}
                                        <ul>
                                            <li> <Link to="/schools-spanish" onClick={() => handleNavLinkClick()}>  Школы испанского языка </Link> </li>
                                            <li> <Link to="/teachers-spanish" onClick={() => handleNavLinkClick()}> Преподаватели испанского языка </Link> </li>
                                            <li>  <Link to="/events-spanish" onClick={() => handleNavLinkClick()}> Культурные мероприятия </Link> </li>
                                            <li>  <Link to="/spanish-courses" onClick={() => handleNavLinkClick()}> Лингвистическое погружение </Link> </li>
                                            <li>  <amob href="#">Переводы и документация</amob> </li>
                                        </ul>
                                    </div>

                                    <div className="sub-menuMob-item">
                                        <h2> Второй по распространенности язык в мире </h2>
                                        <div className="imagemob">
                                            <img
                                                src="/assets/img/language/hispanidad.gif"
                                                alt="product image"
                                            />
                                        </div>

                                        <button type="button" className="btn">
                                            <Link to="/teachers-spanish" onClick={() => handleNavLinkClick()}> Говорим по-испански! </Link>
                                        </button>
                                    </div>

                                </div>
                            </li>

                            {/*=============== 5 LOOK====================*/}

                            <li>
                                <amob href="#" className="menu-link" onClick={handleSubMenuClick}>
                                    Магазины
                                    <span className="drop-icon">
                                        <i className="fas fa-chevron-down" />
                                    </span>
                                </amob>
                                <div className="sub-menuMob">

                                    <div className="sub-menuMob-item">
                                        {/*  <h4>Топ Категории</h4> */}
                                        <ul>
                                            <li><Link to="/store" onClick={() => handleNavLinkClick()}> Мода и красота</Link></li>
                                            <li><Link to="/store" onClick={() => handleNavLinkClick()}> Я Латин❤️ </Link></li>
                                            <li><Link to="/store" onClick={() => handleNavLinkClick()}> Товары </Link></li>
                                            <li><Link to="/store" onClick={() => handleNavLinkClick()}> Ярмарки и выставки </Link></li>
                                            <li><Link to="/store" onClick={() => handleNavLinkClick()}> Скидки и акции </Link></li>
                                        </ul>
                                    </div>

                                    <div className="sub-menuMob-item">
                                        <h2> Эксклюзивный стиль без ограничений </h2>
                                        <div className="imagemob">
                                            <img
                                                src="/assets/img/gif/store-peru.gif"
                                                alt="product image"
                                            /> </div>

                                        <button type="button" className="btn">
                                            <Link to="/store" onClick={() => handleNavLinkClick()}>  Это Тебе! </Link>
                                        </button>

                                    </div>


                                </div>
                            </li>

                            {/*=============== 6 ACTUALIDAD ===================*/}

                            <li>
                                <amob href="#" className="menu-link" onClick={handleSubMenuClick}>
                                    Сообщество
                                    <span className="drop-icon">
                                        <i className="fas fa-chevron-down" />
                                    </span>
                                </amob>
                                <div className="sub-menuMob">
                                    {/* item */}
                                    <div className="sub-menuMob-item">
                                        {/*  <h4>Топ Категории</h4> */}
                                        <ul>
                                            <li> <amob href="#">Посольства</amob> </li>
                                            <li> <amob href="#">Визы в Испанию и Латинскую Америку</amob> </li>
                                            <li> <amob href="#">Бизнес</amob> </li>
                                            <li> <amob href="#">Доставка и Переводы</amob> </li>
                                            <li> <amob href="#"> У МЕНЯ ЕСТЬ ПЛАН</amob> </li>

                                        </ul>
                                    </div>
                                    {/* end of item */}

                                    {/* item */}
                                    <div className="sub-menuMob-item">
                                        <h2>Исследуй мир и живи</h2>
                                        <div className="imagemob">
                                            <img
                                                src="/assets/img/gif/dominican-republic.gif"
                                                alt="product image"
                                            /> </div>

                                        <button type="button" className="btn">
                                            <Link to="/new-year-Latam" onClick={() => handleNavLinkClick()}> Путешествуем! </Link>
                                        </button>
                                    </div>

                                </div>
                            </li>

                            {/*=============== 7 ABOUT ===================*/}

                            <li>
                                <amob href="#" className="menu-link" onClick={handleSubMenuClick}>
                                    ПРИВЕТ
                                    <span className="drop-icon">
                                        <i className="fas fa-chevron-down" />
                                    </span>
                                </amob>
                                <div className="sub-menuMob">
                                    {/* item */}
                                    <div className="sub-menuMob-item">
                                        {/*  <h4>Топ Категории</h4> */}
                                        <ul>
                                            <li>
                                                <amob href="#">КЛУБ - Членство</amob>
                                            </li>
                                            <li>
                                                <amob href="#">Поддержи нас</amob>
                                            </li>
                                            <li>
                                                <amob href="#">Цифровые решения</amob>
                                            </li>
                                            <li>
                                                <amob href="#"> О Нас </amob>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="sub-menuMob-item">
                                        <h2>Латино сообщество</h2>
                                        <div className="imagemob">
                                            <img
                                                src="/assets/img/gif/latino-flags.gif"
                                                alt="product image"
                                            /> </div>

                                        <button type="button" className="btn">
                                            <Link to="/events-dance" onClick={() => handleNavLinkClick()}>  Спасибо! </Link>
                                        </button>
                                    </div>

                                </div>
                            </li>

                            {/*=============== 6 OFERTAS ====================*/}

                            <li>
                                <amob href="#" onClick={handleSubMenuClick} >Foro latino</amob>
                            </li>
                        </ul>
                    </div>

                </nav>
            </div>

            {/*  </div> */}

        </main>

    );
}

export default MenuMobRu;