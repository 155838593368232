


import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import MenuMobEn from './MenuMobEn';
import MenuDeskEn from './MenuDeskEn';


const MenuEn = ( { changeLanguage, navigateTo }) => {
   
  
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  useEffect(() => {
    const handleTouchStart = () => {
      setIsTouchDevice(true);
    };

    const handleMouseDown = () => {
      setIsTouchDevice(false);
    };

    // Agrega los listeners de eventos
    window.addEventListener('touchstart', handleTouchStart);
    window.addEventListener('mousedown', handleMouseDown);

    // Limpia los listeners cuando el componente se desmonta
    return () => {
      window.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);

  const shouldUseMobileMenu = isMobile   && window.innerWidth <= 750;

  /* const shouldUseMobileMenu = isMobile || (isTouchDevice && window.innerWidth <= 750); */

  return (
    <div>
      {/* {shouldUseMobileMenu ?  <MenuMobEn setCurrentLang={setCurrentLang} />  :  <MenuDeskEn setCurrentLang={setCurrentLang}/> }
        */}
        {shouldUseMobileMenu ?  <MenuMobEn changeLanguage={changeLanguage}  />  :  <MenuDeskEn  changeLanguage={changeLanguage} /> }
       
    </div>
  );
};
 

export default MenuEn;