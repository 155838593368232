


/* tsteit */

import Footer from "./FooterEs";
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';



function RestaHome() {

  const homeSliderOptions = {
    loop: true,
    autoplay: true,
    margin: 0,
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
    nav: true,
    dots: true,
    autoplayHoverPause: false,
    items: 1,
    navText: [
      "<span class='ion-ios-arrow-back'></span>",
      "<span class='ion-ios-arrow-forward'></span>"
    ],
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 1
      },
      1000: {
        items: 1
      }
    }
  };

  const carouselTestimonyOptions = {
    center: true,
    loop: true,
    items: 1,
    margin: 30,
    stagePadding: 0,
    nav: false,
    navText: ['<span class="ion-ios-arrow-back">', '<span class="ion-ios-arrow-forward">'],
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 1
      },
      1000: {
        items: 1
      }
    }
  };

  return (

    <main>


       <section className="hero-wrap portada-rest-orbit">
        {/* <div ref={homeSliderRef}  className="home-slider owl-carousel js-fullheight"> */}
        <OwlCarousel className="home-slider owl-carousel js-fullheight"  {...homeSliderOptions}>




          {/* Item Slider Lima*/}
          <div
            className="slider-item js-fullheight"
            /* className="slider-item " */
            style={{ backgroundImage: 'url("/assets/img/restaurant/food/cebiche-peru-2.jpg")' }}
          >
            <div className="overlay" />
            <div className="container">
              <div className="row no-gutters slider-text js-fullheight align-items-center justify-content-center">
                <div className="col-md-12 ftco-animate">
                  <div className="text w-100 mt-5 text-center">
                    <span className="subheading">Peruvian Food</span>
                    <h1>Lima </h1>
                    <span className="subheading-2 sub">M. Belorusskaya</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Item Slider Idilio */}
          <div
            className="slider-item js-fullheight"
            /* className="slider-item " */
            style={{ backgroundImage: 'url("/assets/img/restaurant/food/lomo-saltado-7.jpg")' }}
          >
            <div className="overlay" />
            <div className="container">
              <div className="row no-gutters slider-text js-fullheight align-items-center justify-content-center">
                <div className="col-md-12 ftco-animate">
                  <div className="text w-100 mt-5 text-center">
                    <span className="subheading">Peruvian, Mexican & Spanish Food</span>
                    <h1>El Idilio </h1>
                    <span className="subheading-2 sub">M. Mayakovskaya</span>
                    {/* <span className="subheading-2 sub">М. Маяковская</span> 
                    Эль Идилио
                    */}


                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Item Slider Casa Agave */}
          <div
            className="slider-item js-fullheight"
            /* className="slider-item " */
            style={{ backgroundImage: 'url("/assets/img/restaurant/food/mexican-food.png")' }}
          >
            <div className="overlay" />
            <div className="container">
              <div className="row no-gutters slider-text js-fullheight align-items-center justify-content-center">
                <div className="col-md-12 ftco-animate">
                  <div className="text w-100 mt-5 text-center">
                    <span className="subheading">Peruvian & Mexican Food</span>
                    <h1>Casa Agave</h1>
                    <span className="subheading-2 sub">M. Kitay-Gorod</span>
                  </div>
                </div>
              </div>
            </div>
          </div>


          {/* Item Slider Villa */}
          <div
            className="slider-item js-fullheight"
            style={{ backgroundImage: 'url("/assets/img/restaurant/food/mexicana-com.png")' }}
          >
            <div className="overlay" />
            <div className="container">
              <div className="row no-gutters slider-text js-fullheight align-items-center justify-content-center">
                <div className="col-md-12 ftco-animate">
                  <div className="text w-100 mt-5 text-center">
                    <span className="subheading"> Mexican Food</span>
                    <h1> Pancho Villa </h1>
                    <span className="subheading-2 sub">M. Oktyabrskaya</span>
                    {/* <span className="subheading-2 sub">м. Октябрьская</span>
                     https://panchovilla.ru/                 
                    */}


                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Item Slider Dionis */}
          <div
            className="slider-item js-fullheight"
            style={{ backgroundImage: 'url("/assets/img/restaurant/food/interview-ceviche.jpg")' }}
          >
            <div className="overlay" />
            <div className="container">
              <div className="row no-gutters slider-text js-fullheight align-items-center justify-content-center">
                <div className="col-md-12 ftco-animate">
                  <div className="text w-100 mt-5 text-center">
                    <span className="subheading"> Peruvian & Hindu Food</span>
                    <h1> Dionis </h1>
                    <span className="subheading-2 sub">M. Yugo-Zapadnaya</span>
                    {/* <span className="subheading-2 sub">м. Юго-Западная</span>
                     https://dioniscafe.ru/                 
                    */}


                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Item Slider Liberty */}
          <div
            className="slider-item js-fullheight"
            style={{ backgroundImage: 'url("/assets/img/restaurant/food/comida-mexicana-1.jpg")' }}
          >
            <div className="overlay" />
            <div className="container">
              <div className="row no-gutters slider-text js-fullheight align-items-center justify-content-center">
                <div className="col-md-12 ftco-animate">
                  <div className="text w-100 mt-5 text-center">
                    <span className="subheading"> Cuban Food</span>
                    <h1> Liberty </h1>
                    <span className="subheading-2 sub"> M. Kitay-Gorod</span>
                    {/* <span className="subheading-2 sub">м. Китай-город</span>
                     https://clubliberty.ru/                
                    */}


                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Item Slider Mama Juana */}
          <div
            className="slider-item js-fullheight"
            /* className="slider-item " */
            style={{ backgroundImage: 'url("/assets/img/restaurant/food/ceviche-peruano-4.jpeg")' }}
          >
            <div className="overlay" />
            <div className="container">
              <div className="row no-gutters slider-text js-fullheight align-items-center justify-content-center">
                <div className="col-md-12 ftco-animate">
                  <div className="text w-100 mt-5 text-center">
                    <span className="subheading">Spanish & Latinoamerican Food</span>
                    <h1>Mama Juana </h1>
                    <span className="subheading-2 sub">M. Mayakovskaya</span>
                    {/* <span className="subheading-2 sub">М. Маяковская</span>
                    https://mamajuana-restaurant.ru/
                    
                    */}


                  </div>
                </div>
              </div>
            </div>
          </div>


          {/* Item Slider */}
          <div
            className="slider-item js-fullheight"
            style={{ backgroundImage: 'url("/assets/img/restaurant/food/paella-mixta.jpg")' }}
          >
            <div className="overlay" />
            <div className="container">
              <div className="row no-gutters slider-text js-fullheight align-items-center justify-content-center">
                <div className="col-md-12 ftco-animate">
                  <div className="text w-100 mt-5 text-center">
                    <span className="subheading"> Latinoamerican Food</span>
                    <h1>Aruba </h1>
                    <span className="subheading-2 sub">M. Taganskaya</span>
                    {/* <span className="subheading-2 sub">м. Таганская</span>
                     https://arubarestoran.ru/                    
                    */}


                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Item Slider */}
          <div
            className="slider-item js-fullheight"
            style={{ backgroundImage: 'url("/assets/img/restaurant/food/ceviche-caliente.jpg")' }}
          >
            <div className="overlay" />
            <div className="container">
              <div className="row no-gutters slider-text js-fullheight align-items-center justify-content-center">
                <div className="col-md-12 ftco-animate">
                  <div className="text w-100 mt-5 text-center">
                    <span className="subheading"> Latinoamerican Food</span>
                    <h1> MAYA </h1>
                    <span className="subheading-2 sub">M. Arbatskaya</span>
                    {/* <span className="subheading-2 sub">м. Арбатская</span>
                     https://maya.lucky-group.rest/                  
                    */}


                  </div>
                </div>
              </div>
            </div>
          </div>








          {/* Item Slider */}
          <div
            className="slider-item js-fullheight"
            /* className="slider-item " */

            style={{ backgroundImage: 'url("/assets/img/tsteit/bg_1.jpg")' }}
          >
            <div className="overlay" />
            <div className="container">
              <div className="row no-gutters slider-text js-fullheight align-items-center justify-content-center">
                <div className="col-md-12 ftco-animate">
                  <div className="text w-100 mt-5 text-center">
                    <span className="subheading">Taste.Latino Restaurant</span>
                    <h1>Cooking Orbit</h1>
                    <span className="subheading-2">2024</span>
                  </div>
                </div>
              </div>
            </div>
          </div>





        </OwlCarousel>
      </section>


      <section className="ftco-section ftco-no-pt ftco-no-pb ">
        <div className="container">
          <div className="row d-flex">
            <div className="col-md-6 d-flex">
              <div
                className="img img-2 w-100 mr-md-2"
                style={{ backgroundImage: "url(/assets/img/tsteit/bg_6.jpg)" }}
              />
              <div
                className="img img-2 w-100 ml-md-2"

                style={{ backgroundImage: 'url("/assets/img/tsteit/bg_4.jpg")' }}
              />
            </div>


            <div className="col-md-6 ftco-animate makereservation p-4 p-md-5">
              <div className="heading-section ftco-animate mb-5">
                <span className="subheading">This is our secrets</span>
                <h2 className="mb-4">Perfect Ingredients</h2>
                <p>
                  Far far away, behind the word mountains, far from the countries
                  Vokalia and Consonantia, there live the blind texts. Separated
                  they live in Bookmarksgrove right at the coast of the Semantics, a
                  large language ocean.
                </p>
                <p>
                  <a href="#" className="btn btn-primary">
                    Learn more
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>





      <section
        className="ftco-section ftco-intro"
        /* style={{ backgroundImage: "url(/assets/img/tsteit/bg_3.jpg)" }} */
        style={{ backgroundImage: 'url("/assets/img/tsteit/bg_3.jpg")' }}
      >
        <div className="overlay" />
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <span>Now Booking</span>
              <h2>Private Dinners &amp; Happy Hours</h2>
            </div>
          </div>
        </div>
      </section>
      <section className="ftco-section">
        <div className="container">
          <div className="row justify-content-center mb-5 pb-2">
            <div className="col-md-7 text-center heading-section ftco-animate">
              <span className="subheading">Specialties</span>
              <h2 className="mb-4">Our Menu</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-4">
              <div className="menu-wrap">
                <div className="heading-menu text-center ftco-animate">
                  <h3>Breakfast</h3>
                </div>
                <div className="menus d-flex ftco-animate">
                  <div
                    className="menu-img img"

                    style={{ backgroundImage: 'url("/assets/img/tsteit/breakfast-1.jpg")' }}
                  />
                  <div className="text">
                    <div className="d-flex">
                      <div className="one-half">
                        <h3>Beef Roast Source</h3>
                      </div>
                      <div className="one-forth">
                        <span className="price">$29</span>
                      </div>
                    </div>
                    <p>
                      <span>Meat</span>, <span>Potatoes</span>, <span>Rice</span>,{" "}
                      <span>Tomatoe</span>
                    </p>
                  </div>
                </div>
                <div className="menus d-flex ftco-animate">
                  <div
                    className="menu-img img"

                    style={{ backgroundImage: 'url("/assets/img/tsteit/breakfast-2.jpg")' }}
                  />
                  <div className="text">
                    <div className="d-flex">
                      <div className="one-half">
                        <h3>Beef Roast Source</h3>
                      </div>
                      <div className="one-forth">
                        <span className="price">$29</span>
                      </div>
                    </div>
                    <p>
                      <span>Meat</span>, <span>Potatoes</span>, <span>Rice</span>,{" "}
                      <span>Tomatoe</span>
                    </p>
                  </div>
                </div>
                <div className="menus border-bottom-0 d-flex ftco-animate">
                  <div
                    className="menu-img img"

                    style={{ backgroundImage: 'url("/assets/img/tsteit/breakfast-3.jpg")' }}
                  />
                  <div className="text">
                    <div className="d-flex">
                      <div className="one-half">
                        <h3>Beef Roast Source</h3>
                      </div>
                      <div className="one-forth">
                        <span className="price">$29</span>
                      </div>
                    </div>
                    <p>
                      <span>Meat</span>, <span>Potatoes</span>, <span>Rice</span>,{" "}
                      <span>Tomatoe</span>
                    </p>
                  </div>
                </div>
                <span className="flat flaticon-bread" style={{ left: 0 }} />
                <span className="flat flaticon-breakfast" style={{ right: 0 }} />
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="menu-wrap">
                <div className="heading-menu text-center ftco-animate">
                  <h3>Lunch</h3>
                </div>
                <div className="menus d-flex ftco-animate">
                  <div
                    className="menu-img img"
                    /* style={{ backgroundImage: "url(/assets/img/tsteit/lunch-1.jpg)" }} */
                    style={{ backgroundImage: 'url("/assets/img/tsteit/lunch-1.jpg")' }}
                  />
                  <div className="text">
                    <div className="d-flex">
                      <div className="one-half">
                        <h3>Beef Roast Source</h3>
                      </div>
                      <div className="one-forth">
                        <span className="price">$29</span>
                      </div>
                    </div>
                    <p>
                      <span>Meat</span>, <span>Potatoes</span>, <span>Rice</span>,{" "}
                      <span>Tomatoe</span>
                    </p>
                  </div>
                </div>
                <div className="menus d-flex ftco-animate">
                  <div
                    className="menu-img img"
                    /* style={{ backgroundImage: "url(/assets/img/tsteit/lunch-2.jpg)" }} */
                    style={{ backgroundImage: 'url("/assets/img/tsteit/lunch-2.jpg")' }}
                  />
                  <div className="text">
                    <div className="d-flex">
                      <div className="one-half">
                        <h3>Beef Roast Source</h3>
                      </div>
                      <div className="one-forth">
                        <span className="price">$29</span>
                      </div>
                    </div>
                    <p>
                      <span>Meat</span>, <span>Potatoes</span>, <span>Rice</span>,{" "}
                      <span>Tomatoe</span>
                    </p>
                  </div>
                </div>
                <div className="menus border-bottom-0 d-flex ftco-animate">
                  <div
                    className="menu-img img"
                    /* style={{ backgroundImage: "url(/assets/img/tsteit/lunch-3.jpg)" }} */
                    style={{ backgroundImage: 'url("/assets/img/tsteit/lunch-3.jpg")' }}
                  />
                  <div className="text">
                    <div className="d-flex">
                      <div className="one-half">
                        <h3>Beef Roast Source</h3>
                      </div>
                      <div className="one-forth">
                        <span className="price">$29</span>
                      </div>
                    </div>
                    <p>
                      <span>Meat</span>, <span>Potatoes</span>, <span>Rice</span>,{" "}
                      <span>Tomatoe</span>
                    </p>
                  </div>
                </div>
                <span className="flat flaticon-pizza" style={{ left: 0 }} />
                <span className="flat flaticon-chicken" style={{ right: 0 }} />
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="menu-wrap">
                <div className="heading-menu text-center ftco-animate">
                  <h3>Dinner</h3>
                </div>
                <div className="menus d-flex ftco-animate">
                  <div
                    className="menu-img img"
                    /* style={{ backgroundImage: "url(/assets/img/tsteit/dinner-1.jpg)" }} */
                    style={{ backgroundImage: 'url("/assets/img/tsteit/dinner-1.jpg")' }}
                  />
                  <div className="text">
                    <div className="d-flex">
                      <div className="one-half">
                        <h3>Beef Roast Source</h3>
                      </div>
                      <div className="one-forth">
                        <span className="price">$29</span>
                      </div>
                    </div>
                    <p>
                      <span>Meat</span>, <span>Potatoes</span>, <span>Rice</span>,{" "}
                      <span>Tomatoe</span>
                    </p>
                  </div>
                </div>
                <div className="menus d-flex ftco-animate">
                  <div
                    className="menu-img img"
                    /* style={{ backgroundImage: "url(/assets/img/tsteit/dinner-2.jpg)" }} */
                    style={{ backgroundImage: 'url("/assets/img/tsteit/dinner-2.jpg")' }}
                  />
                  <div className="text">
                    <div className="d-flex">
                      <div className="one-half">
                        <h3>Beef Roast Source</h3>
                      </div>
                      <div className="one-forth">
                        <span className="price">$29</span>
                      </div>
                    </div>
                    <p>
                      <span>Meat</span>, <span>Potatoes</span>, <span>Rice</span>,{" "}
                      <span>Tomatoe</span>
                    </p>
                  </div>
                </div>
                <div className="menus border-bottom-0 d-flex ftco-animate">
                  <div
                    className="menu-img img"
                    /* style={{ backgroundImage: "url(/assets/img/tsteit/dinner-3.jpg)" }} */
                    style={{ backgroundImage: 'url("/assets/img/tsteit/dinner-3.jpg")' }}
                  />
                  <div className="text">
                    <div className="d-flex">
                      <div className="one-half">
                        <h3>Beef Roast Source</h3>
                      </div>
                      <div className="one-forth">
                        <span className="price">$29</span>
                      </div>
                    </div>
                    <p>
                      <span>Meat</span>, <span>Potatoes</span>, <span>Rice</span>,{" "}
                      <span>Tomatoe</span>
                    </p>
                  </div>
                </div>
                <span className="flat flaticon-omelette" style={{ left: 0 }} />
                <span className="flat flaticon-burger" style={{ right: 0 }} />
              </div>
            </div>
            {/*  */}
            <div className="col-md-6 col-lg-4">
              <div className="menu-wrap">
                <div className="heading-menu text-center ftco-animate">
                  <h3>Desserts</h3>
                </div>
                <div className="menus d-flex ftco-animate">
                  <div
                    className="menu-img img"
                    /* style={{ backgroundImage: "url(/assets/img/tsteit/dessert-1.jpg)" }} */
                    style={{ backgroundImage: 'url("/assets/img/tsteit/dessert-1.jpg")' }}
                  />
                  <div className="text">
                    <div className="d-flex">
                      <div className="one-half">
                        <h3>Beef Roast Source</h3>
                      </div>
                      <div className="one-forth">
                        <span className="price">$29</span>
                      </div>
                    </div>
                    <p>
                      <span>Meat</span>, <span>Potatoes</span>, <span>Rice</span>,{" "}
                      <span>Tomatoe</span>
                    </p>
                  </div>
                </div>
                <div className="menus d-flex ftco-animate">
                  <div
                    className="menu-img img"
                    /* style={{ backgroundImage: "url(/assets/img/tsteit/dessert-2.jpg)" }} */
                    style={{ backgroundImage: 'url("/assets/img/tsteit/dessert-2.jpg")' }}
                  />
                  <div className="text">
                    <div className="d-flex">
                      <div className="one-half">
                        <h3>Beef Roast Source</h3>
                      </div>
                      <div className="one-forth">
                        <span className="price">$29</span>
                      </div>
                    </div>
                    <p>
                      <span>Meat</span>, <span>Potatoes</span>, <span>Rice</span>,{" "}
                      <span>Tomatoe</span>
                    </p>
                  </div>
                </div>
                <div className="menus border-bottom-0 d-flex ftco-animate">
                  <div
                    className="menu-img img"
                    /* style={{ backgroundImage: "url(/assets/img/tsteit/dessert-3.jpg)" }} */
                    style={{ backgroundImage: 'url("/assets/img/tsteit/dessert-3.jpg")' }}
                  />
                  <div className="text">
                    <div className="d-flex">
                      <div className="one-half">
                        <h3>Beef Roast Source</h3>
                      </div>
                      <div className="one-forth">
                        <span className="price">$29</span>
                      </div>
                    </div>
                    <p>
                      <span>Meat</span>, <span>Potatoes</span>, <span>Rice</span>,{" "}
                      <span>Tomatoe</span>
                    </p>
                  </div>
                </div>
                <span className="flat flaticon-cupcake" style={{ left: 0 }} />
                <span className="flat flaticon-ice-cream" style={{ right: 0 }} />
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="menu-wrap">
                <div className="heading-menu text-center ftco-animate">
                  <h3>Wine Card</h3>
                </div>
                <div className="menus d-flex ftco-animate">
                  <div
                    className="menu-img img"
                    /* style={{ backgroundImage: "url(/assets/img/tsteit/wine-1.jpg)" }} */
                    style={{ backgroundImage: 'url("/assets/img/tsteit/wine-1.jpg")' }}
                  />
                  <div className="text">
                    <div className="d-flex">
                      <div className="one-half">
                        <h3>Beef Roast Source</h3>
                      </div>
                      <div className="one-forth">
                        <span className="price">$29</span>
                      </div>
                    </div>
                    <p>
                      <span>Meat</span>, <span>Potatoes</span>, <span>Rice</span>,{" "}
                      <span>Tomatoe</span>
                    </p>
                  </div>
                </div>
                <div className="menus d-flex ftco-animate">
                  <div
                    className="menu-img img"
                    /* style={{ backgroundImage: "url(/assets/img/tsteit/wine-2.jpg)" }} */
                    style={{ backgroundImage: 'url("/assets/img/tsteit/wine-2.jpg")' }}
                  />
                  <div className="text">
                    <div className="d-flex">
                      <div className="one-half">
                        <h3>Beef Roast Source</h3>
                      </div>
                      <div className="one-forth">
                        <span className="price">$29</span>
                      </div>
                    </div>
                    <p>
                      <span>Meat</span>, <span>Potatoes</span>, <span>Rice</span>,{" "}
                      <span>Tomatoe</span>
                    </p>
                  </div>
                </div>
                <div className="menus border-bottom-0 d-flex ftco-animate">
                  <div
                    className="menu-img img"
                    /* style={{ backgroundImage: "url(/assets/img/tsteit/wine-3.jpg)" }} */
                    style={{ backgroundImage: 'url("/assets/img/tsteit/wine-3.jpg")' }}
                  />
                  <div className="text">
                    <div className="d-flex">
                      <div className="one-half">
                        <h3>Beef Roast Source</h3>
                      </div>
                      <div className="one-forth">
                        <span className="price">$29</span>
                      </div>
                    </div>
                    <p>
                      <span>Meat</span>, <span>Potatoes</span>, <span>Rice</span>,{" "}
                      <span>Tomatoe</span>
                    </p>
                  </div>
                </div>
                <span className="flat flaticon-wine" style={{ left: 0 }} />
                <span className="flat flaticon-wine-1" style={{ right: 0 }} />
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="menu-wrap">
                <div className="heading-menu text-center ftco-animate">
                  <h3>Drinks &amp; Tea</h3>
                </div>
                <div className="menus d-flex ftco-animate">
                  <div
                    className="menu-img img"
                    /*  style={{ backgroundImage: "url(/assets/img/tsteit/drink-1.jpg)" }} */
                    style={{ backgroundImage: 'url("/assets/img/tsteit/drink-1.jpg")' }}
                  />
                  <div className="text">
                    <div className="d-flex">
                      <div className="one-half">
                        <h3>Beef Roast Source</h3>
                      </div>
                      <div className="one-forth">
                        <span className="price">$29</span>
                      </div>
                    </div>
                    <p>
                      <span>Meat</span>, <span>Potatoes</span>, <span>Rice</span>,{" "}
                      <span>Tomatoe</span>
                    </p>
                  </div>
                </div>
                <div className="menus d-flex ftco-animate">
                  <div
                    className="menu-img img"
                    /* style={{ backgroundImage: "url(/assets/img/tsteit/drink-2.jpg)" }} */
                    style={{ backgroundImage: 'url("/assets/img/tsteit/drink-2.jpg")' }}

                  />
                  <div className="text">
                    <div className="d-flex">
                      <div className="one-half">
                        <h3>Beef Roast Source</h3>
                      </div>
                      <div className="one-forth">
                        <span className="price">$29</span>
                      </div>
                    </div>
                    <p>
                      <span>Meat</span>, <span>Potatoes</span>, <span>Rice</span>,{" "}
                      <span>Tomatoe</span>
                    </p>
                  </div>
                </div>
                <div className="menus border-bottom-0 d-flex ftco-animate">
                  <div
                    className="menu-img img"

                    style={{ backgroundImage: 'url("/assets/img/tsteit/drink-3.jpg")' }}
                  />
                  <div className="text">
                    <div className="d-flex">
                      <div className="one-half">
                        <h3>Beef Roast Source</h3>
                      </div>
                      <div className="one-forth">
                        <span className="price">$29</span>
                      </div>
                    </div>
                    <p>
                      <span>Meat</span>, <span>Potatoes</span>, <span>Rice</span>,{" "}
                      <span>Tomatoe</span>
                    </p>
                  </div>
                </div>
                <span className="flat flaticon-wine" style={{ left: 0 }} />
                <span className="flat flaticon-wine-1" style={{ right: 0 }} />
              </div>
            </div>
          </div>
        </div>
      </section>


      <section
        className="ftco-section testimony-section"

        style={{ backgroundImage: 'url("/assets/img/tsteit/bg_5.jpg")' }}
      >
        <div className="overlay" />
        <div className="container">
          <div className="row justify-content-center mb-3 pb-2">
            <div className="col-md-7 text-center heading-section heading-section-white ftco-animate">
              <span className="subheading">Testimony</span>
              <h2 className="mb-4">Happy Customer</h2>
            </div>
          </div>
          <div className="row ftco-animate justify-content-center">
            <div className="col-md-7">
              {/* <div  ref={carouselTestimonyRef} className="carousel-testimony owl-carousel ftco-owl"> */}
              <OwlCarousel className="carousel-testimony owl-carousel ftco-owl" {...carouselTestimonyOptions}>

                <div className="item">
                  <div className="testimony-wrap text-center">
                    <div className="text p-3">
                      <p className="mb-4">
                        Far far away, behind the word mountains, far from the
                        countries Vokalia and Consonantia, there live the blind
                        texts.
                      </p>
                      <div
                        className="user-img mb-4"
                        /* style={{ backgroundImage: "url(/assets/img/tsteit/person_1.jpg)" }} */
                        style={{ backgroundImage: 'url("/assets/img/tsteit/person_1.jpg")' }}
                      >
                        <span className="quote d-flex align-items-center justify-content-center">
                          <i className="fa fa-quote-left" />
                        </span>
                      </div>
                      <p className="name">John Gustavo</p>
                      <span className="position">Customer</span>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="testimony-wrap text-center">
                    <div className="text p-3">
                      <p className="mb-4">
                        Far far away, behind the word mountains, far from the
                        countries Vokalia and Consonantia, there live the blind
                        texts.
                      </p>
                      <div
                        className="user-img mb-4"
                        /* style={{ backgroundImage: "url(/assets/img/tsteit/person_1.jpg)" }} */
                        style={{ backgroundImage: 'url("/assets/img/tsteit/person_1.jpg")' }}
                      >
                        <span className="quote d-flex align-items-center justify-content-center">
                          <i className="fa fa-quote-left" />
                        </span>
                      </div>
                      <p className="name">John Gustavo</p>
                      <span className="position">Customer</span>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="testimony-wrap text-center">
                    <div className="text p-3">
                      <p className="mb-4">
                        Far far away, behind the word mountains, far from the
                        countries Vokalia and Consonantia, there live the blind
                        texts.
                      </p>
                      <div
                        className="user-img mb-4"
                        /* style={{ backgroundImage: "url(/assets/img/tsteit/person_1.jpg)" }} */
                        style={{ backgroundImage: 'url("/assets/img/tsteit/person_1.jpg")' }}
                      >
                        <span className="quote d-flex align-items-center justify-content-center">
                          <i className="fa fa-quote-left" />
                        </span>
                      </div>
                      <p className="name">John Gustavo</p>
                      <span className="position">Customer</span>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="testimony-wrap text-center">
                    <div className="text p-3">
                      <p className="mb-4">
                        Far far away, behind the word mountains, far from the
                        countries Vokalia and Consonantia, there live the blind
                        texts.
                      </p>
                      <div
                        className="user-img mb-4"
                        /* style={{ backgroundImage: "url(/assets/img/tsteit/person_1.jpg)" }} */
                        style={{ backgroundImage: 'url("/assets/img/tsteit/person_1.jpg")' }}
                      >
                        <span className="quote d-flex align-items-center justify-content-center">
                          <i className="fa fa-quote-left" />
                        </span>
                      </div>
                      <p className="name">John Gustavo</p>
                      <span className="position">Customer</span>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="testimony-wrap text-center">
                    <div className="text p-3">
                      <p className="mb-4">
                        Far far away, behind the word mountains, far from the
                        countries Vokalia and Consonantia, there live the blind
                        texts.
                      </p>
                      <div
                        className="user-img mb-4"
                        /* style={{ backgroundImage: "url(/assets/img/tsteit/person_1.jpg)" }} */
                        style={{ backgroundImage: 'url("/assets/img/tsteit/person_1.jpg")' }}
                      >
                        <span className="quote d-flex align-items-center justify-content-center">
                          <i className="fa fa-quote-left" />
                        </span>
                      </div>
                      <p className="name">John Gustavo</p>
                      <span className="position">Customer</span>
                    </div>
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>


      <section className="ftco-section bg-light">
        <div className="container">
          <div className="row justify-content-center mb-5 pb-2">
            <div className="col-md-7 text-center heading-section ftco-animate">
              <span className="subheading">Chef</span>
              <h2 className="mb-4">Our Master Chef</h2>
            </div>
          </div>

          <div className="row">

            <div className="col-md-6 col-lg-3 ftco-animate">
              <div className="staff">
                <div
                  className="img-chef"
                  style={{ backgroundImage: 'url("/assets/img/tsteit/chef-4.jpg")' }}
                />

                <div className="text px-4 pt-2">
                  <h3>John Gustavo</h3>
                  <span className="position mb-2">CEO, Co Founder</span>
                  <div className="faded">
                    <p>
                      I am an ambitious workaholic, but apart from that, pretty
                      simple person.
                    </p>
                    <ul className="ftco-social d-flex">
                      <li className="ftco-animate">
                        <a href="#">
                          <span className="icon-twitter" />
                        </a>
                      </li>
                      <li className="ftco-animate">
                        <a href="#">
                          <span className="icon-facebook" />
                        </a>
                      </li>
                      <li className="ftco-animate">
                        <a href="#">
                          <span className="icon-google-plus" />
                        </a>
                      </li>
                      <li className="ftco-animate">
                        <a href="#">
                          <span className="icon-instagram" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>


            <div className="col-md-6 col-lg-3 ftco-animate">
              <div className="staff">
                <div
                  className="img-chef"
                  /* style={{ backgroundImage: "url(/assets/img/tsteit/chef-2.jpg)" }} */
                  style={{ backgroundImage: 'url("/assets/img/tsteit/chef-2.jpg")' }}
                />
                <div className="text px-4 pt-2">
                  <h3>Michelle Fraulen</h3>
                  <span className="position mb-2">Head Chef</span>
                  <div className="faded">
                    <p>
                      I am an ambitious workaholic, but apart from that, pretty
                      simple person.
                    </p>
                    <ul className="ftco-social d-flex">
                      <li className="ftco-animate">
                        <a href="#">
                          <span className="icon-twitter" />
                        </a>
                      </li>
                      <li className="ftco-animate">
                        <a href="#">
                          <span className="icon-facebook" />
                        </a>
                      </li>
                      <li className="ftco-animate">
                        <a href="#">
                          <span className="icon-google-plus" />
                        </a>
                      </li>
                      <li className="ftco-animate">
                        <a href="#">
                          <span className="icon-instagram" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 ftco-animate">
              <div className="staff">
                <div
                  className="img-chef"
                  /* style={{ backgroundImage: "url(/assets/img/tsteit/chef-3.jpg)" }} */
                  style={{ backgroundImage: 'url("/assets/img/tsteit/chef-3.jpg")' }}

                />
                <div className="text px-4 pt-2">
                  <h3>Alfred Smith</h3>
                  <span className="position mb-2">Chef Cook</span>
                  <div className="faded">
                    <p>
                      I am an ambitious workaholic, but apart from that, pretty
                      simple person.
                    </p>
                    <ul className="ftco-social d-flex">
                      <li className="ftco-animate">
                        <a href="#">
                          <span className="icon-twitter" />
                        </a>
                      </li>
                      <li className="ftco-animate">
                        <a href="#">
                          <span className="icon-facebook" />
                        </a>
                      </li>
                      <li className="ftco-animate">
                        <a href="#">
                          <span className="icon-google-plus" />
                        </a>
                      </li>
                      <li className="ftco-animate">
                        <a href="#">
                          <span className="icon-instagram" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>


            <div className="col-md-6 col-lg-3 ftco-animate">
              <div className="staff">
                <div
                  className="img-chef"
                  /* style={{ backgroundImage: "url(/assets/img/tsteit/chef-1.jpg)" }} */
                  style={{ backgroundImage: 'url("/assets/img/tsteit/chef-1.jpg")' }}
                />
                <div className="text px-4 pt-2">
                  <h3>Antonio Santibanez</h3>
                  <span className="position mb-2">Chef Cook</span>
                  <div className="faded">
                    <p>
                      I am an ambitious workaholic, but apart from that, pretty
                      simple person.
                    </p>
                    <ul className="ftco-social d-flex">
                      <li className="ftco-animate">
                        <a href="#">
                          <span className="icon-twitter" />
                        </a>
                      </li>
                      <li className="ftco-animate">
                        <a href="#">
                          <span className="icon-facebook" />
                        </a>
                      </li>
                      <li className="ftco-animate">
                        <a href="#">
                          <span className="icon-google-plus" />
                        </a>
                      </li>
                      <li className="ftco-animate">
                        <a href="#">
                          <span className="icon-instagram" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </section>


      <section className="ftco-section ftco-no-pt ftco-no-pb ftco-intro bg-primary-orbit">
        <div className="container py-5">
          <div className="row py-2">
            <div className="col-md-12 text-center">
              <h2>We Make Delicious &amp; Nutritious Food</h2>
              <a href="#" className="btn btn-orbit">
                Book A Table Now
              </a>
            </div>
          </div>
        </div>
      </section>


      <section className="ftco-section bg-light">
        <div className="container">
          <div className="row justify-content-center mb-5 pb-2">
            <div className="col-md-7 text-center heading-section ftco-animate">
              <span className="subheading">Blog</span>
              <h2 className="mb-4">Recent Blog</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 ftco-animate">
              <div className="blog-entry">
                <a
                  href="blog-single.html"
                  className="block-20"
                  style={{ backgroundImage: 'url("/assets/img/tsteit/image_1.jpg")' }}
                ></a>
                <div className="text px-4 pt-3 pb-4">
                  <div className="meta">
                    <div>
                      <a href="#">August 3, 2024</a>
                    </div>
                    <div>
                      <a href="#">Admin</a>
                    </div>
                  </div>
                  <h3 className="heading">
                    <a href="#">
                      Even the all-powerful Pointing has no control about the blind
                      texts
                    </a>
                  </h3>
                  <p className="clearfix">
                    <a href="#" className="float-left read btn btn-primary">
                      Read more
                    </a>
                    <a href="#" className="float-right meta-chat">
                      <span className="fa fa-comment" /> 3
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 ftco-animate">
              <div className="blog-entry">
                <a
                  href="blog-single.html"
                  className="block-20"
                  style={{ backgroundImage: 'url("/assets/img/tsteit/image_2.jpg")' }}
                ></a>
                <div className="text px-4 pt-3 pb-4">
                  <div className="meta">
                    <div>
                      <a href="#">August 3, 2024</a>
                    </div>
                    <div>
                      <a href="#">Admin</a>
                    </div>
                  </div>
                  <h3 className="heading">
                    <a href="#">
                      Even the all-powerful Pointing has no control about the blind
                      texts
                    </a>
                  </h3>
                  <p className="clearfix">
                    <a href="#" className="float-left read btn btn-primary">
                      Read more
                    </a>
                    <a href="#" className="float-right meta-chat">
                      <span className="fa fa-comment" /> 3
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 ftco-animate">
              <div className="blog-entry">
                <a
                  href="blog-single.html"
                  className="block-20"

                  style={{ backgroundImage: 'url("/assets/img/tsteit/image_3.jpg")' }}
                ></a>
                <div className="text px-4 pt-3 pb-4">
                  <div className="meta">
                    <div>
                      <a href="#">August 3, 2024</a>
                    </div>
                    <div>
                      <a href="#">Admin</a>
                    </div>
                  </div>
                  <h3 className="heading">
                    <a href="#">
                      Even the all-powerful Pointing has no control about the blind
                      texts
                    </a>
                  </h3>
                  <p className="clearfix">
                    <a href="#" className="float-left read btn btn-primary">
                      Read more
                    </a>
                    <a href="#" className="float-right meta-chat">
                      <span className="fa fa-comment" /> 3
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>





      <section className="ftco-section ftco-wrap-about ftco-no-pb ftco-no-pt">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-sm-4 p-4 p-md-5 d-flex align-items-center justify-content-center bg-primary">
              <form action="#" className="appointment-form">
                <h3 className="mb-3">Book your Table</h3>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="name"
                        className="form-control"
                        placeholder="Name"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Phone"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <div className="input-wrap">
                        <div className="icon">
                          <span className="fa fa-calendar" />
                        </div>
                        <input
                          type="text"
                          className="form-control book_date"
                          placeholder="Check-In"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <div className="input-wrap">
                        <div className="icon">
                          <span className="fa fa-clock-o" />
                        </div>
                        <input
                          type="text"
                          className="form-control book_time"
                          placeholder="Time"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <div className="form-field">
                        <div className="select-wrap">
                          <div className="icon">
                            <span className="fa fa-chevron-down" />
                          </div>
                          <select name="" id="" className="form-control">
                            <option value="">Guest</option>
                            <option value="">1</option>
                            <option value="">2</option>
                            <option value="">3</option>
                            <option value="">4</option>
                            <option value="">5</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="submit"
                        defaultValue="Book Your Table Now"
                        className="btn btn-white py-3 px-4"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div
              className="col-sm-8 wrap-about py-5 ftco-animate img"
              /* style={{ backgroundImage: "url(/assets/img/tsteit/about.jpg)" }} */
              style={{ backgroundImage: 'url("assets/img/tsteit/about.jpg")' }}
            >
              <div className="row pb-5 pb-md-0">
                <div className="col-md-12 col-lg-7">
                  <div className="heading-section mt-5 mb-4">
                    <div className="pl-lg-3 ml-md-5">
                      <span className="subheading">About the restaurant</span>
                      <h2 className="mb-4">Welcome to Taste.Latino</h2>
                    </div>
                  </div>
                  <div className="pl-lg-3 ml-md-5">
                    <p>
                      On her way she met a copy. The copy warned the Little Blind
                      Text, that where it came from it would have been rewritten a
                      thousand times and everything that was left from its origin
                      would be the word "and" and the Little Blind Text should turn
                      around and return to its own, safe country. A small river
                      named Duden flows by their place and supplies it with the
                      necessary regelialia. It is a paradisematic country, in which
                      roasted parts of sentences fly into your mouth.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />


    </main >



  );

}

export default RestaHome;





