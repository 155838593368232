

import React from "react";
import { Link } from "react-router-dom";


function Sidebar() {



    return (


        <div className="col-md-10 col-lg-4 p-b-30">
            <div className="p-l-10 p-rl-0-sr991 p-t-70">
                {/* Category */}
                <div className="p-b-60">
                    <div className="how2 how2-cl4 flex-s-c">
                        <h3 className="f1-m-2 cl3 tab01-title">Category</h3>
                    </div>
                    <ul className="p-t-35">
                        <li className="how-bor3 p-rl-4">
                            <Link to="/video-events" className="dis-block f1-s-10 text-uppercase cl2 hov-cl10 trans-03 p-tb-13"> Videos </Link>
                        </li>
                        <li className="how-bor3 p-rl-4">
                            <Link to="/events-dance" className="dis-block f1-s-10 text-uppercase cl2 hov-cl10 trans-03 p-tb-13"> Events </Link>
                        </li>
                        <li className="how-bor3 p-rl-4">
                            <Link to="/schools-dance" className="dis-block f1-s-10 text-uppercase cl2 hov-cl10 trans-03 p-tb-13"> Schools Dance </Link>
                        </li>
                        <li className="how-bor3 p-rl-4">
                            <Link to="/teachers-dance" className="dis-block f1-s-10 text-uppercase cl2 hov-cl10 trans-03 p-tb-13"> Teachers Dance </Link>
                        </li>
                        <li className="how-bor3 p-rl-4">
                            <Link to="/teachers-spanish" className="dis-block f1-s-10 text-uppercase cl2 hov-cl10 trans-03 p-tb-13"> Teachers Spanish </Link>
                        </li>
                        <li className="how-bor3 p-rl-4">
                            <Link to="/" className="dis-block f1-s-10 text-uppercase cl2 hov-cl10 trans-03 p-tb-13"> Viral </Link>
                        </li>
                    </ul>
                </div>


                {/*================================ ARCHIVO ======================================*/}
                {/* Archive */}
                <div className="p-b-37">
                    <div className="how2 how2-cl4 flex-s-c">
                        <h3 className="f1-m-2 cl3 tab01-title">Video Archivo</h3>
                    </div>
                    <ul className="p-t-32">
                        <li className="p-rl-4 p-b-19">

                            <Link to="/video-events" className="flex-wr-sb-c f1-s-10 text-uppercase cl2 hov-cl10 trans-03">  <span>Jun</span>
                                <span>(438)</span> </Link>

                        </li>
                        <li className="p-rl-4 p-b-19">

                            <Link to="/video-events" className="flex-wr-sb-c f1-s-10 text-uppercase cl2 hov-cl10 trans-03">  <span>May</span>
                                <span>(784)</span> </Link>

                        </li>
                        <li className="p-rl-4 p-b-19">

                            <Link to="/video-events" className="flex-wr-sb-c f1-s-10 text-uppercase cl2 hov-cl10 trans-03">  <span>Apr</span>
                                <span>(341)</span> </Link>

                        </li>
                        <li className="p-rl-4 p-b-19">

                            <Link to="/video-events" className="flex-wr-sb-c f1-s-10 text-uppercase cl2 hov-cl10 trans-03">  <span>Mar</span>
                                <span>(075)</span> </Link>

                        </li>




                    </ul>
                </div>
                {/*================================ LO MÁS LEÍDO =====================================*/}
                {/* Popular Posts */}
                <div className="p-b-30">
                    <div className="how2 how2-cl4 flex-s-c">
                        <h3 className="f1-m-2 cl3 tab01-title">Популярные посты</h3>
                    </div>
                    <ul className="p-t-35">

                        <li className="flex-wr-sb-s p-b-30">
                            <a
                                href="#"
                                className="size-w-10 wrap-pic-w hov1 trans-03"
                            >
                                <img src="/assets/img/blog/openair5.jpg" alt="IMG" />
                            </a>
                            <div className="size-w-11">
                                <h6 className="p-b-4">
                                    <Link to="/open-air-in-Moscow"
                                        className="f1-s-5 cl3 hov-cl10 trans-03"
                                    >
                                        Опен-эйр: Сальса и Бачата под открытым небом в Москве
                                    </Link>
                                </h6>
                                <span className="cl8 txt-center p-b-24">
                                    <Link to="/" className="f1-s-6 cl8 hov-cl10 trans-03"> Ec </Link>
                                    <span className="f1-s-3 m-rl-3">-</span>
                                    <span className="f1-s-3">8 hours ago</span>
                                </span>
                            </div>
                        </li>


                        <li className="flex-wr-sb-s p-b-30">
                            <a
                                href="#"
                                className="size-w-10 wrap-pic-w hov1 trans-03"
                            >
                                <img
                                    src="/assets/img/dance/BavR.gif"
                                    alt="IMG"
                                />
                            </a>
                            <div className="size-w-11">
                                <h6 className="p-b-4">
                                    <Link to="/latino-dance-in-Moscow" className="f1-s-5 cl3 hov-cl10 trans-03">
                                        Латино ритм в Москве: Исследуй и наслаждай вечеринки, заставляющие танцевать Россию
                                    </Link>
                                </h6>
                                <span className="cl8 txt-center p-b-24">

                                    <Link to="/" className="f1-s-6 cl8 hov-cl10 trans-03"> E </Link>

                                    <span className="f1-s-3 m-rl-3">-</span>
                                    <span className="f1-s-3">6 hours ago</span>
                                </span>
                            </div>
                        </li>



                        <li className="flex-wr-sb-s p-b-30">
                            <a
                                href="#"
                                className="size-w-10 wrap-pic-w hov1 trans-03"
                            >
                                <img src="../../images/ia-robot-tecno.png" alt="IMG" />
                            </a>
                            <div className="size-w-11">
                                <h6 className="p-b-4">
                                    <a
                                        href="#"
                                        className="f1-s-5 cl3 hov-cl10 trans-03"
                                    >
                                        Eventos de español {" "}
                                    </a>
                                </h6>
                                <span className="cl8 txt-center p-b-24">
                                    <Link to="/" className="f1-s-6 cl8 hov-cl10 trans-03"> Dance </Link>
                                    <span className="f1-s-3 m-rl-3">-</span>
                                    <span className="f1-s-3">5 hours ago</span>
                                </span>
                            </div>
                        </li>
                    </ul>
                </div>



                {/* ================= Tag ================= */}
                <div>
                    <div className="how2 how2-cl4 flex-s-c m-b-30">
                        <h3 className="f1-m-2 cl3 tab01-title">Tags</h3>
                    </div>
                    <div className="flex-wr-s-s m-rl--5">
                        <Link to="/"
                            className="flex-c-c size-h-2 bo-1-rad-20 bocl12 f1-s-1 cl8 hov-btn2 trans-03 p-rl-20 p-tb-5 m-all-5"
                        > Actualidad </Link>

                        <Link to="/"
                            className="flex-c-c size-h-2 bo-1-rad-20 bocl12 f1-s-1 cl8 hov-btn2 trans-03 p-rl-20 p-tb-5 m-all-5"
                        > Política </Link>

                        <Link to="/"
                            className="flex-c-c size-h-2 bo-1-rad-20 bocl12 f1-s-1 cl8 hov-btn2 trans-03 p-rl-20 p-tb-5 m-all-5"
                        > Eventos </Link>


                        <Link to="/"
                            className="flex-c-c size-h-2 bo-1-rad-20 bocl12 f1-s-1 cl8 hov-btn2 trans-03 p-rl-20 p-tb-5 m-all-5"
                        > Viral </Link>

                        <Link to="/"
                            className="flex-c-c size-h-2 bo-1-rad-20 bocl12 f1-s-1 cl8 hov-btn2 trans-03 p-rl-20 p-tb-5 m-all-5"
                        > Sociedad </Link>

                        <Link to="/"
                            className="flex-c-c size-h-2 bo-1-rad-20 bocl12 f1-s-1 cl8 hov-btn2 trans-03 p-rl-20 p-tb-5 m-all-5"
                        > Tendencia </Link>

                        <Link to="/"
                            className="flex-c-c size-h-2 bo-1-rad-20 bocl12 f1-s-1 cl8 hov-btn2 trans-03 p-rl-20 p-tb-5 m-all-5"
                        > Entretenimiento </Link>

                        <Link to="/"
                            className="flex-c-c size-h-2 bo-1-rad-20 bocl12 f1-s-1 cl8 hov-btn2 trans-03 p-rl-20 p-tb-5 m-all-5"
                        > Negocios </Link>


                    </div>
                </div>
            </div>
        </div>








    );

}

export default Sidebar;
