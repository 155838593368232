

/* fox - blog */

import Footer from "./FooterEs";
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import WOW from 'wowjs';
import YandexAd from '../YandexAd';
import Adx1BannerVideo from '../Adx1BannerVideo'; 



function FoxEventsDance() {

    useEffect(() => {
        const wow = new WOW.WOW();
        wow.init();
    }, []);

    const eventsData = [
        {day: "27-28", mos: "Jul", image: "/assets/img/events/lima-28-07.jpg", link: "https://vk.com/lima_restaurant", title: " Лима ресторан - Праздник и большая вечеринка Перу  🇵🇪 ! ",
            description: "В субботу 27.07 большая вечеринка - сбор гостей и дегустации писко  🇵🇪 . В воскресенье у нас семейный день и такой же праздник"
        },
        {day: "24-28", mos: "Jul", image: "/assets/img/events/agave-28-07.jpg", link: "https://vk.com/bachatachka", title: "Bachata Love в Casa Agave ",
            description: "✨ 24.07 Среда BACHATA 🔸 25.07 Четверг SALSA 🔸 28.07 Воскресенье BACHATA 💰Бесплатный вход (за репост) на все 3 дня"
        },
        {day: "24-28", mos: "Jul", image: "/assets/img/events/pancho-28-07.jpg", link: "https://vk.com/shtabpanchovilla", title: "Pancho Villa - Stand Up - Fuzion Latino ",
            description: "💥СУББОТА 21-30 Fuzion Latino (живая музыка). караоке в зале «Отель Тамаринд»🎤✨"
        },
        {day: "27", mos: "Jul", image: "/assets/img/events/fusion-27-07.jpg", link: "https://vk.com/latinfusion", title: "LATIN FUSION Party 💃🏼💣",
            description: "Вечеринка будет в нашем стиле, мега-экстра-крутая 💯 команда профессиональных танцоров, лучшие треки бачаты, сальсы, кизомбы, анимации и многое другое 😍"
        }, 
        {day: "27", mos: "Jul", image: "/assets/img/events/corazon-27-07.jpg", link: "https://vk.com/corazon.bachata", title: "Corazon • Бачата-вечеринки 🔥",
            description: "🎼 Много бачаты, сальса и кизомба по запросу 🎧 DJ Don Corazon 👗 Дресс-код: прилично, удобно и красиво 🥤 Бар + кофейня, 2 кулера с водой 💧"
        },
        {day: "26", mos: "Jul", image: "/assets/img/events/nama-26-07.jpg", link: "https://vk.com/bachatanama", title: " Bachata NAMA - MOSCOW | 🏆 ",
            description: " Все лучшие танцоры, зажигают на мульти-дэнс пати Bachata Nama!💃🕺. В наличии 3 зала: Бачата, Кизомба и Бачата-ремиксы!🔥."
        },
        {day: "26", mos: "Jul", image: "/assets/img/events/muza-26-07.jpg", link: "https://vk.com/kizombacosmosparty", title: " Moulin Rouge PARTY 🔥🎶 ",
            description: "🌹 🤩 Встречаемся в уютной локации Twenty One Club! Вас ждет качественный, музыкальный сет "
        },
        {day: "26", mos: "Jul", image: "/assets/img/events/liberty-26-07.jpg", link: "https://vk.com/libertybarmsk", title: " CUBA LIBRE PARTY 🤩🥳 ",
            description: "26 июля -  концерт группы Los Latinos 💃 Кубинский праздник - День национального восстания. 27 июля - концерт группы Шведов Шоу🔥 "
        },
        {day: "25", mos: "Jul", image: "/assets/img/events/brillo-25-07.jpg", link: "https://vk.com/brillolatino", title: "LATINO NIGHT в Баре Papa's",
            description: "танцы до утра! LATINO NIGHT в легендарном Papa’s Вar❣ Старт в 22:00🤗 В 23:30 танцевальная анимация от Carlos-Raydel Depestre-Tamayo 💿"
        },
        {day: "23", mos: "Jul", image: "/assets/img/events/feliz-23-07.jpg", link: "https://vk.com/fe.liz.latino", title: "Smart PARTY в Papas bar&grill 🤩🎶 ",
            description: "😎 Smart Party с нашими приглашенными ПРЕПОДАВАТЕЛЯМИ✨🤩🥳  "
        },

        {day: "20", mos: "Jul", image: "/assets/img/events/idilio-20-07.jpg", link: "https://vk.com/public210911605", title: " Выходные в El Idilio 😍 ",
            description: " самый качественный отдых, который можно позволить себе😉 Вечера зажигательной музыки и танцев с вкусной кухней и самой дружелюбной атмосферой! " 
        },
        {day: "11", mos: "Aug", image: "/assets/img/events/salsa-timba-11-08.jpg", link: "https://vk.com/salsatimbajazz", title: "SALSAMORE - Сальса-Тимба 💃🕺",
            description: "Море сальсы и другой прекрасной кубинской музыки! Готовьтесь отдохнуть от московской жары в прохладном зале клуба!"
        },
        
        {day: "19", mos: "Jul", image: "/assets/img/events/nama-19-07.jpg", link: "https://vk.com/bachatanama", title: " Bachata NAMA - MOSCOW | 🏆 ",
            description: " Все лучшие танцоры, зажигают на мульти-дэнс пати Bachata Nama!💃🕺. В наличии 3 зала: Бачата, Кизомба и Бачата-ремиксы!🔥."
           },
        {day: "17-21", mos: "Jul", image: "/assets/img/events/agave-21-07.jpg", link: "https://vk.com/bachatachka", title: "Bachata Love в Casa Agave ",
            description: "✨ 17.07 Среда BACHATA 🔸 18.07 Четверг SALSA 🔸 21.07 Воскресенье BACHATA 💰Бесплатный вход (за репост) на все 3 дня"
        },
        {day: "21", mos: "Jul", image: "/assets/img/events/muza-21-07.jpg", link: "https://vk.com/kizombacosmosparty", title: " KIZOMBA COSMOS PARTY 🔥🎶 ",
            description: "🌹SUMMER ROMANTIC VIBE! KIZOMBA | URBANKIZ | TARRAXA | SEMBA 🤩 Встречаемся в уютной локации Mandarin! Вас ждет качественный, музыкальный сет "
        },
        {day: "19-20", mos: "Jul", image: "/assets/img/events/liberty-20-07.jpg", link: "https://vk.com/libertybarmsk", title: " CUBA LIBRE PARTY 🤩🥳 ",
            description: "19 июля концерт латино группы Havana Base ✨20 концерт группы Легкое поведение  💃"
        },
        {day: "20", mos: "Jul", image: "/assets/img/events/viva-20-07.jpg", link: "https://vk.com/vivadancers", title: "Танцы под открытым небом! VIVA Dance",
            description: "танцы до утра! LATINO NIGHT в легендарном Papa’s Вar❣ Старт в 22:00🤗 В 23:30 танцевальная анимация от Carlos-Raydel Depestre-Tamayo 💿"
        },
        {day: "18", mos: "Jul", image: "/assets/img/events/brillo-18-07.jpg", link: "https://vk.com/brillolatino", title: " Brillo latino в Баре Papa's",
            description: "танцы до утра! LATINO NIGHT в легендарном Papa’s Вar❣ Старт в 22:00🤗 В 23:30 танцевальная анимация от Carlos-Raydel Depestre-Tamayo 💿"
        },
        {day: "17-20", mos: "Jul", image: "/assets/img/events/pancho-17-07.jpg", link: "https://vk.com/shtabpanchovilla", title: "Pancho Villa - GRAN FIESTA LATINA",
            description: "22:00-05:00 Latin Party Douglas show Dj Dairiel Работаем до 5 утра!"
        },
        {day: "16", mos: "Jul", image: "/assets/img/events/feliz-16-07.jpg", link: "https://vk.com/fe.liz.latino", title: "Smart PARTY в Papas bar&grill 🤩🎶 ",
            description: "😎 Smart Party с нашими приглашенными ПРЕПОДАВАТЕЛЯМИ✨🤩🥳  "
            },

            
        {day: "10-14", mos: "Jul", image: "/assets/img/events/agave-14-07.jpg", link: "https://vk.com/bachatachka", title: "Bachata Love в Casa Agave ",
            description: "✨ 10.07 Среда BACHATA🔸11.07 Четверг SALSA🔸14.07 Воскресенье BACHATA 💰Бесплатный вход (за репост) на все 3 дня"
        },
        {day: "14", mos: "Jul", image: "/assets/img/events/feliz-14-07.jpg", link: "https://vk.com/fe.liz.latino", title: "feLIZ Romantic PARTY 🤩🎶 ",
            description: "танцуем на feLIZ Smart PARTY в Shushas на Пушкинской 🤩🥳 С 20:00 до 21:00 вас ждет ПОДАРОЧНЫЙ мастер-класс по Бачате от Эли Оздемир и Батаева Павла😍 😍"
        }, 
        {day: "14", mos: "Jul", image: "/assets/img/events/amor-14-07.jpg", link: "https://vk.com/por_amor_danza", title: "Por Amor вечеринки Bachata",
            description: "На площадке Нова 🖤 В рамках самого масштабного фестиваля, делаем дневную вечеринку на свежем воздухе для вас🤗 Формат музыки 5 бачат, 1 сальса, 5 бачат, 1 кизомба 🖤"
            },
        {day: "13", mos: "Jun", image: "/assets/img/events/fusion-13-07.jpg", link: "https://vk.com/latinfusion", title: "LATIN FUSION Party 💃🏼💣",
            description: "Вечеринка будет в нашем стиле, мега-экстра-крутая 💯 команда профессиональных танцоров, лучшие треки бачаты, сальсы, кизомбы, анимации и многое другое 😍"
            }, 
        {day: "13", mos: "Jul", image: "/assets/img/events/corazon-13-07.jpg", link: "https://vk.com/corazon.bachata", title: "Corazon • Бачата-вечеринки 🔥",
            description: "🎼 Много бачаты, сальса и кизомба по запросу 🎧 DJ Don Corazon 👗 Дресс-код: прилично, удобно и красиво 🥤 Бар + кофейня, 2 кулера с водой 💧"
        },
        {day: "12-13", mos: "Jul", image: "/assets/img/events/liberty-12-07.jpg", link: "https://vk.com/libertybarmsk", title: " CUBA LIBRE PARTY 🤩🥳 ",
            description: "12 июля  -  концерт группы Экспонат ✨13 июля мастер-класс Latino Dance 💃"
            },
        {day: "12", mos: "Jul", image: "/assets/img/events/nama-12-07.jpg", link: "https://vk.com/bachatanama", title: " Bachata NAMA - MOSCOW | 🏆 ",
            description: " Все лучшие танцоры, зажигают на мульти-дэнс пати Bachata Nama!💃🕺. В наличии 3 зала: Бачата, Кизомба и Бачата-ремиксы!🔥."
           },
        {day: "11-13", mos: "Jul", image: "/assets/img/events/pancho-11-07.jpg", link: "https://vk.com/shtabpanchovilla", title: "Pancho Villa - GRAN FIESTA LATINA",
            description: "22:00-05:00 Latin Party Douglas show Dj Dairiel Работаем до 5 утра!"
        },
        {day: "11", mos: "Jul", image: "/assets/img/events/brillo-11-07.jpg", link: "https://vk.com/brillolatino", title: " Brillo latino в Баре Papa's",
            description: "танцы до утра! LATINO NIGHT в легендарном Papa’s Вar❣ Старт в 22:00🤗 В 23:30 анимация от Ernesto Yamo За пультом DJ Adamis Zulueta Cruz 💿"
        },
        {day: "07", mos: "Jul", image: "/assets/img/events/agave-07-07.jpg", link: "https://vk.com/bachatachka", title: "Bachata Love в Casa Agave ",
            description: "✨ 3.07 Среда BACHATA🔸4.07 Четверг SALSA🔸7.07 Воскресенье Bachata Dominikana Party 💰Бесплатный вход (за репост) на все 3 дня"
        },
        {day: "07", mos: "Jul", image: "/assets/img/events/feliz-07-07.jpg", link: "https://vk.com/fe.liz.latino", title: "feLIZ Romantic PARTY 🤩🎶 ",
            description: "танцуем на feLIZ Smart PARTY в Shushas на Пушкинской 🤩🥳 С 20:00 до 21:00 вас ждет ПОДАРОЧНЫЙ мастер-класс по Бачате от Кейви Родригес Гонсалеса и Вероники Горшковой 😍"
        },
        {day: "07", mos: "Jul", image: "/assets/img/events/amor-07-07.jpg", link: "https://vk.com/por_amor_danza", title: "Por Amor вечеринки Bachata",
            description: " В рамках самого масштабного фестиваля, делаем дневную вечеринку на свежем воздухе для вас🤗 Формат музыки 5 бачат, 1 сальса, 5 бачат, 1 кизомба 🖤"
            },
        {day: "07", mos: "Jul", image: "/assets/img/events/muza-07-07.jpg", link: "https://vk.com/kizombacosmosparty", title: " KIZOMBA COSMOS PARTY 🔥🎶 ",
            description: "🌹SUMMER ROMANTIC VIBE! KIZOMBA | URBANKIZ | TARRAXA | SEMBA 🤩 Встречаемся в уютной локации Mandarin! Вас ждет качественный, музыкальный сет "
            },
        {day: "06-07", mos: "Jul", image: "/assets/img/events/liberty-06-07.jpg", link: "https://vk.com/libertybarmsk", title: " День рождения бара LIBERTY 🤩🥳 ",
            description: "Клубу Либерти исполняется 10 лет! В честь юбилея устраиваем грандиозную вечеринку на два дня! Подумали и решили тематикой сделать полет на Кубу."
            },
        {day: "06", mos: "Jul", image: "/assets/img/events/latin-fusion-06-07.jpg", link: "https://vk.com/latinfusion", title: "LATIN FUSION Party 💃🏼💣",
            description: "в СУББОТУ 🔥 У нас будет особенная вечеринка🥰 будем отмечать ДР нашего дорогого Дугласа, основателя LATIN FUSION PARTY 🎉"
            },
        {day: "05", mos: "Jul", image: "/assets/img/events/nama-05-07.jpg", link: "https://vk.com/bachatanama", title: " Bachata NAMA - MOSCOW | 🏆 ",
             description: " Все лучшие танцоры, зажигают на мульти-дэнс пати Bachata Nama!💃🕺. В наличии 3 зала: Бачата, Кизомба и Бачата-ремиксы!🔥."
            },
        {day: "04", mos: "Jul", image: "/assets/img/events/brillo-04-07.jpg", link: "https://vk.com/brillolatino", title: " Brillo latino в Баре Papa's",
            description: "танцы до утра! LATINO NIGHT в легендарном Papa’s Вar❣ Старт в 22:00🤗 В 23:30 анимация от Ernesto Yamo За пультом DJ Adamis Zulueta Cruz 💿"
        },
        {day: "04", mos: "Jul", image: "/assets/img/events/pancho-04-07.jpg", link: "https://vk.com/shtabpanchovilla", title: "Pancho Villa - Latin Party show",
            description: "22:00-05:00 Latin Party Douglas show Dj Dairiel Работаем до 5 утра!"
        },


        {day: "23", mos: "Jun", image: "/assets/img/events/amor-23-06.jpg", link: "https://vk.com/por_amor_danza", title: "Por Amor вечеринки Bachata",
            description: " В этот день приглашаем вас прийти в самую теплую и дружескую атмосферу🤗 Душевные разговоры, вкусная еда, хорошая музыка и, конечно же, ТАНЦЫ🥳"
            },
        {day: "16", mos: "Jun", image: "/assets/img/events/muza-16-06.jpg", link: "https://vk.com/kizombacosmosparty", title: " KIZOMBA COSMOS PARTY 🔥🎶 ",
            description: "🌹SPRING KIZOMBA ATMOSPHERE! MK DINO & MARIA KIZZGIRL. 🤩приглашаем отдохнуть в романтичной и уютной атмосфере! "
        },
        {day: "15", mos: "Jun", image: "/assets/img/events/corazon-15-06.jpg", link: "https://vk.com/corazon.bachata", title: "Corazon • Бачата-вечеринки 🔥",
            description: "🎼 Много бачаты, сальса и кизомба по запросу 🎧 DJ Don Corazon, DJ Daniela 👗 Дресс-код: прилично, удобно и красиво 🥤 Бар + кофейня, 2 кулера с водой 💧"
        },
        {day: "06-09", mos: "May", image: "/assets/img/events/agave-09-06.jpg", link: "https://vk.com/bachatachka", title: "Bachata Love в Casa Agave ",
            description: "⚡Начинаем с класса Bachata, далее BACHATA PARTY. 3 Подарочных класса Bachata и Salsa от профессиональных преподавателей!"
        }, 
        {day: "09", mos: "Jun", image: "/assets/img/events/brillo-09-06.jpg", link: "https://vk.com/brillolatino", title: " Brillo latino в Баре Club Liberty",
            description: "В это воскресенье у нас Международный день принцесс 👸 Повод почувствовать себя куколками 🪆 Самой очаровательной дарим коктейль 🍹"
        },
        {day: "09", mos: "Jun", image: "/assets/img/events/feliz-09-06.jpg", link: "https://vk.com/fe.liz.latino", title: "feLIZ Smart PARTY 🤩🎶 ",
            description: "танцуем на feLIZ Smart PARTY в Shushas на Пушкинской 🤩🥳 С 20:00 до 21:00 вас ждет ПОДАРОЧНЫЙ мастер-класс по Бачате от Кондрушиной Екатерины и Кочеткова Влада"
        },
        {day: "09", mos: "Jun", image: "/assets/img/events/latin-fusion-09-06.jpg", link: "https://vk.com/latinfusion", title: "LATIN FUSION Party 💃🏼💣",
            description: "Вечеринка будет в нашем стиле, мега-экстра-крутая 💯 команда профессиональных танцоров, лучшие треки бачаты, сальсы, кизомбы, анимации и многое другое 😍"
        },
        {day: "07", mos: "Jun", image: "/assets/img/events/nama-07-06.jpg", link: "https://vk.com/bachatanama", title: " Bachata NAMA - MOSCOW | 🏆 ",
            description: " Все лучшие танцоры, зажигают на мульти-дэнс пати Bachata Nama!💃🕺. В наличии 3 зала: Бачата, Кизомба и Бачата-ремиксы!🔥."
        },
        

        {day: "06", mos: "Jun", image: "/assets/img/events/corazon-01-06.jpg", link: "https://vk.com/corazon.bachata", title: "Corazon • Бачата-вечеринки 🔥",
        description: "🎼 Много бачаты, сальса и кизомба по запросу 🎧 DJ Don Corazon, DJ Daniela 👗 Дресс-код: прилично, удобно и красиво 🥤 Бар + кофейня, 2 кулера с водой 💧"
        },         
        
    ];


    return (

        <main>            

            <section
                className="hero-wrap hero-wrap-2-fox"
                style={{ backgroundImage: 'url("/assets/img/gallery/dance-girl-man.png")' }}
            >
                <div className="overlay" />
                <div className="container">
                    <div className="row no-gutters slider-text align-items-center justify-content-center">
                        <div className="col-md-9 ftco-animate text-center fadeInUp ftco-animated">
                            <h1 className="mb-2 bread">Próximos Eventos</h1>
                            <p className="breadcrumbs">
                                <span className="mr-2">
                                    <a href=" ">
                                        Inicio <i className="ion-ios-arrow-forward" />
                                    </a>
                                </span>{" "}
                                <span>
                                        Eventos <i className="ion-ios-arrow-forward" />
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

             {/* -------------- Yandex Banner 03 -------------- */}
             <Adx1BannerVideo blockId="R-A-9762127-3" renderTo="yandex_rtb_R-A-9762127-3" />


            <section className="ftco-section-fox bg-light">
                <div className="container">
                    <div className="row">
                         
                        {eventsData.map((event, index) => (
                            <div key={index} className="col-md-6 col-lg-4 ftco-animate wow zoomIn" data-wow-delay={`${(index % 3) * 0.2 + 0.1}s`}>
                                <div className="blog-entry ">
                                    <div className="overflow-hidden">
                                        <a className="img-event block-20 d-flex align-items-end"
                                            style={{ backgroundImage: `url("${event.image}")` }}
                                        >
                                            <div className="meta-date text-center p-2">
                                                <span className="day">{event.day}</span>
                                                <span className="mos">{event.mos}</span>
                                                <span className="yr">2024</span>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="text bg-white p-4">
                                        <h3 className="heading">
                                            <Link to={event.link} target="_blank">{event.title}</Link>
                                        </h3>
                                        <p>{event.description}</p>
                                        <div className="d-flex align-items-center mt-4">
                                            <p className="mb-0">
                                                <Link to={event.link} target="_blank" className="btn btn-primary">
                                                   Leer Más <span className="ion-ios-arrow-round-forward" />
                                                </Link>
                                            </p>
                                            <p className="ml-auto mb-0">
                                                <a href="#" className="mr-2">
                                                    Admin
                                                </a>
                                                <a href="#" className="meta-chat">
                                                    <span className="icon-chat" /> 3
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </section>

            <section className="containerFox banner-yandex">
                {/* -------------- Yandex Banner 01 -------------- */}
                <YandexAd blockId="R-A-9762127-1" renderTo="yandex_rtb_R-A-9762127-1" />
            </section>


            <Footer />


        </main >



    );

}

export default FoxEventsDance;
