

import Menu from "./MenuMobEs";
import Footer from "./FooterEs";
import { Link } from 'react-router-dom';
 

import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


function About() {



    return (


        <main >

               {/* <Menu /> */}  

            <section>

                             

                <main>
                    <div className="about-details section-padding30">
                        <div className="container">
                            <div className="row">
                                <div className="offset-xl-1 col-lg-8">
                                    <div className="about-details-cap mb-50">
                                        <h4>Our Mission</h4>
                                        <p>
                                            Consectetur adipiscing elit, sued do eiusmod tempor ididunt
                                            udfgt labore et dolore magna aliqua. Quis ipsum suspendisces
                                            gravida. Risus commodo viverra sebfd dho eiusmod tempor maecenas
                                            accumsan lacus. Risus commodo viverra sebfd dho eiusmod tempor
                                            maecenas accumsan lacus.
                                        </p>
                                        <p>
                                            {" "}
                                            Risus commodo viverra sebfd dho eiusmod tempor maecenas accumsan
                                            lacus. Risus commodo viverra sebfd dho eiusmod tempor maecenas
                                            accumsan.
                                        </p>
                                    </div>
                                    <div className="about-details-cap mb-50">
                                        <h4>Our Vision</h4>
                                        <p>
                                            Consectetur adipiscing elit, sued do eiusmod tempor ididunt
                                            udfgt labore et dolore magna aliqua. Quis ipsum suspendisces
                                            gravida. Risus commodo viverra sebfd dho eiusmod tempor maecenas
                                            accumsan lacus. Risus commodo viverra sebfd dho eiusmod tempor
                                            maecenas accumsan lacus.
                                        </p>
                                        <p>
                                            {" "}
                                            Risus commodo viverra sebfd dho eiusmod tempor maecenas accumsan
                                            lacus. Risus commodo viverra sebfd dho eiusmod tempor maecenas
                                            accumsan.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/*? About Area Start*/}
                    <div
                        className="support-company-area pt-100 pb-100 section-bg fix"
                        data-background="/assets/img/gallery/section_bg02.jpg"
                    >
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-xl-6 col-lg-6">
                                    <div className="support-location-img">
                                        <img src="/assets/img/gallery/about.png" alt="" />
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                    <div className="right-caption">
                                        {/* Section Tittle */}
                                        <div className="section-tittles section-tittles2 mb-50">
                                            <span>Our Top Services</span>
                                            <h2>Our Best Services</h2>
                                        </div>
                                        <div className="support-caption">
                                            <p className="pera-top">
                                                Mollit anim laborum duis adseu dolor iuyn voluptcate velit ess
                                                cillum dolore egru lofrre dsu quality mollit anim laborumuis
                                                au dolor in voluptate velit cillu.
                                            </p>
                                            <p className="mb-65">
                                                Mollit anim laborum.Dvcuis aute serunt iruxvfg dhjkolohr indd
                                                re voluptate velit esscillumlore eu quife nrulla parihatur.
                                                Excghcepteur sfwsignjnt occa cupidatat non aute iruxvfg
                                                dhjinulpadeserunt moll.
                                            </p>
                                            <a href="about.html" className="btn post-btn ">
                                                More About Us
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* About Area End*/}


                    {/* Team Start */}
                    <div className="team-area section-padding30">
                        <div className="container">
                            <div className="row">
                                <div className="cl-xl-7 col-lg-8 col-md-10">
                                    {/* Section Tittle */}
                                    <div className="section-tittles mb-70">
                                        <span>Our Professional members </span>
                                        <h2>Our Team Mambers</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {/* single Tem */}
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-">
                                    <div className="single-team mb-30">
                                        <div className="team-img">
                                            <img src="/assets/img/gallery/team2.png" alt="" />
                                        </div>
                                        <div className="team-caption">
                                            <h3>
                                                <a href="#">Ethan Welch</a>
                                            </h3>
                                            <span>UX Designer</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-">
                                    <div className="single-team mb-30">
                                        <div className="team-img">
                                            <img src="/assets/img/gallery/team3.png" alt="" />
                                        </div>
                                        <div className="team-caption">
                                            <h3>
                                                <a href="#">Ethan Welch</a>
                                            </h3>
                                            <span>UX Designer</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-">
                                    <div className="single-team mb-30">
                                        <div className="team-img">
                                            <img src="/assets/img/gallery/team1.png" alt="" />
                                        </div>
                                        <div className="team-caption">
                                            <h3>
                                                <a href="#">Ethan Welch</a>
                                            </h3>
                                            <span>UX Designer</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Team End */}


                    {/* banner-last Start */}
                    <div className="banner-area gray-bg pb-90">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-10 col-md-10">
                                    <div className="banner-one">
                                        <img src="/assets/img/gallery/body_card3.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* banner-last End */}
                </main>


                
              

            </section>




            <Footer />

        </main>



    );

}

export default About;