


import Footer from "../../../components/es/FooterEs";
import { Link } from "react-router-dom";


function LatinoDanceMoscow() {



   return (


      <main >

         {/* <Menu /> */}

         {/*================Blog Area =================*/}
         <section className="blog_area single-post-area section-padding">
            <div className="container">
               <div className="row">
                  <div className="col-lg-8 posts-list">
                     <div className="single-post">
                        <div className="feature-img">
                           <img
                              className="img-fluid"
                              src="/assets/img/events/latina-fest1.jpg"
                              alt=""
                           />
                        </div>
                        <div className="blog_details">
                           <h2>
                           Se celebrará en Moscú un festival cultural de países de América Latina y el Caribe                           </h2>
                           <ul className="blog-info-link mt-3 mb-4">
                              <li>
                                 <a href="#">
                                    <i className="fa fa-user" /> Party, Lifestyle
                                 </a>
                              </li>
                              <li>
                                 <a href="#">
                                    <i className="fa fa-comments" /> 03 Comments
                                 </a>
                              </li>
                           </ul>
                           <p className="excert">
                           Del 20 al 28 de julio, Moscú acogerá un festival de cultura de los países de América Latina y el Caribe, organizado por el Departamento de Relaciones Económicas Exteriores e Internacionales de Moscú. Este evento está dirigido a fortalecer los vínculos culturales entre Rusia y los países de América Latina y el Caribe, conocidos por sus duraderas relaciones comerciales, económicas y culturales con Rusia.
                           </p>
                           <h3>
                              .
                           </h3>

                           <p>
                           El festival será un paso importante en el desarrollo de la cooperación humanitaria internacional y en la creación de una imagen positiva de Moscú como un centro cultural. Los organizadores del evento incluyen el Departamento de Cultura de Moscú, el Centro Moscovita de Cooperación Internacional, la Casa de América Latina, el Gosfilmofond de Rusia, la Universidad del Gobierno de Moscú, la Biblioteca de Literatura Extranjera M.I. Rudomino, el Teatro Académico de Moscú V. Mayakovsky y las embajadas de los países de América Latina y el Caribe.
                             </p>
                           <h3>
                              .
                           </h3>
                           <p>
                              Lo mejor de estas fiestas son abiertas para todos gracias a las donaciones de los asistentes. Tanto si eres un bailarín experimentado como si estás dando tus primeros pasos en el mundo del baile, siempre hay un lugar para ti en la pista de baile. La diversidad de participantes y la atmósfera inclusiva hacen que cada noche sea única y emocionante.
                           </p>
                           El Jardín "Ermitage" será la principal sede del festival los días 20 y 21 de julio. Los visitantes podrán disfrutar de música, danzas, gastronomía y artesanías latinoamericanas. La inauguración oficial tendrá lugar el 20 de julio a las 13:00. El programa incluye actuaciones de grupos musicales, talleres de danza y artesanías, desfiles de moda, conferencias sobre la cultura y tradiciones de la región, así como un mercado de productos auténticos.
                           <p>
                           En el marco del festival, del 24 al 28 de julio, se proyectarán obras maestras del cine latinoamericano en el cine "Illuzion". Además, en la Biblioteca de Literatura Extranjera se llevarán a cabo conferencias temáticas dedicadas a la cultura y tradiciones de los países de la región. Se prestará especial atención al Día del Libertador de América del Sur, Simón Bolívar, al maratón poético y a los conciertos.
                             </p>



                           <div className="quote-wrapper">
                              <div className="quotes">
                                 <h3>
                                    .
                                 </h3>
                                 El festival ofrece una oportunidad única para que los moscovitas y los visitantes de la ciudad se sumerjan en la cultura de América Latina y el Caribe, disfruten de los ritmos de sus danzas, aprendan cosas nuevas y desarrollen su potencial creativo.
                                 </div>
                           </div>
                           <h3>
                              Únete a la Fiesta
                           </h3>

                           <p className="f1-s-11 cl6 p-b-25">                              
                               Можно присоединиться: 
                                <br />  
                                <h3>
                              <Link to="https://latina-fest.ru/" target="_blank"> latina-fest.ru </Link><br />
                              </h3>
                           </p>
                        </div>
                     </div>
                     <div className="navigation-top">
                        <div className="d-sm-flex justify-content-between text-center">
                           <p className="like-info">
                              <span className="align-middle">
                                 <i className="fa fa-heart" />
                              </span>{" "}
                              Lily and 4 people like this
                           </p>
                           <div className="col-sm-4 text-center my-2 my-sm-0">
                              {/* <p class="comment-count"><span class="align-middle"><i class="fa fa-comment"></i></span> 06 Comments</p> */}
                           </div>
                           <ul className="social-icons">
                              <li>
                                 <a href="#">
                                    <i className="fab fa-facebook-f" />
                                 </a>
                              </li>
                              <li>
                                 <a href="#">
                                    <i className="fab fa-twitter" />
                                 </a>
                              </li>
                              <li>
                                 <a href="#">
                                    <i className="fab fa-dribbble" />
                                 </a>
                              </li>
                              <li>
                                 <a href="#">
                                    <i className="fab fa-behance" />
                                 </a>
                              </li>
                           </ul>
                        </div>
                        <div className="navigation-area">
                           <div className="row">
                              <div className="col-lg-6 col-md-6 col-12 nav-left flex-row d-flex justify-content-start align-items-center">
                                 <div className="thumb">
                                    <a href="#">
                                       <img
                                          className="img-fluid"
                                          src="/assets/img/post/preview.png"
                                          alt=""
                                       />
                                    </a>
                                 </div>
                                 <div className="arrow">
                                    <a href="#">
                                       <span className="lnr text-white ti-arrow-left" />
                                    </a>
                                 </div>
                                 <div className="detials">
                                    <p>Prev Post</p>
                                    <a href="#">
                                       <h4>Space The Final Frontier</h4>
                                    </a>
                                 </div>
                              </div>
                              <div className="col-lg-6 col-md-6 col-12 nav-right flex-row d-flex justify-content-end align-items-center">
                                 <div className="detials">
                                    <p>Next Post</p>
                                    <a href="#">
                                       <h4>Telescopes 101</h4>
                                    </a>
                                 </div>
                                 <div className="arrow">
                                    <a href="#">
                                       <span className="lnr text-white ti-arrow-right" />
                                    </a>
                                 </div>
                                 <div className="thumb">
                                    <a href="#">
                                       <img
                                          className="img-fluid"
                                          src="/assets/img/post/next.png"
                                          alt=""
                                       />
                                    </a>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="blog-author">
                        <div className="media align-items-center">
                           <img src="/assets/img/blog/author.png" alt="" />
                           <div className="media-body">
                              <a href="#">
                                 <h4>Harvard milan</h4>
                              </a>
                              <p>
                                 Second divided from form fish beast made. Every of seas all
                                 gathered use saying you're, he our dominion twon Second
                                 divided from
                              </p>
                           </div>
                        </div>
                     </div>
                     <div className="comments-area">
                        <h4>05 Comments</h4>
                        <div className="comment-list">
                           <div className="single-comment justify-content-between d-flex">
                              <div className="user justify-content-between d-flex">
                                 <div className="thumb">
                                    <img src="/assets/img/comment/comment_1.png" alt="" />
                                 </div>
                                 <div className="desc">
                                    <p className="comment">
                                       Multiply sea night grass fourth day sea lesser rule open
                                       subdue female fill which them Blessed, give fill lesser
                                       bearing multiply sea night grass fourth day sea lesser
                                    </p>
                                    <div className="d-flex justify-content-between">
                                       <div className="d-flex align-items-center">
                                          <h5>
                                             <a href="#">Emilly Blunt</a>
                                          </h5>
                                          <p className="date">Jul 14, 2024 at 3:12 pm </p>
                                       </div>
                                       <div className="reply-btn">
                                          <a href="#" className="btn-reply text-uppercase">
                                             reply
                                          </a>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="comment-list">
                           <div className="single-comment justify-content-between d-flex">
                              <div className="user justify-content-between d-flex">
                                 <div className="thumb">
                                    <img src="/assets/img/comment/comment_2.png" alt="" />
                                 </div>
                                 <div className="desc">
                                    <p className="comment">
                                       Multiply sea night grass fourth day sea lesser rule open
                                       subdue female fill which them Blessed, give fill lesser
                                       bearing multiply sea night grass fourth day sea lesser
                                    </p>
                                    <div className="d-flex justify-content-between">
                                       <div className="d-flex align-items-center">
                                          <h5>
                                             <a href="#">Emilly Blunt</a>
                                          </h5>
                                          <p className="date">Jul 14, 2024 at 3:12 pm </p>
                                       </div>
                                       <div className="reply-btn">
                                          <a href="#" className="btn-reply text-uppercase">
                                             reply
                                          </a>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="comment-list">
                           <div className="single-comment justify-content-between d-flex">
                              <div className="user justify-content-between d-flex">
                                 <div className="thumb">
                                    <img src="/assets/img/comment/comment_3.png" alt="" />
                                 </div>
                                 <div className="desc">
                                    <p className="comment">
                                       Multiply sea night grass fourth day sea lesser rule open
                                       subdue female fill which them Blessed, give fill lesser
                                       bearing multiply sea night grass fourth day sea lesser
                                    </p>
                                    <div className="d-flex justify-content-between">
                                       <div className="d-flex align-items-center">
                                          <h5>
                                             <a href="#">Emilly Blunt</a>
                                          </h5>
                                          <p className="date">Jul 14, 2024 at 3:12 pm </p>
                                       </div>
                                       <div className="reply-btn">
                                          <a href="#" className="btn-reply text-uppercase">
                                             reply
                                          </a>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="comment-form">
                        <h4>Leave a Reply</h4>
                        <form
                           className="form-contact comment_form"
                           action="#"
                           id="commentForm"
                        >
                           <div className="row">
                              <div className="col-12">
                                 <div className="form-group">
                                    <textarea
                                       className="form-control w-100"
                                       name="comment"
                                       id="comment"
                                       cols={30}
                                       rows={9}
                                       placeholder="Write Comment"
                                       defaultValue={""}
                                    />
                                 </div>
                              </div>
                              <div className="col-sm-6">
                                 <div className="form-group">
                                    <input
                                       className="form-control"
                                       name="name"
                                       id="name"
                                       type="text"
                                       placeholder="Name"
                                    />
                                 </div>
                              </div>
                              <div className="col-sm-6">
                                 <div className="form-group">
                                    <input
                                       className="form-control"
                                       name="email"
                                       id="email"
                                       type="email"
                                       placeholder="Email"
                                    />
                                 </div>
                              </div>
                              <div className="col-12">
                                 <div className="form-group">
                                    <input
                                       className="form-control"
                                       name="website"
                                       id="website"
                                       type="text"
                                       placeholder="Website"
                                    />
                                 </div>
                              </div>
                           </div>
                           <div className="form-group">
                              <button
                                 type="submit"
                                 className="button button-contactForm btn_1 boxed-btn"
                              >
                                 Send Message
                              </button>
                           </div>
                        </form>
                     </div>
                  </div>
                  <div className="col-lg-4">
                     <div className="blog_right_sidebar">
                        <aside className="single_sidebar_widget search_widget">
                           <form action="#">
                              <div className="form-group">
                                 <div className="input-group mb-3">
                                    <input
                                       type="text"
                                       className="form-control"
                                       placeholder="Search Keyword"
                                       onfocus="this.placeholder = ''"
                                       onblur="this.placeholder = 'Search Keyword'"
                                    />
                                    <div className="input-group-append">
                                       <button className="btns" type="button">
                                          <i className="ti-search" />
                                       </button>
                                    </div>
                                 </div>
                              </div>
                              <button
                                 className="button rounded-0 primary-bg text-white w-100 btn_1 boxed-btn"
                                 type="submit"
                              >
                                 Search
                              </button>
                           </form>
                        </aside>
                        <aside className="single_sidebar_widget post_category_widget">
                           <h4 className="widget_title">Category</h4>
                           <ul className="list cat-list">
                              <li>
                                 <a href="#" className="d-flex">
                                    <p>Resaurant food</p>
                                    <p>(37)</p>
                                 </a>
                              </li>
                              <li>
                                 <a href="#" className="d-flex">
                                    <p>Travel news</p>
                                    <p>(10)</p>
                                 </a>
                              </li>
                              <li>
                                 <a href="#" className="d-flex">
                                    <p>Modern technology</p>
                                    <p>(03)</p>
                                 </a>
                              </li>
                              <li>
                                 <a href="#" className="d-flex">
                                    <p>Product</p>
                                    <p>(11)</p>
                                 </a>
                              </li>
                              <li>
                                 <a href="#" className="d-flex">
                                    <p>Inspiration</p>
                                    <p>(21)</p>
                                 </a>
                              </li>
                              <li>
                                 <a href="#" className="d-flex">
                                    <p>Health Care</p>
                                    <p>(21)</p>
                                 </a>
                              </li>
                           </ul>
                        </aside>
                        <aside className="single_sidebar_widget popular_post_widget">
                           <h3 className="widget_title">Recent Post</h3>
                           <div className="media post_item">
                              <img src="/assets/img/post/post_1.png" alt="post" />
                              <div className="media-body">
                                 <a href="#">
                                    <h3>From life was you fish...</h3>
                                 </a>
                                 <p>05 Days ago</p>
                              </div>
                           </div>
                           <div className="media post_item">
                              <img src="/assets/img/post/post_2.png" alt="post" />
                              <div className="media-body">
                                 <a href="#">
                                    <h3>The Amazing Hubble</h3>
                                 </a>
                                 <p>02 Days ago</p>
                              </div>
                           </div>
                           <div className="media post_item">
                              <img src="/assets/img/post/post_3.png" alt="post" />
                              <div className="media-body">
                                 <a href="#">
                                    <h3>Astronomy Or Astrology</h3>
                                 </a>
                                 <p>03 Days ago</p>
                              </div>
                           </div>
                           <div className="media post_item">
                              <img src="/assets/img/post/post_4.png" alt="post" />
                              <div className="media-body">
                                 <a href="#">
                                    <h3>Asteroids telescope</h3>
                                 </a>
                                 <p>01 Days ago</p>
                              </div>
                           </div>
                        </aside>
                        <aside className="single_sidebar_widget tag_cloud_widget">
                           <h4 className="widget_title">Tag Clouds</h4>
                           <ul className="list">
                              <li>
                                 <a href="#">project</a>
                              </li>
                              <li>
                                 <a href="#">love</a>
                              </li>
                              <li>
                                 <a href="#">technology</a>
                              </li>
                              <li>
                                 <a href="#">travel</a>
                              </li>
                              <li>
                                 <a href="#">restaurant</a>
                              </li>
                              <li>
                                 <a href="#">life style</a>
                              </li>
                              <li>
                                 <a href="#">design</a>
                              </li>
                              <li>
                                 <a href="#">illustration</a>
                              </li>
                           </ul>
                        </aside>
                        <aside className="single_sidebar_widget instagram_feeds">
                           <h4 className="widget_title">Instagram Feeds</h4>
                           <ul className="instagram_row flex-wrap">
                              <li>
                                 <a href="#">
                                    <img
                                       className="img-fluid"
                                       src="/assets/img/post/post_5.png"
                                       alt=""
                                    />
                                 </a>
                              </li>
                              <li>
                                 <a href="#">
                                    <img
                                       className="img-fluid"
                                       src="/assets/img/post/post_6.png"
                                       alt=""
                                    />
                                 </a>
                              </li>
                              <li>
                                 <a href="#">
                                    <img
                                       className="img-fluid"
                                       src="/assets/img/post/post_7.png"
                                       alt=""
                                    />
                                 </a>
                              </li>
                              <li>
                                 <a href="#">
                                    <img
                                       className="img-fluid"
                                       src="/assets/img/post/post_8.png"
                                       alt=""
                                    />
                                 </a>
                              </li>
                              <li>
                                 <a href="#">
                                    <img
                                       className="img-fluid"
                                       src="/assets/img/post/post_9.png"
                                       alt=""
                                    />
                                 </a>
                              </li>
                              <li>
                                 <a href="#">
                                    <img
                                       className="img-fluid"
                                       src="/assets/img/post/post_10.png"
                                       alt=""
                                    />
                                 </a>
                              </li>
                           </ul>
                        </aside>
                        <aside className="single_sidebar_widget newsletter_widget">
                           <h4 className="widget_title">Newsletter</h4>
                           <form action="#">
                              <div className="form-group">
                                 <input
                                    type="email"
                                    className="form-control"
                                    onfocus="this.placeholder = ''"
                                    onblur="this.placeholder = 'Enter email'"
                                    placeholder="Enter email"
                                    required=""
                                 />
                              </div>
                              <button
                                 className="button rounded-0 primary-bg text-white w-100 btn_1 boxed-btn"
                                 type="submit"
                              >
                                 Subscribe
                              </button>
                           </form>
                        </aside>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         {/*================ Blog Area end =================*/}




         <Footer />

      </main>



   );

}

export default LatinoDanceMoscow;

