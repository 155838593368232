


import React, { useEffect } from 'react';

const AdFullscMob = () => {
  useEffect(() => {
    window.yaContextCb = window.yaContextCb || [];
    window.yaContextCb.push(() => {
      window.Ya.Context.AdvManager.render({
        blockId: "R-A-9762127-2",
        type: "fullscreen",
        platform: "touch"
      });
    });
  }, []);

  return (
    <div id="yandex_rtb_R-A-9762127-2"></div>
  );
};

export default AdFullscMob;

/* import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const AdFullscMob = ({ blockId }) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const interval = setInterval(() => {
        if (window.Ya && window.Ya.Context && window.Ya.Context.AdvManager) {
          clearInterval(interval);
          window.yaContextCb.push(() => {
            window.Ya.Context.AdvManager.render({
              blockId: blockId,
              type: 'fullscreen',
              platform: 'touch',
            });
          });
        }
      }, 100);
      return () => clearInterval(interval);
    }
  }, [blockId]);

  return null; 
};

AdFullscMob.propTypes = {
  blockId: PropTypes.string.isRequired,
};

export default AdFullscMob; */